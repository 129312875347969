import { ApiService } from "./ApiService";
import LoginData from "../types/LoginData";
import AuthenticationResponse from "../types/AuthenticationResponse";
import { KnownUser } from "../types/Helper";

const baseUrl = `${process.env.AUTH_PROXY ?? process.env.REACT_APP_AUTH_PROXY}`;

class LoginService extends ApiService {
  /** Jwt Token delivered via request body */
  async login(loginData: LoginData): Promise<AuthenticationResponse> {
    const response = await fetch(baseUrl + "login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    });
    const loginResponse = await this.apiResponseHandler(response);
    return loginResponse.json();
  }

  async validateToken(authToken: string): Promise<boolean> {
    const response = await fetch(baseUrl + `login/validate`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });
    const isValid = await this.apiResponseHandler(response);
    return await isValid.json();
  }

  async logout(): Promise<Response> {
    const response = await fetch(baseUrl + "login/logout", {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await this.apiResponseHandler(response);
  }

  async forgotPassword(resetData: string): Promise<KnownUser> {
    const response = await fetch(baseUrl + "register/forgot-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: resetData,
    });
    const loginResponse = await this.apiResponseHandler(response);
    return loginResponse.json();
  }
}

const loginService = new LoginService();
export default loginService;
