export const formatNumberWithCommas = (number: number = 0) => {
	// Convert the number to a string
	let numberString = number?.toString();

	// Check if the number has a decimal part
	if (numberString.includes('.')) {
		// Split the string into integer and decimal parts
		let parts = numberString.split('.');

		// Add commas to the integer part
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

		// Shorten the decimal part to have a length of 2
		parts[1] = parts[1].substring(0, 2);

		// Join the integer and decimal parts back together
		return parts.join('.');
	} else {
		// Add commas to the whole number part
		return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
};
