import React from 'react';

const PatientSvg = () => {
	return (
		<svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M10.5016 0.378418C9.28258 0.378418 7.57987 0.864358 6.3465 2.09773L7.81135 4.76657C8.13145 4.35921 8.53997 4.02998 9.00604 3.80375C9.47211 3.57752 9.98351 3.46022 10.5016 3.46072C11.606 3.46072 12.5887 3.98242 13.2148 4.7937L14.6515 2.03642C13.5842 0.870105 11.6701 0.378418 10.5016 0.378418ZM11.5871 2.33814H10.9051V3.01948H10.0859V2.33814H9.4039V1.5192H10.0859V0.834985H10.9051V1.5192H11.5871V2.33814ZM7.03614 14.1479H7.71078L6.7472 17.4505H14.2761L13.317 14.1479H13.9922L14.9574 17.4505H17.1729L15.6608 12.4468C15.4245 11.6208 14.3888 10.1665 12.6015 10.1224L8.49237 10.1189C6.66451 10.1279 5.60706 11.6106 5.36792 12.4465L3.82645 17.4502H6.0732L7.03614 14.1479ZM0.644531 18.5035H20.3535V20.3786H0.644531V18.5035Z'
				fill='black'
			/>
		</svg>
	);
};

export default PatientSvg;
