import { OfferedServiceDto } from "../types/services/ServiceTypes";
import { ApiService } from "./ApiService";
const baseUrl = `${process.env.ACC_PROXY ?? process.env.REACT_APP_ACC_PROXY}`;

class ServicesOffered extends ApiService {
  createService = async (payload: OfferedServiceDto) => {
    const response = await fetch(`${baseUrl}offered-services`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const createServiceResponse = await this.apiResponseHandler(response);
    return await createServiceResponse.json();
  };

  getListOfServicesOffered = async (hospitalId: number): Promise<OfferedServiceDto[]> => {
    const response = await fetch(`${baseUrl}offered-services/${hospitalId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const getServicesResponse = await this.apiResponseHandler(response);
    return await getServicesResponse.json();
  };
}

const servicesOffered = new ServicesOffered();
export default servicesOffered;
