import { Route, Routes } from "react-router-dom";
import React, { useState } from "react";
import "./Dashboard.scss";
import { SideBar } from "../../modules/sidebar/SideBar";
import { Patient } from "../customer/newpatient/Patient";
import { Sales } from "../sales/Sales";
import { UserCtx } from "../../context/UserCtx";
import WhoAmI from "../../userAuth/WhoAmI";
import { ErrorPage } from "../error/ErrorPage";
import { ServicePage } from "../service/ServicePage";
import { ManageAccount } from "../manageaccount/ManageAccount";
import { Notifications } from "../notifications/Notifications";
import { PatientProfile } from "../customer/patientprofile/PatientProfile";
import { Admission } from "../admission/Admission";
import { Payments } from "../../modules/payments/Payments";
import { Appointment } from "../appointment/Appointment";
import { PatientComponent } from "../customer/patientcomponent/PatientComponent";
import { getLoggedInUser } from "../../util/Helpers";
import { DashboardComponent } from "./DataComponent/DashboardComponent";
import { Department } from "../department/Department";
import { DashboardProvider } from "../../context/DashboardCtx";
import Billing from "../billing/Billing";
import { MedicalHistory } from "../customer/MedicalHistory/MedicalHistory";
import AdmissionList from "../admission/admissionlist/AdmissionList";

export const Dashboard = () => {
  const loggedUser = getLoggedInUser();
  const [whoAmI, setWhoAmI] = useState<WhoAmI>(loggedUser ?? ({} as WhoAmI));

  return (
    <UserCtx value={whoAmI}>
      <DashboardProvider>
        <div>
          {whoAmI?.userId > 100000 ? (
            <div className={"dashboard-container"}>
              <SideBar />
              <main className={"dashboard-main-wrapper"}>
                <Routes>
                  <Route index element={<DashboardComponent />} />

                  {/*Sales routes*/}
                  <Route path={"/sales"} element={<Sales />} />

                  {/*Patient routes*/}
                  <Route path={"/patients"} element={<Patient />} />
                  <Route path={"/patients/profile"} element={<PatientProfile />} />
                  <Route path={"/patients/profile/edit"} element={<PatientComponent isNewCustomer={false} />} />
                  <Route path={"/patients/admission"} element={<Admission />} />
                  <Route path={"/patients/payments"} element={<Payments />} />
                  <Route path={"/patients/medical-history"} element={<MedicalHistory />} />

                  {/*ServicePage routes*/}
                  <Route path={"/services"} element={<ServicePage />} />

                  {/*Departments routes*/}
                  <Route path={"/departments"} element={<Department />} />

                  {/*Appointments routes*/}
                  <Route path={"/appointments"} element={<Appointment />} />

                  {/*Appointments routes*/}
                  <Route path={"/admission"} element={<AdmissionList/>} />


                  {/*Purchases routes*/}
                  <Route path={"/purchases"} element={<>Purchases Page</>} />

                  {/*Notifications routes*/}
                  <Route path={"/notifications"} element={<Notifications />} />

                  {/*Account management routes*/}
                  <Route path={"/manage-account"} element={<ManageAccount />} />

                  {/*Billing routes*/}
                  <Route path={"/billing"} element={<Billing />} />

                  {/*Fallback routes*/}
                  <Route
                    path={"/*"}
                    element={
                      <ErrorPage
                        message={"You are not supposed to be here !!!"}
                      />
                    }
                  />
                </Routes>
              </main>
            </div>
          ) : (
            <ErrorPage message={"You are not supposed to be here !!!"} />
          )}
        </div>
      </DashboardProvider>
    </UserCtx>
  );
};
