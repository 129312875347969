import React, {useState} from "react";
import './Checkbox.scss'
import {FieldErrors, FieldValues, UseFormRegister} from "react-hook-form";
import {BsInfo} from "react-icons/bs";

interface CheckBoxProps extends React.HTMLProps<HTMLInputElement> {
    id: string,
    name: string,
    onClick?: any,
    checked: boolean
    label: string
    onChange: any
    flipLabel?: boolean
    register?: UseFormRegister<FieldValues>
    errors: FieldErrors,
    withHint?: string
}

export const Checkbox = (props: CheckBoxProps) => {
    const register = props.register as UseFormRegister<FieldValues>;
    const error = props.errors && props.errors[props.name as string];

    return (
        <div className={"checkbox-component-layout"}>
            <div className={"checkbox-container"}>
                {
                    props.flipLabel &&
                    <>
                        <label className={"align-label-to-box"}>
                            <b>{props.label}</b>
                        </label>
                        {
                            props.withHint  &&
                            <span>
                                <BsInfo style={{fontSize: '20px'}} className={'unset-disable-section'}
                                        title={props.withHint}/>
                                </span>
                        }
                    </>

                }
                <input className={"format-checkbox"}
                       id={props.id}
                       type={"checkbox"}
                       checked={props.checked}
                       disabled={props.disabled}
                       {...register(props.name, {
                           required: props.required,
                       })}
                    onChange={props.onChange}
                    name={props.name}
                />

                {
                    !props.flipLabel && <>
                        <label className={"align-label-to-box"}>
                            <b>{props.label}</b>
                        </label>
                        {
                            props.withHint  &&
                            <span>
                                <BsInfo style={{fontSize: '20px'}} className={'unset-disable-section'}
                                        title={props.withHint}/>
                                </span>
                        }
                    </>
                }
            </div>
        </div>
    )
}