import React from 'react';
import './Notification.scss';

const notifications = [
	{
		id: 1,
		type: 'invoice',
		details: 'The invoice receipt for the customer.',
		time: '09:45',
		date: '23-12-2023',
		open: false,
	},
	{
		id: 2,
		type: 'New expenditure',
		details: 'New list of products have been updated by the Admin.',
		time: '09:45',
		date: '23-12-2023',
		open: true,
	},
	{
		id: 3,
		type: 'New Services Created',
		details: 'The invoice receipt for the customer.',
		time: '09:45',
		date: '23-12-2023',
		open: true,
	},
	{
		id: 4,
		type: 'Account debited',
		details: 'The invoice receipt for the customer.',
		time: '09:45',
		date: '23-12-2023',
		open: false,
	},
	{
		id: 5,
		type: 'invoice',
		details: 'The invoice receipt for the customer.',
		time: '09:45',
		date: '23-12-2023',
		open: true,
	},
];
const Notification = () => {
	return (
		<div className='notification_container'>
			<section>
				<h2>Notifications</h2>
				<ul className='notification_list'>
					{notifications.map((item) => (
						<li key={item.id} className='notification_item'>
							<span data-open={item.open} className='notification_type'>
								{item.type}
							</span>
							<div className='notification_details'>
								<p className=''>{item.details}</p>
								<div className='date_time'>
									<p>time {item.time}</p>
									<p>date {item.date}</p>
								</div>
							</div>
						</li>
					))}
				</ul>
			</section>
		</div>
	);
};

export default Notification;
