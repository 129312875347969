import React from "react";
import "./TodaySalesAndServiceReport.scss";
import SalesAndServiceIcon from "../../../../resources/assets/svg/Dashboard/SalesAndServiceIcon";
import ServicesTable from "./ServicesTable";
import SalesIcon from "../../../../resources/assets/svg/Dashboard/SalesIcon";
import useDashboardHook from "../../../../customhook/useDashboardHook";
import { formatNumberWithCommas } from "../../../../util/numberFormatter";
import InputDate from "../../../../components/Date-Time/InputDate/InputDate";
import { useForm } from "react-hook-form";
import { formatDateDDMMYYYY } from "../../../../util/Helpers";

const TodaySalesAndServiceReport = () => {
  const { salesServices, fetchDataRepresentation } = useDashboardHook();

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    const fromDate = `${date} 00:00:00`;
    const toDate = `${date} 23:59:59`;
    fetchDataRepresentation(fromDate, toDate);
  };

  const {
    register,
    trigger,
    formState: { isValid, errors },
  } = useForm({ mode: "onBlur" });

  return (
    <div className="sale_service_container">
      <h2 className="header">Today&apos;s Sales and Service Report</h2>
      <hr />
      <section className="today_sales_container">
        <div className="sales_wrapper">
          <span>
            <SalesIcon />
          </span>
          <div className="sales_contents">
            <h4 className="today_Sales_title">Total Sales</h4>
            <h2 className="today_Sales_amount">
              ₦ {formatNumberWithCommas(salesServices?.todayTotal)}
            </h2>
            <p className="today_Sales_increment">
              {`${
                (salesServices?.lossGainPercentage as number) < 0 ? "" : "+"
              }${salesServices?.lossGainPercentage || 0}`}
              % from yesterday
            </p>
          </div>
        </div>
        <div className="date-container">
          {/* <input type='date' className='date_input' onChange={handleDateChange} /> */}
          <InputDate
            id="dashboardDate"
            name="date"
            label="Date"
            defaultValue={formatDateDDMMYYYY(new Date(), true)}
            onChange={handleDateChange}
            errors={errors}
            placeholder="00-00-1900"
            register={register}
            // required="date is required"
            pattern={{
              value: /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(19|20)\d\d$/,
              message: "Date format allowed is dd-mm-yyyy, e.g: 20-03-2024",
            }}
          />
        </div>
      </section>
      <section className="today_services_container">
        <h2>
          <SalesAndServiceIcon />
          latest sales and services
        </h2>
        <ServicesTable
          rowData={
            salesServices?.listOfTodaySales
              ?.map((item) => item.selectedServices)
              .flat() || []
          }
        />
      </section>
      <section className="used_items_container">
        <header className="used_items_header">Used items</header>
      </section>
    </div>
  );
};

export default TodaySalesAndServiceReport;
