import {useEffect, useState} from "react";
import InvoiceDao from "../../types/invoice/InvoiceDao";
import './Payments.scss'
import paymentService from "../../services/PaymentService";
import {useLocation} from "react-router-dom";
import PatientDao from "../../types/PatientDao";
import {Invoice} from "../invoice/Invoice";
import {MdAdd} from "react-icons/md";
import {Authorities} from "../../userAuth/WhoAmI";
import {useWhoAmI} from "../../context/UserCtx";
import {ErrorNotification} from "../errornotification/ErrorNotification";
import {ActionType, DashboardTopNav} from "../../components/dashboardnav/DashboardTopNav";
import {userHasRole} from "../../util/UserRolesHelper";

type PaymentsStates = 'Payment History' | 'New Invoice';

export const Payments = () => {
	//TODO: integrate: --> 'New Invoice' | 'Edit Invoice' | 'Cancel Invoice' | 'Refund Invoice'

    const [currentPaymentState, setCurrentPaymentState] = useState<PaymentsStates>('Payment History');
    const [patientPayments, setPatientPayments] = useState<InvoiceDao>();
    const [paymentError, setPaymentError] = useState<string>()
    const location = useLocation();
    const {patientDao} = location?.state?.patient || {} as PatientDao;
    const cartItemCount = patientPayments?.selectedServices?.length ?? 0;
    const rolesAllowedAccess: Authorities[] = [Authorities.CASHIER, Authorities.ADMIN, Authorities.SUPER_ADMIN]
    const user = useWhoAmI();

	const patientPaymentsHandler = async () => {
		paymentService
			.getPatientLatestPayment(user.hospital.id, patientDao?.id)
			.then((response) => {
				setPatientPayments(response);
			})
			.catch(() => {
				setPaymentError('Something went wrong getting patient latest payment, please try again later!');
			});
	};

    useEffect(() => {
        if(!userHasRole(user, rolesAllowedAccess)) {
            setPaymentError('You are not supposed to be here, please contact your administrator');
            return
        }

		if (currentPaymentState === 'Payment History') {
			patientPaymentsHandler().then();
			setCurrentPaymentState('Payment History');
			setPaymentError('');
		}
	}, []);

	const headLinerActionHandler = (action: ActionType) => {
		setCurrentPaymentState(action?.name as PaymentsStates);
	};

	return (
		<div className={'payments-component'}>
			{!paymentError && (
				<>
					<div style={{ display: 'flex', justifyContent: 'space-around' }}>
						{/*<Button id={'new-invoice-btn'}
                        name={'new-invoice-btn'}
                        buttonName={'Create New Invoice'}
                        onClick={() => setCurrentPaymentState('New Invoice')}
                        className={currentPaymentState == 'New Invoice' ? '' : 'active-state-btn'}
                />

                <Button id={'payment-btn'}
                        name={'payment-btn'}
                        buttonName={'Payment History'}
                        onClick={() => setCurrentPaymentState('Payment History')}
                        className={currentPaymentState == 'Payment History' ? '' : 'active-state-btn'}
                />*/}

						<DashboardTopNav selectedAction={currentPaymentState}
							action={[
								{ name: 'Payment History', icon: '' },
								{ name: 'New Invoice', icon: <MdAdd /> },
							]}
							getClickedAction={headLinerActionHandler}
							getSearchResult={(e: any) => e}
							placeholder={'search payment with invoice number or date'}
							itemList={['Payment History', 'New Invoice']}
							hideSearchBar={currentPaymentState === 'New Invoice'}
						/>

						{/*<div className={'payment-cart'}>
                    <strong>
                        <BiCart /> <i title={'contains all services the patient received in the hospital'}>Cart</i>
                        <sup>
                            <span className={'cart-items-count'} title={'Number of items in the Cart'}>
                                {cartItemCount}
                            </span>
                        </sup>
                    </strong>
                </div>*/}
					</div>

					<i className={'patient-current-payment-state'}>
						<h1>{currentPaymentState}</h1>
					</i>

					{currentPaymentState === 'Payment History' && (
						<div className={'patient-payment-history-section'} style={{ margin: '10px 0 0 0' }}>
							<div className={'patient-payment-box'}>
								<div className={'patient-payment-headline'}>
									<p>invoiceNumber</p>
									<p>createdDate</p>
									<p>balance</p>
									<p>amountPaid</p>
								</div>

								{patientPayments && patientPayments?.selectedServices?.length > 0 && (
									<div className={'patient-payment-headline'}>
										<p>{patientPayments?.invoiceNumber}</p>
										<p>{patientPayments?.createdDate}</p>
										<p>{patientPayments?.balance}</p>
										<p>{patientPayments?.amountPaid}</p>

										{/*//This section and all details of the one clicked should open in Modal*/}
										{/*{
                                        item.selectedServices && item.selectedServices.length > 0 && item.selectedServices.map((ser) => (
                                            <div className={'payment-service-list-wrapper'}>
                                                <p>{ser.description}</p>
                                                <p>{ser.unitPrice}</p>
                                                <p>{ser.quantity}</p>
                                                <p>{ser.price}</p>
                                            </div>
                                        ))
                                    }*/}
									</div>
								)}
							</div>
						</div>
					)}

					{currentPaymentState === 'New Invoice' && (
						<div style={{ margin: '10px 0 0 0' }}>
							<Invoice />
						</div>
					)}
				</>
			)}

			{paymentError && <ErrorNotification name={'payment-error'} stringErrors={paymentError} />}
		</div>
	);
};
