import { SalesDOTResponse } from '../types/Dashboard/DashboardModels';
import { ApiService } from './ApiService';
import { PaymentState } from "../pages/admission/Admission";
import { SalesOverviewDTO } from '../types/sales/SalesOverviewDTO';
import { salesOverview } from '../mockeddata/SalesOverviewDataMock';

const baseUrl = `${process.env.ACC_PROXY ?? process.env.REACT_APP_ACC_PROXY}sales`;

class SalesService extends ApiService {
	async fetchSalesDOT(hospitalId: number, fromDate: string, toDate: string): Promise<SalesDOTResponse> {
		const response = await fetch(`${baseUrl}/${hospitalId}/today-sales?from=${fromDate}&to=${toDate}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});

		const data = await this.apiResponseHandler(response);
		return await data.json();
	}

	async admissionFeeIsPaid(hospitalId: number, patientId: number): Promise<PaymentState> {
		const response = await fetch(baseUrl + `/${hospitalId}/${patientId}/admission-fee`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			}
		}
		)
		const paid = await this.apiResponseHandler(response);
		return await paid.json();
	}

	async consultationFeeIsPaid(hospitalId: number, patientId: number): Promise<PaymentState> {
		const response = await fetch(baseUrl + `/${hospitalId}/${patientId}/consultation-fee`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			}
		}
		)
		const paid = await this.apiResponseHandler(response);
		return await paid.json();
	}
	getCloseCounter = async (): Promise<SalesOverviewDTO> => {
		return salesOverview;
	}
}

const salesService = new SalesService();
export default salesService;
