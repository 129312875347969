import './Patient.scss';
import { useEffect, useState } from 'react';
import { Spinner } from '../../../components/spinner/Spinner';
import { Pagination } from '../../../components/pagination/Pagination';
import PatientDao from '../../../types/PatientDao';
import { MdAdd, MdQueue } from 'react-icons/md';
import { PatientComponent } from '../patientcomponent/PatientComponent';
import { AdmissionQueue } from '../../admission/admissionqueue/AdmissionQueue';
import { useWhoAmI } from '../../../context/UserCtx';
import customerService from '../../../services/PatientService';
import { ActionType, DashboardTopNav } from '../../../components/dashboardnav/DashboardTopNav';
import {BsList} from "react-icons/bs";
import {Modal} from "../../../components/modal/Modal";
import {ErrorNotification} from "../../../modules/errornotification/ErrorNotification";
import {userIsAdmin, userIsDoctor, userIsReceptionist, userIsSuperAdmin} from "../../../util/UserRolesHelper";

export type DialogPageType = '' | 'Generate Invoice' | 'Continue To Profile' | 'Back To Invoice'
	| 'Save' | 'Saved' | 'Print' | 'Send To Email' | 'Send Email' | 'To Dashboard' | 'Email Sent' | 'SummaryModal';

export type PatientActions = 'Patient List' | 'Admission Queue' | 'Add Patient';

export const Patient = () => {
	const baseUrl = `${process.env.ACC_PROXY ?? process.env.REACT_APP_ACC_PROXY}`;
	const [listOfPatients, setListOfPatients] = useState<PatientDao[]>([]);
	const [loading, setLoading] = useState(true);
	const [searchResult, setSearchResult] = useState<PatientDao[]>();
	const [clickedAction, setClickedAction] = useState<PatientActions>('Patient List');
	const [enableFilter, setEnableFilter] = useState<boolean>(false);
	const [openModal, setOpenModal] = useState<boolean>(true);
	const user = useWhoAmI();
	const [patientError, setPatientError] = useState('');

	useEffect(() => {
		if (user?.hospital?.id) {
			setLoading(true);
			customerService.getPatientList(user?.hospital?.id).then((patients) => {
				setListOfPatients(patients);
			}).catch(() => { }).finally(() => setLoading(false));
		}
	}, [user?.hospital?.id, patientError]);

	const getSearchResult = (result: PatientDao[]): PatientDao[] => {
		setSearchResult(result);
		if (result && result?.length < 1) {
			setPatientError('No registered patient found')
		}
		return result;
	};

	const getSearchPatientError = (searchErrMsg: string) => {
		setLoading(true)
		setPatientError(searchErrMsg)
		return searchErrMsg;
	}

	const getHeadLinerAction = (actResult: ActionType) => {
		switch (actResult?.name.toLowerCase()) {
			case 'patient list':
				setClickedAction('Patient List');
				break;

			case 'add patient':
				setClickedAction('Add Patient');
				break;

			case 'admission queue':
				setClickedAction('Admission Queue');
				setOpenModal(true);
				break;

			default:
				setClickedAction('Patient List');
				break;
		}
	};

	const getNavs = () => {
		const navsArr = [];
		navsArr.push({ name: 'Patient List', icon: <BsList /> })

		if (userIsSuperAdmin(user) || userIsAdmin(user) || userIsReceptionist(user) || userIsDoctor(user)) {
			navsArr.push({ name: 'Admission Queue', icon: <MdQueue /> })
		}

		if (userIsSuperAdmin(user) || userIsAdmin(user) || userIsReceptionist(user)) {
			navsArr.push({ name: 'Add Patient', icon: <MdAdd /> })
		}
		return navsArr;
	}

	return (
		<div className={'patient-component'}>
			{!loading && (
				<>
					{
						patientError &&
						<ErrorNotification
							name={'patient-error'}
							stringErrors={patientError}
							onClose={() => window.location.reload()}
						/>
					}

					{user && user?.authorities.length > 0 && (
						<>
							<DashboardTopNav selectedAction={clickedAction}
								action={getNavs()}
								placeholder={'search patient with id or dob+lastname'}
								itemList={searchResult ?? listOfPatients}
								getSearchResult={getSearchResult}
								getClickedAction={getHeadLinerAction}
								hideSearchBar={clickedAction !== 'Patient List'}
								searchEndpoint={{ endpoint: `${baseUrl}patient/${user.hospital.id}/search?sq=` }}
								getSearchError={getSearchPatientError}
							/>

							{clickedAction === 'Patient List' &&
								((searchResult && searchResult?.length > 0) || (listOfPatients && listOfPatients.length > 0)) && (
									<div className={'patient-accordion-wrapper'}>
										{enableFilter && <p>Filter slider from the right side</p>}

										<Pagination itemList={searchResult ?? listOfPatients} itemsPerPage={20} />
									</div>
								)}
						</>
					)}

					{clickedAction === 'Add Patient' && <PatientComponent isNewCustomer={true} onClickCancel={setClickedAction} />}

					{clickedAction === 'Admission Queue' && <div>
						<Modal
							isOpen={openModal}
							modalTitle={'Title'}
							onClose={() => {
								setClickedAction('Patient List');
								setOpenModal(false);
							}}
						>
							<AdmissionQueue />
						</Modal>
					</div>}
				</>
			)}

			{loading && <Spinner id={'patient-page-spinner'} />}
		</div>
	);
};
