import React from "react";

const AdmissionIcon = () => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.11523 4H3.11523V3H6.11523V21H5.11523V4ZM7.88523 21V3H18.8852V21H7.88523ZM8.88523 11.794C9.14657 11.534 9.44723 11.3367 9.78723 11.202C10.1272 11.0673 10.4932 11 10.8852 11H15.8852C16.2772 11 16.6432 11.0673 16.9832 11.202C17.3232 11.3367 17.6239 11.534 17.8852 11.794V4H8.88523V11.794ZM13.3852 9C12.9632 9 12.6076 8.85533 12.3182 8.566C12.0296 8.27733 11.8852 7.922 11.8852 7.5C11.8852 7.078 12.0296 6.72267 12.3182 6.434C12.6076 6.14467 12.9632 6 13.3852 6C13.8066 6 14.1619 6.14467 14.4512 6.434C14.7406 6.72267 14.8852 7.078 14.8852 7.5C14.8852 7.922 14.7406 8.27733 14.4512 8.566C14.1619 8.85533 13.8066 9 13.3852 9ZM8.88523 20H17.8852V14C17.8852 13.45 17.6892 12.9793 17.2972 12.588C16.9052 12.1967 16.4346 12.0007 15.8852 12H10.8852C10.3352 12 9.86423 12.196 9.47223 12.588C9.08023 12.98 8.88457 13.4507 8.88523 14V20ZM12.8852 18.5H13.8852V16.5H15.8852V15.5H13.8852V13.5H12.8852V15.5H10.8852V16.5H12.8852V18.5Z" fill="black"/>
</svg>

};

export default AdmissionIcon;
