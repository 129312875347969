import { SalesOverviewDTO } from "../types/sales/SalesOverviewDTO";

export const salesOverview: SalesOverviewDTO = {
  bankTransfer: 24300,
  cash: 0.00,
  date: "15/06/2024",
  installment: 0.00,
  paid: 393636,
  totalBalance: 923,
  totalInstallment: 4923,
  totalPaid: 34923,
  totalWaived: 4923,
  waived: 0.00,
  POS: 21363,
};
