
export type Month =
    'JAN' | 'JANUARY'
    | 'FEB' | 'FEBRUARY'
    | 'MAR' | 'MARCH'
    | 'APR' | 'APRIL'
    | 'MAY'
    | 'JUN' | 'JUNE'
    | 'JUL' | 'JULY'
    | 'AUG' | 'AUGUST'
    | 'SEP' | 'SEPTEMBER'
    | 'OCT' | 'OCTOBER'
    | 'NOV' | 'NOVEMBER'
    | 'DEC' | 'DECEMBER'

export const getAllMonths = (): Month[] => {
    return [
        'JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY',
        'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'
    ]
}

export type MonthRecords = {
    id?: number
    month: Month
    files: File[]
}

export type OldMedicalRecords = {
    year: number,
    months: MonthRecords[]
}

export const OldMedicalRecordsMock: OldMedicalRecords = {
    year: 2024,
    months: [
        {
            month: 'MAY',
            files: []
        }
    ]
}

export const OldMedicalRecordsMock2: OldMedicalRecords = {
    year: 2022,
    months: [
        {
            month: 'APRIL',
            files: []
        },
        {
            month: 'MAY',
            files: []
        }
    ]
}