import React, {useEffect, useState} from 'react';
import './Button.scss';
import classNames from 'classnames';

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
    id: string;
    name?: string;
    buttonName: string;
    onClick?: any;
    disabled?: boolean;
    buttonNameColor?: string;
    backgroundColor?: string;
}

export const Button = (props: ButtonProps) => {
    const [buttonIsClicked, setButtonIsClicked] = useState<boolean>(false);

	useEffect(() => {
		setButtonIsClicked(false)
	}, []);

    const buttonClickHandler = () => {
		setButtonIsClicked(true);
		props?.onClick && props?.onClick();
    };

    return (
        <div className={'button-component'}>
            <button
                className={classNames(props.className)}
                id={props.id} name={props.name}
                disabled={props?.disabled ?? buttonIsClicked}
                onClick={buttonClickHandler}
                onMouseLeave={() => setButtonIsClicked(false)}
            >
                <i className={buttonIsClicked ? 'loading-spinner' : ''}/>
                <b style={{color: props.buttonNameColor, paddingLeft: '5px', whiteSpace:'nowrap'}}>{props.buttonName}</b>
            </button>
            {
                props.label &&
                <label className={'button-component-label'}>{props.label}</label>
            }
        </div>
    );
};
