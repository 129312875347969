
export enum ParamsEnum {
    /** Application Paths */
    HOME = '/',
    DASHBOARD = '/dashboard',
    LOGIN = '/login',
    HOSPITAL = '/dashboard/hospital',
    PATIENTS = '/dashboard/patients',
    PATIENT_PROFILE = '/dashboard/patients/profile',
    EDIT_PATIENT = '/dashboard/patients/profile/edit',
    NEW_PATIENT = '/dashboard/patients/new',
    ADMIT_PATIENT = '/dashboard/patients/admission?admissionType=clinic',
    PAYMENTS = '/dashboard/patients/payments',
    ADMISSION = '/dashboard/admission',
    MANAGE_ACCOUNT = '/dashboard/manage-account',

    /** Authentication */
    ACCESS_TOKEN = 'A_T',
    REFRESH_TOKEN = "R_T",

    /** url query param */
    ADMISSION_TYPE = 'admissionType',

    /** Appointment */
    APPOINTMENTS = '/dashboard/appointments'
}