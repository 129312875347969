import React from "react";

const StaffsIcon = () => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_3352_23261)">
<path d="M11.5585 5.34448H12.2931V4.73361H12.9044V3.99936H12.2931V3.38623H11.5585V3.99936H10.9473V4.73361H11.5585V5.34448Z" fill="black"/>
<path d="M20.6742 0.252441H3.20897C1.49747 0.252441 0.105469 1.64482 0.105469 3.35669V20.8193C0.105469 22.5312 1.49784 23.9239 3.20897 23.9239H20.6742C22.3857 23.9239 23.7777 22.5316 23.7777 20.8193V3.35669C23.7777 1.64519 22.3857 0.252441 20.6742 0.252441ZM11.9315 2.97682C12.9792 2.97682 14.6952 3.41744 15.6522 4.46294L14.3641 6.93494C14.0774 6.56265 13.7089 6.26125 13.2872 6.05402C12.8655 5.8468 12.4017 5.7393 11.9318 5.73982C11.4673 5.73927 11.0088 5.8444 10.5909 6.04723C10.173 6.25006 9.80678 6.54529 9.51984 6.91057L8.20659 4.51807C9.31247 3.41257 10.8383 2.97682 11.9315 2.97682ZM14.2921 8.71582C14.2916 9.34246 14.0422 9.94325 13.5989 10.3861C13.1555 10.8289 12.5544 11.0775 11.9277 11.0772C11.3014 11.0772 10.7008 10.8284 10.258 10.3856C9.81513 9.94272 9.56634 9.34209 9.56634 8.71582C9.56634 8.08954 9.81513 7.48892 10.258 7.04607C10.7008 6.60323 11.3014 6.35444 11.9277 6.35444C12.5544 6.35414 13.1555 6.60274 13.5989 7.04556C14.0422 7.48838 14.2916 8.08917 14.2921 8.71582ZM7.32909 13.7959C7.54359 13.0463 8.49122 11.7169 10.1303 11.7091L13.8143 11.7124C15.4167 11.7518 16.3452 13.0553 16.5571 13.7963L17.9127 18.2821H15.9263L15.0612 15.3214H14.456L15.3158 18.2821H8.56584L9.42947 15.3214H8.82459L7.96134 18.2821H5.94684L7.32909 13.7959ZM20.7642 20.9071H3.09459V19.2259H20.7638L20.7642 20.9071Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_3352_23261">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

};

export default StaffsIcon;
