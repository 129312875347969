// Table.tsx
import React from 'react';
import './Table.scss';

interface TableRow {
	invoice: number;
	salesTitle: string;
	salesDate:Date| string;
	patientName: string;
	phone: string;
	modeOfPayment: string;
	cost: string;
	balance: string;
}

interface TableProps {
	rowData: TableRow[];
}

const Table: React.FC<TableProps> = ({ rowData }) => {
	return (
    <section className="table-container">
      <table className="custom-table">
        <thead>
          <tr>
            <th>Invoice</th>
            <th className="title_date">
              <p>Sales Title</p>
              <p>Sales Date</p>
            </th>
            <th className="name_phone">
              <p>Patient ID</p>
            </th>
            <th>Mode of Payment</th>
            <th>
              <p>Cost (&#8358;)</p>
              <p>Balance (&#8358;)</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {rowData.map((row, index) => (
            <tr key={index}>
              <td className="invoice">{row.invoice}</td>
              <td>
                <p>{row.salesTitle}</p>
                <p>{row.salesDate as string}</p>
              </td>
              <td>
                <p>{row.patientName}</p>
                <p>{row.phone}</p>
              </td>
              <td>{row.modeOfPayment}</td>
              <td>
                <p>{row.cost}</p>
                <p>{row.balance}</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default Table;
