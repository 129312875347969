import React, {useEffect, useRef, useState} from "react";
import './ErrorNotification.scss'
import {FieldErrors} from "react-hook-form";
import {BiError} from "react-icons/bi";
import {Button} from "../../components/button/Button";

interface ErrorNotificationProps {
    name: string
    fieldErrors?: FieldErrors
    stringErrors?: string
    //withBackBtn?: boolean
    onClose?(): any
}

export const ErrorNotification = (props: ErrorNotificationProps) => {
    const errorRef = useRef<HTMLDivElement>(null)
    let fieldErrors = props.fieldErrors && props.fieldErrors[props.name as string];
    let error: any
    const [notError, setNotError] = useState<string>(props.stringErrors || '') // Todo: refactor

    const executeScroll = () => errorRef?.current?.scrollIntoView()

    const [isError, setIsError] = useState<boolean>(error !== undefined)

    const closeErrorHandler = () => {
        setIsError(false)
        setNotError('')
        error = undefined;
        fieldErrors = undefined;
        props.onClose && props.onClose();
    }

    useEffect(() => {
        if (props.fieldErrors && props.fieldErrors[props.name as string]?.message) {
            error = fieldErrors?.message
            setNotError(String(fieldErrors?.message) || props.stringErrors || '')
        }

        setIsError(!!props.stringErrors)
        executeScroll();
    },[props.stringErrors, notError, isError])

    return (
        <div className={"error-notification-component"} ref={errorRef}>
            {
                isError && notError &&
                <div className={'error-notification'}>
                    {
                        <span className={'error-notification-icon-label-wrapper'}>
                            <BiError size={'25px'} color={'red'}/>
                            <label>{notError}</label>
                            <button onClick={() => closeErrorHandler()}>&times;</button>
                        </span>
                    }
                </div>
            }

            {/*{
                props.withBackBtn && (
                    <div className={'error-notification-component-back-btn'}>
                        <Button id={'go-back'} name={'go-back'}
                                buttonName={'Go Back'}
                                onClick={() => window.history.back()}
                        />
                    </div>
                )
            }*/}
        </div>
    )
}