import { ApiService } from "./ApiService";
import NewPatientData from "../types/NewPatientData";
import PatientDao from "../types/PatientDao";

const baseUrl = `${process.env.ACC_PROXY ?? process.env.REACT_APP_ACC_PROXY}`;

class PatientService extends ApiService {
  async createPatient(patientData: NewPatientData): Promise<PatientDao> {
    const response = await fetch(baseUrl + "patient", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      //credentials: "include", //enable when we integrate cookies
      body: JSON.stringify(patientData),
    });
    const arrOfServicesWeOffer = await this.apiResponseHandler(response);
    return await arrOfServicesWeOffer.json();
  }

  async updatePatient(
    patientId: number,
    patientData: NewPatientData
  ): Promise<PatientDao> {
    const response = await fetch(`${baseUrl}patient/${patientId}/update`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      //credentials: "include", //enable when we integrate cookies
      body: JSON.stringify(patientData),
    });
    const arrOfServicesWeOffer = await this.apiResponseHandler(response);
    return arrOfServicesWeOffer.json();
  }

  async getPatientList(hospitalId: number): Promise<PatientDao[]> {
    const response = await fetch(baseUrl + `patient/${hospitalId}/patients`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      //credentials: "include" //enable when we integrate cookies
    });
    const arrOfServicesWeOffer = await this.apiResponseHandler(response);
    return await arrOfServicesWeOffer.json();
  }

   getPatient = async(patientId: number): Promise<PatientDao> => {
    const response = await fetch(baseUrl + `patient/${patientId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const arrOfServicesWeOffer = await this.apiResponseHandler(response);
    return arrOfServicesWeOffer.json();
  }

  async deletePatient(patientId: number, userId: number): Promise<boolean> {
    const response = await fetch(baseUrl + `patient/${patientId}/${userId}/delete`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const arrOfServicesWeOffer = await this.apiResponseHandler(response);
    return arrOfServicesWeOffer.json();
  }
}

const patientService = new PatientService();
export default patientService;
