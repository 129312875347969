import React, { ReactNode } from "react";
import "./Modal.scss";
import MedicalCloseIcon from "../../resources/assets/svg/Dashboard/MedicalCloseIcon";

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	onAction?: (value: string) => void;
	modalTitle?: string;
	children: ReactNode;
}

export const Modal: React.FC<ModalProps> = ({
	isOpen,
	onClose,
	onAction,
	modalTitle,
	children,
}) => {
	/*const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleActionClick = () => {
        onAction(inputValue);
        setInputValue('');
    };
*/
	if (!isOpen) {
		return null;
	}

	return (
		<div className="modal-overlay-2">
			<div className="modal-2">
				<div className="modal-header-2">
					<h2>{modalTitle || "Modal Title"}</h2>
					<button onClick={onClose}>
						<MedicalCloseIcon />
					</button>
				</div>

				<div className="modal-content-2">{children}</div>
			</div>
		</div>
	);
};
