import React from "react";

const PatientIcon = () => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 8.5V19.5M21 12.5V19.5M13 7.5H19M5.5 11H8.5M3 14H21M3 17H21M16 4.5V10.5" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

};

export default PatientIcon;
