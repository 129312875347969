import { ApiService } from "./ApiService";
import { AppointmentDto } from "../types/AppointmentDto";

const baseUrl = `${process.env.ACC_PROXY ?? process.env.REACT_APP_ACC_PROXY}`;

class AppointmentService extends ApiService {

  async newAppointment(appointmentDto: AppointmentDto): Promise<AppointmentDto> {
    const response = await fetch(baseUrl + "appointment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(appointmentDto),
    });
    const responseResult = await this.apiResponseHandler(response);
    return await responseResult.json();
  }

  async getAllAppointments(hospitalId: number): Promise<AppointmentDto[]> {
    const response = await fetch(baseUrl + "appointment/"+hospitalId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    });
    const responseResult = await this.apiResponseHandler(response);
    return await responseResult.json();
  }

  async updateAppointment(appointmentDto: AppointmentDto): Promise<AppointmentDto> {
    const response = await fetch(baseUrl + "appointment", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(appointmentDto),
    });
    const responseResult = await this.apiResponseHandler(response);
    return await responseResult.json();
  }
}

const appointmentService = new AppointmentService();
export default appointmentService;
