import "./Header.scss";

import {AiOutlineMail} from "react-icons/ai";
import {IoIosClose} from "react-icons/io";
import {RxHamburgerMenu} from "react-icons/rx";
import {deleteCookie, getLoggedInUser, logout, userIsLogged} from "../../util/Helpers";
import {Button} from "../../components/button/Button";
import loginService from "../../services/LoginService";
import {useEffect, useState} from "react";
import {ParamsEnum} from "../../util/ParamsEnum";
import WhoAmI from "../../userAuth/WhoAmI";
import {Spinner} from "../../components/spinner/Spinner";
import HeaderLogo from "../../resources/assets/svg/Header/HeaderLogo";

export const Header = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [loading, setLoading] = useState(true);
    const user = getLoggedInUser() ?? {} as WhoAmI;
    const {href, pathname} = window.location;
    const [location, setLocation] = useState<{ href: string, pathname: string }>()
    const isDashboard = href?.includes('dashboard');

    useEffect(() => {
        setLocation({href, pathname});
        setLoading(false);
    }, [href, pathname]);
    const logoClickHandler = () => {
        window.location.href = "/";
    };

    const emailSupportHandler = () => {
        //TODO: if user is logged take to send us email
        console.log("email support");
    };

    const logoutHandler = () => {
        loginService
            .logout()
            .then(() => {
                deleteCookie(ParamsEnum.ACCESS_TOKEN);
                logout();
                localStorage.clear();
                window.location.href = ParamsEnum.HOME;
            })
            .catch(() => undefined);
    };

    const userIsLoggedAndIsInDashboard = () => {
        return (user?.userId > 0 && location?.pathname?.includes('/dashboard'))
            || (location?.pathname?.includes('/login')) || (location?.pathname?.includes('/register'));
    }

    const getStartedBtnHandler = () => {
        window.location.href = userIsLogged() ? "/dashboard" : '/register';
    }

    return (
        <div className={"header-component-container"}>
            {
                !loading && (
                    <div className={"header-layout"}>
                        <h3 className={"header-logo-text-wrapper"} onClick={logoClickHandler} role={'button'}>
                            <a href={'/'}><HeaderLogo/> </a>
                        </h3>
                        <section
                            className={(userIsLoggedAndIsInDashboard()) && !isMobile ? 'path-has-dashboard' : 'header-section'}>
                            <div className={!isMobile ? "desk-top" : "mobile"}>
                                <nav className={'header-nav-wrapper'}>
                                    <ul className="header-links">
                                        <li className="links">
                                            <a href="/">Home</a>
                                        </li>
                                        <li className="links">
                                            <a href="/#features">Features</a>
                                        </li>
                                        <li className="links">
                                            <a href="/#about">About</a>
                                        </li>
                                        <li className="links">
                                            <a href="/#contactUs">Contact</a>
                                        </li>
                                        <li className="links">
                                            { userIsLogged() ?
                                                <a onClick={() => logoutHandler()}>Logout</a> : <a href={'/login'}>Login</a>
                                            }
                                        </li>
                                    </ul>
                                </nav>
                                <div className="header-get-started-btn">
                                    <Button
                                        id="get-started-btn"
                                        name="get-started-btn"
                                        buttonName={userIsLogged() ? 'Dashboard' : 'Get Started'}
                                        onClick={getStartedBtnHandler}
                                    />
                                </div>

                                {
                                    isDashboard && (
                                        <h3
                                            className={"support-text-icon-wrapper"}
                                            onClick={emailSupportHandler}
                                        >
                                            <p>Support</p>
                                            <AiOutlineMail style={{color: "black"}}/>
                                        </h3>
                                    )
                                }
                            </div>
                        </section>

                        <button className="view-icon" onClick={() => setIsMobile(!isMobile)}>
                            {isMobile ? (
                                <div>
                                    <IoIosClose style={{color: "#0492c2", width: "80px"}}/>
                                </div>
                            ) : (
                                <div>
                                    <RxHamburgerMenu style={{color: "#0492c2", width: "80px"}}/>
                                </div>
                            )}
                        </button>
                    </div>
                )
            }

            {
                loading && <Spinner />
            }
        </div>
    );
};
