import { Fragment, isValidElement } from "react";
import "./Listing.scss";
import { useState } from "react";
import { capitalizeFirstCharacter } from "../../util/Helpers";
import Pagination from "../pagination/pagination-component/PaginationComponent";
import useOutsideClick from "../../customhook/useOutsideClick";

interface ListingProps {
    items: any[];
    withAction?: boolean;
    actions?: any[];

    getAction?(action: any): any;

    getClickedItem?(clickedItem: any): any;
    onClickListItem?: (obj: Record<string, any>) => void
    goToPage?: (page: number) => void;
    itemsPerPage?: number;
    currentPage?: number;
    totalPages?: number;
    totalItems?: number;
    hidePagination?: boolean;
    hideHeading?: boolean;
}

export const Listing = (props: ListingProps) => {
    const { ref, isComponentVisible, setIsComponentVisible } =
        useOutsideClick(false);
    const [currentActionIndex, setCurrentActionIndex] = useState<number>(-1);

    const currentActionHandler = (action: any) => {
        props.getAction && props.getAction(action);
        setIsComponentVisible(!isComponentVisible);
        setCurrentActionIndex(-1);
    };

    const actionsModalHandler = (itemClicked: any, itemIndex: number) => {
        setIsComponentVisible(!isComponentVisible);
        setCurrentActionIndex(itemIndex);
        props.getClickedItem && props.getClickedItem(itemClicked);
    };

    return (
        <div className="listing_wrapper">
            <div className={"listing-component"} role={"button"} tabIndex={0}>
                {/*TODO: render the keys in one row as the column names / labels*/}
                {props?.items &&
                    props?.items?.length > 0 &&
                    props?.items?.slice(0, 1).map((obj, index) => (
                        <div
                            className={
                                "listing-component-item-flex listing-component-items-column"
                            }
                            key={Math.random().toFixed(index)}
                        >
                            {obj &&
                                !props.hideHeading &&
                                Object.entries(obj).map(([key, value]) => (
                                    <p key={key}>{String(capitalizeFirstCharacter(key))}</p>
                                ))}
                            {props.withAction && (
                                <span>
                                    <p className={"listing-action"}>Acts</p>
                                </span>
                            )}
                        </div>
                    ))}

                {props?.items &&
                    props?.items?.length > 0 &&
                    props?.items?.map((obj, index) => (
                        <div
                            className={`listing-component-item-flex ${props.onClickListItem ? 'hasPointer' : ''}`}
                            key={Math.random().toFixed(index)} onClick={() => props.onClickListItem && props?.onClickListItem(obj)}
                        >
                            {obj &&
                                Object.entries(obj).map(([key, value]) =>
                                    isValidElement(value) ? (
                                        <Fragment key={key}>{value}</Fragment>
                                    ) : (
                                        <p key={key}>{String(value) || "N/A"}</p>
                                    )
                                )}

                            {props.withAction && (
                                <>
                                    <button
                                        className={"listing-action"}
                                        onClick={() => actionsModalHandler(obj, index)}
                                    >
                                        ...
                                    </button>

                                    {currentActionIndex === index && isComponentVisible && (
                                        <div className={"listing-actions-wrapper"} ref={ref}>
                                            {props?.actions?.map((act, actIndex) => (
                                                (act && <button
                                                    id={"" + actIndex}
                                                    className={"listing-single-action-wrapper"}
                                                    onClick={() => currentActionHandler(act)}
                                                    key={Math.random().toFixed(actIndex)}
                                                >
                                                    {act}
                                                </button>)
                                            ))}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    ))}
                <Pagination
                    itemsPerPage={props.itemsPerPage as number}
                    currentPage={props.currentPage as number}
                    goToPage={props.goToPage!}
                    hide={props.hidePagination} totalPages={props.totalPages} totalItems={props.totalItems}
                />
            </div>
        </div>
    );
};
