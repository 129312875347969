import React from 'react';
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { HorizontalBarChartProps } from '../../../types/Dashboard/DashboardModels';

export default function HorizontalBarChart({ data }: HorizontalBarChartProps) {
	return (
		<section className='horizontal_chart_wrapper'>
			<h2 className='chart_header'>Patient on Admission and Clinic</h2>
			<ResponsiveContainer width='100%' height='100%' minHeight={400} minWidth={500}>
				<ComposedChart
					layout='vertical'
					data={data}
					margin={{
						top: 20,
						right: 20,
						bottom: 20,
						left: 20,
					}}>
					<CartesianGrid stroke='#000' horizontal={false} strokeWidth={0.8} />
					<XAxis type='number' />
					<YAxis dataKey='name' type='category' scale='band' width={120} />
					<Tooltip />
					<Legend />
					<Bar dataKey='clinic' barSize={20} fill='#47B892' label={{ fill: '#fff', fontSize: 20 }} />
					<Bar dataKey='admission' barSize={20} fill='#00B0FF' label={{ fill: '#fff', fontSize: 20 }} />
				</ComposedChart>
			</ResponsiveContainer>
		</section>
	);
}
