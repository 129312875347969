import React from "react";

const AdminOnlineIcon = () => {
  return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26 20C28.2091 20 30 18.2091 30 16C30 13.7909 28.2091 12 26 12C23.7909 12 22 13.7909 22 16C22 18.2091 23.7909 20 26 20Z" fill="#47B892"/>
<path d="M22 30H20V25C20 23.6739 19.4732 22.4021 18.5355 21.4645C17.5979 20.5268 16.3261 20 15 20H9C7.67392 20 6.40215 20.5268 5.46447 21.4645C4.52678 22.4021 4 23.6739 4 25V30H2V25C2 23.1435 2.7375 21.363 4.05025 20.0503C5.36301 18.7375 7.14348 18 9 18H15C16.8565 18 18.637 18.7375 19.9497 20.0503C21.2625 21.363 22 23.1435 22 25V30ZM12 4C12.9889 4 13.9556 4.29324 14.7779 4.84265C15.6001 5.39206 16.241 6.17295 16.6194 7.08658C16.9978 8.00021 17.0969 9.00555 16.9039 9.97545C16.711 10.9454 16.2348 11.8363 15.5355 12.5355C14.8363 13.2348 13.9454 13.711 12.9755 13.9039C12.0055 14.0969 11.0002 13.9978 10.0866 13.6194C9.17295 13.241 8.39206 12.6001 7.84265 11.7779C7.29324 10.9556 7 9.98891 7 9C7 7.67392 7.52678 6.40215 8.46447 5.46447C9.40215 4.52678 10.6739 4 12 4ZM12 2C10.6155 2 9.26215 2.41054 8.11101 3.17971C6.95986 3.94888 6.06266 5.04213 5.53284 6.32122C5.00303 7.6003 4.86441 9.00776 5.1345 10.3656C5.4046 11.7235 6.07128 12.9708 7.05025 13.9497C8.02922 14.9287 9.2765 15.5954 10.6344 15.8655C11.9922 16.1356 13.3997 15.997 14.6788 15.4672C15.9579 14.9373 17.0511 14.0401 17.8203 12.889C18.5895 11.7378 19 10.3845 19 9C19 7.14348 18.2625 5.36301 16.9497 4.05025C15.637 2.7375 13.8565 2 12 2Z" fill="black"/>
</svg>

};

export default AdminOnlineIcon;
