import {ApiService} from "./ApiService";
import {UserDto} from "../types/UserDto";
import { UserData } from "../types/Helper";

const baseUrl = `${process.env.AUTH_PROXY ?? process.env.REACT_APP_AUTH_PROXY}`

class UserService extends ApiService {

    async getAllUsers(hospitalId: number): Promise<UserDto[]> {
        const response = await fetch(baseUrl + `user/${hospitalId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const users = await this.apiResponseHandler(response);
        return await users.json();
    }

      async getUserInfo(hospitalId: number, userId: number): Promise<UserDto>{
        const response = await fetch(baseUrl + `user/${hospitalId}/${userId}`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const user = await this.apiResponseHandler(response)
        return await user.json()
    }
}

const userService = new UserService();
export default userService;