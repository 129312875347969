import "./Appointment.scss";
import { useEffect, useState } from "react";
import { ActionType, DashboardTopNav } from "../../components/dashboardnav/DashboardTopNav";
import { Listing } from "../../components/listing/Listing";
import { MdAdd } from "react-icons/md";
import { Modal } from "../../components/modal/Modal";
import { BookAppointment } from "./BookAppointment";
import { Button } from "../../components/button/Button";
import { AppointmentDto } from "../../types/AppointmentDto";
import {  useWhoAmI } from "../../context/UserCtx";
import appointmentService from "../../services/AppointmentService";
import { ErrorNotification } from "../../modules/errornotification/ErrorNotification";
import { BsList } from "react-icons/bs";
const navActions = [{ name: 'List of Appointment', icon: <BsList /> }, { name: "Book Appointment", icon: <MdAdd /> }]

type ActivePage = "List" | "Edit" | "Delete" | "Add";

type AppointmentDtoOmit = Omit<AppointmentDto, 'patientEmail' | 'dateCreated' | 'personnelEmail' | 'lastUpdated'
    | 'emailSentToPersonnel' | 'emailSentToPatient' | 'createdBy' | 'personnelId' | 'patientId' | 'hospitalName' | 'hospitalId'>;

export const Appointment = () => {
    const [activePage, setActivePage] = useState<ActivePage>("List");
    const [openModal, setOpenModal] = useState(false);
    const [navAction, setNavAction] = useState<ActionType>();
    const [searchResults, setSearchResults] = useState<AppointmentDto[]>([]);
    const [appointment, setAppointment] = useState<AppointmentDto>({} as AppointmentDto);
    const [appointmentError, setAppointmentError] = useState<string>();
    const user = useWhoAmI();
    const [listOfAppointment, setListOfAppointment] = useState<AppointmentDto[]>([]);
    const [omitAppointmentDto, setOmitAppointmentDto] = useState<AppointmentDtoOmit[]>([]);

    const getAllAppointmentsHandler = () => {
        appointmentService
            .getAllAppointments(user.hospital.id)
            .then((response: AppointmentDto[]) => {
                setListOfAppointment(response);
                omitAppointmentHandler(response);
            })
            .catch(() => {
                setAppointmentError(
                    "Technical error occurred while getting list of appointments, please try again later!"
                );
            });
    };

    useEffect(() => {
        getAllAppointmentsHandler();
    }, []);

    const deleteItem = (appt: AppointmentDto) => {
        const newList = listOfAppointment?.filter(
            (item) => item.patientName !== appt.patientName
        );
        setListOfAppointment(newList);
        setActivePage("List");
    };

    const getClickedItem = (aptClicked: AppointmentDto) => {
        const clickedApt: AppointmentDto | undefined = listOfAppointment?.find((apt) => apt?.id === aptClicked?.id)
        clickedApt && setAppointment(clickedApt);
    };

    const getAppointmentSearchResult = (searchResult: AppointmentDto[]): any[] => {
        setSearchResults(searchResult);
        omitAppointmentHandler(searchResult)
        return searchResult;
    };

    const getClickedNavAction = (action: ActionType): ActionType => {
        setNavAction(action);
        switch (action?.name.toLowerCase()) {
            case "book appointment":
                setOpenModal(true);
                setActivePage("Add");
                break;
            default:
                setActivePage("List");
                break;
        }

        return action;
    };

    const getClickAppointmentAction = (actionClicked: ActivePage) => {
        setActivePage(actionClicked ?? activePage);
        if (actionClicked === "Edit") {
            setOpenModal(true);
            setActivePage("Edit");
        }

        if (actionClicked === "Delete") {
            setActivePage("Delete");
            setOpenModal(true);
        }
    };

    const omitAppointmentHandler = (aptDto: AppointmentDto[]) => {
        const omitPropsArr: AppointmentDtoOmit[] = [];
        aptDto?.map(({ dateCreated, lastUpdated, emailSentToPatient, personnelEmail, patientEmail, emailSentToPersonnel,
            hospitalId, patientId, personnelId, hospitalName, createdBy, ...rest }) => {
            omitPropsArr.push(rest as AppointmentDtoOmit)
        });
        setOmitAppointmentDto(omitPropsArr);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        setActivePage("List");
        setNavAction(navActions[0])
    }

    return (
        <div className={"appointment-component"}>
            <div>
                <DashboardTopNav selectedAction={navAction?.name || "List of Appointment"}
                    action={navActions}
                    placeholder={"search appointment with id or date"}
                    itemList={listOfAppointment}
                    getSearchResult={getAppointmentSearchResult}
                    getClickedAction={getClickedNavAction}
                />
            </div>

            {activePage === "List" && (
                <div>
                    {
                        listOfAppointment?.length < 1 &&
                        <ErrorNotification
                            name={'empty-list'}
                            stringErrors={'This no registered appointment to be displayed, add appointment'}
                        />
                    }

                    {omitAppointmentDto?.length > 0 && (
                        <Listing
                            withAction
                            items={omitAppointmentDto}
                            actions={["Edit", "Delete"]}
                            getAction={getClickAppointmentAction}
                            getClickedItem={getClickedItem}
                        />
                    )}
                </div>
            )}

            {activePage === "Add" && (
                <div className="appointment-modal">
                    <Modal
                        isOpen={openModal}
                        onClose={handleCloseModal}
                        modalTitle="Book Appointment"
                    >
                        <BookAppointment />
                    </Modal>
                </div>
            )}

            {activePage === "Edit" && (
                <div className="appointment-modal">
                    <Modal
                        isOpen={openModal}
                        onClose={handleCloseModal}
                        modalTitle={"Edit Appointment"}
                    >
                        <BookAppointment appointmentDto={appointment} isEdit={true} />
                    </Modal>
                </div>
            )}

            {activePage === "Delete" && (
                <div className="delete-appointment-modal">
                    <Modal
                        isOpen={openModal}
                        onClose={handleCloseModal}
                        modalTitle={"Delete Appointment"}
                    >
                        <p>
                            Are you sure you want to delete {appointment.patientName}'s
                            appointment?
                        </p>
                        <div style={{ marginTop: "2rem" }}>
                            <Button
                                id="delete"
                                name="delete"
                                buttonName={activePage}
                                onClick={() => deleteItem(appointment)}
                            />
                        </div>
                    </Modal>
                </div>
            )}
        </div>
    );
};
