import './Invoice.scss'
import {DialogPageType} from "../../pages/customer/newpatient/Patient";
import {useEffect, useRef, useState} from "react";
import {Button} from "../../components/button/Button";
import {SearchBar} from "../../components/searchbar/SearchBar";
import {useForm} from "react-hook-form";
import servicesOffered from "../../services/ServicesOffered";
import InvoiceCalculation from "../../types/invoice/InvoiceCalculation";
import {PaymentMethod, PaymentStatus} from "../../types/Helper";
import {InvoiceSummary} from "../invoicesummary/InvoiceSummary";
import NewInvoiceReqData from "../../types/invoice/NewInvoiceReqData";
import {InvoiceHeader} from "./InvoiceHeader";
import {ErrorNotification} from "../errornotification/ErrorNotification";
import {convertToLocaleString, isEmpty} from "../../util/Helpers";
import {Spinner} from "../../components/spinner/Spinner";
import {InputField} from "../../components/inputfield/InputField";
import {useLocation} from "react-router-dom";
import {TextArea} from "../../components/textarea/TextArea";
import {Patient} from "../../types/PatientDao";
import {useWhoAmI} from "../../context/UserCtx";
import {userHasRole} from "../../util/UserRolesHelper";
import {OfferedServiceDto} from "../../types/services/ServiceTypes";
import {Authorities} from "../../userAuth/WhoAmI";
import {Simulate} from "react-dom/test-utils";
import select = Simulate.select;
import {MdDelete} from "react-icons/md";

type InvoiceActivePage = 'Create Sale' | 'Invoice' | 'Summary'

interface InvoiceProps {
    generateInvoiceBtn?(action: DialogPageType): any,

    continueBtn?(action: DialogPageType): any,

    getActivePage?(activePage: any): any
    backFromSummary?(): NewInvoiceReqData
}

export const Invoice = (props: InvoiceProps) => {
    const user = useWhoAmI();
    const [listOfServices, setListOfServices] = useState<OfferedServiceDto[]>([]) //from BE
    const [searchQueryInput, setSearchQueryInput] = useState<string>('');
    const [invoiceActivePage, setInvoiceActivePage] = useState<InvoiceActivePage>('Invoice');
    const {register, setError,formState: {errors, isValid}} = useForm({mode: 'onBlur'});
    const [filteredService, setFilteredService] = useState<OfferedServiceDto[]>([]);
    const [selectedService, setSelectedService] = useState<OfferedServiceDto[]>([] as OfferedServiceDto[])
    const [invoiceCalculations, setInvoiceCalculations] = useState<InvoiceCalculation>({} as InvoiceCalculation)
    const [newInvoice, setNewInvoice] = useState<NewInvoiceReqData>(props.backFromSummary ? props.backFromSummary() as NewInvoiceReqData : {} as NewInvoiceReqData)
    const [invoiceError, setInvoiceError] = useState('')
    const [loading, setLoading] = useState(true)
    const searchBarRef = useRef<HTMLDivElement>(null)
    const filteredServicesRef = useRef<HTMLDivElement>(null)
    const location = useLocation();
    const {patientDao} = location?.state?.patient || {} as Patient;
    const noAccessErr = 'no-access';

    const getListOfServicesHandler = () => {
        servicesOffered.getListOfServicesOffered(user.hospital.id)
            .then((response) => {
                setListOfServices([...response])
            })
        setLoading(false);
    }

    useEffect(() => {
        if(!userHasRole(user, [Authorities.SUPER_ADMIN, Authorities.ADMIN, Authorities.CASHIER])) {
            setInvoiceError('Access required, contact your administrator');
            setError(noAccessErr, {message: 'Access required, contact your administrator'})
            setLoading(false);
            return
        }

        getListOfServicesHandler();
        calculateBalancePrice();

        if(!disableSummaryBtn()) {
            setInvoiceError('');
        }

        if(props.backFromSummary) {
            setSelectedService(props.backFromSummary().selectedServices)
        }

    }, [
        selectedService, invoiceCalculations.total, newInvoice.paymentStatus,
        newInvoice.paymentMethod, newInvoice.amountPaid
    ]);

    const addServiceHandler = (item: OfferedServiceDto) => {
        const itemAlreadyAdded = selectedService.length > 0 && selectedService.find(existsAlready => {
            return existsAlready.id === item.id && existsAlready.serviceName === item.serviceName
        });

        if (itemAlreadyAdded && itemAlreadyAdded.quantity > 0) {
            const unitPrice = itemAlreadyAdded.price / itemAlreadyAdded.quantity;

            itemAlreadyAdded.quantity++;
            itemAlreadyAdded.price = unitPrice * itemAlreadyAdded.quantity;

            const alreadyExistingItemIndex = itemAlreadyAdded?.id ? selectedService.indexOf(itemAlreadyAdded) : -1;
            selectedService[alreadyExistingItemIndex] = itemAlreadyAdded;
            setSelectedService([...selectedService]);
            return
        }
        setSelectedService([...selectedService, item]);
    }

    const removeAddedServiceHandler = (serviceToBeRemoved: OfferedServiceDto, index: number) => {
        if (serviceToBeRemoved.quantity > 1) {
            const unitPrice = serviceToBeRemoved.price / serviceToBeRemoved.quantity;

            serviceToBeRemoved.quantity--;
            serviceToBeRemoved.price = Number((unitPrice * serviceToBeRemoved.quantity).toFixed(2));

            selectedService[index] = serviceToBeRemoved
            setSelectedService([...selectedService])
            return
        }

        const updateSelectedService = [...selectedService.slice(0, index), ...selectedService.slice(index + 1)];
        setSelectedService([...updateSelectedService]);
    }

    const searchQueryHandler = (evt: any) => {
        const target = evt.target;
        const searchQuery = target.value.toLowerCase();
        const filteredService = listOfServices && listOfServices.length > 0 && listOfServices.filter((service, index) =>
            searchQuery && searchQuery !== '' && service.serviceName.toLowerCase().includes(searchQuery)
        )
        filteredService && setFilteredService(filteredService);
    }

    const searchBarOutsideClickHandler = (evt: any) => {
        if (searchBarRef && !searchBarRef.current?.contains(evt.target) &&
            filteredServicesRef && !filteredServicesRef.current?.contains(evt.target)) {
            evt.target.value = '';
            setFilteredService([]); // clears services displayed to be selected from
            return
        }

        //  clears input in search bar
        if (searchBarRef && searchBarRef.current?.contains(evt.target)) {
            evt.target.value = '';
            return
        }
    }

    const updateNewInvoice = () => {
        setNewInvoice((prevState) => ({
            ...prevState,
            customerId: patientDao.id,
            companyId: user.hospital.id,
            createdBy: user.userId,
            total: invoiceCalculations.total,
            deposit: invoiceCalculations?.deposit,
            discount: invoiceCalculations.discount,
            balance: invoiceCalculations.balance,
            selectedServices: selectedService
        }));

        // paid in full
        if(newInvoice.paymentStatus === PaymentStatus.PAID) {
            setNewInvoice((prevState) => ({
                ...prevState,
                balance: 0,
                amountPaid: invoiceCalculations.total
            }));
        }
    }

    const amountPaidHandler = (evt: any) => {
        const target = evt.target;
        const value = target.value;

        const updateNewInvoice = {...newInvoice}

        if(isNaN(value)) {
            updateNewInvoice.amountPaid = 0;
        }
        else updateNewInvoice.amountPaid = value;

        setNewInvoice(updateNewInvoice);
    }

    const calculateTotalPrice = (): number => {
        let sum = 0;
        selectedService.length > 0 && selectedService?.map(item => {
            sum += item.price
        })
        return sum;
    }

    const calculateBalancePrice = () => {
        let totalPrice = Number(calculateTotalPrice().toFixed(2));
        const customerDeposit = patientDao?.deposit;

        if (totalPrice === 0) {
            setInvoiceCalculations((prevState) => ({
                ...prevState,
                deposit: customerDeposit,
                total: totalPrice,
            }));
        }

        let balanceToTwoDecimal = 0;
        let deposit = 0;

        if (customerDeposit > 0 && customerDeposit >= totalPrice) {
            deposit = (customerDeposit === totalPrice) ?
                -customerDeposit : Number((customerDeposit - totalPrice).toFixed(2))

            setInvoiceCalculations((prevState) => ({
                ...prevState,
                total: totalPrice,
                deposit: deposit,
                balance: 0
            }));
        }

        if (customerDeposit > 0 && customerDeposit < totalPrice) {
            balanceToTwoDecimal = Number((totalPrice - customerDeposit).toFixed(2));
            deposit = 0 - customerDeposit

            setInvoiceCalculations((prevState) => ({
                ...prevState,
                total: totalPrice,
                deposit: deposit,
                balance: balanceToTwoDecimal,
            }));
        }

        let discount = 0;
        const amountPaid = Number(newInvoice.amountPaid);
        if((newInvoice.paymentStatus === PaymentStatus.INSTALLMENT || newInvoice.paymentStatus === PaymentStatus.WAIVER)
            && amountPaid > 0 && totalPrice >= amountPaid) {
            balanceToTwoDecimal = Number((totalPrice - amountPaid).toFixed(2));
            discount = Number(((newInvoice.amountPaid * 100) / totalPrice).toFixed(2));
        }

        const updateInvoiceCal = {...invoiceCalculations};
        updateInvoiceCal.total = totalPrice;
        updateInvoiceCal.deposit = deposit;
        updateInvoiceCal.balance = balanceToTwoDecimal;
        updateInvoiceCal.discount = discount;

        setInvoiceCalculations(updateInvoiceCal);
    }

    const BackBtnHandler = () => {
        window.history.back();
    }

    const invoiceSummaryHandler = (): NewInvoiceReqData => {
        return newInvoice;
    }

    const getPaymentStatus = (status: PaymentStatus) => {
        setNewInvoice((prevState) => ({
            ...prevState, paymentStatus: status
        }))
    }

    const getPaymentMethod = (method: PaymentMethod) => {
        setNewInvoice((prevState) => ({
            ...prevState, paymentMethod: method
        }))
    }

    const disableSummaryBtn = (): boolean => {
        if(newInvoice.paymentStatus === PaymentStatus.INSTALLMENT &&
            (!newInvoice.amountPaid || (newInvoice.amountPaid < 1))) {
            setError('instalment-amount-paid', {message: 'Only numbers greater than 0 is allowed'});
            setInvoiceError('Amount paid cannot be 0 or character');
            return true;
        }

        //TODO check amountWaived and compare it to the amountPaid
        if(newInvoice.paymentStatus === PaymentStatus.WAIVER &&
            (!newInvoice.amountPaid || (newInvoice.amountPaid < 1))) {
            setError('instalment-amount-paid', {message: 'Only numbers greater than 0 is allowed'});
            setInvoiceError('Amount waived cannot be 0 or character');
            return true;
        }

        return isValid && (isEmpty(newInvoice.paymentMethod) || isEmpty(newInvoice.paymentStatus) || selectedService.length <= 0);
    }

    const summaryBtnHandler = () => {
        updateNewInvoice();

        if(disableSummaryBtn()) {
            setInvoiceError('Please select all required fields marked *');
            return
        }

        if(!disableSummaryBtn()) {
            setInvoiceError('');
            setError('instalment-amount-paid', {})
            setInvoiceActivePage('Summary');
        }
    }

    return (
        <div className={'invoice-component'}>
            {
                !loading && (
                   <>
                       {
                           (invoiceError || (errors && errors[noAccessErr]?.message)) &&
                           <div>
                               <ErrorNotification
                                   name={noAccessErr}
                                   stringErrors={invoiceError}
                                   fieldErrors={errors}
                               />
                           </div>
                       }

                       <div>
                        {invoiceActivePage === 'Invoice' && (
                            <div className={'invoice-component-layout'} onClick={searchBarOutsideClickHandler}>
                                <InvoiceHeader page={'invoice'}
                                               getPaymentMethod={getPaymentMethod}
                                               getPaymentStatus={getPaymentStatus}
                                />

                                <div className={'invoice-billing-wrapper'}>
                                    <h3>Billing</h3>
                                    <div className={'add-service-or-sale'}>
                                        <SearchBar ref={searchBarRef}
                                                   id={'search-service-or-sale'}
                                                   name={'search-service-or-sale'}
                                                   defaultValue={searchQueryInput}
                                                   placeholder={'search service to add *'}
                                                   onChange={searchQueryHandler}
                                                   register={register}
                                                   errors={errors}
                                                   pattern={{
                                                       value: /^(?! )[A-Za-z\s]*$/,
                                                       message: 'Only characters a - z are allowed'
                                                   }}
                                        />
                                    </div>
                                    <div className={'add-filtered-service-wrapper'} ref={filteredServicesRef}>
                                        {filteredService && filteredService.length > 0 &&
                                            filteredService && filteredService.map((item, index) => (
                                                <div key={index}
                                                     className={'filter-service remove-bottom-border invoice-add-sales-services-wrapper'}
                                                     onClick={() => addServiceHandler(item)}
                                                >
                                                    <p>{item?.serviceName}</p>
                                                    <p>{item?.quantity}</p>
                                                    <p>{item?.categoryName}</p>
                                                    <p>{item?.price}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                                <div>
                                    <div className={'added-items-headline'}>
                                        <div className={'invoice-add-sales-services-wrapper width-97-percent'}>
                                            <p>S/N</p>
                                            <p>Service name</p>
                                            <p>Qty</p>
                                            <p>Department</p>
                                            <p>Category</p>
                                            <p>Unit price</p>
                                            <p>Price (N)</p>
                                        </div>
                                        <span className={'width-3-percent'}></span>
                                    </div>

                                    <div>
                                        {
                                            selectedService?.length > 0 && selectedService?.map((svc, index) => (
                                                <>
                                                    <div className={'invoice-table-divider'}>
                                                        <div key={index}
                                                             className={'width-97-percent add-bottom-border invoice-add-sales-services-wrapper'}>
                                                            <p>{index + 1}</p>
                                                            <p>{svc?.serviceName}</p>
                                                            <p>{svc?.quantity}</p>
                                                            <p>{svc?.departmentName}</p>
                                                            <p>{svc?.categoryName}</p>
                                                            <p>{svc?.unitPrice}</p>
                                                            <p>{svc?.price?.toFixed(2)}</p>
                                                        </div>

                                                        <span className={'width-3-percent remove-item'} onClick={() => removeAddedServiceHandler(svc, index)}><MdDelete/></span>
                                                    </div>


                                                </>
                                            ))
                                        }
                                    </div>

                                    {
                                        (newInvoice.paymentStatus === PaymentStatus.INSTALLMENT || newInvoice.paymentStatus === PaymentStatus.WAIVER)
                                        && <>
                                            <div className={'instalment-amount-paid-wrapper'}>
                                                <InputField
                                                    id={'instalment-amount-paid'}
                                                    name={'instalment-amount-paid'}
                                                    placeholder={newInvoice.paymentStatus === PaymentStatus.WAIVER ? 'Enter amount waived' : 'Enter amount paid'}
                                                    label={newInvoice.paymentStatus === PaymentStatus.WAIVER ? 'Enter amount waived' : 'Enter amount paid'}
                                                    required={'Amount paid is required'}
                                                    onChange={amountPaidHandler}
                                                    defaultValue={!newInvoice.amountPaid ? 0 :
                                                        Number(newInvoice.amountPaid)}
                                                    register={register}
                                                    errors={errors}
                                                    pattern={{
                                                        value: /[0-9]/,
                                                        message: 'Only numbers greater than 0 is allowed'
                                                    }}
                                                    min={1}
                                                    minLength={1}
                                                />
                                            </div>
                                            {
                                                newInvoice.paymentStatus === PaymentStatus.WAIVER &&
                                                <div className={'instalment-amount-paid-wrapper'}>
                                                    <TextArea name={'reason-of waiver'}
                                                              label={'Explain reason of waiver'}
                                                              onChange={(e: any) => e}
                                                              required={true}
                                                              max={1500}
                                                              maxLength={1500}
                                                    />
                                                </div>
                                            }
                                        </>
                                    }

                                    {/*<div>
                                        {
                                            invoiceError && (
                                                <div>
                                                    <ErrorNotification name={'invoice-error'} stringErrors={invoiceError}/>
                                                </div>
                                            )
                                        }
                                    </div>*/}

                                    <div className={'invoice-calculations'}>
                                        <div className={'width-50-l-percent'}></div>
                                        <div className={'width-50-r-percent'}>
                                            <div className={'invoice-calculations-left'}>
                                                <p>Total: </p>
                                                <p>Amount paid: </p>
                                                {invoiceCalculations?.deposit > 0  && <p>Deposit: </p>}
                                                {newInvoice.paymentStatus === PaymentStatus.WAIVER && <p>Discount: </p>}
                                                <p>Balance: </p>
                                            </div>
                                            <div className={'invoice-calculations-right'}>
                                                <p>{convertToLocaleString(invoiceCalculations?.total) || 0}</p>
                                                <p>{newInvoice.paymentStatus === PaymentStatus.PAID ? convertToLocaleString(invoiceCalculations?.total) : convertToLocaleString(newInvoice?.amountPaid) || 0}</p>
                                                <p>{invoiceCalculations?.deposit > 0 ? invoiceCalculations?.deposit : ''}</p>
                                                {newInvoice.paymentStatus === PaymentStatus.WAIVER &&
                                                    <p>{invoiceCalculations?.discount || 0} %</p>}
                                                <p>{convertToLocaleString(invoiceCalculations?.balance) || 0}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={'invoice-buttons-wrapper'}>
                                    <Button id={'back-invoice-btn'} name={'back-invoice-btn'} buttonName={'Back'}
                                            onClick={BackBtnHandler}
                                    />

                                    <Button id={'summary-invoice-btn'} name={'summary-invoice-btn'} buttonName={'Summary'}
                                            onClick={summaryBtnHandler}
                                    />
                                </div>
                            </div>
                        )}
                        {
                            invoiceActivePage === 'Summary' && isEmpty(invoiceError) && (
                                <InvoiceSummary next={invoiceSummaryHandler}/>
                                /*<InvoiceSummary next={() => newInvoice}/>*/
                            )
                        }
                    </div>
                   </>
                )
            }

            {
                loading && <Spinner />
            }
        </div>
    )
}