import React, { useEffect, useState } from 'react';
import './Accordion.scss'
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { PatientDetailCtxProvider } from "../../pages/customer/PatientDetailCtx";
import { SideBarChildrenType } from "../../appstate/SidebarChildrenState";
import PatientDao from "../../types/PatientDao";
import { useCustomNavigate } from "../../customhook/CustomUseNavigate";
import patientService from '../../services/PatientService';
import { Spinner } from '../spinner/Spinner';
import { ErrorNotification } from '../../modules/errornotification/ErrorNotification';

interface AccordionProp<T> {
    items: {
        patients: T[],
        serviceIcon: { serviceName: SideBarChildrenType, icon: any }[]
    }[]
}

const Accordion = (props: AccordionProp<PatientDao>) => {
    const { getPatient } = patientService
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
    const [activePatient, setActivePatient] = useState<PatientDao>({} as PatientDao);
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState('')
    const navigate = useCustomNavigate();
    const location = useLocation();

    const toggleItem = (index: number) => {
        setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const calculateAgeWithMonths = (dob: string) => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const birthYear = Number(dob.split('-')[2]);
        const res = currentYear - birthYear;
        return (res < 1) ? (res * -1) : res;
    }

    const navigateHandler = (patient: PatientDao, path: string) => {
        setActivePatient(patient);
        navigate(path, { state: { patient: patient } });
    }
    useEffect(() => {
        setLoading(true)
        if (typeof location.state === 'number') {
            getPatient(location.state).then((response) => navigateHandler(response, `${location?.pathname}/admission`)).catch(()=>setError("An error occured while fetching patient's details")).finally(() => setLoading(false))
        } else setLoading(false)
    }, [location.state, getPatient])

    if (loading) {
        return <Spinner />
    }
    return (
        <PatientDetailCtxProvider value={{ patientDao: activePatient.patientDao, address: activePatient.address }}>
            <div className='accordion_container'>
                <div className='accordion_wrapper'>
                    <div className={'accordion-item-header'}>
                        <span className={'patient-initials-span-width'}></span> {/*Do not delete*/}
                        <h3>PatientId</h3>
                        <h3>Surname</h3>
                        <h3>Firstname</h3>
                        <h3>Age</h3>
                        <h3>Phone No</h3>
                        <h3>Gender</h3>
                        <h3>Marital status</h3>
                        <h3>Date Registered</h3>
                    </div>

                    {props.items.length > 0 && props.items.map((item, index) => (
                        <div key={Math.random().toFixed(index)} className={'accordion-map-wrapper'}>
                            {
                                item.patients && item.patients.length > 0 && item.patients.map((pat, cusIndex) => (
                                    <div key={Math.random().toFixed(cusIndex)} className={'accordion-item-border'}
                                        onClick={() => toggleItem(cusIndex)}>
                                        <Link className={(expandedIndex === cusIndex) ? 'accordion-active-item-content' : ''}
                                            to={''}
                                        >
                                            <span className={'patient-initials-span-width'}>
                                                {expandedIndex === cusIndex ? <FaChevronDown /> : <FaChevronRight />}
                                                <span className={'surname-firstname-initials'}>
                                                    {pat.patientDao?.lastname[0]?.toUpperCase() + pat.patientDao?.firstname[0]?.toUpperCase()}
                                                </span>
                                            </span>
                                            <p title={'CustomerId'}>
                                                {pat.patientDao?.id}
                                            </p>
                                            <p title={'Lastname'}>
                                                {pat.patientDao?.lastname}
                                            </p>
                                            <p title={'Firstname'}>
                                                {pat.patientDao?.firstname}
                                            </p>
                                            <p title={'Age'}>
                                                {calculateAgeWithMonths(pat.patientDao?.dob)}
                                            </p>
                                            <p title={'Phone number'}>
                                                {pat.patientDao?.phoneNumber}
                                            </p>
                                            <p title={'Gender'}>
                                                {pat.patientDao?.gender}
                                            </p>
                                            <p>
                                                {pat.patientDao?.maritalStatus}
                                            </p>
                                            <p title={'Date registered'}>
                                                {pat.patientDao?.dateCreated?.toLocaleString()}
                                            </p>
                                        </Link>

                                        {expandedIndex === cusIndex && (
                                            <section className={'patient-services-section'}>
                                                <div className={'patient-services-wrapper'}>
                                                    {Array.isArray(item?.serviceIcon) && item?.serviceIcon?.length > 0 && (
                                                        <ul className={'accordion-content-wrapper'}>
                                                            {item?.serviceIcon?.map((itemContent, idx) => (
                                                                (itemContent?.serviceName && <div key={idx} className={'accordion-content-list'}>
                                                                    <a
                                                                        onClick={() => navigateHandler(pat, `${location?.pathname}/${itemContent?.serviceName?.toLowerCase()}?pid=${pat.patientDao.id}`)}
                                                                    >
                                                                        {/*  <a
                                                                onClick={() => navigateHandler(cus, `${location?.pathname}/${itemContent?.serviceName?.toLowerCase()}`
                                                                    + (itemContent?.serviceName === 'admission' ? `${checkInUrlParam}` : ''))}
                                                            >*/}
                                                                        <p className={'accordion-service-icon-title-wrapper'}
                                                                            title={'Click to list ' + itemContent.serviceName}
                                                                        >
                                                                            <span
                                                                                className={'accordion-service-icon-wrapper'}>{itemContent.icon}</span>
                                                                            <span>{itemContent.serviceName}</span>
                                                                        </p>
                                                                    </a>
                                                                </div>)
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            </section>
                                        )}
                                    </div>
                                ))
                            }

                            {/*{
                        !item.patients || item.patients.length < 1 && <Spinner/>
                    }*/}
                        </div>
                    ))}
                </div>
                {error && <ErrorNotification name={'patient-error'} stringErrors={error}/>}
            </div>
        </PatientDetailCtxProvider>
    );
};

export default Accordion;
