/* eslint-disable no-sequences */
import "./ServicePage.scss";
import { DashboardTopNav } from "../../components/dashboardnav/DashboardTopNav";
import { useCallback, useEffect, useState } from "react";
import { IoFilter } from "react-icons/io5";
import { Spinner } from "../../components/spinner/Spinner";
import { MdAdd } from "react-icons/md";
import ServiceList from "../../components/ServicesList/ServiceList";
import { Modal } from "../../components/modal/Modal";
import AddService from "../../components/ServicesList/addservice/AddService";
import { useWhoAmI } from "../../context/UserCtx";
import {
  userHasRole,
  userIsAdmin,
  userIsSuperAdmin,
} from "../../util/UserRolesHelper";
import { Authorities } from "../../userAuth/WhoAmI";
import { LightBulb } from "../../resources/assets/svg/servicepage/LightBulb";
import { ListIcon } from "../../resources/assets/svg/servicepage/ListIcon";
import { OfferedServiceDto } from "../../types/services/ServiceTypes";
import servicesOffered from "../../services/ServicesOffered";
import { ErrorNotification } from "../../modules/errornotification/ErrorNotification";

export const ServicePage = () => {
  const [loading, setLoading] = useState(true);
  const [action, setAction] = useState<string>("List of Services");
  const [list, setList] = useState<OfferedServiceDto[]>([]);
  const [searchResults, setSearchResults] = useState<OfferedServiceDto[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(true);
  const [serviceError, setServiceError] = useState<string>("");
  const [added, setAdded] = useState<boolean>(false);
  const user = useWhoAmI();

  const handleGetListOfServices = useCallback(() => {
    setLoading(true);
    servicesOffered
      .getListOfServicesOffered(user.hospital.id)
      .then((response) => setList(response))
      .catch(() => setServiceError("Error while fetching list of services"))
      .finally(() => setLoading(false));
  }, [user.hospital.id])

  useEffect(() => {
    handleGetListOfServices();
  }, [added, handleGetListOfServices]);

  const getServicesSearchResult = (
    searchResult: OfferedServiceDto[]
  ): any[] => {
    setSearchResults(searchResult);
    return searchResult;
  };

  const getClickedNavAction = (action: { name: string; icon: any }): string => {
    switch (action?.name?.toLowerCase()) {
      case "add service":
        setOpenModal(true);
        break;
      case "list of services":
        setOpenModal(false);
        break;
      default:
        setOpenModal(false);
        break;
    }
    setAction(action?.name);
    return action?.name;
  };

  const handleCloseModal = () => {
    setAction("List of Services");
    setOpenModal(false);
  };

  const getNavs = () => {
    const navsArr = [];

    if (userIsSuperAdmin(user) || userIsAdmin(user)) {
      navsArr.push({ name: "Add Service", icon: <MdAdd /> });
    }

    navsArr.push({ name: "List of Services", icon: <ListIcon /> });
    return navsArr;
  };

  return (
    <div className={"service-component-layout"}>
      <DashboardTopNav
        selectedAction={action}
        action={getNavs()}
        placeholder={"search service with name"}
        itemList={list}
        getSearchResult={getServicesSearchResult}
        getClickedAction={getClickedNavAction}
      />
      <div id="filter-options-container">
        <button>
          <IoFilter /> Filter
        </button>
      </div>
      <div className="service_list_notification">
        <LightBulb />
        <div className="service_notification_texts">
          <span>Below is the List of Services Offered in the Hospital.</span>
          <span> Click on “Add Service” to add new services.</span>
        </div>
      </div>
      {/* {(action === "List of Services" || action === "Edit") && ( */}
      <ServiceList
        services={searchResults.length > 0 ? searchResults : list}
        onClickAction={setAction}
        triggerSubmitted={setAdded}
      />
      {/* )} */}
      {action === "Add Service" &&
        userHasRole(user, [Authorities.ADMIN, Authorities.SUPER_ADMIN]) && (
          <Modal
            isOpen={openModal}
            onClose={handleCloseModal}
            modalTitle="Add a New Service"
          >
            <AddService
              data={{} as OfferedServiceDto}
              onClickProceed={handleCloseModal}
              triggerSubmitted={setAdded}
            />
          </Modal>
        )}
      {loading && <Spinner />}
      {serviceError && (
        <ErrorNotification
          name={"service-page-error"}
          stringErrors={serviceError}
        />
      )}
    </div>
  );
};
