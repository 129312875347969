import './Sales.scss';
import Table from './Table/Table';
import DownloadIcon from '../../../../resources/assets/svg/Dashboard/DownloadIcon';
import OpenIcon from '../../../../resources/assets/svg/Dashboard/OpenIcon';
import useDashboardHook from '../../../../customhook/useDashboardHook';
import { salesTableData } from '../../../../util/Helpers';

const Sales = () => {
	const {salesServices} = useDashboardHook()
	const tableData = [
		{
			id: 1,
			invoice: '23435354',
			salesTitle: 'hospital bed',
			salesDate: '23/12/2024',
			patientName: 'john doe',
			phone: '08176764532',
			modeOfPayment: 'cash',
			cost: '5000',
			balance: 0,
		},
		{
			id: 2,
			invoice: '23435354',
			salesTitle: 'hospital bed',
			salesDate: '23/12/2024',
			patientName: 'john doe',
			phone: '08176764532',
			modeOfPayment: 'cash',
			cost: '5000',
			balance: 0,
		},
		{
			id: 3,
			invoice: '23435354',
			salesTitle: 'hospital bed',
			salesDate: '23/12/2024',
			patientName: 'john doe',
			phone: '08176764532',
			modeOfPayment: 'cash',
			cost: '5000',
			balance: 0,
		},
		{
			id: 4,
			invoice: '23435354',
			salesTitle: 'hospital bed',
			salesDate: '23/12/2024',
			patientName: 'john doe',
			phone: '08176764532',
			modeOfPayment: 'cash',
			cost: '5000',
			balance: 0,
		},
		{
			id: 5,
			invoice: '23435354',
			salesTitle: 'hospital bed',
			salesDate: '23/12/2024',
			patientName: 'john doe',
			phone: '08176764532',
			modeOfPayment: 'cash',
			cost: '5000',
			balance: 0,
		},
		{
			id: 6,
			invoice: '23435354',
			salesTitle: 'hospital bed',
			salesDate: '23/12/2024',
			patientName: 'john doe',
			phone: '08176764532',
			modeOfPayment: 'cash',
			cost: '5000',
			balance: 0,
		},
		{
			id: 7,
			invoice: '23435354',
			salesTitle: 'hospital bed',
			salesDate: '23/12/2024',
			patientName: 'john doe',
			phone: '08176764532',
			modeOfPayment: 'cash',
			cost: '5000',
			balance: 0,
		},
		{
			id: 8,
			invoice: '23435354',
			salesTitle: 'hospital bed',
			salesDate: '23/12/2024',
			patientName: 'john doe',
			phone: '08176764532',
			modeOfPayment: 'cash',
			cost: '5000',
			balance: 0,
		},
		{
			id: 9,
			invoice: '23435354',
			salesTitle: 'hospital bed',
			salesDate: '23/12/2024',
			patientName: 'john doe',
			phone: '08176764532',
			modeOfPayment: 'cash',
			cost: '5000',
			balance: 0,
		},
		{
			id: 10,
			invoice: '23435354',
			salesTitle: 'hospital bed',
			salesDate: '23/12/2024',
			patientName: 'john doe',
			phone: '08176764532',
			modeOfPayment: 'cash',
			cost: '5000',
			balance: 0,
		},
	];
	return (
		<div className='sales_container'>
			<header className='sales_header'>
				<h2>Sales</h2>
				<div className='download_open_container'>
					<span>
						<DownloadIcon />
						Download
					</span>
					<span>
						<OpenIcon />
						Open
					</span>
				</div>
			</header>
			<Table rowData={salesTableData(salesServices?.listOfTodaySales!)} />
		</div>
	);
};

export default Sales;
