import { BillingData } from "../types/billing/BillingsListDTO";
import { PaymentSummaryDTO } from "../types/billing/PaymentSummaryDTO";
import { YearsMonthsDTO } from "../types/billing/YearsMonthsDTO";
import { ApiService } from "./ApiService";

const baseUrl = `${process.env.ACC_PROXY ?? process.env.REACT_APP_ACC_PROXY}`;

class BillingServices extends ApiService {
  async getYearsAndMonths(hospitalId: number): Promise<YearsMonthsDTO[]> {
    const response = await fetch(
      `${baseUrl}billing/${hospitalId}/year-months`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  }

  async getPaymentSummary({
    hospitalId,
    yr,
    mnt,
  }: {
    hospitalId: number;
    yr: number;
    mnt: string;
  }): Promise<PaymentSummaryDTO> {
    const response = await fetch(
      `${baseUrl}billing/${hospitalId}/monthly?yr=${yr}&mnt=${mnt}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    // const paymentSummary = await this.apiResponseHandler(response);
    // return await paymentSummary.json();
    return await response.json();
  }
  async getBills({
    hospitalId,
    yr,
  }: {
    hospitalId: number;
    yr: number;
  }): Promise<BillingData[]> {
    const response = await fetch(`${baseUrl}billing/${hospitalId}?yr=${yr}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    // const paymentSummary = await this.apiResponseHandler(response);
    // return await paymentSummary.json();
    return await response.json();
  }
  async makePayment(hospitalId: number, payload: FormData) {
    const response = await fetch(
      `${baseUrl}billing/${hospitalId}/payment-slip/upload`,
      {
        method: "POST",
        body: payload,
      }
    );
    return await response.json();
  }
}

const billingServices = new BillingServices();
export default billingServices;

