import { JSX, useState } from "react";
import "./AppContainer.scss";
import { Header } from "../header/Header";
import { Footer } from "../footer/Footer";
import { UserCtx } from "../../context/UserCtx";
import WhoAmI from "../../userAuth/WhoAmI";
import { getLoggedInUser } from "../../util/Helpers";

interface ApplicationContainerProps {
  children: JSX.Element;
}

export const AppContainer = (props: ApplicationContainerProps) => {
  const loggedUser = getLoggedInUser();
  const [whoAmI, setWhoAmI] = useState<WhoAmI>(
    loggedUser === null ? ({} as WhoAmI) : loggedUser
  );
  //TODO: make request to BE for fetch/refresh/validate and setWhoAmI

  return (
    <>
      <div className={"app-wrapper"}>
        <Header />
        <UserCtx value={whoAmI}>
          <div className={"app-content-wrapper"}>{props.children}</div>
        </UserCtx>
        <Footer />
      </div>
    </>
  );
};
