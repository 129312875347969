import HospitalDao from "../types/HospitalDao";
import { ApiService } from "./ApiService";
import {UserData} from "../types/Helper";
import {UserDto} from "../types/UserDto";

const baseUrl = `${process.env.AUTH_PROXY ?? process.env.REACT_APP_AUTH_PROXY}`;

class AdminService extends ApiService {
  async getAdminInfo(hospitalId: number): Promise<HospitalDao> {
    const response = await fetch(baseUrl + "admin/hospital/" + hospitalId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const responseResult = await this.apiResponseHandler(response);
    return await responseResult.json();
  }

  async updateHospitalInfo(hospital: HospitalDao): Promise<HospitalDao> {
    const response = await fetch(baseUrl + "admin/hospital", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(hospital),
    });
    const infoToUpdate = await this.apiResponseHandler(response);
    return await infoToUpdate.json();
  }

  getStaffCount = async (hospitalId: number): Promise<number> => {
    const response = await fetch(`${baseUrl}admin/${hospitalId}/staff-count`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const staffCount = await this.apiResponseHandler(response);
    return await staffCount.json();
  }
  async updateEmployeeInfo(hospitalId: number, userData: UserData): Promise<UserDto> {
    const response = await fetch(baseUrl + `admin/${hospitalId}/employee`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });
    const infoToUpdate = await this.apiResponseHandler(response);
    return await infoToUpdate.json();
  }

  async enableOrDisableUser(hospitalId: number, username: string): Promise<Boolean> {
    const response = await fetch(baseUrl + `admin/${hospitalId}/user/disable`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: username
    })

    const disabled = await this.apiResponseHandler(response)
    return await disabled.json()
  }
}

const adminService = new AdminService();
export default adminService;
