import { useCallback, useEffect, useState } from "react";
import "./ManageAccount.scss";
import { Listing } from "../../components/listing/Listing";
import { Authorities } from "../../userAuth/WhoAmI";
import { Modal } from "../../components/modal/Modal";
import { useWhoAmI } from "../../context/UserCtx";
import { MdAdd } from "react-icons/md";
import { capitalizeFirstCharacter, restructureUsers } from "../../util/Helpers";
import { userHasRole, userIsAdmin, userIsSuperAdmin } from "../../util/UserRolesHelper";
import { Button } from "../../components/button/Button";
import { AddUser } from "../adduser/AddUser";
import { BiUser } from "react-icons/bi";
import { UserData } from "../../types/Helper";
import { ActionType, DashboardTopNav } from "../../components/dashboardnav/DashboardTopNav";
import { BsInfo } from "react-icons/bs";
import userService from "../../services/UserService";
import { UserDto } from "../../types/UserDto";
import { HospitalInfo } from "./hospitalInfo/HospitalInfo";
import UserInfo from "./userInfo/UserInfo";
import { ErrorNotification } from "../../modules/errornotification/ErrorNotification";
import { ToggleSwitch } from "../../components/switch/ToggleSwitch";
import adminService from "../../services/AdminService";
import { UsersDtoCtx } from "../../context/UsersDtoCtx";

type UserAccountActions = 'hospital info' | 'list' | 'add' | 'edit' | 'delete' | 'user info' | 'enable' | 'disable'

//type UserWithoutAuthorities = Omit<UserDto, 'authorities'>;

interface ManageAccountActions {
    add: boolean;
    edit: boolean;
    enable: boolean;
    disable: boolean;
    delete?: boolean;
}

export const ManageAccount = () => {
    const user = useWhoAmI();
    const isSuperAdmin = userIsSuperAdmin(user)
    const [action, setAction] = useState<UserAccountActions>(isSuperAdmin ? 'hospital info' : 'user info');
    const [selectedAction, setSelectedAction] = useState<string>(isSuperAdmin ? 'hospital info' : 'user info');
    const [listOfUsers, setListOfUsers] = useState<UserDto[]>([]);
    const [userData, setUserData] = useState<UserData>({} as UserData);
    //const [omitAuthorities, setOmitAuthorities] = useState<UserData[]>([]);
    const [manageAccountError, setManageAccountError] = useState<string>();
    const [openModal, setOpenModal] = useState<ManageAccountActions>({} as ManageAccountActions)
    const [userIsActive, setUserIsActive] = useState(!!userData?.active);
    const rolesAllowedAccess: string[] = [Authorities.ADMIN, Authorities.SUPER_ADMIN];

    const getListAllUsersHandler =useCallback(() => {
        userHasRole(user, ['ADMIN', 'SUPER_ADMIN']) ? (
            userService.getAllUsers(user.hospital.id)
                .then((data: UserDto[]) => {
                    setListOfUsers(data);
                    //omitUserAuthoritiesHandler(data);
                }).catch(() => {
                    setManageAccountError("Technical error occurred while getting all users, please try again later!")
                })
        ) : setAction('hospital info')
    },[user,]) 

    /*const omitUserAuthoritiesHandler = (data: UserDto[]) => {
        const newArray: UserDto[] = []
        data?.map(({authorities, dob, ...rest}) => {
            newArray.push(rest as UserDto)
        });
        //setOmitAuthorities(newArray);
    }*/

    const handleToggle = (toggled: boolean) => {
        setUserIsActive(toggled)
        adminService.enableOrDisableUser(user.hospital.id, userData.username)
            .then((response) => {
                setUserData((prevState) => ({ ...prevState, active: !!response }))
                getListAllUsersHandler()
            }).catch(() => {
                setManageAccountError('Technical error occurred while disabling or enabling user')
            })
    };

    const handleSelectDefaultAction = useCallback(()=>{
        setSelectedAction(isSuperAdmin ? 'hospital info' : 'user info')
        setAction(isSuperAdmin ? 'hospital info' : 'user info')
    },[isSuperAdmin])

    useEffect(() => {
        getListAllUsersHandler();
        handleSelectDefaultAction()
    }, [getListAllUsersHandler, handleSelectDefaultAction]);

    const getClickedAction = (activeAction: UserAccountActions) => {
        setAction(activeAction ?? action)

        if (activeAction === 'edit') {
            setOpenModal({ add: false, edit: true, enable: false, disable: false })
        }

        if (activeAction === "enable") {
            setOpenModal({ add: false, edit: false, enable: true, disable: false });
        }

        if (activeAction === "disable") {
            setOpenModal({ add: false, edit: false, enable: false, disable: true });
        }

        if (activeAction === "delete") {
            setOpenModal({ add: false, edit: false, enable: false, disable: false, delete: true });
        }
    };

    const getClickedUser = (selectedUser: UserData) => {
        const userClicked = listOfUsers?.find((usr) => usr?.username === selectedUser?.username)
        userClicked && setUserData(userClicked as unknown as UserData);
        userClicked && setUserIsActive(userClicked?.active);
    };

    const closeModalHandler = (prop: string) => {
        setOpenModal((prevState) => ({ ...prevState, [prop]: false }))
        setAction('list')
        setSelectedAction('users')
    };

    const getFoundUser = (foundUser: any[]): any => {
        //console.log("found user", foundUser);
    };

    const getClickedNavAction = (navAction: ActionType): any => {
        setAction(navAction.name as UserAccountActions)
        switch (navAction?.name.toLowerCase()) {
            case 'users':
                setAction('list')
                setSelectedAction('users')
                setOpenModal({ add: false, edit: false, enable: false, disable: false });
                break;
            case 'add user':
                setAction('add')
                setSelectedAction('add user')
                setOpenModal({ add: true, edit: false, enable: false, disable: false });
                break;
            case 'hospital info':
                setAction('hospital info')
                setSelectedAction('hospital info')
                setOpenModal({ add: true, edit: false, enable: false, disable: false });
                break;
            case 'user info':
                setAction('user info')
                setSelectedAction('user info')
                setOpenModal({ add: false, edit: false, enable: false, disable: false });
                break;
            default:
                setAction('hospital info')
                break;
        }
    }

    const getNavs = () => {
        const navsArr = [];

        if (userIsSuperAdmin(user)) {
            navsArr.push({ name: 'Hospital Info', icon: <BsInfo /> })
            navsArr.push({ name: "Add User", icon: <MdAdd /> })
        }

        if (userIsSuperAdmin(user) || userIsAdmin(user)) {
            navsArr.push({ name: 'Users', icon: <BiUser /> })
        }

        navsArr.push({ name: 'User Info', icon: <BsInfo /> })
        return navsArr;
    }

    return (
        <div className={"manage-account-component"}>
            <div>
                {
                    manageAccountError && <ErrorNotification name={'man-acc-error'} stringErrors={manageAccountError} />
                }
            </div>
            <div>
                {userHasRole(user, rolesAllowedAccess) && (
                    <UsersDtoCtx>
                        <DashboardTopNav
                            selectedAction={selectedAction}
                            action={getNavs()}
                            placeholder={"search user with id or dob+lastname"}
                            itemList={listOfUsers}
                            getSearchResult={getFoundUser}
                            getClickedAction={getClickedNavAction}
                            hideSearchBar={action !== 'list' as UserAccountActions || listOfUsers?.length < 1}
                        />

                        {action === 'hospital info' && (
                            <div>
                                <HospitalInfo />
                            </div>
                        )}

                        {
                            action === 'list' &&
                            <section className={'service_list_wrapper'}>
                                <Listing
                                    items={restructureUsers(listOfUsers)}
                                    withAction={true}
                                    getAction={getClickedAction}
                                    getClickedItem={getClickedUser}
                                    actions={[
                                        "edit",
                                        `${userIsActive ? 'disable' : 'enable'}`,
                                        `${userHasRole(user, [Authorities.SUPER_ADMIN]) ? "delete" : ''}`
                                    ]}
                                />
                            </section>

                        }
                    </UsersDtoCtx>
                )}
            </div>

            {
                action === 'user info' && (
                    <UserInfo />
                )
            }

            {action === "add" && (
                <div>
                    <Modal
                        isOpen={openModal.add}
                        onClose={() => closeModalHandler('add')}
                        modalTitle={"Add New User"}
                    >
                        <AddUser callBack={getListAllUsersHandler} />
                    </Modal>
                </div>
            )}

            {action === "edit" && (
                <div>
                    <Modal
                        isOpen={openModal.edit}
                        onClose={() => closeModalHandler('edit')}
                        modalTitle={"Edit User"}
                    >
                        {
                            userData && <AddUser userData={userData} isEdit={true} callBack={getListAllUsersHandler} />
                        }

                    </Modal>
                </div>
            )}

            {action === "enable" && (
                <div className={'add-user-modal-wrapper'}>
                    <Modal
                        isOpen={openModal.enable}
                        onClose={() => closeModalHandler('enable')}
                        modalTitle={userIsActive ? 'Disable user' : 'Enable user'}
                    >
                        {
                            userData && <div>
                                <ToggleSwitch label={userIsActive ? 'Disable user' + userData.firstname + ' ' + userData.lastname : 'Enable user: ' + userData.firstname + ' ' + userData.lastname}
                                    onToggle={handleToggle} toggled={userIsActive} />
                            </div>
                        }

                    </Modal>
                </div>
            )}

            {action === "disable" && (
                <div className={'add-user-modal-wrapper'}>
                    <Modal
                        isOpen={openModal.disable}
                        onClose={() => closeModalHandler('disable')}
                        modalTitle={userIsActive ? 'Disable user' : 'Enable user'}
                    >
                        {
                            userData && <div>
                                <ToggleSwitch label={userIsActive ? 'Disable this user: ' + userData.firstname + ' ' + userData.lastname : 'Enable this user: ' + userData.firstname + ' ' + userData.lastname}
                                    onToggle={handleToggle} toggled={userIsActive} />
                            </div>
                        }

                    </Modal>
                </div>
            )}

            {
                (action === 'delete') && (
                    <div className={'add-user-modal-wrapper'}>
                        <Modal isOpen={openModal.delete!}
                            onClose={() => closeModalHandler('delete')}
                            modalTitle={`${capitalizeFirstCharacter(action)} User`}
                        >
                            <p>
                                {`Are you sure you want to ${action} this user?`}
                            </p>

                            <div className={'manage-user-btn-wrapper'}>
                                <Button id={'delete-user'} name={'delete-user'}
                                    buttonName={`${capitalizeFirstCharacter(action)}`}
                                    onClick={() => console.log('deleteUserHandler')} />
                            </div>
                        </Modal>
                    </div>
                )
            }
        </div>
    )
}