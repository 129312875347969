import {ApiService} from "../ApiService";
import {Month, OldMedicalRecords} from "../../types/OldMedicalRecords";
import {blobToBase64} from "../../util/Helpers";

const authBaseUrl = `${process.env.ACC_PROXY ?? process.env.REACT_APP_ACC_PROXY}records`;
const accBaseUrl = `${process.env.ACC_PROXY ?? process.env.REACT_APP_ACC_PROXY}records`;

class OldMedicalRecordsService extends ApiService {
    async uploadOldRecord(file: Blob | string, hospitalId: number, patientId: number, yr: number, mnt: Month): Promise<string> {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const fetchResult = await fetch(`${authBaseUrl}/${hospitalId}/${patientId}?year=${yr}&month=${mnt}`, {
                method: "POST",
                body: formData,
            })

            const blob = await fetchResult.blob();
            return blobToBase64(blob);
        } catch (error) {
            console.error('Error uploading file:', error);
            return '';
        }
    }

    async getPatientRecordList(hospitalId: number, patientId: number, yr?: number, mnt?: string): Promise<OldMedicalRecords[]>  {
        const response = await fetch(`${authBaseUrl}/${hospitalId}/${patientId}?${yr ?? ''}&mnt=${mnt ?? ''}`, {
            method: "GET"
        })

        const oldRecordsArr = await this.apiResponseHandler(response);
        return oldRecordsArr.json();
    }

    async getOldRecordById(fileId: number): Promise<string> {
        try {
            const response = await fetch(`${accBaseUrl}/old-medical-record?fileId=${fileId}`, {
                method: "GET"
            })

            const blob = await response.blob();
            return blobToBase64(blob);
        } catch (error) {
            console.error('Error getting a file:', error);
            return '';
        }
    }
}

const oldMedicalRecordsService = new OldMedicalRecordsService();
export default oldMedicalRecordsService;