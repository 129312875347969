import "./TextArea.scss";
import React, {TextareaHTMLAttributes} from "react";
import {FieldErrors, FieldValues, UseFormRegister} from "react-hook-form";

interface TextAreaProps extends React.HTMLProps<TextareaHTMLAttributes<any>> {
  name: string;
  label: string;
  onChange?: any;
  required: boolean;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  error?: string;
  errors?: FieldErrors;
  stringErrors?: string;
  register?: UseFormRegister<FieldValues>;
  className?: string;
}

export const TextArea = (props: TextAreaProps) => {
  const register = props.register && (props.register as UseFormRegister<FieldValues>);
  let fieldErrors = props.errors && props.errors[props.name as string];
  let stringError = props.stringErrors;
  let error: any

  if (fieldErrors && !stringError) {
    error = fieldErrors.message
  }

  if (!fieldErrors && stringError) {
    error = stringError
  }

  return (
    <div className={"textarea-component"}>
      <div className={"textarea-component-label"}>
        <label>{props.label}</label>
      </div>
      <div className={"textarea-component-textarea-wrapper"}>
        <textarea
          id={props.id && props.id}
          className={props.className}
          placeholder={props.placeholder}
          value={props.value || ''}
          required={props.required}
          minLength={props.minLength}
          maxLength={props.maxLength}
          {...(register
            ? register(props.name, {
              required: props.required,
              min: props.min,
              max: props.max,
              onChange: (e: any) => {
                props.onChange(e);
              },
              onBlur: props.onBlur && props.onBlur,
            })
            : register)}
        />
        {error && (
          <p className={"textarea-component-error"}>{error}</p>
        )}
      </div>
    </div>
  );
};
