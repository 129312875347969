import React from "react";

const RangeIcon = () => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.99042 10.266C9.06591 10.266 10.7484 8.58349 10.7484 6.508C10.7484 4.43251 9.06591 2.75 6.99042 2.75C4.91494 2.75 3.23242 4.43251 3.23242 6.508C3.23242 8.58349 4.91494 10.266 6.99042 10.266Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.01 21.2499C19.0854 21.2499 20.768 19.5674 20.768 17.4919C20.768 15.4164 19.0854 13.7339 17.01 13.7339C14.9345 13.7339 13.252 15.4164 13.252 17.4919C13.252 19.5674 14.9345 21.2499 17.01 21.2499Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.48633 21.25L18.5133 2.75" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

};

export default RangeIcon;
