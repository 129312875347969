import { useRef } from "react";
import { Checkbox } from "../checkbox/Checkbox";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { DaysCheckBoxTypes } from "../../types/services/ServiceTypes";
type Props = {
  allDays: DaysCheckBoxTypes[];
  handleCheck: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  className?: string;
};

const DaysCheckbox = (props: Props) => {
  const checkBoxRef = useRef<HTMLDivElement>(null);


  return (
    <div ref={checkBoxRef} className={`${props.className} checkbox-group`}>
      {props.allDays.map((day, index) => {
        /* This is not a good code
				//TODO: please remove all js code from the html. do all the logic in ts section above and perform only rendering here
				const [checked, setChecked] = useState(
					values.includes(day.value) ? true : false
				);*/
        return (
          <Checkbox
            key={day.value}
            checked={day.checked}
            id={day.value}
            errors={{}}
            label={day.label}
            name={day.value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.handleCheck(event, index)
            }
            register={(() => {}) as unknown as UseFormRegister<FieldValues>}
          />
        );
      })}
    </div>
  );
};

export default DaysCheckbox;
