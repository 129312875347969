// components/Modal.tsx
import React from 'react';
import './DashboardComponentModal.scss';
import ProfileIcon from '../../../resources/assets/svg/Dashboard/Modal/ProfileIcon';
import LightIcon from '../../../resources/assets/svg/Dashboard/Modal/LightIcon';
import CloseIcon from '../../../resources/assets/svg/Dashboard/CloseIcon';
import { useDashboardContext } from '../../../context/DashboardCtx';
import { Button } from '../../../components/button/Button';

interface ModalProps {
	isOpen: boolean;
	onClose?: () => void;
}

const DashboardComponentModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
	const { options, toggleOption, handleSave } = useDashboardContext();

	return (
		<section data-isopen={isOpen} className={'modal'}>
			<div className={'content'}>
				<div className={'content_header'}>
					<div className={'header_close_customize'}>
						<span onClick={onClose} style={{ cursor: 'pointer' }}>
							<CloseIcon />
						</span>
						<h2>Customize</h2>
					</div>
					<ProfileIcon />
				</div>
				<div className={'content_sub_header'}>
					<LightIcon />
					<p>Customize what you wish to see on your Dashboard</p>
				</div>

				<ul className='lists'>
					{options
						.filter((option) => option.visible)
						.map((option) => (
							<li key={option.id}>
								<div className='icon_label_container'>
									<span className='icon'>{option.icon}</span>
									<span className='label'>{option.label}</span>
								</div>
								<label className='switch'>
									<input type='checkbox' checked={option.enabled} onChange={() => toggleOption(option.id)} />
									<span className={'slider'}></span>
								</label>
							</li>
						))}
				</ul>
				<section className={'btn_container'}>
					<Button
						id='modal-btn'
						buttonName='Save'
						onClick={() => {
							handleSave();
							onClose && onClose();
						}}
						className={'save_btn'}
					/>
				</section>
			</div>
		</section>
	);
};

export default DashboardComponentModal;
