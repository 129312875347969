import './MedicalHistory.scss';
import {useLocation} from 'react-router-dom';
import CalendarDropDown from './CalendarDropDown/CalendarDropDown';
import {useEffect, useState} from 'react';
import YearlyFolder from './YearlyFolder/YearlyFolder';
import {OldMedicalRecords} from "../../../types/OldMedicalRecords";
import oldMedicalRecordsService from "../../../services/imageservices/OldMedicalRecordsService";
import {useWhoAmI} from "../../../context/UserCtx";
import PatientDao from "../../../types/PatientDao";

type YearMonthDto = {year: number, month: string};

export const MedicalHistory = () => {
    const location = useLocation();
    const [patient,] = useState<PatientDao>(location?.state?.patient || {} as PatientDao);
    const {patientDao} = {...patient}
    const user = useWhoAmI();
    const [yearMonthDto,setYearMonthDto] = useState<YearMonthDto>({} as YearMonthDto)
    //const [oldRecords, setOldRecords] = useState<OldMedicalRecords[]>([OldMedicalRecordsMock, OldMedicalRecordsMock2]); // should come from backend for already saved folders
    const [oldRecords, setOldRecords] = useState<OldMedicalRecords[]>([]); // from backend
    const [newOldRecordToUpload, setNewOldRecordToUpload] = useState<OldMedicalRecords>({} as OldMedicalRecords); // new one to be sent to BE
    const [oldRecordError, setOldRecordError] = useState<string>('')

    // fetch all old records for a given patient Id
    const fetchOldRecords = () => {
        oldMedicalRecordsService.getPatientRecordList(user?.hospital?.id, patientDao?.id)
            .then((data: OldMedicalRecords[]) => {
                console.log('list from BE', data)
                setOldRecords(data);
            }).catch(() => {
                setOldRecordError('Technical error occurred while fetching old records')
        })
    }

    useEffect(() => {
        fetchOldRecords()
    }, []);

    const getSelectedYear = (selectedYr: number) => {
        setNewOldRecordToUpload({...newOldRecordToUpload, year: selectedYr, months: []})
    }

    return (
        <div className={'patient_history'}>
            <div>
                <section>
                    <div className='patient_history-old_transaction_history_body'>
                        <div>
                            <p>Upload the paper medical record for this patient here.</p>
                            <br/>
                            <p>To upload new record, click add year and select the year, then select month and
                                uplaod the file</p>
                        </div>
                        <div>
                            <CalendarDropDown getSelectedYear={getSelectedYear}/>
                        </div>
                    </div>

                    {
                        newOldRecordToUpload.year > 1899 &&
                        <div style={{margin: '30px 0'}}>
                            <YearlyFolder oldRecords={newOldRecordToUpload} newRecord={true}/>
                        </div>
                    }

                    {
                        oldRecords?.map((oldRec, index) => (
                            <div key={Math.random().toFixed(index)} style={{margin: '30px 0'}}>
                                <YearlyFolder oldRecords={oldRec} newRecord={false}/>
                            </div>
                        ))
                    }
                </section>
            </div>
        </div>
    );
};
