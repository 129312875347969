import { useEffect, useState } from "react";
import imageIllustration1 from "../../resources/assets/images/doctor_and_nurse.png";
import imageIllustrationn2 from "../../resources/assets/images/doctor_and_nurse_1.png";
import imageIllustrationn3 from "../../resources/assets/images/bar_chart.png";
import "./AsideBanner.scss";

function AsideBanner() {
	const [currentSlide, setCurrentSlide] = useState(1);

	useEffect(() => {
		const slideContainer = document.querySelector(
			"#carousel"
		) as HTMLDivElement;
		const slideContainerWidth: number = slideContainer.scrollWidth;

		slideContainer.scrollTo({
			top: 0,
			left: (slideContainerWidth / 3) * (currentSlide - 1),
		});

		const interval = setInterval(() => {
			if (currentSlide === 3) return setCurrentSlide(1);
			setCurrentSlide(currentSlide + 1);
		}, 3000);
		return () => clearInterval(interval);
	});
	return (
		<div id="aside-banner">
			<div>
				<h1>Welcome Back</h1>
				<p>Sign Up or Login to view your profile</p>
			</div>
			<div id="carousel-container">
				<div id="carousel">
					<div className="carousel-item">
						<img
							loading="eager"
							src={imageIllustration1}
							alt="A doctor and his nurse"
							height={400}
							style={{ aspectRatio: "1/1" }}
						/>
					</div>
					<div className="carousel-item">
						<img
							loading="eager"
							src={imageIllustrationn2}
							alt="A doctor and his nurse"
							height={400}
							width={400}
						/>
					</div>
					<div className="carousel-item">
						<img
							loading="eager"
							src={imageIllustrationn3}
							alt="A bar chart"
							height={400}
							width={400}
						/>
					</div>
				</div>
				<div id="carousel-controls">
					<button
						onClick={() => setCurrentSlide(1)}
						className={`${currentSlide === 1 ? "active" : ""}`}
					></button>
					<button
						onClick={() => setCurrentSlide(2)}
						className={`${currentSlide === 2 ? "active" : ""}`}
					></button>
					<button
						onClick={() => setCurrentSlide(3)}
						className={`${currentSlide === 3 ? "active" : ""}`}
					></button>
				</div>
			</div>
		</div>
	);
}

export default AsideBanner;
