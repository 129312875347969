import { useEffect, useState } from "react";
import "./Register.scss";
import { useForm } from "react-hook-form";
import { RegistrationData } from "../../types/Helper";
import { ParamsEnum } from "../../util/ParamsEnum";
import { userIsLogged } from "../../util/Helpers";
import registerService from "../../services/RegisterService";
import WhoAmI from "../../userAuth/WhoAmI";
import { ErrorNotification } from "../../modules/errornotification/ErrorNotification";
import CompanyRegForm from "../../components/companyRegistrationForm/CompanyRegForm";
import AsideBanner from "../../components/asideBanner/AsideBanner";
import EmailVerificationForm from "../../components/emailVerificationForm/EmailVerificationForm";
import RegistrationForm from "../../components/registrationForm/RegistrationForm";
import { RegistrationSuccessModal } from "../../components/registrationSuccessModal/RegistrationSuccessModal";
import { Spinner } from "../../components/spinner/Spinner";

export type FormSubmissionStatus = "" | "failed";

export type RegistrationStage =
  | "main_registration"
  | "email_verification"
  | "company_registration"
  | "success";

export function Register() {
  const {
    register,
    formState: { errors, isValid },
    setError,
    trigger,
    getValues, clearErrors
  } = useForm({ mode: "onBlur" });

  const [userData, setUserData] = useState<RegistrationData>(
    {} as RegistrationData
  );
  const [formSubmissionStatus, setFormSubmissionStatus] =
    useState<FormSubmissionStatus>("");
  const [registrationStage, setRegistrationStage] =
    useState<RegistrationStage>("main_registration");
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [whoAmI, setWhoAmI] = useState<WhoAmI>({} as WhoAmI);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    try {
      if (userIsLogged()) {
        window.location.href = ParamsEnum.DASHBOARD;
        return;
      }
    } catch (error) {
      return;
    }
    setLoading(false);
  }, [isSubmittingForm, formSubmissionStatus]);

  const userDataHandler = (evt: any) => {
    const { target } = evt;
    const { name, value } = target;

    const updateUserData = { ...userData };
    (updateUserData[name as keyof RegistrationData] as any) = (name === 'email' ? value.toLowerCase() : value).trim();
    setUserData(updateUserData)
  };

  const handleAcceptTerms = (state: boolean) => {
    setUserData((prevData) => ({ ...prevData, privacyConsent: state }))
    state && clearErrors('privacyConsent')
  }

  const handleSubmit = () => {
    trigger().then();
    if (!userData.privacyConsent) {
      return setError('privacyConsent', { message: 'Kindly accept terms to proceed' })
    }
    if (isValid) {
      setIsSubmittingForm(true);
      registerService
        .registerSuperAdmin(userData)
        .then((whoAmI) => {
          setWhoAmI(whoAmI);
          setRegistrationStage("email_verification");
          setIsSubmittingForm(false);
        })
        .catch(() => {
          setFormSubmissionStatus("failed");
          setTimeout(() => setFormSubmissionStatus(""), 5000);
          setIsSubmittingForm(false);
        });
    }
  };
  return (
    <>
      {
        !loading &&
        <>
          {registrationStage !== "company_registration" && (
            <div className={"signup-component-wrapper"}>
              <main id="signup-main">
                <AsideBanner />
                <span style={{ clear: "left" }}></span>
                <div id="divider"></div>
                {registrationStage === "email_verification" && (
                  <EmailVerificationForm
                    setRegistrationStage={setRegistrationStage}
                    isSubmittingForm={isSubmittingForm}
                    setFormSubmissionStatus={setFormSubmissionStatus}
                    setIsSubmittingForm={setIsSubmittingForm}
                    hospitalId={whoAmI?.hospital?.id}
                    userId={whoAmI?.userId}
                  />
                )}

                {registrationStage === "main_registration" && (
                  <RegistrationForm
                    errors={errors}
                    handleSubmit={handleSubmit}
                    isSubmittingForm={isSubmittingForm}
                    userData={userData}
                    userDataHandler={userDataHandler}
                    register={register}
                    setError={setError}
                    getValues={getValues}
                    handleAcceptTerms={handleAcceptTerms}
                  />
                )}

                {registrationStage === "success" && (
                  <RegistrationSuccessModal
                    message={
                      "Congratulations, your account was created successfully, we will contact you soonest!"
                    }
                  />
                )}
              </main>

              {errors && errors["account-verification-input"]?.message && (
                <div className={"signup-error-wrapper"}>
                  <span></span> {/*Do not delete, used for styling*/}
                  <span>
                    <ErrorNotification
                      name={"code-verification-error"}
                      stringErrors={String(
                        errors["account-verification-input"]?.message
                      )}
                    />
                  </span>
                </div>
              )}

              {formSubmissionStatus === "failed" && (
                <div className={"signup-error-wrapper"}>
                  <span></span> {/*Do not delete, used for styling*/}
                  <span>
                    <ErrorNotification
                      name={"signup-error"}
                      stringErrors={"Something went wrong, please try again later"}
                    />
                  </span>
                </div>
              )}
            </div>
          )}

          {registrationStage === "company_registration" && (
            <CompanyRegForm
              whoAmI={whoAmI}
              setRegistrationStage={setRegistrationStage}
            />
          )}
        </>
      }

      {
        loading && <Spinner />
      }
    </>
  );
}
