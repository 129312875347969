import React, {useState} from "react";
import './CustomHeader.scss';
import ArrowDownIcon from '../../../../resources/assets/svg/ArrowDownIcon';

interface Props {
    title: string;
    withIcon?: boolean;
    deg?: number
}

const CustomHeader = ({title, withIcon, deg = 180}: Props) => {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <div className='custom_header'
             data-rotate={deg}
             data-isopen={open}
             onClick={() => setOpen(!open)}
        >
            <header>
                {title}
                {
                    withIcon &&
                    <span
                        className='arrow_down_icon'
                        data-rotate={deg}
                        data-isopen={open}
                        onClick={() => setOpen(!open)}
                    >
                      <ArrowDownIcon/>
                  </span>
                }
            </header>
        </div>
    )
};

export default CustomHeader;
