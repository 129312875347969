import CloseIcon from "../../../resources/assets/svg/Dashboard/CloseIcon";
import CheckIcon from "../../../resources/assets/svg/Dashboard/CheckIcon";

const UploadSuccess: React.FC<{ onClickClose: () => void }> = ({
  onClickClose,
}) => {
  return (
    <div className="billing_upload_success_wrapper">
      <CheckIcon />
      <span>File has been uploaded </span>
      <span>Successfully</span>
      <i
        className={"billing_close_icon remove_file_icon"}
        onClick={onClickClose}
      >
        <CloseIcon />
      </i>
    </div>
  );
};

export default UploadSuccess;
