import { SearchBar } from "../searchbar/SearchBar";
import { useForm } from "react-hook-form";
import "./DashboardTopNav.scss";
import React, { useEffect, useRef, useState } from "react";
import { capitalizeFirstCharacter } from "../../util/Helpers";
import { LightBulb } from "../../resources/assets/svg/servicepage/LightBulb";

export interface ActionType {
    name: string;
    icon: React.ReactNode;
}

interface SearchEndpointInfo {
    endpoint: string
    httpMethod?: string
    contentType?: string
}

interface PageNavsProps<T> {
    action: ActionType[];
    onChange?: any;
    placeholder?: string;
    itemList?: T[];
    hideSearchBar?: boolean;
    title?: string
    searchEndpoint?: SearchEndpointInfo
    hasLightBulb?: boolean
    message?:string
    selectedAction: string

    getSearchResult?(result: T[]): T[];

    getClickedAction?: (action: ActionType) => void;

    getSearchError?(errMsg: string): string
}

export const DashboardTopNav = (props: PageNavsProps<any>) => {
    const {
        register,
        formState: { errors },
    } = useForm({ mode: "onBlur" });
    const [searchQuery, setSearchQuery] = useState<string>("");
    const patientSearchRef = useRef<HTMLDivElement>(null);
    const [clickedAction, setClickedAction] = useState<string>(props.selectedAction);

    useEffect(() => {
        props.selectedAction && setClickedAction(props.selectedAction)
    }, [props.selectedAction]);

    const searchItemHandler = (evt: any) => {
        const {
            httpMethod,
            endpoint,
            contentType
        } = props?.searchEndpoint ? props?.searchEndpoint : {} as SearchEndpointInfo;

        const { value } = evt.target;

        endpoint && fetch(endpoint + value, {
            method: httpMethod ?? "GET",
            headers: {
                "Content-Type": contentType ?? "application/json",
            },
        }).then((response) => {
            const result = response.json();
            result.then((data) => {
                props.getSearchResult && props.getSearchResult(data)
            }).catch(() => {
                props.getSearchError && props.getSearchError('Error occurred while search for ' + props?.searchEndpoint)
            })
        })

    }

    const outSideClickHandler = (evt: any) => {
        if (patientSearchRef && patientSearchRef.current?.contains(evt.target)) {
            evt.target.value = ""; //TODO
        }
    };

    const actionClickedHandler = (activeAction: ActionType) => {
        props.getClickedAction && props.getClickedAction(activeAction);
        setClickedAction(activeAction.name);
    };
    const isActiveAction = (action: string) => {
        return action.toLowerCase() === clickedAction.toLowerCase()
    }
    
    return (
        <div className={"head-liner-component"} onClick={outSideClickHandler}>
            <div className={"patient-headline-text"}>
                <h1>
                    {props.title && capitalizeFirstCharacter(props.title)}
                </h1>
                <h1 className={"flex-align-end"}>
                    {props.action &&
                        props.action.map((eachAct, index) => (
                            <span
                                key={Math.random().toFixed(index)}
                                className={isActiveAction(eachAct.name) ? 'active_headerLine_span' : 'headerLine-span'}
                                onClick={() => actionClickedHandler(eachAct)} aria-disabled={isActiveAction(eachAct.name)}
                            >
                                {eachAct.icon || ""} {capitalizeFirstCharacter(eachAct?.name)}
                            </span>
                        ))}
                </h1>
            </div>

            {!props.hideSearchBar && props?.itemList && props?.itemList?.length > 0 && (
                <div className={"patient-searchbar-wrapper"}>
                    <SearchBar
                        ref={patientSearchRef}
                        id={"link-item-search"}
                        name={"link-item-search"}
                        defaultValue={searchQuery}
                        placeholder={props?.placeholder || 'Search item'}
                        onChange={(e: any) => searchItemHandler(e)}
                        register={register}
                        errors={errors}
                        pattern={{
                            value: /^(?! )[A-Za-z0-9\s]*$/,
                            message: "Only characters a - z and numbers are allowed",
                        }}
                    />
                </div>
            )}
            {props.hasLightBulb && <p className="lightbulb_message"><LightBulb />{ props.message}</p> }
        </div>
    );
};