import { WiTime4 } from "react-icons/wi";
import "./InputTime.scss";
import { useEffect, useRef, useState } from "react";
import {
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import { CiCircleInfo } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import { FaCheck } from "react-icons/fa";
import classNames from "classnames";
import {generateTimes} from "../../../util/Helpers";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import {InputProps} from "../../inputfield/InputField";

export interface InputTimeProps extends InputProps {
}

const InputTime = (props: InputTimeProps) => {
  const [inputTime, setInputTime] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const [touched, setTouched] = useState(false);
  const [meridian, setMeridian] = useState<string>("AM");
  const [timeClick, setTimeClick] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement>(null);
  const register = props.register as UseFormRegister<FieldValues>;
  const error = props.errors && props.errors[props.name as string];
  const [times,] = useState(generateTimes())

  const markTouched = () => {
    setTouched(true);
    if (props.onBlur) {
      props.onBlur();
    }
  };

  useEffect(() => {
    if (props.defaultValue) {
      setInputTime(String(props.defaultValue));
    }
  }, [props.defaultValue]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleTimeChange = (e: any) => {
    const inputValue = e.target.value;
    setInputTime(inputValue);
    if (props.onChange) {
      props.onChange({ ...e, target: { ...e.target, value: inputValue, name: props.name } });
    }
  };

  const handleMeridianClick = (selectedMeridian: string) => {
    if (inputTime) {
      const [currentTime] = inputTime.split(" ");
      const formattedTime = `${currentTime} ${selectedMeridian}`;

      setInputTime(formattedTime);
      const event = {
        target: { value: formattedTime, name: props.name },
      };
      handleTimeChange(event);
    } else {
      setInputTime(` ${selectedMeridian}`);
      const event = {
        target: { value: ` ${selectedMeridian}`, name: props.name },
      };
      handleTimeChange(event);
    }
    setMeridian(selectedMeridian);
  };

  const handleClickTime = (time: string) => {
    const formattedTime = `${time} ${meridian || "AM"}`;
    setInputTime(formattedTime);
    markTouched();
    setTimeClick(true)
    setShow(false);
    const event = {
      target: { value: formattedTime, name: props.name },
    };
    handleTimeChange(event);
  };


  return (
    <div className="time-field-container">
      <label htmlFor={props.id}>
        <strong>
          {props.label}{" "}
          {props.required && <sup style={{ color: "#b24d4d" }}>*</sup>}
        </strong>
        {props.withHint && (
          <span style={{ paddingLeft: "5px" }} title={props.withHint}>
            <CiCircleInfo />
          </span>
        )}
      </label>
      <div className="custom-time">
        <WiTime4
          className="time-icon"
          onClick={() => {
            setShow(true);
          }}
          style={{ fontSize: "1rem" }}
        />
        <input
          className={classNames("time-input", {
            "input-success": !error && touched,
            "input-error": error && error.message,
          })}
          id={props.id}
          placeholder={props.placeholder}
          type={props?.type ? props.type : "text"}
          disabled={props.disabled}
          value={inputTime}
          autoComplete="off"
          onFocus={(e) => {
            setTouched(false);
            setShow(true);
          }}
          {...register(props.name, {
            required: props.required,
            pattern: props.pattern,
            validate: (value) => props.validate && props.validate(value),
            min: props.min,
            max: props.max,
            onChange: (e: any) => {
              handleTimeChange(e);
            },
            onBlur: () => {
              markTouched();
            },
          })}
          autoFocus={props.autoFocus}
        />
        <span className="icon-position">
						{(!error || !error.message) &&
							!timeClick &&
							(!show ? (
								<BiChevronDown color={"gray"} size={"1.5em"}  onClick={() => {
                setShow(!show);
                }} />
							) : (
                <BiChevronUp color={"gray"} size={"1.5em"}  onClick={() => {
                    setShow(!show);
                }} />
							))}
						{error && error.message && <IoMdClose color={"red"} size={"1em"}  />}
						{(!error || !error.message) && timeClick && (
							<FaCheck color={"#4198BE"} size={"1em"}  />
						)}
					</span>
        {show && (
          <div
            ref={dropdownRef}
            className={"time-picker"}
            tabIndex={-1}
            onMouseDown={(e) => e.preventDefault()}
          >
            <div className="meridian">
              <h3
                className={meridian === "AM" ? "selected" : ""}
                onClick={() => handleMeridianClick("AM")}
              >
                AM
              </h3>
              <h3
                className={meridian === "PM" ? "selected" : ""}
                onClick={() => handleMeridianClick("PM")}
              >
                PM
              </h3>
            </div>
            <ul className="time-order-list">
              {times.map((time, index) => (
                <li key={Math.random().toFixed(index)} className="time-list" onClick={() => handleClickTime(time)}>
                  {time}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {error?.message && (
        <label className="error-message" style={{ lineHeight: 1 }}>
          <strong className={"input-error"}>
            {error?.message as unknown as string}
          </strong>
        </label>
      )}
    </div>
  );
};

export default InputTime;
