import React, { JSX, useState } from "react";
import "./InputField.scss";
import {
  FieldValues,
  UseFormRegister,
  FieldErrors,
  Message,
  ValidationRule,
} from "react-hook-form";
import { FaCheck } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import classNames from "classnames";
import { CiCircleInfo } from "react-icons/ci";
import ShowPassword from "../showPassword/ShowPassword";

export interface InputProps {
  id: string;
  name: string;
  type?: string;
  defaultValue?: string | number;
  autoFocus?: boolean;
  disabled?: boolean;
  onChange?: any;
  onBlur?: any;
  onFocus?: any;
  onKeyDown?: any;
  register: UseFormRegister<FieldValues>;
  required?: Message | ValidationRule<boolean>;
  pattern?: ValidationRule<RegExp>;
  placeholder?: string;
  minLength?: number;
  maxLength?: number;
  min?: ValidationRule<number | string>;
  max?: ValidationRule<number | string>;
  label?: string;
  errors: FieldErrors;
  inputSuccessIcon?: JSX.Element;
  inputErrorIcon?: JSX.Element;
  animate?: boolean;
  validate?(value: string): any;
  withHint?: string;
  step?: string;
  readOnly?: boolean;
  showPassword?: boolean;
  togglePasswordVisibility?: (type: 'text' | 'password') => void
}
export const InputField = (props: InputProps) => {
  const [touched, setTouched] = useState(false);
  const [inputType, setInputType] = useState<'text' | 'password'>('password');
  const register = props.register as UseFormRegister<FieldValues>;
  const error = props.errors && props.errors[props.name as string];

  //TODO: add success and error icons, parameterized input icons (e.g. email-icon, phone-icon, etc.)

  const markTouched = () => {
    setTouched(true);
    props.onBlur && props.onBlur();
  };

  const keydownHandler = (e: any) => {
    props.onKeyDown && props.onKeyDown(e);
  };

  const toggleShowPassword = (state: boolean) => {
    setInputType(state ? 'text' : 'password')
    props.togglePasswordVisibility && props.togglePasswordVisibility(state ? 'text' : 'password')
  }

  return (
    <div className={"input-field-container"}>
      <label>
        <strong>
          {props.label}{" "}
          {props.required && <sup style={{ color: "#b24d4d" }}>*</sup>}
        </strong>

        {props.withHint && (
          <span style={{ paddingLeft: "5px" }} title={props.withHint}>
            <CiCircleInfo />
          </span>
        )}
      </label>
      <div className={"input-field-wrapper"}>
        <span className={"input-success-icon-wrapper"}>
          {!error?.message && touched && (
            <i className={"input-success-icon"}>
              <FaCheck size={"1em"} />
            </i>
          )}
          {error?.message && (
            <i className={"input-error-icon"}>
              <IoMdClose size={"1em"} />
            </i>
          )}
        </span>

        <input
          className={classNames("format-input", {
            "input-success": !error && touched,
            "input-error": error && error.message,
          })}
          id={props.id}
          placeholder={props.placeholder}
          type={
            props.type ?
              props.type === "password"
                ? inputType
                : props?.type
              : "text"
          }
          disabled={props.disabled}
          {...register(props.name, {
            required: props.required,
            pattern: props.pattern,
            validate: (value) => props.validate && props.validate(value),
            min: props.min,
            max: props.max,
            onChange: (e: any) => {
              props.onChange(e);
            },
            onBlur: () => {
              markTouched();
            },
          })}
          onKeyDown={(e: any) => keydownHandler(e)}
          defaultValue={props.defaultValue}
          maxLength={props.maxLength}
          minLength={props.minLength}
          autoFocus={props.autoFocus}
          step={props.step}
          readOnly={props.readOnly}
        />

        {error?.message && (
          <label style={{ lineHeight: 1 }}>
            <strong className={"input-error"}>
              {error.message as unknown as string}
            </strong>
          </label>
        )}
      </div>
      {props.showPassword &&
        <ShowPassword
          toggleShowPassword={toggleShowPassword}
          isVisible={props.type === "password"}
        />
      }
    </div>
  );
};
