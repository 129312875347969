import { useEffect, useState } from "react";
import { Listing } from "../../../components/listing/Listing";
import "./AdmissionQueue.scss";
import AdmissionDao from "../../../types/admission/AdmissionDao";
import { LightBulb } from "../../../resources/assets/svg/servicepage/LightBulb";
import { DashboardTopNav } from "../../../components/dashboardnav/DashboardTopNav";
import { ParamsEnum } from "../../../util/ParamsEnum";
import { useCustomNavigate } from "../../../customhook/CustomUseNavigate";
import PatientDao from "../../../types/PatientDao";
import patientService from "../../../services/PatientService";
import admissionService from "../../../services/AdmissionService";
import { useWhoAmI } from "../../../context/UserCtx";
import { ErrorNotification } from "../../../modules/errornotification/ErrorNotification";

export const AdmissionQueue = () => {
  const [admissionQueue, setAdmissionQueue] = useState<AdmissionDao[]>([]);
  const [patientSelected, setPatientSelected] = useState<PatientDao>(
    {} as PatientDao
  );
  const [clickedError, setClickedError] = useState<string>("");
  const [errMessage, setErrorMessage] = useState<string>("");
  const navigate = useCustomNavigate();
  const user = useWhoAmI();
  const navigateToAdmission = ParamsEnum.ADMIT_PATIENT;

  // const admission: AdmissionDao[] = [
  //     {
  //         id: '123e4567-e89b-12d3-a456-426614174000' as UUID,
  //         consultationAssessment: 'headache',
  //         vitalSignsExtraNotes: [
  //             'cough', 'stomach'
  //         ],
  //         doctorsExtraNotes: [
  //             'exercise', 'jump'
  //         ],
  //         dischargedByDoctor: true,
  //         dischargedByAccount: true,
  //         dischargedDate: '01-03-07',
  //         confirmConsultation: true,
  //         drugPrescription: true,
  //         laboratory: true,
  //         surgery: true,
  //         admitPatient: true,
  //         followUp: true,
  //         drugPrescriptionText: 'panadol',
  //         laboratoryText: 'malaria',
  //         surgeryText: 'operation',
  //         admitPatientText: 'quickly',
  //         followUpText: 'Fast',
  //         admissionState: AdmissionState.DISCHARGED_BY_DOCTOR,
  //         patientId: 0,
  //         invoiceNumber: 0,
  //         hospitalId: 0,
  //         createdBy: 0,
  //         patientCategory: PatientCategory.IN_PATIENT,
  //         admissionStatus: AdmissionType.CLINIC,
  //         vitalSigns: '',
  //         paidAdmissionFee: false,
  //         paidConsultationFee: false,
  //         admissionTime: '12:09Am',
  //         admissionDate: '01-01-1945',
  //         doctorToSeePatient: 'John Doe',
  //         purposeOfVisit: 'cough',
  //         seenByDoctor: 0
  //     },
  //     {
  //         id: '123e4567-e89b-12d3-a456-426614174000' as UUID,
  //         consultationAssessment: 'headache',
  //         vitalSignsExtraNotes: [
  //             'cough', 'stomach'
  //         ],
  //         doctorsExtraNotes: [
  //             'exercise', 'jump'
  //         ],
  //         dischargedByDoctor: true,
  //         dischargedByAccount: true,
  //         dischargedDate: '01-03-07',
  //         confirmConsultation: true,
  //         drugPrescription: true,
  //         laboratory: true,
  //         surgery: true,
  //         admitPatient: true,
  //         followUp: true,
  //         drugPrescriptionText: 'panadol',
  //         laboratoryText: 'malaria',
  //         surgeryText: 'operation',
  //         admitPatientText: 'quickly',
  //         followUpText: 'Fast',
  //         admissionState: AdmissionState.DISCHARGED_BY_DOCTOR,
  //         patientId: 0,
  //         invoiceNumber: 0,
  //         hospitalId: 0,
  //         createdBy: 0,
  //         patientCategory: PatientCategory.IN_PATIENT,
  //         admissionStatus: AdmissionType.CLINIC,
  //         vitalSigns: '',
  //         paidAdmissionFee: false,
  //         paidConsultationFee: false,
  //         admissionTime: '12:09Am',
  //         admissionDate: '01-01-1945',
  //         doctorToSeePatient: 'John Doe',
  //         purposeOfVisit: 'cough',
  //         seenByDoctor: 0
  //     },
  //     {
  //         id: '123e4567-e89b-12d3-a456-426614174000' as UUID,
  //         consultationAssessment: 'headache',
  //         vitalSignsExtraNotes: [
  //             'cough', 'stomach'
  //         ],
  //         doctorsExtraNotes: [
  //             'exercise', 'jump'
  //         ],
  //         dischargedByDoctor: true,
  //         dischargedByAccount: true,
  //         dischargedDate: '01-03-07',
  //         confirmConsultation: true,
  //         drugPrescription: true,
  //         laboratory: true,
  //         surgery: true,
  //         admitPatient: true,
  //         followUp: true,
  //         drugPrescriptionText: 'panadol',
  //         laboratoryText: 'malaria',
  //         surgeryText: 'operation',
  //         admitPatientText: 'quickly',
  //         followUpText: 'Fast',
  //         admissionState: AdmissionState.DISCHARGED_BY_DOCTOR,
  //         patientId: 0,
  //         invoiceNumber: 0,
  //         hospitalId: 0,
  //         createdBy: 0,
  //         patientCategory: PatientCategory.IN_PATIENT,
  //         admissionStatus: AdmissionType.CLINIC,
  //         vitalSigns: '',
  //         paidAdmissionFee: false,
  //         paidConsultationFee: false,
  //         admissionTime: '12:09Am',
  //         admissionDate: '01-01-1945',
  //         doctorToSeePatient: 'John Doe',
  //         purposeOfVisit: 'cough',
  //         seenByDoctor: 0
  //     }
  // ]

  const getAdmissionList = () => {
    admissionService
      .appointmentList(user.hospital.id)
      .then((response: AdmissionDao[]) => {
        setAdmissionQueue(response);
      })
      .catch(() => {
        setErrorMessage(
          "Technical error occurred while getting list of admission, please try again later!"
        );
      });
  };

  useEffect(() => {
    getAdmissionList();
  }, []);

  const getClickedPatientHandler = (adm: AdmissionDao) => {
    const foundAdmission =
      admissionQueue?.length > 0 &&
      admissionQueue.find((admQueue) => admQueue.id === adm.id);

    const patientId = foundAdmission && foundAdmission?.patientId;
    console.log({ patientId });

    // use the above patientId to make a request to the acc BE, endpoint accounting-service/v1/patient/{patient_id} and get this patient

    if (!patientId || patientId < 1) {
      return;
    }

    patientService
      .getPatient(patientId)
      .then((data) => {
        setPatientSelected(data);
      })
      .catch(() => {
        setClickedError(
          "Technical error occurred while getting list of admission, please try again later!"
        );
      });
    navigate(navigateToAdmission, { state: { patient: patientSelected } });
  };

  return (
    <div className={"admission-queue-component"}>
      {/* <div style={{display: 'inline-flex', margin: '10px'}}>
                <p>patient Id</p>
                <p>patient name</p>
                <p>patient age</p>
                <p>patient vital signs</p>
                <p>patient Payment status</p>
                <p>patient admission status</p>
            </div> */}
      <div>
        <DashboardTopNav
          placeholder={"search appointment with id or date"}
          itemList={admissionQueue}
          action={[]}
          hideSearchBar={true}
          selectedAction={''}
        />
      </div>
      <div className="admission-queue-notification">
        <LightBulb />
        <div>
          <p>Below is the list of queue</p>
        </div>
      </div>
      <hr />
      <div className="queue-listing">
        {clickedError && (
          <ErrorNotification name="clicked-error" stringErrors={clickedError} />
        )}
        {admissionQueue.length < 1 ? (
          <ErrorNotification
            name={"empty-list"}
            stringErrors={"This no registered admission to be displayed"}
          />
        ) : (
          <Listing
            items={admissionQueue.map((actAdm) => ({
              id: actAdm.id,
              DoctorInCharge: actAdm.doctorToSeePatient,
              PurposeOfVisit: actAdm.purposeOfVisit,
              AdmissionState: actAdm.admissionState,
              Category: actAdm.patientCategory,
              AdmittedTime: actAdm.admissionTime,
            }))}
            getClickedItem={getClickedPatientHandler}
            withAction={true}
          />
        )}
      </div>
    </div>
  );
};
