import { useEffect, useState } from "react";
import imageIllustration1 from "../../resources/assets/images/doctor_and_nurse.png";
import imageIllustrationn2 from "../../resources/assets/images/doctor_and_nurse_1.png";
import imageIllustrationn3 from "../../resources/assets/images/bar_chart.png";
import "./Login.scss";
import { InputField } from "../../components/inputfield/InputField";
import { useForm } from "react-hook-form";
import loginService from "../../services/LoginService";
import LoginData from "../../types/LoginData";
import { Button } from "../../components/button/Button";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { isEmpty, userIsLogged } from "../../util/Helpers";
import { Spinner } from "../../components/spinner/Spinner";
import { ParamsEnum } from "../../util/ParamsEnum";
import { ErrorNotification } from "../../modules/errornotification/ErrorNotification";
import WhoAmI from "../../userAuth/WhoAmI";
import { UserCtx } from "../../context/UserCtx";
import AuthenticationResponse from "../../types/AuthenticationResponse";

type HospitalIsActive =
  | "This hospital is active"
  | "This hospital has been disabled";

export function LoginPage() {
  const {
    register,
    trigger,
    formState: { errors, isValid },
  } = useForm({ mode: "onBlur" });
  const [hospitalIsActive, setHospitalIsActive] = useState<HospitalIsActive>(
    "This hospital is active"
  );
  const [userData, setUserData] = useState<LoginData>({} as LoginData);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [loginError, setLoginError] = useState("");
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [user, setUser] = useState<WhoAmI>({} as WhoAmI);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (userIsLogged()) {
      window.location.href = ParamsEnum.DASHBOARD;
      return;
    }

    const slideContainer = document.querySelector(
      "#carousel"
    ) as HTMLDivElement;
    const slideContainerWidth: number = slideContainer?.scrollWidth;

    slideContainer &&
      slideContainer.scrollTo({
        top: 0,
        left: (slideContainerWidth / 3) * (currentSlide - 1),
      });

    const interval = setInterval(() => {
      if (currentSlide === 3) return setCurrentSlide(1);
      setCurrentSlide((currentSlide + 1) as unknown as 1 | 2);
    }, 3000);
    setLoading(false);
    return () => clearInterval(interval);
  }, [currentSlide]);

  const userDataHandler = (evt: any) => {
    const { target } = evt;
    const { name, value } = target;

    const updateUserData = { ...userData };
    updateUserData[name as keyof LoginData] = value;
    setUserData(updateUserData);
  };

  const loginHandler = async () => {
    await trigger();
    if (isValid) {
      setIsSubmittingForm(true);
      loginService.login(userData)
        .then((response: AuthenticationResponse) => {
          if (response.accessToken) {
            //setCookies(ParamsEnum.ACCESS_TOKEN, response.accessToken)
            sessionStorage.setItem(
              ParamsEnum.ACCESS_TOKEN,
              response.accessToken
            );

            const decodeAuthUser = jwtDecode(response.accessToken) as WhoAmI;

            setUser({
              userId: decodeAuthUser.userId,
              hospital: decodeAuthUser.hospital,
              username: decodeAuthUser.username,
              firstname: decodeAuthUser.firstname,
              lastname: decodeAuthUser.lastname,
              email: decodeAuthUser.email,
              lastLogin: decodeAuthUser.lastLogin,
              authorities: decodeAuthUser.authorities,
              department: decodeAuthUser.department,
              sub: decodeAuthUser.sub,
              exp: decodeAuthUser.exp,
              iat: decodeAuthUser.iat,
            });
            window.location.href = ParamsEnum.DASHBOARD;
          }
        })
        .catch((err: any) => {
          setIsSubmittingForm(false);
          // handle when hospital is deactivated
          if (String(err).includes("403")) {
            setHospitalIsActive("This hospital has been disabled");
            return;
          }
          setLoginError(
            "Technical error occurred while login you in, please try again later!"
          );
          setIsSubmittingForm(false);
        });
    }
  };

  return (
    <>
      {hospitalIsActive === "This hospital has been disabled" && (
        <ErrorNotification
          name={"hospital-is-inactive"}
          stringErrors={hospitalIsActive}
        />
      )}
      {!loading && (
        <UserCtx value={user}>
          <main id="login-main">
            <div id="aside-banner">
              <div>
                <h1>Welcome Back</h1>
                <p>Sign Up or Login to view your profile</p>
              </div>
              <div id="carousel-container">
                <div id="carousel">
                  <div className="carousel-item">
                    <img
                      loading="eager"
                      src={imageIllustration1}
                      alt="A doctor and his nurse"
                      height={400}
                      style={{ aspectRatio: "1/1" }}
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      loading="eager"
                      src={imageIllustrationn2}
                      alt="A doctor and his nurse"
                      height={400}
                      width={400}
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      loading="eager"
                      src={imageIllustrationn3}
                      alt="A bar chart"
                      height={400}
                      width={400}
                    />
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    loading="eager"
                    src={imageIllustrationn3}
                    alt="A bar chart"
                    height={400}
                    width={400}
                  />
                </div>
              </div>
              <div id="carousel-controls">
                <button
                  onClick={() => setCurrentSlide(1)}
                  className={`${currentSlide === 1 ? "active" : ""}`}
                ></button>
                <button
                  onClick={() => setCurrentSlide(2)}
                  className={`${currentSlide === 2 ? "active" : ""}`}
                ></button>
                <button
                  onClick={() => setCurrentSlide(3)}
                  className={`${currentSlide === 3 ? "active" : ""}`}
                ></button>
              </div>
            </div>

            <span style={{ clear: "left" }}></span>
            <div id="divider"></div>
            <div id="form-container">
              <div id="active-form-tabs">
                <Link to={"/register"} className="button">
                  Sign Up
                </Link>
                <Link className="button active-form-tab login" to={"/login"}>
                  Login
                </Link>
              </div>
              <div id="login-form">
                <p>
                  Fields with <sup>*</sup> are compulsory.
                </p>

                <InputField
                  id="username"
                  name="username"
                  defaultValue={userData.username}
                  type={"username"}
                  onChange={userDataHandler}
                  errors={errors}
                  placeholder="Username"
                  label="Username"
                  pattern={{
                    value: /^[a-zA-Z0-9]{4,24}$/,
                    message:
                      "Min. of 4 alphanumeric values and allowed special characters !#@",
                  }}
                  register={register}
                  min={3}
                  minLength={3}
                  required={"Username is required"}
                  animate={true}
                />

                <InputField
                  id="password"
                  name="password"
                  defaultValue={userData.password}
                  type={"password"}
                  onChange={userDataHandler}
                  errors={errors}
                  placeholder="Password"
                  label="Password"
                  pattern={{
                    value: /^[a-zA-Z0-9!#@]{8,24}$/,
                    message:
                      "Min. of 8 alphanumeric values and allowed special characters !#@",
                  }}
                  register={register}
                  min={8}
                  minLength={8}
                  required={"Password is required"}
                  animate={true}
                  onKeyDown={(evt: KeyboardEvent) => {
                    if (evt.key === "Enter") {
                      loginHandler().then();
                    }
                  }}
                  showPassword={true}
                />

                <Button
                  id={"login-button"}
                  name={"login-button"}
                  disabled={isSubmittingForm}
                  buttonName={isSubmittingForm ? "Please wait..." : "Login"}
                  onClick={loginHandler}
                />
                <p className="text-sm px-2">
                  Don't have an account yet?{" "}
                  <strong>
                    <Link to="/register">Sign Up</Link>
                  </strong>
                </p>
                <p className="text-sm px-2">
                  <Link to="/forgot_password">Forgot password</Link>
                </p>
              </div>
            </div>
          </main>

          {!isEmpty(loginError) && (
            <div className={"login-error-wrapper"}>
              <span></span> {/*Do not delete, used for styling*/}
              <span>
                <ErrorNotification
                  name={"login-error"}
                  stringErrors={loginError}
                />
              </span>
            </div>
          )}
        </UserCtx>
      )}

      {loading && <Spinner />}
    </>
  );
}
