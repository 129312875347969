import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
	{
		name: 'Jan',
		emergency: 4000,
		inpatients: 2400,
		outpatients: 2400,
		'surgical patients': 1300,
	},
	{
		name: 'Feb',
		emergency: 3000,
		inpatients: 1398,
		outpatients: 2210,
		'surgical patients': 350,
	},
	{
		name: 'Mar',
		emergency: 2000,
		inpatients: 9800,
		outpatients: 2290,
		'surgical patients': 150,
	},
	{
		name: 'April',
		emergency: 2780,
		inpatients: 3908,
		outpatients: 2000,
		'surgical patients': 1050,
	},
	{
		name: 'May',
		emergency: 1890,
		inpatients: 4800,
		outpatients: 2181,
		'surgical patients': 1150,
	},
	{
		name: 'Jun',
		emergency: 2390,
		inpatients: 3800,
		outpatients: 2500,
		'surgical patients': 1040,
	},
];

const MixedBarChart = () => {
	return (
		<ResponsiveContainer width='100%' height='100%' minHeight={500} minWidth={500}>
			<BarChart
				data={data}
				width={100}
				height={550}
				margin={{
					top: 20,
					bottom: 5,
				}}
				barSize={30}>
				<CartesianGrid strokeWidth={0.8} />
				<XAxis dataKey='name' />
				<YAxis />
				<Tooltip />
				<Legend />
				<Bar dataKey='inpatients' fill='#00B0FF' />
				<Bar dataKey='outpatients' fill='#176724' />
				<Bar dataKey='surgical patients' fill='#6D1818' />
				<Bar dataKey='emergency' fill='#FFA000' />
			</BarChart>
		</ResponsiveContainer>
	);
};

export default MixedBarChart;
