import './AdmittedPatients.scss';
import CustomPieChart from '../../../components/Charts/PieChart/PieChart';
import HorizontalBarChart from '../../../components/Charts/HorizontalBarChart/HorizontalBarChart';
import { useDashboardContext } from '../../../context/DashboardCtx';
import { AgeRange } from '../../../types/Dashboard/DashboardModels';
import useDashboardHook from '../../../customhook/useDashboardHook';

const AdmittedPatients = () => {
	const { selectedOptions } = useDashboardContext();
	const { patientsAgeRange, admissionDto } = useDashboardHook();

	const chartData = [
		{ name: AgeRange.ZERO_TO_FIFTEEN, value: patientsAgeRange?.ZERO_TO_FIFTEEN , colorShade: 'dark' },
		{ name: AgeRange.SIXTEEN_TO_THIRTY_TWO, value: patientsAgeRange?.SIXTEEN_TO_THIRTY_TWO, colorShade: 'dark' },
		{ name: AgeRange.THIRTY_THREE_TO_FORTY_NINE, value: patientsAgeRange?.THIRTY_THREE_TO_FORTY_NINE , colorShade: 'light' },
		{ name: AgeRange.FIFTY_AND_ABOVE, value: patientsAgeRange?.FIFTY_AND_ABOVE , colorShade: 'light' },
	];
	const colors = ['#D9D9D9', '#FFA000', '#0492C2', '#412222'];

	const horizontalData = [
		{
			name: 'Operation',
			patients: 10,
			departments: 1200,
			clinic: 1228,
			admission: 480,
		},
		{
			name: 'Emergency ED',
			patients: 50,
			departments: 800,
			clinic: 1400,
			admission: 490,
		},
		{
			name: 'Critical Care',
			patients: 28,
			departments: 967,
			clinic: 1506,
			admission: 590,
		},
		{
			name: 'Radiology',
			patients: 150,
			departments: 1108,
			clinic: 1100,
			admission: 460,
		},
		{
			name: 'Pediatric',
			patients: 17,
			departments: 1098,
			clinic: 989,
			admission: 350,
		},
	];
	return (
    <>
      {selectedOptions.find(
        (item) =>
          item.label === "Admitted patient Age range" ||
          item.label === "Patient on admission and clinic"
      ) && (
        <section className="admitted_patient_container">
          <div className="charts_container">
            {selectedOptions.find(
              (item) => item.label === "Admitted patient Age range"
            ) && (
              <div className="pie_chart_Container">
                <CustomPieChart data={chartData} colors={colors.reverse()} />
              </div>
            )}

            {selectedOptions.find(
              (item) => item.label === "Patient on admission and clinic"
            ) && (
              <div className="horizontal_chart_container">
                <HorizontalBarChart data={horizontalData} />
                <p style={{ textAlign: "center" }}>Patients</p>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default AdmittedPatients;
