import React from "react";
import "./AccountingApp.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Dashboard } from "./pages/dashboard/Dashboard";
import { HomePage } from "./pages/HomePage";
import { LoginPage } from "./pages/login/LoginPage";
import { ErrorPage } from "./pages/error/ErrorPage";
import { PrivacyPolicy } from "./pages/privacy/PrivacyPolicy";
import { TermsOfUse } from "./pages/termsofuse/TermsOfUse";
import { Register } from "./pages/register/Register";
import ForgetPassword from "./pages/forgetPassword/ForgetPassword";

export const AccountingApp = () => {
  return (
    <BrowserRouter>
      {
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot_password" element={<ForgetPassword />} />
          <Route path="/dashboard/*" element={<Dashboard />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      }
    </BrowserRouter>
  );
};
