import './Admission.scss'
import {ReactNode, useEffect, useState} from "react";
import {ErrorNotification} from "../../modules/errornotification/ErrorNotification";
import {AdmitPatientInClinic} from "./admitpatient/AdmitPatientInClinic";
import {useSearchParams} from "react-router-dom";
import {capitalizeFirstCharacter,} from "../../util/Helpers";
import salesService from "../../services/SalesService";
import {BiCheck} from "react-icons/bi";
import {IoClose} from "react-icons/io5";
import {Dropdown} from "../../components/dropdown/Dropdown";
import {AdmissionType} from "../../types/admission/AdmissionType";
import PatientDao from "../../types/PatientDao";
import {useWhoAmI} from "../../context/UserCtx";
import {Authorities} from "../../userAuth/WhoAmI";
import {userHasRole} from "../../util/UserRolesHelper";
import {DashboardTopNav} from "../../components/dashboardnav/DashboardTopNav";
import admissionService from "../../services/AdmissionService";
import AdmissionDao from "../../types/admission/AdmissionDao";
import {AdmissionState} from "../../types/admission/AdmissionState";
import {Spinner} from "../../components/spinner/Spinner";
import {MdHistory} from "react-icons/md";
import {LuView} from "react-icons/lu";
import {CiUser} from "react-icons/ci";
import {PatientProfile} from "../customer/patientprofile/PatientProfile";
import {MedicalHistory} from "../customer/MedicalHistory/MedicalHistory";
import patientService from "../../services/PatientService";

type AdmissionNavTabs = 'Current Session' | 'Old Medical Record' | 'View Profile';

type AdmissionError = '' | 'consultation_fee_unpaid' | 'admission_fee_unpaid' | 'latest_payments_error'
export type PaymentState = { paid: boolean }

export const Admission = () => {
    const user = useWhoAmI();
    const [searchParams, setSearchParams] = useSearchParams();
    const patientId = searchParams.get("pid");
    const [admissionType, setAdmissionType] = useState<AdmissionType>(AdmissionType.CLINIC);
    const [patient, setPatient] = useState<PatientDao>({} as PatientDao);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [errorState, setErrorState] = useState<AdmissionError>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [activeAdmission, setActiveAdmission] = useState<AdmissionDao>({} as AdmissionDao);
    const rolesAllowedAccess: Authorities[] = [
        Authorities.RECEPTIONIST, Authorities.NURSE, Authorities.DOCTOR, Authorities.ADMIN, Authorities.SUPER_ADMIN
    ]
    const [paidAdmissionFee, setPaidAdmissionFee] = useState<boolean>(false)
    const [paidConsultationFee, setPaidConsultationFee] = useState<boolean>(false)
    const [currentTab, setCurrentTab] = useState<AdmissionNavTabs>('Current Session')

    const admissionFeeIsPaidHandler = () => {
        salesService.admissionFeeIsPaid(user.hospital.id, patient?.patientDao?.id)
            .then((response) => {
                if (!response.paid) {
                    setErrorState('admission_fee_unpaid')
                    setErrorMessage('No admission fee payment is made for this patient');
                    return;
                }
                setPaidAdmissionFee(response.paid);
            })
            .catch(() => {
                setErrorState('admission_fee_unpaid')
                setErrorMessage('Something went wrong getting patient admission fee')
            });
    }

    const consultationFeeIsPaidHandler = () => {
        patient?.patientDao?.id > 0 && salesService.consultationFeeIsPaid(user.hospital.id, patient?.patientDao?.id)
            .then((response) => {
                if (!response.paid) {
                    setErrorState('consultation_fee_unpaid')
                    setErrorMessage('No consultation fee payment has been made for this patient in the last 8 hours');
                    return;
                }
                setPaidConsultationFee(response.paid)
            }).catch((e) => {
            setErrorState('consultation_fee_unpaid')
            setErrorMessage('Something went wrong getting patient consultation fee' + e)
        });
    }

    const getPatientActiveAdmission = () => {
        if (activeAdmission.admissionState !== AdmissionState.CHECKED_OUT && patient?.patientDao?.id > 0) {
            admissionService.getPatientLatestAdmission(user?.hospital?.id, patient?.patientDao?.id)
                .then((data: AdmissionDao) => {
                    if (data.admissionState && data.admissionState !== AdmissionState.CHECKED_OUT) {
                        setActiveAdmission(data);
                    }
                }).catch((e) => {
                    setErrorMessage('Technical error occurred while getting this patient active admission'+e)
            })
        }
    }

    const getPatientHandler = () => {
        patientService.getPatient(Number(patientId)).then((pt) => {
            setPatient(pt);

            admissionType === AdmissionType.ADMITTED && admissionFeeIsPaidHandler();
            admissionType === AdmissionType.CLINIC && consultationFeeIsPaidHandler();
        }).catch(() => {
            setErrorMessage("Technical error occurred while verifying the selected patient");
        })
    }

    useEffect(() => {
        if(Number(patientId) > 0) {
            getPatientHandler();
            getPatientActiveAdmission();
        }
        setLoading(false);

    }, [paidConsultationFee, admissionType, currentTab, patient?.patientDao?.id]);

    const admissionTypeHandler = (evt: any) => {
        const target = evt.target;
        const value = target.value;
        setAdmissionType(value);
    }

    const getSelectedAdmissionTypeHandler = (selectedType: AdmissionType) => {
        setAdmissionType(selectedType);
    }

    const getActiveNav = (activeTab: {name: string, icon: ReactNode}) => {
        setCurrentTab(activeTab?.name as AdmissionNavTabs || 'Current Session')
    }

    return (
        <>
            {
                !loading && patient && (
                    <div>
                        <DashboardTopNav selectedAction={currentTab}
                            title={
                                `${patient.patientDao?.lastname} ${patient.patientDao?.firstname} is currently in ${activeAdmission?.admissionState?.toLowerCase().replaceAll('_', ' ')
                                || admissionType}`
                            }
                            action={[
                                {name: 'View Profile', icon: <CiUser/>},
                                {name: 'Current Session', icon: <LuView/>},
                                {name: 'Old Medical Record', icon: <MdHistory/>},
                            ]}
                            placeholder={`${capitalizeFirstCharacter(admissionType)}`}
                            hideSearchBar={true}
                            getClickedAction={getActiveNav}
                        />

                        {
                            (currentTab === 'View Profile') &&
                            <PatientProfile />
                        }

                        {
                            (currentTab === 'Current Session') &&
                            <>
                                {
                                    userHasRole(user, rolesAllowedAccess) &&
                                    <div className={'admission-component'}>
                                        {
                                            (errorState || errorMessage) &&
                                            <div>
                                                <ErrorNotification name={'admission-error'} stringErrors={errorMessage}/>
                                            </div>
                                        }
                                        {
                                            !errorState && !errorMessage &&
                                            <>
                                                {
                                                    admissionType && (
                                                        <>
                                                            {
                                                                patient && (
                                                                    <div>
                                                                        <div>
                                                                            {/* <div>
                                                                    <DashboardTopNav
                                                                        title={
                                                                            `${customerDao?.lastname} ${customerDao?.firstname} is currently
                                                                            ${activeAdmission?.admissionState?.toLowerCase().replaceAll('_', ' ')
                                                                            || admissionType}`
                                                                        }
                                                                        action={[
                                                                            {name: 'View Profile', icon: <CiUser />},
                                                                            {name: 'Current Session', icon: <LuView />},
                                                                            {name: 'Old Medical Record', icon: <MdHistory />}
                                                                        ]}
                                                                        placeholder={`${capitalizeFirstCharacter(admissionType)}`}
                                                                        hideSearchBar={true}
                                                                        getClickedAction={getActiveNav}
                                                                    />
                                                                </div>*/}

                                                                            <div className={'patient-fee-payment-wrapper'}>
                                                                                <div className={'fee-line'}>
                                                                                    <p className={paidAdmissionFee ? 'paid' : 'unpaid'}>
                                                                                        {`Admission fee${paidAdmissionFee ? '' : ' NOT'} paid`}
                                                                                        {
                                                                                            <span
                                                                                                className={'paid-unpaid-span-icon-wrapper'}>
                                                                    {
                                                                        paidAdmissionFee ? <BiCheck size={25}/> :
                                                                            <IoClose size={20} color={'red'}/>
                                                                    }
                                                                 </span>
                                                                                        }
                                                                                    </p>
                                                                                </div>

                                                                                <div className={'fee-line'}>
                                                                                    <p className={paidConsultationFee ? 'paid' : 'unpaid'}>
                                                                                        {`Consultation fee${paidConsultationFee ? '' : ' NOT'} paid`}
                                                                                        {
                                                                                            <span
                                                                                                className={'paid-unpaid-span-icon-wrapper'}>
                                                                    {
                                                                        paidConsultationFee ? <BiCheck size={25}/> :
                                                                            <IoClose size={20} color={'red'}/>
                                                                    }
                                                                </span>
                                                                                        }
                                                                                    </p>
                                                                                </div>

                                                                                <div className={'admission-type-dropdown'}>
                                                                                    <Dropdown name={'admissionStatus'}
                                                                                              onChange={admissionTypeHandler}
                                                                                              options={[
                                                                                                  {
                                                                                                      label: AdmissionType.CLINIC.toLowerCase(),
                                                                                                      value: 'clinic'
                                                                                                  },
                                                                                                  {
                                                                                                      label: AdmissionType.ADMITTED.toLowerCase(),
                                                                                                      value: 'admit'
                                                                                                  },
                                                                                                  {
                                                                                                      label: AdmissionType.EMERGENCY.toLowerCase(),
                                                                                                      value: 'emergency'
                                                                                                  },
                                                                                              ]}
                                                                                              label={'Admission Type'}
                                                                                              placeholder={activeAdmission?.admissionStatus?.toLowerCase() ?? 'Admission Type'}
                                                                                              defaultValue={''}
                                                                                              getSelectedOptionValue={getSelectedAdmissionTypeHandler || admissionType}
                                                                                              errors={errorMessage as any}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                            {
                                                                admissionType && admissionType === AdmissionType.CLINIC.toLowerCase()
                                                                && !errorMessage && paidConsultationFee &&
                                                                <AdmitPatientInClinic paidConsultationFee={paidConsultationFee}
                                                                                      activeAdmission={activeAdmission} patient={patient}/>
                                                            }

                                                            {
                                                                /*admissionType && admissionType === AdmissionType.ADMITTED.toLowerCase() && newAdmission && !errorMessage && (
                                                                    newAdmission.paidAdmissionFee && <AdmitPatientInClinic newAdmissionDto={newAdmission}/>
                                                                )*/
                                                            }
                                                        </>
                                                    )
                                                }

                                                {
                                                    admissionType && admissionType === AdmissionType.DISCHARGE && !errorMessage && (
                                                        <p>Discharge logic or component goes here</p>
                                                    )
                                                }
                                            </>
                                        }
                                    </div>
                                }

                                {
                                    !userHasRole(user, rolesAllowedAccess) &&
                                    <ErrorNotification
                                        name={'unauthorized-access'}
                                        stringErrors={'You are not authorized to view this page, please contact your administrator.'}
                                    />
                                }
                            </>
                        }

                        {
                            (currentTab === 'Old Medical Record') &&
                            <MedicalHistory />
                        }
                       
                    </div>
                )
            }

            {
                loading && <Spinner />
            }
        </>
    )
}