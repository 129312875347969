import {
  DaysCheckBoxTypes,
  ServiceDays,
} from "../../types/services/ServiceTypes";

export const days: DaysCheckBoxTypes[] = [
  { value: ServiceDays.SUN, label: "Sun", checked: false },
  { value: ServiceDays.MON, label: "Mon", checked: false },
  { value: ServiceDays.TUE, label: "Tue", checked: false },
  { value: ServiceDays.WED, label: "Wed", checked: false },
  { value: ServiceDays.THU, label: "Thu", checked: false },
  { value: ServiceDays.FRI, label: "Fri", checked: false },
  { value: ServiceDays.SAT, label: "Sat", checked: false },
];
