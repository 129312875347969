import {ApiService} from "../ApiService";
import {arrayBufferToBlobUrl, blobToBase64} from "../../util/Helpers";

const authBaseUrl = `${process.env.AUTH_PROXY ?? process.env.REACT_APP_AUTH_PROXY}`;

class ImageService extends ApiService {
    /** Uploads the imag and returns the image path */
    async uploadHospitalLogo(file: Blob | string, hospitalId: number): Promise<string> {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const fetchRes = await fetch(`${authBaseUrl}admin/hospital/${hospitalId}/logo/upload`, {
                method: "POST",
                body: formData,
            })

            const data = await fetchRes.text();
            return String(data)
        } catch (error) {
            console.error('Error uploading file:', error);
            return '';
        }
    }

    async getHospitalLogo(hospitalId: number): Promise<string> {
        try {
            const response = await fetch(`${authBaseUrl}admin/hospital/${hospitalId}/get-logo`, {
                method: "GET"
            })

            const blob = await response.blob();
            return blobToBase64(blob);
        } catch (error) {
            console.error('Error uploading file:', error);
            return '';
        }
    }

    // working
    async getHospitalLogoByte(hospitalId: number): Promise<string> {
        try {
            const response = await fetch(`${authBaseUrl}admin/hospital/${hospitalId}/get-logo-byte`, {
                method: "GET"
            })

            const blobByte = await response.arrayBuffer();

            return arrayBufferToBlobUrl(blobByte, 'png');
        } catch (error) {
            console.error('Error uploading file:', error);
            return '';
        }
    }
}

const imageService = new ImageService();
export default imageService;