import {createContext, ReactNode, useCallback, useEffect, useState} from "react";
import {UserDto} from "../types/UserDto";
import {useWhoAmI} from "./UserCtx";
import userService from "../services/UserService";

interface CtxProp {
    value?: UserDto[];
    children: ReactNode;
}

const ListOfUsersCtx = createContext<UserDto[]>({} as UserDto[]);

/** Cxt responsible for getting all users belonging to a given hospital */
export const UsersDtoCtx = (prop: CtxProp) => {
    const loggedUser = useWhoAmI();
    const [listOfUsers, setListOfUsers] = useState<UserDto[]>();

    useEffect(() => {
        if (loggedUser?.hospital?.id) {
            userService.getAllUsers(loggedUser?.hospital?.id)
                .then((users: UserDto[]) => {
                    setListOfUsers(users)
                });
        }

        if (prop.value) {
            getHospitalUsersCtxValue(prop.value);
        }
    }, [prop.value, loggedUser?.hospital?.id]);

    const getHospitalUsersCtxValue = useCallback(
        (newState: UserDto[]) => setListOfUsers(newState),
        []
    );

    return (
        <ListOfUsersCtx.Provider value={listOfUsers || {} as UserDto[]}>
            {prop.children}
        </ListOfUsersCtx.Provider>
    );
}