import React from "react";

export const ListIcon = () => {
    return <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 5.5001H4M11 9.9001H4M11 1.1001H4" stroke="white" strokeWidth="0.6875" strokeLinecap="round"
              strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0.999 2.2C1.499 2.2 1.999 1.65 1.999 1.1C1.999 0.55 1.499 0 0.999 0C0.499 0 0 0.55 0 1.1C0 1.65 0.499 2.2 0.999 2.2ZM0.999 6.6C1.499 6.6 1.999 6.05 1.999 5.5C1.999 4.95 1.499 4.4 0.999 4.4C0.499 4.4 0 4.95 0 5.5C0 6.05 0.499 6.6 0.999 6.6ZM0.999 11C1.499 11 1.999 10.45 1.999 9.9C1.999 9.35 1.499 8.8 0.999 8.8C0.499 8.8 0 9.35 0 9.9C0 10.45 0.499 11 0.999 11Z"
              fill="white"/>
    </svg>
};
