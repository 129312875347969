import { ReactNode } from "react";

export enum ServiceFrequencyEnum {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  BI_WEEKLY = "BI_WEEKLY",
  MONTHLY = "MONTHLY",
}

export enum ServiceDays {
  SUN = "SUNDAY",
  MON = "MONDAY",
  TUE = "TUESDAY",
  WED = "WEDNESDAY",
  THU = "THURSDAY",
  FRI = "FRIDAY",
  SAT = "SATURDAY",
}

export interface DaysCheckBoxTypes {
  value: ServiceDays;
  label: string;
  checked: boolean;
  [key: string]: any;
}

export interface DayTimesProps {
  day: ServiceDays;
  startTime: Date | string;
  endTime: Date | string;
  [key: string]: any;
}

export type TimeTypes = Omit<DayTimesProps, "day">;

export class OfferedServiceDto {
  constructor(
    public id: number,
    public serviceId: number,
    public serviceName: string,
    public unitPrice: number,
    public price: number,
    public quantity: number,
    public hospitalId: number,
    public createdBy: number,
    public deleted: boolean,
    public departmentId: number,
    public departmentName: string,
    public hodName: string,
    public categoryName: string,
    public serviceTimes: {
      frequency: ServiceFrequencyEnum|null;
      openingTimes: {
        day: ServiceDays;
        startTime: Date | string;
        endTime: Date | string;
      }[];
    },
    public notifyOtherUsers: boolean,
    public notifyPatients: boolean
  ) {}
}

export interface ServiceListDTO {
  name: string;
  department:string
  category: string;
  availableDays: ReactNode | string[];
  unitPrice: number;
  [key: string]: any;
}
