import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Dropdown.scss";
import classNames from "classnames";
import { FieldErrors } from "react-hook-form";
import { ErrorNotification } from "../../modules/errornotification/ErrorNotification";
import { FaCheck } from "react-icons/fa";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";

export type DropdownOptionsType = { value: any; label: string };

interface DropdownProps {
	id?: string;
	name: string;
	defaultValue?: string;
	onChange: any;
	options: DropdownOptionsType[];
	label?: string;
	placeholder: string;
	getSelectedOptionValue(selectedOption: string): any;
	errors: FieldErrors;
	ref?: any;
}

export const Dropdown = (props: DropdownProps) => {
	const [selectedOption, setSelectedOption] = useState<string>();
	const [isListOpen, setIsListOpen] = useState<boolean>(false);
	const [optionSelected, setOptionSelected] = useState<boolean>(false);
	const selectedOptionRef = useRef<HTMLDivElement>(null);
	const error = props.errors && props.errors[props.name as string];

	const closeOpenDropdown = useCallback(
		(e: any) => {
			if (
				isListOpen &&
				selectedOptionRef.current &&
				!selectedOptionRef.current.contains(e.target)
			) {
				setIsListOpen(false);
			}
		},
		[isListOpen]
	);

	useEffect(() => {
		document.addEventListener("mousedown", closeOpenDropdown);
	}, [closeOpenDropdown]);

	const selectedOptionHandler = (evt: any) => {
		const target = evt.target;
		props.onChange({ target: { name: props.name, value: target.value } });
		props.getSelectedOptionValue(target.value);
		setSelectedOption(target.value);
		setOptionSelected(true);
		setIsListOpen(false);
	};

	return (
		<div
			id={props.id}
			className={classNames("dropdown-component-container", {
				"selected-option-success": optionSelected,
				"dropdown-error":
					error && error.message && selectedOption === props.placeholder,
			})}
			ref={selectedOptionRef}
		>
			<div className={"dropdown-content-wrapper"}>
				<label style={{ fontWeight: 'bold' }}>
					{props?.label ?? props.placeholder}{" "}
					<sup className={"dropdown-content-wrapper-sup"}>*</sup>
				</label>
				<div
					className={"selected-option-container"}
					onClick={(e) => { setIsListOpen(!isListOpen); e.stopPropagation() }}
				>
					<span id={"selected-option"}>
						{selectedOption ?? props.placeholder}
					</span>
					<span>
						{(!error || !error.message) &&
							!selectedOption &&
							(isListOpen ? (
								<BiChevronUp color={"gray"} size={"1.5em"} />
							) : (
								<BiChevronDown color={"gray"} size={"1.5em"} />
							))}
						{error && error.message && <IoMdClose color={"red"} size={"1em"} />}
						{(!error || !error.message) && selectedOption && (
							<FaCheck color={"#4198BE"} size={"1em"} />
						)}
					</span>
				</div>
				{isListOpen && (
					<div className="options-wrapper">
						{/* <span>
                            <input type={'search'} placeholder={'search ' + props.placeholder.toLowerCase()} />
                        </span>*/}
						{props.options &&
							props.options.map((option) => (
								<option
									key={`${option.value}-${Math.random().toString(16).slice(2)}`}
									onClick={(e) => selectedOptionHandler(e)}
									className="dropdown-options-item"
									id={props.id}
									value={option.value}
									label={option.label}
									onChange={(e) => selectedOptionHandler(e)}
								>
									{option.value}
								</option>
							))}
					</div>
				)}
				{error && error.message && (
					<strong className="input-error">{error?.message as string}</strong>
					// <ErrorNotification name={props.name} fieldErrors={props.errors} />
				)}
			</div>
		</div>
	);
};