import React from 'react';

const IncreaseSvg = () => {
	return (
		<svg width='16' height='16' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M1.54692 5.40492L10.9908 1.11M10.9908 1.11L9.22522 0.444824M10.9908 1.11L10.3338 2.8756M11.4096 11.1205H9.35661V5.37207C9.35661 5.26317 9.39987 5.15873 9.47687 5.08173C9.55388 5.00473 9.65832 4.96147 9.76721 4.96147H10.999C11.1079 4.96147 11.2124 5.00473 11.2894 5.08173C11.3664 5.15873 11.4096 5.26317 11.4096 5.37207V11.1205ZM7.30359 11.1205H5.25057V6.60388C5.25057 6.49498 5.29383 6.39055 5.37083 6.31354C5.44784 6.23654 5.55228 6.19328 5.66117 6.19328H6.89299C7.00189 6.19328 7.10632 6.23654 7.18333 6.31354C7.26033 6.39055 7.30359 6.49498 7.30359 6.60388V11.1205ZM3.19755 11.1205H1.14453V7.8357C1.14453 7.7268 1.18779 7.62236 1.26479 7.54535C1.3418 7.46835 1.44624 7.42509 1.55514 7.42509H2.78695C2.89585 7.42509 3.00028 7.46835 3.07729 7.54535C3.15429 7.62236 3.19755 7.7268 3.19755 7.8357V11.1205Z'
				stroke='white'
				strokeWidth='0.821208'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default IncreaseSvg;
