import React, { useEffect, useState } from "react";
import "./BookAppointment.scss";
import { InputField } from "../../components/inputfield/InputField";
import { useForm } from "react-hook-form";
import {
  Dropdown,
  DropdownOptionsType,
} from "../../components/dropdown/Dropdown";
import { Button } from "../../components/button/Button";
import {
  AppointmentDto,
  ReasonForAppointment,
} from "../../types/AppointmentDto";
import { UserDto } from "../../types/UserDto";
import { ErrorNotification } from "../../modules/errornotification/ErrorNotification";
import { useWhoAmI } from "../../context/UserCtx";
import PatientDao, { Patient } from "../../types/PatientDao";
import appointmentService from "../../services/AppointmentService";
import {Modal} from "../../components/modal/Modal";
import {ParamsEnum} from "../../util/ParamsEnum";
import {Spinner} from "../../components/spinner/Spinner";
import {InputSearch} from "../../components/inputsearch/InputSearch";
import InputDate from "../../components/Date-Time/InputDate/InputDate";
import InputTime from "../../components/Date-Time/inputTime/InputTime";
import userService from "../../services/UserService";

interface NewAppointmentFormProps {
  appointmentDto?: AppointmentDto;
  isEdit?: boolean
}

export const BookAppointment = (props: NewAppointmentFormProps) => {
  const [appointmentData, setAppointmentData] = useState<AppointmentDto>(props.appointmentDto ?? {} as AppointmentDto);
  const user = useWhoAmI();

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [foundPatient, setFoundPatient] = useState<PatientDao[]>([]);
  const [selectedPatient, setSelectedPatient] = useState<Patient>({} as Patient);
  const [appointmentIsCreated, setAppointmentIsCreated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false)
  const [listOfUsers, setListOfUsers] = useState<UserDto[]>([]);
  const [bookAptError, setBookAptError] = useState<string>();
  const [selectedPersonnel, setSelectedPersonnel] = useState<UserDto>({} as UserDto);
  const [personnelOptions, setPersonnelOptions] = useState<DropdownOptionsType[]>([]);
  const [searchPatients, setSearchPatients] = useState<PatientDao[]>([]);
  const {register, trigger, formState: { isValid, errors }} = useForm({ mode: "onBlur" });
  const [edited, setEdited] = useState(props?.isEdit)

  const initPersonnelList = () => {
    const usersNames: DropdownOptionsType[] = [];
    listOfUsers?.length > 0 && listOfUsers.forEach((person) => {
      usersNames.push({
        label: `${person.firstname} ${person.lastname}`,
        value: `${person.firstname} ${person.lastname}`,
      });
    });
    setPersonnelOptions(usersNames);
  };

  const selectedPersonnelHandler = (name: string) => {
    const selectedUser = listOfUsers
      ?.filter((user) => `${user.firstname} ${user.lastname}` === name)
      .shift();
    selectedUser && setSelectedPersonnel(selectedUser);
  };

  const initAppointmentDto = () => {
    setAppointmentData((prevState) => ({
      ...prevState,
      hospitalId: user.hospital.id,
      hospitalName: user.hospital.name,
      createdBy: user.userId,
      personnelId: selectedPersonnel?.id,
      personnelName:
        selectedPersonnel?.firstname + " " + selectedPersonnel?.lastname,
      personnelEmail: selectedPersonnel?.email,
      patientId: selectedPatient?.id,
      patientName: selectedPatient?.firstname + " " + selectedPatient?.lastname,
      patientEmail: selectedPatient?.email
    }));
  };

  useEffect(() => {
    if (user?.hospital?.id) {
      userService.getAllUsers(user?.hospital?.id)
          .then((users: UserDto[]) => {
            setListOfUsers(users)
          });
    }

    if (edited && props.appointmentDto) {
      setAppointmentData(props.appointmentDto);
    }

    initPersonnelList();

    if(!edited) {
      initAppointmentDto();
    }
  }, [selectedPatient.firstname, user, selectedPersonnel, appointmentData.personnelId, props.appointmentDto?.personnelName, listOfUsers.length]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAppointmentData((prev) => ({ ...prev, [name]: value }));
  };

  const bookAppointmentHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAppointmentData((prev) => ({ ...prev, [name]: value }));
  };

  const handleResults = (results: PatientDao[]): void => {
    setFoundPatient(results);
    setSearchPatients(results);
  };

  const handleSubmit = () => {
    trigger().then();

    if (!isValid || !appointmentData.patientName || !appointmentData.reason) {
      setBookAptError("Please check again to ensure you have entered all necessary input and correctly");
      return;
    }

    if (edited) {
      appointmentService.updateAppointment(appointmentData)
          .then((resp: AppointmentDto) => {
            setEdited(true)
            setAppointmentData(resp);
            setAppointmentIsCreated(true);
          })
          .catch(() => {
            setBookAptError("Technical error occurred while updating appointment, please inform HealthProSuite");
          })
      return;
    }

    if(!edited && appointmentData) {
      appointmentService.newAppointment(appointmentData)
          .then((resp: AppointmentDto) => {
            setAppointmentData(resp);
            setAppointmentIsCreated(true);
          })
          .catch(() => {
            setBookAptError("Technical error occurred while saving new appointment, please inform HealthProSuite");
          })
    }
  }

  const patientName = props.appointmentDto?.patientName ?? (selectedPatient && selectedPatient.firstname
      ? `${selectedPatient?.firstname} ${selectedPatient?.lastname}` : searchQuery)

  return (
    <div style={{ position: "relative", zIndex: "10" }}>
      {bookAptError && (
        <ErrorNotification
          name={"book-apt-error"}
          stringErrors={bookAptError}
        />
      )}
      <section className="appointment-form">
        <div className="left">
          <InputField
            id="title"
            name="title"
            defaultValue={appointmentData.title}
            onChange={onChangeHandler}
            errors={errors}
            placeholder="Appointment Title"
            label="Appointment Title"
            pattern={{
              value: /^.*[a-zA-Z0-9\s]{6,50}$/,
              message: "min of 6 letters",
            }}
            register={register}
            minLength={6}
            min={6}
            max={50}
            maxLength={50}
            required={"Add Appointment title is required"}
          />
          <div className="patient-input-container search-input-label-w">
            <InputSearch
                id={'search-patient'}
                name={'search-patient'}
                placeholder={'search patient...'}
                label={'Search patient'}
                defaultValue = {patientName ?? searchQuery}
                register={register}
                searchQuery={searchQuery}
                controllerReqPath={'patient'}
                onResults={handleResults}
                onChange={(e: any) => {
                  setSearchQuery(e.target.value);
                }}
                errors={errors}
                onFocus={(e: any) => {
                  setSearchQuery(searchQuery ?? '')
                }}
            />

            {loading && <span><Spinner /></span>}

            { searchQuery &&
                foundPatient?.length > 0 && (
                    foundPatient.map((patient, index) => (
                        <div key={Math.random().toFixed(index)} className={'search-input-result-w'}>
                          <button onClick={() => {
                            setSelectedPatient(patient.patientDao)
                            setFoundPatient([])
                            setLoading(false);
                          }}>
                            {patient.patientDao.lastname + ' ' + patient.patientDao.firstname}
                          </button>
                        </div>
                    ))
                )
            }
          </div>
          <div className="time-date">
            <div className="time-container">
              <InputTime
                id="appointmentTime"
                name="appointmentTime"
                defaultValue={props.appointmentDto && props.appointmentDto?.appointmentTime || appointmentData.appointmentTime}
                onChange={bookAppointmentHandler}
                errors={errors}
                placeholder="00:00 AM"
                label="Select Appt. Time"
                register={register}
                required="Appointment time is required"
                pattern={{
                  value: /^(0[1-9]|1[0-2]):[0-5][0-9]\s?(AM|PM|am|pm)$/,
                  message: "Time format allowed is hh:mm AM/PM e.g: 09:00 AM",
                }}
              />
            </div>
            <div className="date-container">
              <InputDate
                id="appointmentDate"
                name="appointmentDate"
                defaultValue={props.appointmentDto?.appointmentDate ? String(props.appointmentDto?.appointmentDate) : (appointmentData?.appointmentDate || '01-01-2024')}
                onChange={bookAppointmentHandler}
                errors={errors}
                placeholder={String(props.appointmentDto?.appointmentDate) || "01-01-2024"}
                label="Select Appt. Date"
                register={register}
                required="Appointment date is required"
                pattern={{
                  value:
                    /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(19|20)\d\d$/,
                  // value:
                  //   /^(((0[1-9]|1[0-9]|2[0-9]|3[0-1]){2})-((0[1-9]|1[012]){2})-((\d){4}))$/,
                  /*value: /^.*(\d{2}-(1+[012]|0+[1-9]{1,2})-\d{4})$/,*/
                  message: "Date format allowed is dd-mm-yyyy, e.g: 20-03-2024",
                }}
              />
            </div>
          </div>
        </div>
        <div className="right">
          <div className="dropdown-input">
            <Dropdown
              id="reason"
              name="reason"
              defaultValue={props.appointmentDto?.reason ?? appointmentData.reason}
              onChange={bookAppointmentHandler}
              errors={errors}
              placeholder={props.appointmentDto?.reason ?? "Select purpose of Appointment"}
              label={"Purpose of Appointment"}
              getSelectedOptionValue={(value) => {
                setAppointmentData({
                  ...appointmentData,
                  reason: value as unknown as ReasonForAppointment,
                });
              }}
              options={[
                { label: "Clinic", value: ReasonForAppointment.CLINIC },
                { label: "Admission", value: ReasonForAppointment.ADMISSION },
                { label: "Checkup", value: ReasonForAppointment.CHECK_UP },
                {
                  label: "Consultation",
                  value: ReasonForAppointment.CONSULTATION,
                },
                { label: "Antenatal", value: ReasonForAppointment.ANTENATAL },
                { label: "Postnatal", value: ReasonForAppointment.POSTNATAL },
                {
                  label: "Child delivery",
                  value: ReasonForAppointment.CHILD_DELIVERY,
                },
                {
                  label: "Immunization",
                  value: ReasonForAppointment.IMMUNIZATION,
                },
                { label: "Surgery", value: ReasonForAppointment.SURGERY },
                { label: "Laboratory", value: ReasonForAppointment.LABORATORY },
              ]}
            />
          </div>
          <div className="personal dropdown-input">
            <Dropdown
              id="personnelName"
              name="personnelName"
              label="Select personnel"
              defaultValue={props.appointmentDto?.personnelName ?? appointmentData?.personnelName}
              onChange={onChangeHandler}
              errors={errors}
              placeholder={props.appointmentDto?.personnelName ?? "Select personnel"}
              getSelectedOptionValue={(value) => {
                setAppointmentData({
                      ...appointmentData,
                  personnelName: value,
                });
                selectedPersonnelHandler(value);
              }}
              options={personnelOptions}
            />
          </div>
          <div className="notification">
            <p>Patient and doctor will be notified via Email</p>
          </div>

          <div className="appointment-btn">
            <Button buttonName="Book Now" id="submit" onClick={handleSubmit} />
          </div>
        </div>
      </section>

      {
        appointmentIsCreated &&
          <div className={'saved-appointment-modal-w'}>
            <Modal isOpen={appointmentIsCreated} onClose={() => window.location.href = ParamsEnum.APPOINTMENTS}>
              {
                appointmentData.emailSentToPersonnel && appointmentData.emailSentToPatient &&
                  <p>
                    Appointment {edited ? 'updated' : 'saved'} successfully, {appointmentData.patientName} and {appointmentData.personnelName} notified!
                  </p>
              }

              {
                  appointmentData.emailSentToPersonnel && !appointmentData.emailSentToPatient &&
                  <p>
                    Appointment {edited ? 'updated' : 'saved'} successfully, {appointmentData.personnelName} notified! while
                    <span style={{color: 'red'}}> error notifying {appointmentData.patientName}</span>
                  </p>
              }

              {
                  !appointmentData.emailSentToPersonnel && appointmentData.emailSentToPatient &&
                  <p>
                    Appointment {edited ? 'updated' : 'saved'} successfully, {appointmentData.patientName} is notified while
                    <span style={{color: 'red'}}> error notifying {appointmentData.personnelName}</span>
                  </p>
              }
            </Modal>
          </div>
      }
    </div>
  );
}