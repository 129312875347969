import React, { useState } from "react";
import Checked from "../../resources/assets/svg/Checked";
import Unchecked from "../../resources/assets/svg/Unchecked";
import "./ShowPassword.scss";
import { Link } from "react-router-dom";
import { FieldErrors } from "react-hook-form";

const ShowPassword: React.FC<{
  toggleShowPassword: (state: boolean) => void;
  isVisible?: boolean;
  isTerms?: boolean;
  reversed?: boolean;
  errors?: FieldErrors;
  name?: string;
  customMessage?: string;
}> = ({
  toggleShowPassword,
  isVisible,
  isTerms,
  errors,
  name,
  customMessage,reversed
}) => {
  const [show, setShow] = useState<boolean>(false);
  const errorMessage = errors?.[name as string]?.message;
  const handleShowPassword = () => {
    setShow(!show);
    toggleShowPassword(!show);
  };
  return (
    <>
      {isVisible && (
        <div
          className={`show_password_wrapper ${
            isTerms||reversed ? "show_password_wrapper_reverse" : ""
          } `}
        >
          {customMessage ? (
            <span>{customMessage}</span>
          ) : isTerms ? (
            <span className="show_password_text">
              I have read and accept the HealthProSuite{" "}
              <Link to="/privacy-policy" target="_blank">
                privacy policy
              </Link>{" "}
              and{" "}
              <Link to="/terms-of-use" target="_blank">
                terms of use
              </Link>
            </span>
          ) : (
            <span className="show_password_text">Show Password</span>
          )}
          {show ? (
            <Checked onClick={handleShowPassword} />
          ) : (
            <Unchecked
              onClick={handleShowPassword}
              className="show_password_unchecked"
            />
          )}
        </div>
      )}
      {!!errorMessage && (
        <strong className="input-error">{errorMessage as string}</strong>
      )}
    </>
  );
};

export default ShowPassword;
