import React from "react";

const UploadIcon = () => {
  return <svg width="57" height="45" viewBox="0 0 57 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30.4688 44.2835V31.7762H38.3438L28.5 19.2688L18.6562 31.7762H26.5312V44.2835H16.6875V44.1793C16.3567 44.2002 16.0417 44.2835 15.7031 44.2835C11.787 44.2835 8.03134 42.6364 5.26225 39.7044C2.49316 36.7724 0.9375 32.7958 0.9375 28.6494C0.9375 20.628 6.66656 14.0908 14.0179 13.1944C14.6624 9.62689 16.4609 6.40865 19.104 4.09325C21.7471 1.77785 25.0696 0.510014 28.5 0.507812C31.9309 0.509846 35.254 1.77753 37.8977 4.09288C40.5415 6.40823 42.3407 9.62655 42.9861 13.1944C50.3374 14.0908 56.0586 20.628 56.0586 28.6494C56.0586 32.7958 54.5029 36.7724 51.7338 39.7044C48.9647 42.6364 45.209 44.2835 41.2929 44.2835C40.9622 44.2835 40.6432 44.2002 40.3086 44.1793V44.2835H30.4688Z" fill="#00B0FF" />
  </svg>

};

export default UploadIcon;
