import './PatientProfile.scss';
import { Button } from '../../../components/button/Button';
import pics from '../../../resources/assets/images/profile_pics.png';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { capitalizeFirstCharacter, formatZoneDate, joinTwoStrings } from '../../../util/Helpers';
import PatientDao from '../../../types/PatientDao';
import { useLocation } from 'react-router-dom';
import { useCustomNavigate } from '../../../customhook/CustomUseNavigate';
import { ParamsEnum } from '../../../util/ParamsEnum';
import { UserDetail } from '../../manageaccount/hospitalInfo/HospitalInfo';
import {MdDelete} from "react-icons/md";
import {ActionType, DashboardTopNav} from "../../../components/dashboardnav/DashboardTopNav";
import {Modal} from "../../../components/modal/Modal";
import patientService from "../../../services/PatientService";
import {useWhoAmI} from "../../../context/UserCtx";

type ClickedNavType = '' | 'delete'

export const PatientProfile = () => {
	const [isMobile, setIsMobile] = useState(typeof window !== 'undefined' && window.innerWidth < 960);
	const location = useLocation();
	const user = useWhoAmI();
	const { patientDao, address } = (location?.state?.patient) || ({} as PatientDao);
	const navigate = useCustomNavigate();
	const [patientIsDeleted, setPatientIsDeleted] = useState<boolean>(false)
	const [patientError, setPatientError] = useState<string>()

	const handleResize = () => {
		setIsMobile(window.innerWidth < 960);
	};

	const deletePatientHandler = () => {
		if (patientDao?.id && user.userId) {
			patientService
				.deletePatient(patientDao.id, user.userId)
				.then((deleted) => {
					setPatientIsDeleted(deleted);
				})
				.catch(() => {
					setPatientError(
						"Technical error occurred while deleting patient, please try again later!"
					);
				});
		}
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [isMobile]);

	const editPatientHandler = (path: string) => {
		navigate(path, { state: { patient: { patientDao: patientDao, address } } });
	};

	const invoiceHandler = (path: string) => {
		navigate(ParamsEnum.PAYMENTS, { state: { patient: { patientDao: patientDao, address } } });
	};

	const handlePatientHistory = (path: string) => {
		navigate('/dashboard/patients/medical-history');
		// navigate(path, { state: { patient: { patientDao, address } } });
	};

	const [currentTab, setCurrentTab] = useState('');
	const [clickedNav, setClickedNav] = useState<ClickedNavType>();

	const getClickedActionHandler = (clickedAction: ActionType) => {
		setClickedNav(clickedAction.name as ClickedNavType);
	}

	return (
		<div style={{ width: '100%' }}>
			<div className={'patient-headline-text'}>
				<DashboardTopNav selectedAction={currentTab}
								 title={`${capitalizeFirstCharacter(patientDao?.lastname)} ${capitalizeFirstCharacter(patientDao?.firstname)} Profile`}
								 action={[
									 {name: 'delete', icon: <MdDelete/>},
								 ]}
								 hideSearchBar={true}
								 getClickedAction={getClickedActionHandler}
								 hasLightBulb={true}
								 message={`${capitalizeFirstCharacter(patientDao?.lastname)} ${capitalizeFirstCharacter(patientDao?.firstname)} personal information page`}
				/>

				{
					clickedNav === 'delete' && (
						<div className={'patient-profile-modal-w'}>
							<Modal isOpen={!!clickedNav}
								   onClose={() => setClickedNav(undefined)}
								   modalTitle={'Delete Patient'}
							>
								{
									!patientIsDeleted && (
										<div>
											<p style={{marginBottom: '20px', lineHeight: '25px'}}>Are you sure u want to delete this patient ? <br/> This action cannot be
												undone</p>

											<Button id={'delete-patient'}
													buttonName={'Delete'}
													onClick={deletePatientHandler}
											/>
										</div>
									)
								}

								{
									patientIsDeleted && (
										<p>Patient is deleted successfully</p>
									)
								}
							</Modal>
						</div>
					)
				}
			</div>

			<div>
				<div className='customer-profile-module'>
					<div className={classNames({ 'customer-profile-picture-info-section': !isMobile })}>
						<div className={classNames({ 'customer-profile-generate-invoice-divider': !isMobile })}>
							<div className={classNames({ 'customer-profile-left': !isMobile })}>
								<div className={classNames('customer-pics-name-wrapper', { 'width-40-percent': !isMobile })}>
									<img src={pics} alt='Profile pic' />
								</div>
								<div className={classNames('customer-pics-name-wrapper', { 'width-45-percent': !isMobile }, { 'customer-pics-mobile-name-wrapper': isMobile })}>
									<UserDetail label={'Patient Id'} value={patientDao?.id} />
									<UserDetail label={'Name'} value={joinTwoStrings(patientDao?.firstname, patientDao?.lastname)} />
									<UserDetail label={'Phone Number'} value={patientDao?.phoneNumber} />
								</div>
								{/* Do not delete this is used for styling */}
								<div className={classNames('customer-pics-name-wrapper', { 'width-15-percent': !isMobile })}>
									<div className={'edit-patient-profile-btn'}>
										<Button id={'edit-patient-profile-btn1'} name={'edit-patient-profile-btn'} buttonName={'View medical history'} onClick={() => handlePatientHistory(ParamsEnum.EDIT_PATIENT)} />
										<Button id={'edit-patient-profile-btn2'} name={'edit-patient-profile-btn'} buttonName={'Edit Profile'} onClick={() => editPatientHandler(ParamsEnum.EDIT_PATIENT)} />
										<Button id={'patient-generate-invoice-btn'} name={'patient-generate-invoice-btn'} buttonName={'Generate Invoice'} onClick={() => invoiceHandler(ParamsEnum.PAYMENTS)} />
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='customer-details-sections-wrapper'>
						<section className={'horizontal-line'}>
							<p>Personal Information</p>
						</section>
						<section className={'customer-details-sections'}>
							<div className='customer-details-sections-children'>
								<div className={'left-right-wrapper'}>
									<UserDetail label='D.O.B' value={patientDao?.dob} />
									<UserDetail label='Marital Status' value={patientDao?.maritalStatus} />
									<UserDetail label='Gender' value={patientDao?.gender} />
								</div>
								<div className={'left-right-wrapper'}>
									<UserDetail label='Nationality' value={address?.country} />
									<UserDetail label='Religion' value={patientDao?.religion} />
									<UserDetail label='Occupation' value={patientDao?.occupation} />
								</div>
								<div className={'left-right-wrapper'}>
									<UserDetail label='Deposit' value={patientDao?.deposit} />
									<UserDetail label='Date created' value={patientDao?.dateCreated.toString()} />
									<UserDetail label='Others' value={patientDao?.isDeleted} />
								</div>
							</div>
						</section>
					</div>

					<div className='customer-details-sections-wrapper'>
						<section className={'horizontal-line'}>
							<p>Contact information</p>
						</section>
						<section className={'customer-details-sections'}>
							<div className='customer-details-sections-children'>
								<div className={'left-right-wrapper'}>
									<UserDetail label='Street' value={address?.streetHouseNr} />
									<UserDetail label='Zip Code' value={address?.postcode} />
									<UserDetail label='State' value={address?.state} />
									<UserDetail label='Country' value={address?.country} />
								</div>
								<div className={'left-right-wrapper'}>
									<UserDetail label='Phone' value={patientDao?.phoneNumber} />
									<UserDetail label='Email' value={patientDao?.email} />
									<UserDetail label='Fax' value={patientDao?.email} />
								</div>
							</div>
						</section>
					</div>

					<div className='customer-details-sections-wrapper'>
						<section className={'horizontal-line'}>
							<p>Next of Kin Profile</p>
						</section>
						<section className={'customer-details-sections'}>
							<div className='customer-details-sections-children'>
								<div className={'left-right-wrapper'}>
									<UserDetail label='Name' value={patientDao?.nextOfKinName} />
									<UserDetail label='Relationship' value={patientDao?.nextOfKinRelationship} />
									<UserDetail label='Phone' value={patientDao?.nextOfKinPhone} />
									<UserDetail label='Email' value={patientDao?.nextOfKinEmail} />
								</div>
								<div className={'left-right-wrapper'}>
									<UserDetail label='Street' value={''} />
									<UserDetail label='Postcode' value={''} />
									<UserDetail label='State' value={''} />
									<UserDetail label='Country' value={''} />
								</div>
							</div>
						</section>
					</div>

					<div className='customer-details-sections-wrapper'>
						<section className={'horizontal-line'}>
							<p>Other Information</p>
						</section>
						<section className={'customer-details-sections'}>
							<div className='customer-details-sections-children customer-admin-other-info'>
								<div className={'left-right-wrapper'}>
									<UserDetail label='Created By' value={patientDao?.createdBy} />
									<UserDetail label='Creation Date' value={formatZoneDate(patientDao?.dateCreated.toString())} />
								</div>
								<div className={'left-right-wrapper'}>
									<UserDetail label='Last Edited' value={patientDao?.lastModifiedBy || 'Not modified yet'} />
									<UserDetail label='Edited By' value={formatZoneDate(patientDao?.dateModified?.toString())} />
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
		</div>
	);
};
