import './AddDepartment.scss'
import {NewDepartmentDto} from "../../../types/department/Department";
import {InputField} from "../../../components/inputfield/InputField";
import {useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import {Button} from "../../../components/button/Button";
import {Dropdown} from "../../../components/dropdown/Dropdown";
import departmentService from "../../../services/DepartmentService";
import {DepartmentDao} from "../../../types/department/DepartmentDao";
import {ErrorNotification} from "../../../modules/errornotification/ErrorNotification";
import {useWhoAmI} from "../../../context/UserCtx";
import {CategoryDto} from "../../../types/department/CategoryDto";
import { Spinner } from '../../../components/spinner/Spinner';
import {getAllHODs} from "../../../util/UserRolesHelper";
import SuccessResponse from "../../customer/MedicalHistory/YearlyFolder/MonthlyFolder/SuccessResponse/SuccessResponse";
import {UserDto} from "../../../types/UserDto";
import userService from "../../../services/UserService";

interface DepartmentProps {
    department?: NewDepartmentDto;
    isEdit?: boolean
}

export const AddDepartment = (props: DepartmentProps) => {
    const user = useWhoAmI();
    const [listOfUsers, setListOfUsers] = useState<UserDto[]>([]);
    const usersDropdownOptions = useMemo(() => getAllHODs(listOfUsers), [listOfUsers]);
    const {register, trigger, setError, formState: {errors, isValid}} = useForm({mode: 'onBlur'});
    const [newDepartment, setNewDepartment] = useState<NewDepartmentDto>(
        props?.department || { categories: [], name: '', unitHead: '', createdBy: user.userId, hospitalId: user.hospital.id }
    );
    const [inputValue, setInputValue] = useState('');
    const [tags, setTags] = useState<string[]>([]);
    const [savedDepartment, setSavedDepartment] = useState<DepartmentDao>();
  const [newDepartmentIsCreated, setNewDepartmentIsCreated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false)

    const filterTagsHandler = () => {
        let filterCategories: string[] = [];
        props?.department?.categories.filter(cat => {
            cat?.name && filterCategories.push(cat?.name)
        })
        filterCategories?.length > 0 && setTags(filterCategories)
    }

    useEffect(() => {
        if (user?.hospital?.id) {
            userService.getAllUsers(user?.hospital?.id)
                .then((users: UserDto[]) => {
                    setListOfUsers(users)
                });
        }

        if(props?.department?.categories && props?.department?.categories?.length > 0) {
            filterTagsHandler();
        }
    }, [listOfUsers.length]);

    useEffect(() => {
        if(props?.department?.categories && props?.department?.categories.length > 0) {
            setTags(props?.department?.categories?.map((item) => item.name));
        }
    },[props?.department])

    const handleInputChange = (e: any) => {
        const value = e.target.value;
        const evtName = e.target.name;

        setInputValue(value);

        if(evtName === 'departmentName') {
            setNewDepartment((prevState) => ({
                ...prevState, name: value
            }));
        }
    };

    const handleInputKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            const newTag = inputValue.trim();
            if (newTag && newTag !== '') {
                setTags([...tags, newTag]);

                const newCategory = {} as CategoryDto;
                newCategory.name = newTag;
                setNewDepartment((prevState) => ({
                    ...prevState, categories: [...newDepartment.categories, newCategory]
                }))

                setInputValue(' ');
                e.target.value = ''
            }
        }
    };

    const handleTagDelete = (tagToDelete: string) => {
        const updatedTags = tags?.filter(tag => tag !== tagToDelete);
        setTags(updatedTags);

    const updatedCategories = newDepartment.categories
        .filter((eachCat: CategoryDto) => eachCat.name !== tagToDelete)

        setNewDepartment((prevState) => ({
            ...prevState, categories: updatedCategories
        }))
    };

    const getUnitHead = (unitHead: string) => {
        setNewDepartment((prevState) => ({
            ...prevState, unitHead, hospitalId: user.hospital.id, createdBy: user.userId
        }))
    }

    const saveNewDepartmentHandler = () => {
        trigger().then();

        if(newDepartment?.categories?.length < 1) {
            setError('categories', {message: 'at least one category is required', type: 'required'})
            return
        }

      if (isValid && newDepartment.unitHead && newDepartment.hospitalId > 0 && newDepartment.createdBy > 0) {
          setLoading(true)
            departmentService.createNewDepartment(newDepartment)
                .then((response) => {
                    setSavedDepartment(response);
                    setNewDepartment({} as NewDepartmentDto) // clear
                    setNewDepartmentIsCreated(true)
                }).catch(() => {
                setError('save-department-error', {message: 'technical error occurred while saving department'})
            }).finally(()=>setLoading(false))
        } else {
            setError('save-department-error', {
                message: 'Some constraint are violated, please control the ' +
                    'inputs once more and try again'
            });
        }
    }

    const saveEditDepartmentHandler = () => {
        if(!props.isEdit) {
            return
        }

        departmentService.updateDepartment(newDepartment)
            .then((response) => {
                setSavedDepartment(response);
                setNewDepartmentIsCreated(true)
            }).catch(() => {
            setError('save-department-error', {message: 'technical error occurred while updating department'})
        }).finally(()=>setLoading(false))
    }

    return (
      <section className={"add-department-component"}>
        {!newDepartmentIsCreated && (
          <>
            <div className={"department-input-wrapper"}>
              <InputField
                id={"departmentName"}
                name={"departmentName"}
                defaultValue={newDepartment?.name || props.department?.name}
                label={"Department name"}
                placeholder={"Department name"}
                onChange={handleInputChange}
                register={register}
                errors={errors}
                min={3}
                minLength={3}
                max={50}
                maxLength={50}
                required={"name of department is required"}
                pattern={{
                  value: /[a-zA-Z0-9\s*]{3,20}$/,
                  message:
                    "Only alphanumeric values are allowed, minimum 3 characters",
                }}
                withHint={"Enter the name of the department"}
              />

              <Dropdown
                name={"unitHead"}
                id={"unitHead"}
                onChange={handleInputChange}
                options={usersDropdownOptions}
                placeholder={props.department?.unitHead||"HOD / Unit Head"}
                label="HOD / Unit Head"
                getSelectedOptionValue={getUnitHead}
                errors={errors}
              />

              <section className={"department-categories-section"}>
                <InputField
                  id={"categories"}
                  name={"categories"}
                  defaultValue={''}
                  onChange={handleInputChange}
                  onKeyDown={handleInputKeyDown}
                  label={"Categories"}
                  placeholder={"Enter least a category ..."}
                  register={register}
                  errors={errors}
                  min={3}
                  minLength={3}
                  max={50}
                  maxLength={50}
                  required={
                    !newDepartment?.categories ||
                    newDepartment?.categories?.length < 1
                  }
                  pattern={{
                    value: /[a-zA-z0-9]{0,20}$/,
                    message:
                      "Only alphanumeric values are allowed, minimum 3 characters",
                  }}
                  withHint={
                    "Write at least one category under this department and hit enter to add the category"
                  }
                />

                {tags && tags.length > 0 && (
                  <ul className={"department-categories-wrapper"}>
                    {tags.map((tag, index) => (
                      <li key={index} className="each-categories">
                        {tag}
                        <button onClick={() => handleTagDelete(tag)}>
                          &times;
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </section>
            </div>

            <div className={"department-btn-wrapper"}>
              <Button
                id={"save-department"}
                name={"save-department"}
                buttonName={"Save"}
                onClick={props.isEdit ? saveEditDepartmentHandler : saveNewDepartmentHandler}
              />
            </div>
          </>
        )}

        {newDepartmentIsCreated && (
            <SuccessResponse onContinue={() => window.location.reload()} successMsg={'Department created'} />
        )}

        {errors && errors["save-department-error"]?.message && (
          <ErrorNotification
            name={"save-department-error"}
            fieldErrors={errors}
          />
        )}
        {loading && <Spinner/>}
      </section>
    );
}