import { useState } from 'react';
import './DashboardComponent.scss';
import DashboardComponentModal from '../Modal/DashboardComponentModal';
import { useDashboardContext } from '../../../context/DashboardCtx';
import DataRepresentation from '../DataRepresentation/DataRepresentation';
import AdmittedPatients from '../AdmittedPatients/AdmittedPatients';
import CombinedSalesComponent from '../CombinedSalesComponent/CombinedSalesComponent';
import Notification from '../Notification/Notification';
import AdminOnline from '../AdminOnline/AdminOnline';
import PatientsChart from '../PatientsChart/PatientsChart';
import { DashboardModalList } from '../../../types/Dashboard/DashboardModels';
import { arraysEqual, handleFilterOptions, returnLabels, saveToStorage } from '../../../util/Helpers';
import useDashboardHook from '../../../customhook/useDashboardHook';
import { Spinner } from '../../../components/spinner/Spinner';

enum ActiveBtn {
	DEFAULT = 'DEFAULT',
	CUSTOMIZE = 'CUSTOMIZE',
	ALL = 'ALL',
}
export const DashboardComponent = () => {
	const { loading } = useDashboardHook()
	const [showModal, setShowModal] = useState<boolean>(false)
	const { options, selectedOptions, dashboardOptions, setOptions, setSelectedOptions } = useDashboardContext();
	const isDefault = arraysEqual(
		returnLabels(handleFilterOptions(options)),
		returnLabels(handleFilterOptions(dashboardOptions))
	);
	const isAll = !options.find((item) => !item.enabled)

	const onClickOption = (option: ActiveBtn) => {
		if (option === ActiveBtn.CUSTOMIZE) {
			return setShowModal(true)
		}
		const isDefault = option === ActiveBtn.DEFAULT
		const allCheckedOptions = isDefault ? [] : dashboardOptions.map((item) => ({ ...item, enabled: true }))
		setOptions(isDefault ? dashboardOptions : allCheckedOptions);
		saveToStorage(
			"options",
			returnLabels(
				handleFilterOptions(isDefault ? dashboardOptions : allCheckedOptions)
			)
		);
		setSelectedOptions(
			handleFilterOptions(isDefault ? dashboardOptions : allCheckedOptions)
		);
	};

	if (loading) {
		return <Spinner />
	}
	return (
		<>
			<main className={'dashboard_component_container'}>
				<>
					<DashboardComponentModal isOpen={showModal} onClose={() => setShowModal(false)} />
				</>
				<section className={'default_cusomize_contaier'}>
					<span data-active={isDefault} className={'default_btn modal_toggle'} onClick={() => onClickOption(ActiveBtn.DEFAULT)}>
						Default
					</span>
					<span data-active={!isDefault && !isAll} className={'customize_btn modal_toggle'} onClick={() => onClickOption(ActiveBtn.CUSTOMIZE)}>
						Customize
					</span>
					<span data-active={isAll} className={'customize_btn modal_toggle'} onClick={() => onClickOption(ActiveBtn.ALL)}>
						All
					</span>
				</section>
				<section className='component_container'>
					<DataRepresentation />
					<CombinedSalesComponent />
				</section>
				<AdmittedPatients />
				<section className='notification_admin_patients_Container'>
					{selectedOptions.find((item) => item.label === DashboardModalList.Notification) && <Notification />}
					{selectedOptions.find((item) => item.label === DashboardModalList.Admin_online) && <AdminOnline />}
					{selectedOptions.find((item) => item.label === DashboardModalList.Bar_Chart_for_patients) && <PatientsChart />}
				</section>
			</main>
		</>
	);
};
