import React, {useEffect, useState} from "react";
import '../inputfield/InputField.scss'
import './InputSearch.scss'
import {
    FieldValues,
    UseFormRegister,
} from "react-hook-form";
import {FaCheck} from "react-icons/fa";
import {IoMdClose} from "react-icons/io";
import classNames from "classnames";
import {CiCircleInfo} from "react-icons/ci";
import {InputProps} from "../inputfield/InputField";
import {useWhoAmI} from "../../context/UserCtx";

export interface InputSearchProps extends InputProps {
    searchQuery: string;
    controllerReqPath: string;
    onResults(results: any[]): void
}

export const InputSearch = (props: InputSearchProps) => {
    const user = useWhoAmI();
    const [touched, setTouched] = useState(false);
    const register = props.register as UseFormRegister<FieldValues>;
    let error = props.errors && props.errors[props.name as string];
    const baseUrl = `${process.env.ACC_PROXY ?? process.env.REACT_APP_ACC_PROXY}`;
    const searchEndpoint = `${baseUrl}${props.controllerReqPath}/${user.hospital.id}/search?sq=${props.searchQuery}`;

    useEffect(() => {
        if (!searchEndpoint) {
            props.onResults([]);
            return;
        }

        const fetchResults = async () => {
            try {
                const response = await fetch(`${searchEndpoint}`);
                const data = await response.json();
                props.onResults(data);
            } catch (err) {
                error = {type: 'required', message: 'Error occurred while search user query'};
                props.onResults([]);
            }
        };

        fetchResults().then()
    }, [props.searchQuery]);

    const markTouched = () => {
        setTouched(true);
        props.onBlur && props.onBlur();
    };

    return (
        <div className={"input-field-container"}>
            <label>
                <strong>
                    {props.label}{" "}
                    {props.required && <sup style={{color: "#b24d4d"}}>*</sup>}
                </strong>

                {props.withHint && (
                    <span style={{paddingLeft: "5px"}} title={props.withHint}>
            <CiCircleInfo/>
          </span>
                )}
            </label>
            <div className={"input-field-wrapper"}>
        <span className={"input-success-icon-wrapper"}>
          {!error?.message && touched && (
              <i className={"input-success-icon"}>
                  <FaCheck size={"1em"}/>
              </i>
          )}
            {error?.message && (
                <i className={"input-error-icon"}>
                    <IoMdClose size={"1em"}/>
                </i>
            )}
        </span>

                <input
                    className={classNames("format-input", {
                        "input-success": !error && touched,
                        "input-error": error && error.message,
                    })}
                    id={props.id}
                    placeholder={props.placeholder || 'Search...'}
                    type={"search"}
                    disabled={props.disabled}
                    value={props.defaultValue || ''}
                    //defaultValue={props.defaultValue || ''}
                    {...register(props.name, {
                        required: props.required,
                        pattern: props.pattern,
                        validate: (value) => props.validate && props.validate(value),
                        min: props.min,
                        max: props.max,
                        onChange: (e: any) => {
                            props.onChange && props.onChange(e)
                        },
                        onBlur: () => {
                            markTouched();
                        }
                    })}
                    onFocus={(e) => {
                        props.onFocus && props.onFocus(e)
                    }}
                />

                {error?.message && (
                    <label style={{lineHeight: 1}}>
                        <strong className={"input-error"}>
                            {error.message as unknown as string}
                        </strong>
                    </label>
                )}
            </div>
        </div>
    );
};
