import React from "react";
import './Spinner.scss'

interface SpinnerProps {
    id?: string
}

export const Spinner = (props: SpinnerProps) => {
    return (
        <div className={'spinner-container'} id={props?.id}>
            <div className={'loading-spinner'} />
            <label>Loading...</label>
        </div>
    )
}