import './Sales.scss'
import { useEffect, useState } from "react";
import { Listing } from "../../components/listing/Listing";
import { MdAdd } from "react-icons/md";
import { ActionType, DashboardTopNav } from "../../components/dashboardnav/DashboardTopNav";
import InvoiceDao from "../../types/invoice/InvoiceDao";
import invoiceService from "../../services/InvoiceService";
import { useWhoAmI } from "../../context/UserCtx";
import { ErrorNotification } from "../../modules/errornotification/ErrorNotification";
import { InvoiceWithoutServices } from "../../types/invoice/InvoiceWithoutServices";
import { BiMoney } from "react-icons/bi";
import { userIsAdmin, userIsCashier, userIsSuperAdmin } from "../../util/UserRolesHelper";
import { BsList } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import SalesOverview from '../../components/salesOverview/SalesOverview';
import salesService from '../../services/SalesService';
import { SalesOverviewDTO } from '../../types/sales/SalesOverviewDTO';
import { Spinner } from '../../components/spinner/Spinner';
// defined type to omit selectedServices and companyId from the rest props
type SalesWithoutList = Omit<InvoiceWithoutServices, 'id' | 'selectedServices' | 'companyId' | 'deposit' | 'deleted' | 'createdBy' | 'customerId'>;
type SalesNavs = 'List' | 'Add Sale' | 'My Sales' | 'Cancel' | 'Refund' | 'View Counter'
type SelectedAction = 'List of Sales' | 'My Sales' | 'View Counter' | 'Add Sale'
export const Sales = () => {
    const user = useWhoAmI();
    const hasAdminRole = userIsSuperAdmin(user) || userIsAdmin(user)
    const [listOfSales, setListOfSales] = useState<InvoiceDao[]>([]);
    const [omitServices, setOmitServices] = useState<SalesWithoutList[]>([]);
    const [salesError, setSalesError] = useState<string>();
    const [action, setAction] = useState<SalesNavs>('List');
    const [loading, setLoading] = useState<boolean>(true)
    const [selectedAction, setSelectedAction] = useState<SelectedAction>(hasAdminRole ? 'List of Sales' : 'My Sales');
    const [salesOverview, setSalesOverview] = useState<SalesOverviewDTO>(
        {} as SalesOverviewDTO
    );

    const listOfSalesHandler = () => {
        const page = 1;
        const size = 20;
        setLoading(true)
        if (userIsSuperAdmin(user) || userIsAdmin(user)) {
            invoiceService.getAllSales(user.hospital.id, page, size)
                .then((data: InvoiceDao[]) => {
                    setListOfSales(data);
                    omitSomeServicesProps(data);
                    setSalesError('')
                }).catch(() => {
                    setSalesError("Technical error occurred while getting all sales, please try again later!")
                })
                .finally(() => setLoading(false))
        }

        if (userIsCashier(user) || userIsSuperAdmin(user) || userIsAdmin(user)) {
            invoiceService.getLoggedInUserSales(user.hospital.id, user.userId, page, size)
                .then((data: InvoiceDao[]) => {
                    setListOfSales(data);
                    omitSomeServicesProps(data);
                }).catch(() => {
                    setSalesError("Technical error occurred while getting your sales, please try again later!")
                })
                .finally(() => setLoading(false))
        }

        salesService.getCloseCounter()
            .then((response) => setSalesOverview(response))
            .catch(() => setSalesError("Technical error occurred while getting sales overview, please try again later!")
            )
        // .finally(() => setLoading(false))
    }

    // convert zone date time from BE as string to date
    function parseHighPrecisionTimestamp(value: string) {
        const [seconds, nanoseconds] = value.toString().split('.');
        const milliseconds = Number(seconds) * 1000 + Number(nanoseconds) / 1e6;
        return new Date(milliseconds);
    }

    const omitSomeServicesProps = (data: InvoiceDao[]) => {
        const newArray: SalesWithoutList[] = []
        data?.map(({ id, selectedServices, companyId, deleted, deposit, createdBy, customerId, ...rest }) => {
            const dt = parseHighPrecisionTimestamp(rest.createdDate);
            rest.createdDate = new Date(dt).toLocaleDateString();
            return newArray.push(rest)
        });
        setOmitServices(newArray);
    }

    useEffect(() => {
        listOfSalesHandler();
    }, [user, action])

    const getSalesSearchResult = (searchResult: any[]): any[] => {
        // console.log('sale found', searchResult)
        // handle
        return searchResult
    }

    const getClickedNavAction = (action: ActionType) => {
        setSelectedAction(action?.name as SelectedAction)
        switch (action?.name?.toLowerCase()) {
            case 'list':
                setAction('List')
                break;
            case 'list of sales':
                setAction('List')
                break;
            case 'add sale':
                setAction('Add Sale')
                break;
            case 'my sales':
                setAction('My Sales')
                break;
            case 'view counter':
                setAction('View Counter')
                break;
            default:
                setAction('List')
                break;
        }
    }

    const getNavs = () => {
        const navsArr = [];

        if (hasAdminRole) {
            navsArr.push({ name: 'List of Sales', icon: <BsList /> })
        }

        navsArr.push({ name: 'My Sales', icon: <BiMoney /> })
        navsArr.push({ name: 'View Counter', icon: <FaEye /> })
        navsArr.push({ name: 'Add Sale', icon: <MdAdd /> })
        return navsArr;
    }

    if (loading) {
        return <Spinner />
    }
    return (
        <div className={"sales-component-layout"}>
            {salesError && (
                <ErrorNotification name={"sales-error"} stringErrors={salesError} />
            )}
            <div>
                <DashboardTopNav selectedAction={selectedAction}
                    action={getNavs()}
                    placeholder={"search sale with id or date"}
                    itemList={omitServices}
                    getSearchResult={getSalesSearchResult}
                    getClickedAction={getClickedNavAction}
                    hideSearchBar={(action !== 'List') || (listOfSales?.length < 1)}
                />
            </div>

            {(action === "List" || action === "My Sales") &&
                (listOfSales?.length > 0 ? (
                    <Listing
                        withAction={true}
                        items={omitServices}
                        actions={["cancel", "refund"]}
                        getAction={getClickedNavAction}
                        getClickedItem={getClickedNavAction}
                    />
                ) : (!loading &&
                    <ErrorNotification
                        name={"empty-sales-for-logged-user"}
                        stringErrors={"You have not made any sales"}
                    />)
                )}

            {action === "Add Sale" && (
                <p>Add sales modal or redirect logic comes here</p>
            )}

            <SalesOverview
                date={salesOverview.date}
                bankTransfer={salesOverview.bankTransfer}
                POS={salesOverview.POS}
                cash={salesOverview.cash}
                paid={salesOverview.paid}
                waived={salesOverview.waived}
                installment={salesOverview.installment}
                totalPaid={salesOverview.totalPaid}
                totalWaived={salesOverview.totalWaived}
                totalInstallment={salesOverview.totalInstallment}
                totalBalance={salesOverview.totalBalance}
                confirmClose={(state) => { return state }}
                closeModal={() => { setSelectedAction('My Sales'); setAction('List') }}
                showCounter={action === "View Counter"}
                onCloseCounter={() => { }}
            />
        </div>
    );
}