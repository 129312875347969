import { FaCheckCircle } from "react-icons/fa";
import { Button } from "../button/Button";
import './RegistrationSuccessModal.scss'
import {useCustomNavigate} from "../../customhook/CustomUseNavigate";


interface RegistrationSuccessModalProps {
	message: string
}

export const RegistrationSuccessModal = (props: RegistrationSuccessModalProps) => {
	const navigate = useCustomNavigate();

	return (
		<div id="success-modal-container">
			<h2>Account registered successfully</h2>
			<div id="success-modal">
				<div>
					<span>
						<FaCheckCircle />
					</span>
					<p>Successful</p>
				</div>
				<div>
					<p>{props.message}</p>
					<Button
						id={"continue-btn"}
						name={"continue-btn"}
						buttonName={"Continue"}
						onClick={() => navigate('/login')}
					/>
				</div>
			</div>
		</div>
	);
}