import React from 'react';

const CloseIcon = () => {
	return (
		<svg width='22' height='22' viewBox='0 0 26 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M23.625 0.5H2.375C1.81141 0.5 1.27091 0.737053 0.872398 1.15901C0.473883 1.58097 0.25 2.15326 0.25 2.75V25.25C0.25 25.8467 0.473883 26.419 0.872398 26.841C1.27091 27.2629 1.81141 27.5 2.375 27.5H23.625C24.1886 27.5 24.7291 27.2629 25.1276 26.841C25.5261 26.419 25.75 25.8467 25.75 25.25V2.75C25.75 2.15326 25.5261 1.58097 25.1276 1.15901C24.7291 0.737053 24.1886 0.5 23.625 0.5ZM20.1267 19.9541C20.2254 20.0586 20.3037 20.1827 20.3572 20.3192C20.4106 20.4558 20.4381 20.6022 20.4381 20.75C20.4381 20.8978 20.4106 21.0442 20.3572 21.1808C20.3037 21.3173 20.2254 21.4414 20.1267 21.5459C20.028 21.6505 19.9108 21.7334 19.7818 21.7899C19.6528 21.8465 19.5146 21.8756 19.375 21.8756C19.2354 21.8756 19.0972 21.8465 18.9682 21.7899C18.8392 21.7334 18.722 21.6505 18.6233 21.5459L13 15.5905L7.37672 21.5459C7.17735 21.757 6.90695 21.8756 6.625 21.8756C6.34305 21.8756 6.07265 21.757 5.87328 21.5459C5.67391 21.3348 5.56191 21.0485 5.56191 20.75C5.56191 20.4515 5.67391 20.1652 5.87328 19.9541L11.4979 14L5.87328 8.04594C5.67391 7.83484 5.56191 7.54853 5.56191 7.25C5.56191 6.95147 5.67391 6.66516 5.87328 6.45406C6.07265 6.24297 6.34305 6.12437 6.625 6.12437C6.90695 6.12437 7.17735 6.24297 7.37672 6.45406L13 12.4095L18.6233 6.45406C18.8226 6.24297 19.0931 6.12437 19.375 6.12437C19.6569 6.12437 19.9274 6.24297 20.1267 6.45406C20.3261 6.66516 20.4381 6.95147 20.4381 7.25C20.4381 7.54853 20.3261 7.83484 20.1267 8.04594L14.5021 14L20.1267 19.9541Z'
				fill='black'
			/>
		</svg>
	);
};

export default CloseIcon;
