import "./PrivacyPolicy.scss";
import { IoList } from "react-icons/io5";

export function PrivacyPolicy() {
	function toggleTOC(): void {
		const TOF = document.querySelector("aside");
		TOF?.classList.toggle("close");
	}
	return (
		<div id="privacy">
			<aside className="close">
				<h2>Table of Content</h2>
				<ol type="1">
					<li>
						<a className="section-link" href="#introduction">
							Introduction
						</a>
					</li>
					<li>
						<a className="section-link" href="#info-collection">
							Information We Collect
						</a>
					</li>
					<li>
						<a className="section-link" href="#info-usage">
							How We Use Your Information
						</a>
					</li>
					<li>
						<a className="section-link" href="#security">
							Security Measures
						</a>
					</li>
					<li>
						<a className="section-link" href="#disclosure">
							Disclosure of Data
						</a>
					</li>
					<li>
						<a className="section-link" href="#transfer">
							Transfer of Data
						</a>
					</li>
					<li>
						<a className="section-link" href="#service-providers">
							Service Providers
						</a>
					</li>
					<li>
						<a className="section-link" href="#links">
							Links to Other Sites
						</a>
					</li>
					<li>
						<a className="section-link" href="#children">
							Children Privacy
						</a>
					</li>
					<li>
						<a className="section-link" href="#choice-and-rights">
							Your Choice and Rights
						</a>
					</li>
					<li>
						<a className="section-link" href="#updates">
							Updates to Privacy Policy
						</a>
					</li>
					<li>
						<a className="section-link" href="#contact">
							Contact Us
						</a>
					</li>
					<li>
						<a className="section-link" href="#conclusion">
							Conclusion
						</a>
					</li>
				</ol>
			</aside>
			<main>
				<header>
					<h1>HealthProSuite Privacy Policy</h1>
					<p>Last updated: 16th January, 2024</p>
				</header>
				<section id="introduction">
					<h2>Introduction</h2>
					<p>
						This page informs you of our policies regarding the collection, use,
						and disclosure of personal data when you use our Service and the
						choices you have associated with that data. We use your data to
						provide and improve the Service. By using our services, you agree to
						the collection and use of information in accordance with this
						policy.
					</p>
				</section>
				<section id="info-collection">
					<h2>Information We Collect</h2>
					<p>
						We may collect personal information such as names, contact details,
						and company information when you engage with our services, subscribe
						to newsletters, or interact with our website. Additionally, we may
						collect non-personal information through cookies and similar
						technologies to enhance your experience on our site.
					</p>
				</section>
				<section id="info-usage">
					<h2>How We Use Your Information</h2>
					<p>
						The information we collect is used to provide and improve our
						services, communicate with you, and enhance your experience with
						HealthProSuite. We may also use your information for marketing
						purposes, with your consent, to keep you informed about our latest
						offerings, events, and industry insights.
					</p>
				</section>
				<section id="security">
					<h2>Security Measures</h2>
					<p>
						We implement industry-standard security measures to protect your
						personal information from unauthorized access, disclosure,
						alteration, and destruction. Our commitment to data security ensures
						a safe environment for your interactions with HealthProSuite.
					</p>
				</section>
				<section id="disclosure">
					<h2>Disclosure of Data</h2>
					<p>
						Legal Requirements HealthProSuite may disclose your Personal Data in
						the good faith belief that such action is necessary to: - To comply
						with a legal obligation - To protect and defend the rights or
						property of HealthProSuite - To prevent or investigate possible
						wrongdoing in connection with the Service - To protect the personal
						safety of users of the Service or the public - To protect against
						legal liability.
					</p>
				</section>
				<section id="transfer">
					<h2>Transfer of Data</h2>
					<p>
						Your information, including Personal Data, may be transferred to —
						and maintained on — computers located outside of your state,
						province, country, or other governmental jurisdiction where the data
						protection laws may differ than those from your jurisdiction.
					</p>
					<p>
						HealthProSuite will take all steps reasonably necessary to ensure
						that your data is treated securely and in accordance with this
						Privacy Policy and no transfer of your Personal Data will take place
						to an organization or a country unless there are adequate controls
						in place including the security of your data and other personal
						information.
					</p>
				</section>
				<section id="service-providers">
					<h2>Service Providers</h2>
					<p>
						We may employ third party companies and individuals to facilitate
						our Service (service Providers), to provide the service on our
						behalf, to perform Service-related tasks or to assist us in
						analyzing how our Service is used. These third parties have access
						to your personal data only to perform these tasks on our behalf and
						are obligated not to disclose or use it for any other purpose.
					</p>
				</section>
				<section id="links">
					<h2>Links to Other Sites</h2>
					<p>
						Our Service may contain links to other sites that are not operated
						by us. If you click on a third-party link, you will be directed to
						that third party's site. We strongly advise you to review the
						Privacy Policy of every site you visit. We have no control over and
						assume no responsibility for the content, privacy policies or
						practices of any third party sites or services.
					</p>
				</section>
				<section id="children">
					<h2>Children Privacy</h2>
					<p>
						Our Service does not address anyone under the age of 18. <br /> We
						do not knowingly collect personally identifiable information from
						anyone under the age of 18. If you are a parent or guardian and you
						are aware that your Children has provided us with Personal Data,
						please contact us. If we become aware that we have collected
						Personal Data from children without verification of parental
						consent, we take steps to remove that information from our servers.
					</p>
				</section>
				<section id="choice-and-rights">
					<h2>Your Choices and Rights</h2>
					<p>
						You have the right to access, correct, or delete your personal
						information. You can opt-out of receiving marketing communications
						at any time. We respect your choices and aim to provide you with
						control over your personal data.
					</p>
				</section>
				<section id="updates">
					<h2>Updates to Privacy Policy</h2>
					<p>
						We may update this Privacy Policy to reflect changes in our
						practices or for legal reasons. We encourage you to review this page
						periodically for the latest information on our privacy practices.
					</p>
				</section>
				<section id="contact">
					<h2>Contact Us</h2>
					<p>
						If you have any questions, concerns, or requests regarding your
						privacy or our practices, please contact us at
						info@HealthProSuite.com.
					</p>
				</section>
				<section id="conclusion">
					<p>
						Thank you for trusting Atrizult with your IT consulting needs. Your
						privacy is important to us, and we are dedicated to maintaining the
						highest standards of data protection. Atrizult Enugu, Nigeria
					</p>
				</section>
			</main>
			<button onClick={toggleTOC}>
				Table of content <IoList />
			</button>
		</div>
	);
}
