import { ApiService } from "./ApiService";
import { ContactInfo } from "../types/Helper";

const baseUrl = `${process.env.ACC_PROXY ?? process.env.REACT_APP_ACC_PROXY}`;

class HomepageService extends ApiService {
  async contact(contactInfo: ContactInfo): Promise<Response> {
   
    const response = await fetch(baseUrl+'contact-form', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contactInfo),
    });

   return await this.apiResponseHandler(response);
  }
}

const homepageService =  new HomepageService();
export default homepageService;
