/* eslint-disable no-sequences */
import "./Department.scss";
import { useEffect, useState } from "react";
import { AddDepartment } from "./adddepartment/AddDepartment";
import { Modal } from "../../components/modal/Modal";
import {
    ActionType,
    DashboardTopNav,
} from "../../components/dashboardnav/DashboardTopNav";
import { MdAdd } from "react-icons/md";
import { Listing } from "../../components/listing/Listing";
import { NewDepartmentDto } from "../../types/department/Department";
import { FcDepartment } from "react-icons/fc";
import { DepartmentDao } from "../../types/department/DepartmentDao";
import departmentService from "../../services/DepartmentService";
import { useWhoAmI } from "../../context/UserCtx";
import { restructureDeptData } from "../../util/Helpers";
import { Spinner } from "../../components/spinner/Spinner";
import { ErrorNotification } from "../../modules/errornotification/ErrorNotification";
import { Button } from "../../components/button/Button";
import {userIsAdmin, userIsSuperAdmin} from "../../util/UserRolesHelper";
import patientService from "../../services/PatientService";

type ActivePage = "List" | "Edit" | "Add" | "DeActivate" | "Delete";

export const Department = () => {
    const [activePage, setActivePage] = useState<ActivePage>("List");
    const [navAction, setNavAction] = useState<ActionType>();
    const [searchResult, setSearchResult] = useState<any[]>([]);
    const [clickedDepartment, setClickedDepartment] = useState<NewDepartmentDto>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [departments, setDepartments] = useState<DepartmentDao[]>([]);
    const [departmentError, setDepartmentError] = useState<string>('')
    const user = useWhoAmI();
    const [departmentIsDeleted, setDepartmentIsDeleted] = useState<boolean>(false);

    const getListOfDepartmentsHandler = () => {
        setLoading(true);
        departmentService.getDepartments(user?.hospital?.id)
            .then((response) => {
                if (response.length === 0) {
                    setDepartmentError(
                        "You have no registered departments to be displayed"
                    );
                }
                setDepartments(response);


            })
            .catch((err) => {
                setDepartmentError('Technical error occurred while fetching departments')
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (activePage === "List" && (user?.hospital?.id > 0)) {
            getListOfDepartmentsHandler()
        }
    }, [activePage, user?.hospital?.id]);

    const getDepartmentSearchResult = (searchResult: any[]): any[] => {
        setSearchResult(searchResult);
        return searchResult;
    };

    const getNavAction = (action: ActionType): ActionType => {        
        setNavAction(action);
        switch (action?.name.toLowerCase()) {
            case "departments":
                setActivePage("List");
                break;
            case "add department":
                setOpenModal(true);
                setActivePage("Add");
                break;
            default:
                setActivePage("List");
                break;
        }
        return action;
    };

    const getClickedDepartment = (departmentModal: NewDepartmentDto) => {
        setClickedDepartment(departments?.find((dept) => dept?.name === departmentModal?.name));
    };

    const getClickedDepartmentAction = (actionClicked: ActivePage) => {
        setActivePage(actionClicked ?? activePage);

        if (actionClicked === "Edit") {
            setOpenModal(true);
            setActivePage("Edit");
        }

        if (actionClicked === "DeActivate") {
            setOpenModal(true);
            setActivePage("DeActivate");
        }

        if (actionClicked === "Delete") {
            setOpenModal(true);
            setActivePage("Delete");
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setActivePage("List");
    };

    const departmentToBeEdited = departments.find(
        (item) => item.name === clickedDepartment?.name
    );

    const getNavs = () => {
        const navsArr = [];

        if(userIsSuperAdmin(user) || userIsAdmin(user)) {
            navsArr.push({name: "Add Department", icon: <MdAdd/>})
        }

        navsArr.push({name: "Departments", icon: <FcDepartment/>})
        return navsArr;
    }

    const deleteDepartmentHandler = () => {
        if (user.hospital.id && clickedDepartment?.id && user.userId) {
            departmentService
                .deleteDepartment(user.hospital.id, clickedDepartment?.id, user.userId)
                .then((deleted) => {
                    setDepartmentIsDeleted(deleted);
                })
                .catch(() => {
                    setDepartmentError(
                        "Technical error occurred while deleting department and its categories and services, " +
                        "please try again later!"
                    );
                });
        }
    }

    return (
        <section className={"department-component"}>
            <section>
                <DashboardTopNav selectedAction={navAction?.name as string || 'Departments'}
                    action={getNavs()}
                    placeholder={"search department with name or date created"}
                    itemList={departments ?? []}
                    getSearchResult={getDepartmentSearchResult}
                    getClickedAction={getNavAction}
                    hideSearchBar={activePage !== "List"}
                />
            </section>

            <section className={departments?.length < 1 ? 'add-on-empty-department' : ''}>
                <>
                    {
                        (departmentError) &&
                        <div>
                            <div>
                                <ErrorNotification
                                    name={'no-registered-department'}
                                    stringErrors={
                                        departmentError
                                    }
                                />
                            </div>

                            <div>
                                <Button
                                    id={'add-department-on-empty-list'}
                                    buttonName={'Add Department'}
                                    onClick={() => {
                                        setOpenModal(true)
                                        setActivePage('Add')
                                    }}
                                />
                            </div>
                        </div>
                    }
                </>
            </section>

            {activePage === "List" && departments?.length > 0 && (
                <section className={'service_list_wrapper'}>
                    <Listing
                        items={restructureDeptData(departments) ?? []}
                        getClickedItem={getClickedDepartment}
                        withAction={userIsSuperAdmin(user) || userIsAdmin(user)}
                        actions={["Edit", "DeActivate", "Delete"]}
                        getAction={getClickedDepartmentAction}
                    />
                </section>
            )}

            {activePage === "Add" && (
                <Modal
                    isOpen={openModal}
                    onClose={handleCloseModal}
                    modalTitle={"Add New Department"}
                >
                    <AddDepartment />
                </Modal>
            )}

            {activePage === "Edit" && (
                <Modal
                    isOpen={openModal}
                    onClose={handleCloseModal}
                    modalTitle={"Edit Department"}
                >
                    <AddDepartment department={departmentToBeEdited} isEdit={true}/>
                </Modal>
            )}

            {
                activePage === 'Delete' && (
                    <div>
                        <Modal isOpen={!!activePage}
                               onClose={() => setActivePage('List')}
                               modalTitle={'Delete Department'}
                        >
                            {
                                !departmentIsDeleted && (
                                    <div>
                                        <p style={{marginBottom: '20px', lineHeight: '25px'}}>Are you sure u want to
                                            delete this department  and all categories and services offered belonging
                                            thereto ? <br/><br/> This action cannot be undone
                                        </p>

                                        <Button id={'delete-department'}
                                                buttonName={'Delete'}
                                                onClick={deleteDepartmentHandler}
                                        />
                                    </div>
                                )
                            }

                            {
                                departmentIsDeleted && (
                                    <p>Department and all categories and services offered belonging thereto are deleted successfully</p>
                                )
                            }
                        </Modal>
                    </div>
                )
            }


            {loading && <Spinner />}
        </section>
    );
};
