import React from "react";

const ServicesIcon = () => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.5 4.5V3H20.25V1.5H18.75V3H18C17.1727 3 16.5 3.6735 16.5 4.5V6C16.5 6.82725 17.1727 7.5 18 7.5H21V9H16.5V10.5H18.75V12H20.25V10.5H21C21.8272 10.5 22.5 9.82725 22.5 9V7.5C22.5 6.6735 21.8272 6 21 6H18V4.5H22.5ZM18 15V16.5H19.9395L17.25 19.1895L15.531 17.4697C15.3906 17.3293 15.2001 17.2502 15.0015 17.25H15C14.9016 17.25 14.8042 17.2694 14.7134 17.3071C14.6225 17.3448 14.54 17.4001 14.4705 17.4697L10.5 21.4395L11.5605 22.5L15.0007 19.0605L16.7197 20.7803C16.8604 20.9209 17.0511 20.9998 17.25 20.9998C17.4489 20.9998 17.6396 20.9209 17.7803 20.7803L21 17.5605V19.5H22.5V15H18ZM3 22.5H1.5V18.75C1.5 15.855 3.855 13.5 6.75 13.5H11.25C12.7417 13.5 14.1675 14.1375 15.1627 15.2498L14.0452 16.2502C13.6933 15.8572 13.2624 15.5428 12.7808 15.3273C12.2992 15.1119 11.7776 15.0004 11.25 15H6.75C4.68225 15 3 16.6823 3 18.75V22.5ZM9 12C10.3924 12 11.7277 11.4469 12.7123 10.4623C13.6969 9.47774 14.25 8.14239 14.25 6.75C14.25 5.35761 13.6969 4.02226 12.7123 3.03769C11.7277 2.05312 10.3924 1.5 9 1.5C7.60761 1.5 6.27226 2.05312 5.28769 3.03769C4.30312 4.02226 3.75 5.35761 3.75 6.75C3.75 8.14239 4.30312 9.47774 5.28769 10.4623C6.27226 11.4469 7.60761 12 9 12ZM9 3C9.99456 3 10.9484 3.39509 11.6517 4.09835C12.3549 4.80161 12.75 5.75544 12.75 6.75C12.75 7.74456 12.3549 8.69839 11.6517 9.40165C10.9484 10.1049 9.99456 10.5 9 10.5C8.00544 10.5 7.05161 10.1049 6.34835 9.40165C5.64509 8.69839 5.25 7.74456 5.25 6.75C5.25 5.75544 5.64509 4.80161 6.34835 4.09835C7.05161 3.39509 8.00544 3 9 3Z" fill="black"/>
</svg>

};

export default ServicesIcon;
