import React from "react";

const FolderIcon = () => {
  return <svg style={{ width: '100%', height: '100%' }} viewBox="0 0 87 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30.6237 0.875C31.567 0.874922 32.4841 1.18563 33.233 1.75908L33.6622 2.13246L45.2754 13.75H73.5404C76.8244 13.7498 79.9844 15.0046 82.3737 17.2575C84.7631 19.5105 86.2013 22.5913 86.3939 25.8697L86.4154 26.625V60.9583C86.4156 64.2424 85.1608 67.4023 82.9079 69.7917C80.6549 72.1811 77.5741 73.6192 74.2957 73.8119L73.5404 73.8333H13.457C10.173 73.8335 7.01303 72.5788 4.62366 70.3258C2.23429 68.0729 0.796153 64.992 0.60349 61.7137L0.582031 60.9583V13.75C0.581848 10.466 1.83659 7.306 4.08954 4.91663C6.34249 2.52726 9.42332 1.08912 12.7017 0.896459L13.457 0.875H30.6237Z" fill="#D2C867" />
  </svg>

};

export default FolderIcon;
