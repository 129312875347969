import './AddExtraNote.scss'
import {TextArea} from "../../../components/textarea/TextArea";
import {Button} from "../../../components/button/Button";
import {useState} from "react";
import {UUID} from "crypto";
import admissionService from "../../../services/AdmissionService";
import {useWhoAmI} from "../../../context/UserCtx";
import {FieldValues, UseFormRegister} from "react-hook-form";
import {Modal} from "../../../components/modal/Modal";

interface AddExtraNoteProps {
    admissionId: UUID,
    name: string,
    register: UseFormRegister<FieldValues>;
    getVitalSignExtraNote?(vitalSign: string): any
    getDoctorExtraNote?(doctorNote: string): any
    closeModal?(close: boolean): any
}

export type ExtraNoteDto = {
    vitalSignsExtraNotes: string,
    doctorsNote: string
}

export const AddExtraNote = (props: AddExtraNoteProps) => {
    const user = useWhoAmI();
    const [extraNote, setExtraNote] = useState<ExtraNoteDto>({} as ExtraNoteDto);
    const [noteIsSaved, setNoteIsSaved] = useState<boolean>(false)

    const updateBtnHandler = () => {
        admissionService.updateAdmissionExtraNote(user.hospital.id, props.admissionId, extraNote)
            .then((updatedAdm) => {
                setExtraNote({} as ExtraNoteDto)
                setNoteIsSaved(true);
            })
            .catch(() => console)
    }

    const extraNoteHandler = (evt: any) => {
        const {name, value} = evt.target;
        setExtraNote({...extraNote, [name]: value})
        name === 'vitalSignsExtraNotes' && props.getVitalSignExtraNote && props.getVitalSignExtraNote(value);
        name === 'doctorsNote' && props.getDoctorExtraNote && props.getDoctorExtraNote(value);
    }

    return (
        <div className={'admit-patient-add-note-modal-w'}>
            <TextArea
                name={props.name}
                register={props.register}
                label={'Add note'}
                value={extraNote.vitalSignsExtraNotes || extraNote.doctorsNote}
                onChange={extraNoteHandler}
                required={true}
                max={1500}
                maxLength={1500}
            />

            <Button id={'save-add-note-btn'} buttonName={'Save Note'}
                    onClick={updateBtnHandler}
            />

            {
                noteIsSaved &&
                <Modal
                    isOpen={noteIsSaved}
                    onClose={() => {
                        setNoteIsSaved(false)
                        props.closeModal && props.closeModal(false)
                    }}
                    modalTitle={'Note Saved successfully'}
                >
                    <p>Extra note saved successfully</p>
                </Modal>
            }
        </div>
    )
}