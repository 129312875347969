import React from 'react';

const RevenueSvg = () => {
	return (
		<svg width='23' height='24' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M9.56976 14.4649V15.8867M9.56976 7.41185V8.73517M9.56976 19.3499C14.497 19.3499 18.0165 17.6042 18.0165 13.7187C18.0165 9.49537 15.9048 6.6798 11.6814 4.56813L13.3426 2.42829C13.427 2.2879 13.4728 2.12768 13.4753 1.96389C13.4778 1.8001 13.437 1.63856 13.357 1.49563C13.2769 1.35271 13.1605 1.23348 13.0196 1.15004C12.8786 1.0666 12.7181 1.02192 12.5543 1.02051H6.58525C6.42145 1.02192 6.26093 1.0666 6.11996 1.15004C5.979 1.23348 5.8626 1.35271 5.78256 1.49563C5.70252 1.63856 5.66169 1.8001 5.66421 1.96389C5.66672 2.12768 5.7125 2.2879 5.79689 2.42829L7.45808 4.5822C3.23472 6.70796 1.12305 9.52353 1.12305 13.7469C1.12305 17.6042 4.64251 19.3499 9.56976 19.3499Z'
				stroke='black'
				strokeWidth='0.821208'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M8.16201 13.8171C8.33378 14.021 8.54998 14.1828 8.79401 14.2902C9.03804 14.3976 9.30342 14.4476 9.5698 14.4365C9.97694 14.4643 10.379 14.3331 10.6914 14.0706C11.0038 13.808 11.2023 13.4346 11.2451 13.0287C11.2023 12.6229 11.0038 12.2495 10.6914 11.9869C10.379 11.7244 9.97694 11.5932 9.5698 11.621C9.16266 11.6487 8.76061 11.5175 8.44819 11.255C8.13577 10.9925 7.93731 10.619 7.89453 10.2132C7.93387 9.80591 8.13138 9.43033 8.44462 9.1671C8.75786 8.90387 9.16185 8.774 9.5698 8.80539C9.83148 8.78938 10.0935 8.83158 10.3369 8.92895C10.5803 9.02632 10.7991 9.17644 10.9776 9.3685'
				stroke='black'
				strokeWidth='0.821208'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default RevenueSvg;
