import HospitalDao from "../types/HospitalDao";

export enum Authorities {
    RECEPTIONIST =  'RECEPTIONIST',
    NURSE =  'NURSE',
    CASHIER =  'CASHIER',
    DOCTOR =  'DOCTOR',
    ADMIN =  'ADMIN',
    HOD =  'HOD',
    SUPER_ADMIN =  'SUPER_ADMIN',
}

export default class WhoAmI {
    constructor(
        public userId: number,
        public hospital: HospitalDao,
        public username: string,
        public firstname: string,
        public lastname: string,
        public email: string,
        public lastLogin: string,
        public authorities: string[],
        public department: string,
        public sub: string,
        public exp: number,
        public iat: number
    ) {
    }
}