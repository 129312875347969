import React from 'react';
import { formatNumberWithCommas } from '../../../../util/numberFormatter';

interface TableProps {
	rowData: any[];
}

const ServicesTable = ({ rowData }: TableProps) => {
	return (
    <table>
      <thead>
        <tr>
          <th>Services</th>
          <th>No of Patients</th>
          <th>Amount(&#8358;)</th>
        </tr>
      </thead>
      <tbody>
        {rowData?.map((item,index) => (
          <tr key={`${item.id}${index}`} className="body_rows">
            <td>{item?.serviceName}</td>
            <td>{item?.quantity}</td>
            <td>{formatNumberWithCommas(item?.price)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ServicesTable;
