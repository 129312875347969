import { PaginationOptions, PaginationResult } from "../types/PaginationProps";

export function getPagination({
  totalPages,
  currentPage,
  maxDisplayLimit,
}: PaginationOptions): PaginationResult {
  if (totalPages <= maxDisplayLimit) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  const pages: PaginationResult = [];
  const halfLimit = Math.floor(maxDisplayLimit / 2);

  if (currentPage <= halfLimit + 1) {
    for (let i = 1; i <= maxDisplayLimit - 1; i++) {
      pages.push(i);
    }
    pages.push("...", totalPages);
  } else if (currentPage >= totalPages - halfLimit) {
    pages.push(1, "...");
    for (let i = totalPages - (maxDisplayLimit - 2); i <= totalPages; i++) {
      pages.push(i);
    }
  } else {
    pages.push(1, "...");
    for (
      let i = currentPage - halfLimit + 1;
      i <= currentPage + halfLimit - 1;
      i++
    ) {
      pages.push(i);
    }
    pages.push("...", totalPages);
  }

  return pages;
}
