import React from "react";
import CheckIcon from "../../resources/assets/svg/Dashboard/CheckIcon";
import { Button } from "../button/Button";
import "./SuccessPopUp.scss";

interface SuccessPopUpProps {
  successMessage: string;
  btnText?: string;
  btnId?: string;
  onBtnClick: () => void;
}
const SuccessPopUp: React.FC<SuccessPopUpProps> = ({
  successMessage,
  btnText,
  onBtnClick,
  btnId,
}) => {
  return (
    <div className="success_popup_container">
      <div className="success_popup_wrapper">
        <CheckIcon />
        <span>{successMessage}</span>
        <span className="success_popup_green_text">Successfully</span>
        <Button
          id={btnId!}
          buttonName={btnText || "Continue"}
          onClick={onBtnClick}
        />
      </div>
    </div>
  );
};

export default SuccessPopUp;
