import React from 'react';
import './AdminOnline.scss';
import GreenDot from '../../../resources/assets/svg/Dashboard/GreenDot';
import RedDot from '../../../resources/assets/svg/Dashboard/RedDot';
import { Link } from 'react-router-dom';

const adminDetails = [
	{
		id: '12343324',
		image: 'https://images.unsplash.com/photo-1503443207922-dff7d543fd0e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bWVufGVufDB8fDB8fHww',
		name: 'Chris K',
		loginTime: '8am',
		logOutTime: '6pm',
	},
	{
		id: '09745324',
		image: 'https://images.unsplash.com/photo-1534030347209-467a5b0ad3e6?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8bWVufGVufDB8fDB8fHww',
		name: 'Chris B',
		loginTime: '8am',
		logOutTime: '',
	},
	{
		id: '56373324',
		image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8bWVufGVufDB8fDB8fHww',
		name: 'John deo',
		loginTime: '6pm',
		logOutTime: '',
	},
	{
		id: '43442424',
		image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fG1lbnxlbnwwfHwwfHx8MA%3D%3D',
		name: 'Chris K',
		loginTime: '6pm',
		logOutTime: '8am',
	},
];
const AdminOnline = () => {
	return (
		<div className='admin_online_container'>
			<header>
				<h2>Admin Details</h2>
				<div className='log_Time'>
					<span>
						<GreenDot /> login
					</span>
					<span>
						<RedDot /> log out
					</span>
				</div>
			</header>
			<ul className='items_container'>
				{adminDetails?.map((item) => (
					<li key={item.id} className='item_container'>
						<div className='item_image_name'>
							<div>
								<img src={item.image} alt='' className='admin_img' />
							</div>
							<div className='admin_name_id_container'>
								<h2 className='admin_name'>{item.name}</h2>
								<p className='admin_id'>Admin ID: {item.id}</p>
							</div>
						</div>
						<div className='time_container'>
							<div className='time_Wrapper'></div>
							<div className=''>
								<p className='login_dot'>
									<GreenDot />
								</p>
								<p className='time'>{item.loginTime}</p>
							</div>
							-
							<div className=''>
								{item.logOutTime ? (
									<div>
										<div className='logout_dot'>
											<RedDot />
											<p className='time'>{item.logOutTime}</p>
										</div>
									</div>
								) : (
									<p className='time'>
										<span style={{ opacity: 0 }}>2am</span>
									</p>
								)}
							</div>
						</div>
					</li>
				))}
			</ul>
			<div className='link_container'>
				<Link to='/manage-account'>View all</Link>
			</div>
		</div>
	);
};

export default AdminOnline;
