import React from "react";

const ImageSvg = () => {
  return <svg width="40" height="43" viewBox="0 0 40 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M38.5914 9.36529V5.19329C38.5914 4.66286 38.3806 4.15415 38.0056 3.77908C37.6305 3.40401 37.1218 3.19329 36.5914 3.19329H28.0914M29.0914 40.1933H36.5914C37.1218 40.1933 37.6305 39.9826 38.0056 39.6075C38.3806 39.2324 38.5914 38.7237 38.5914 38.1933V32.1093M1.59136 34.1093V38.1933C1.59136 38.7237 1.80207 39.2324 2.17715 39.6075C2.55222 39.9826 3.06093 40.1933 3.59136 40.1933H13.2794M13.2794 3.19329H3.59136C3.06093 3.19329 2.55222 3.40401 2.17715 3.77908C1.80207 4.15415 1.59136 4.66286 1.59136 5.19329V10.3653M30.3424 16.8713L16.8224 8.95229M32.0974 22.2853L14.8644 12.3353M30.1134 25.7383L12.8814 15.7873M39.3694 13.0403L18.9234 1.23429C18.5217 1.0024 18.0443 0.939557 17.5963 1.05958C17.1483 1.1796 16.7663 1.47265 16.5344 1.87429L1.23436 28.3823C1.00242 28.7841 0.93955 29.2616 1.05956 29.7098C1.17958 30.158 1.47265 30.5401 1.87436 30.7723L22.3194 42.5783C22.5183 42.6933 22.7379 42.7679 22.9657 42.798C23.1935 42.828 23.425 42.8129 23.647 42.7535C23.8689 42.6941 24.077 42.5915 24.2593 42.4516C24.4416 42.3117 24.5945 42.1373 24.7094 41.9383L40.0104 15.4303C40.2423 15.0285 40.3052 14.551 40.1852 14.1028C40.0651 13.6546 39.7711 13.2724 39.3694 13.0403Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

};

export default ImageSvg;
