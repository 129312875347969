import React from 'react';

const EmergencyIcon = () => {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_3352_23311)'>
				<path
					d='M10.4369 12.2664V12.2626C10.6638 12.1741 10.8134 11.9788 10.8134 11.6098C10.8134 11.0844 10.4718 10.8463 9.94938 10.8463C9.69963 10.8463 9.4735 10.8538 9.23088 10.8808V13.4255H9.72662V12.4198H9.98388C10.0491 12.4198 10.0645 12.4543 10.0836 12.527L10.3187 13.4251H10.8366L10.5254 12.3849C10.51 12.3204 10.4909 12.2818 10.4369 12.2664ZM10.003 12.0241H9.72662V11.249C9.80762 11.2415 9.88788 11.2374 9.96813 11.2374C10.2644 11.2374 10.3255 11.414 10.3255 11.6401C10.3255 11.909 10.2299 12.0241 10.003 12.0241ZM13.8479 12.305H14.521V11.894H13.8479V11.2801H14.5941V10.8654H13.3532V13.4255H14.6245V13.022H13.8479V12.305ZM3.93288 12.305H4.60525V11.894H3.93288V11.2801H4.678V10.8654H3.4375V13.4255H4.70912V13.022H3.93325L3.93288 12.305ZM8.04738 12.305H8.7205V11.894H8.04738V11.2801H8.79325V10.8654H7.55238V13.4255H8.82363V13.022H8.04738V12.305ZM6.08425 11.8363L5.581 10.8654H5.11638V13.4255H5.6005V11.7714L6.08463 12.7423L6.56462 11.7676H6.58V13.4255H7.04463V10.8654H6.58L6.08425 11.8363ZM12.1278 12.4156H12.4765V12.5075C12.4765 12.773 12.4382 13.0723 12.1082 13.0723C11.6781 13.0723 11.6669 12.4621 11.6669 12.1475C11.6669 11.8359 11.698 11.2453 12.124 11.2453C12.3235 11.2453 12.4195 11.3563 12.5305 11.4943L12.8035 11.1294C12.607 10.9344 12.4037 10.8223 12.1311 10.8223C11.4055 10.8223 11.1475 11.5134 11.1475 12.1501C11.1475 12.7801 11.3822 13.4671 12.0929 13.4671C12.7874 13.4671 12.9261 12.8641 12.9261 12.3076V12.0088H12.1274L12.1278 12.4156ZM19.6652 13.4255H20.1572V12.4468L20.8064 10.865H20.3027L19.915 12.0316L19.5269 10.865H18.9966L19.6656 12.4734L19.6652 13.4255ZM15.0321 13.4255H15.5162V11.8445H15.5279L16.3499 13.4255H16.7838V10.8654H16.3037V12.3811H16.2966L15.5125 10.865H15.0325L15.0321 13.4255Z'
					fill='black'
				/>
				<path d='M20.8707 0.30957H3.11253C1.58778 0.30957 0.160156 1.7402 0.160156 3.26457V21.0178C0.160156 22.5429 1.59341 23.9807 3.11891 23.9807H20.8714C22.3962 23.9807 23.832 22.5504 23.832 21.0257V3.26682C23.832 1.74245 22.3962 0.309945 20.8707 0.309945V0.30957ZM21.4748 15.3036H15.156V21.6159H8.83766V15.3036H2.51703V8.9867H8.83766V2.67432H15.156V8.9867H21.4748V15.3036Z' fill='black' />
				<path d='M18.1733 13.4675C18.4879 13.4679 18.7339 13.3216 18.9034 13.1143L18.6499 12.7531C18.5231 12.9219 18.4088 13.0486 18.2003 13.0486C17.8009 13.0486 17.7311 12.4925 17.7311 12.1471C17.7311 11.6863 17.8388 11.2449 18.189 11.2449C18.3776 11.2449 18.4815 11.3713 18.5921 11.5408L18.8884 11.1909C18.6915 10.9573 18.4958 10.8223 18.1965 10.8223C17.4859 10.8223 17.2129 11.4901 17.2129 12.1471C17.2129 12.842 17.4664 13.4675 18.1733 13.4675Z' fill='black' />
			</g>
			<defs>
				<clipPath id='clip0_3352_23311'>
					<rect width='24' height='24' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};

export default EmergencyIcon;
