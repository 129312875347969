
import './ErrorPage.scss'

/**
 * Will be used to handle and display 404 and other technical errors
 * */
export const ErrorPage = (props: {message?: string}) => {
    return (
        <div className={'error-page-component'}>
            <h2>Error Page</h2>
            <p>{props?.message ?? 'Something went wrong please try again later or contact support !!!'}</p>
        </div>
    )
}