import React from "react";

const MailBox = () => {
  return (
    <svg
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="noto:open-mailbox-with-raised-flag">
        <path
          id="Vector"
          d="M37.4883 49.9761H48.3598V73.6251H37.4883V49.9761Z"
          fill="#C87D5E"
        />
        <path
          id="Vector_2"
          d="M48.3598 55.9491C47.3861 54.3697 46.0977 53.0219 44.4708 52.1432C43.4614 51.5969 42.3452 51.2822 41.2111 51.1694H39.317C38.6995 51.2288 38.082 51.3476 37.4883 51.5257V63.4779C38.0167 62.6704 38.8955 62.0885 39.8455 61.8629C41.502 61.4769 43.2595 62.1122 44.6014 63.1513C45.9433 64.1904 46.9467 65.6035 47.9264 66.9869L48.3658 67.6044V55.9491H48.3598Z"
          fill="#874D36"
        />
        <path
          id="Vector_3"
          d="M17.1838 17.2012C24.2256 17.2012 29.9553 21.963 29.9553 27.8234V56.0859H4.40625V27.8234C4.40625 21.963 10.1359 17.2012 17.1838 17.2012Z"
          fill="#4E342E"
        />
        <path
          id="Vector_4"
          d="M32.6378 55.9253H11.3578C10.0932 55.9253 9.0244 54.9931 8.85221 53.7403L5.85971 31.9912C5.82622 31.7463 5.84557 31.497 5.91648 31.2602C5.98738 31.0234 6.10819 30.8045 6.27077 30.6183C6.43335 30.4321 6.63393 30.2829 6.85901 30.1807C7.08409 30.0785 7.32846 30.0257 7.57565 30.0259H32.6378V55.9253Z"
          fill="white"
        />
        <path
          id="Vector_5"
          d="M57.9208 14.897H17.1836C17.1836 14.897 31.1842 16.6129 31.1842 28.4226L31.2555 58.3951H70.7517C72.337 58.3951 73.6195 57.1126 73.6195 55.5273V30.6017C73.6255 21.927 66.5955 14.897 57.9208 14.897Z"
          fill="#1976D2"
        />
        <path
          id="Vector_6"
          d="M49.5605 20.1933L44.7273 20.3596L37.6914 31.1539V22.3961C37.6914 17.7174 32.9414 14.8911 32.9414 14.8911H51.4427L51.5377 14.9861C53.4792 16.8327 52.2383 20.0983 49.5605 20.1933Z"
          fill="#0D47A1"
        />
        <path
          id="Vector_7"
          d="M44.0636 37.5367C46.7329 37.5367 48.8967 35.1017 48.8967 32.0979C48.8967 29.0942 46.7329 26.6592 44.0636 26.6592C41.3943 26.6592 39.2305 29.0942 39.2305 32.0979C39.2305 35.1017 41.3943 37.5367 44.0636 37.5367Z"
          fill="#0D47A1"
        />
        <path
          id="Vector_8"
          d="M39.7148 6.00281L39.7327 30.6791C39.7327 31.5103 40.4036 32.1812 41.2348 32.1812H44.2689C45.1002 32.1812 45.7711 31.5103 45.7711 30.6791V2.375H43.3367C42.8606 2.375 42.3891 2.46888 41.9493 2.65127C41.5095 2.83365 41.11 3.10097 40.7736 3.43792C40.4372 3.77487 40.1705 4.17485 39.9888 4.61496C39.8072 5.05507 39.7141 5.52668 39.7148 6.00281Z"
          fill="#C62828"
        />
        <path
          id="Vector_9"
          d="M41.0391 6.00281L41.0569 30.6791H45.9078V11.5722H54.2678C56.1975 11.5722 56.4231 10.735 56.4231 7.08937C56.4231 3.44375 56.2034 2.375 54.2738 2.375H44.6609C44.1848 2.375 43.7133 2.46888 43.2735 2.65127C42.8337 2.83365 42.4342 3.10097 42.0978 3.43792C41.7614 3.77487 41.4947 4.17485 41.3131 4.61496C41.1314 5.05507 41.0383 5.52668 41.0391 6.00281Z"
          fill="#F44336"
        />
        <path
          id="Vector_10"
          d="M44.1698 6.46024C44.1283 7.6418 43.3564 18.3412 43.2198 20.3481C43.0536 22.7765 41.6523 23.0259 41.6523 20.8171V6.35337C41.6583 4.01399 44.2589 3.8893 44.1698 6.46024Z"
          fill="#FF7555"
        />
        <path
          id="Vector_11"
          d="M43.5813 25.1988L43.6228 36.0704H41.1647C38.76 36.0704 36.8125 33.636 36.8125 30.6316C36.8125 27.6272 38.76 25.1929 41.1647 25.1929C43.5694 25.1929 43.5813 25.1988 43.5813 25.1988Z"
          fill="#82AEC0"
        />
        <path
          id="Vector_12"
          d="M43.5827 36.0704C45.9863 36.0704 47.9348 33.6354 47.9348 30.6316C47.9348 27.6279 45.9863 25.1929 43.5827 25.1929C41.179 25.1929 39.2305 27.6279 39.2305 30.6316C39.2305 33.6354 41.179 36.0704 43.5827 36.0704Z"
          fill="#E0E0E0"
        />
        <path
          id="Vector_13"
          d="M40.9826 30.7917C41.2439 30.9223 41.5586 30.7798 41.7842 30.5898C42.4729 30.0139 42.7342 29.0639 43.3695 28.4345C43.9276 27.8764 44.7767 27.3301 44.1117 26.5048C43.6308 25.9111 42.8173 26.012 42.182 26.2851C40.7748 26.873 39.6586 30.1267 40.9826 30.7917Z"
          fill="white"
        />
        <path
          id="Vector_14"
          d="M17.1831 17.3551C24.1716 17.3551 29.8597 22.0813 29.8597 27.8942V55.931H4.50656V27.8882C4.50656 22.0813 10.1947 17.3551 17.1831 17.3551ZM17.1831 14.897C8.19969 14.897 2.375 20.9473 2.375 28.4167V57.1245C2.375 57.8192 2.93906 58.3892 3.63969 58.3892H32.1931C32.8878 58.3892 33.4578 57.8251 33.4578 57.1245V28.4167C33.4459 20.9473 26.1666 14.897 17.1831 14.897Z"
          fill="#4FC3F7"
        />
        <path
          id="Vector_15"
          d="M10.4959 54.328C10.1456 54.1439 10.4365 53.7936 10.6325 53.473L18.5709 39.6445L20.1325 40.5945L11.2975 53.8827C11.0718 54.227 10.8462 54.512 10.4959 54.328Z"
          fill="#6FBFF0"
          stroke="#6FBFF0"
          strokeWidth="0.59375"
          strokeMiterlimit="10"
        />
        <path
          id="Vector_16"
          d="M23.6842 44.4896L7.21362 31.8427C7.01174 31.7062 6.83956 31.4568 7.04737 31.2074C7.21362 31.0055 7.36799 31.1124 7.65893 31.2846L23.6189 42.2809C24.0227 42.5302 24.5452 42.4234 24.8242 42.0434L29.5861 35.5952L29.5742 38.4868L25.5605 44.1512C25.3521 44.4391 25.0403 44.6352 24.6905 44.6983C24.3407 44.7614 23.9801 44.6866 23.6842 44.4896Z"
          fill="#6FBFF0"
          stroke="#6FBFF0"
          strokeWidth="0.59375"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  );
};

export default MailBox;
