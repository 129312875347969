import  { useCallback, useEffect, useState } from "react";
import logo from "../../../resources/assets/images/Rectangle.png";
import "./HospitalInfo.scss";
import { Button } from "../../../components/button/Button";
import { Modal } from "../../../components/modal/Modal";
import EditHospitalInfo from "../edithospitalinfo/EditHospitalInfo";
import { useWhoAmI } from "../../../context/UserCtx";
import HospitalDao from "../../../types/HospitalDao";
import adminService from "../../../services/AdminService";
import { ErrorNotification } from "../../../modules/errornotification/ErrorNotification";
import { Spinner } from "../../../components/spinner/Spinner";
import { capitalizeFirstCharacter, formatZoneDate } from "../../../util/Helpers";
import useHospitalLogo from "../../../customhook/useHospitalLogo";

export const HospitalInfo = () => {
  const { imgSrc, error } = useHospitalLogo()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [hospitalInfo, setHospitalInfo] = useState<HospitalDao>(
    {} as HospitalDao
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const user = useWhoAmI();
const errorStr = errorMessage||error
  const getHospitalInfo = useCallback(() => {
    adminService
      .getAdminInfo(user.hospital.id)
      .then((response: HospitalDao) => {
        setHospitalInfo(response);
        setLoading(false);
      })
      .catch(() => {
        setErrorMessage(
          "Technical error occurred while getting hospital Information, please try again later!"
        );
      });
  }, [user.hospital.id])

  useEffect(() => {
    getHospitalInfo()
  }, [getHospitalInfo]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (loading) return <Spinner />;

  return (
    <>
      {errorStr && (
        <ErrorNotification
          name={"get-logo-error"}
          stringErrors={errorStr}
        />
      )}
      <div className="">
        <div className="logo-image">
          <img src={imgSrc || logo} alt="Hospital logo" />
        </div>
        <div>
          <div className="hospital-info-w">
            <div className="hospital-info-w_left">
              <UserDetail label={"Hospital id"} value={hospitalInfo?.id} />
              <UserDetail label={"Hospital name"} value={hospitalInfo?.name} />
              <UserDetail label={"Phone number"} value={hospitalInfo?.phone} />
              <UserDetail label={"Email address"} value={hospitalInfo?.email} />
              <UserDetail label={"Street"} value={hospitalInfo?.address?.streetHouseNr} />
              <UserDetail label={"State"} value={hospitalInfo?.address?.state} />
              <UserDetail label={"Postcode"} value={hospitalInfo?.address?.postcode} />
              <UserDetail label={"Country"} value={hospitalInfo?.address?.country} />
              <UserDetail label={"Superuser"} value={capitalizeFirstCharacter(`${hospitalInfo?.superUser}`)} />
            </div>

            <div className={'hospital-info-w_right'}>
              <UserDetail label={"Created by"} value={hospitalInfo?.createdBy} />
              <UserDetail label={"Tax number"} value={hospitalInfo?.taxNumber} />
              <UserDetail label={"CAC number"} value={hospitalInfo?.cacNumber} />
              <UserDetail label={"Date created"} value={formatZoneDate(`${hospitalInfo?.dateCreated as Date || ''}`)} />
              <UserDetail label={"Account status"} value={hospitalInfo?.accountIsEnabled ? "Enabled" : "Disabled"} />
              <UserDetail label={"Hospital category"} value={hospitalInfo?.hospitalCategory} />
              <UserDetail label={"Plan option"} value={hospitalInfo?.planOptions} />
              <UserDetail label={"Team size"} value={hospitalInfo?.teamMembersCount || 1} />
            </div>
          </div>
          <div className="hospital-info-btn-w">
            <Button
              id="edit"
              buttonName="Edit"
              onClick={() => setIsModalOpen(true)}
            />
          </div>
        </div>
      </div>

      <div className="company-info-modal">
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          modalTitle="Edit Hospital Info"
        >
          <EditHospitalInfo hospitalInfo={hospitalInfo} logo={imgSrc} callBack={getHospitalInfo} closeModal={closeModal} />
        </Modal>
      </div>
    </>
  );
};

export const UserDetail: React.FC<{ label: string; value: string | number | boolean | Date }> = ({ label, value }) => {
  return <p className="hospital_info_para">
    <span className="hospital_info_label">{label}:</span>{/*&nbsp;*/}
    <span className="hospital_info_value">{`${value || 'No Entry'}`}</span>
  </p>
}