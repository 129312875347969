import { useForm } from "react-hook-form";
import { InputField } from "../../../components/inputfield/InputField";
import "./EditHospitalInfo.scss";
import { Button } from "../../../components/button/Button";
import  { useRef, useState } from "react";

import { ErrorNotification } from "../../../modules/errornotification/ErrorNotification";
import { InputFile } from "../../../components/inputfile/InputFile";

import HospitalDao from "../../../types/HospitalDao";
import adminService from "../../../services/AdminService";
import { Dropdown } from "../../../components/dropdown/Dropdown";
import { HospitalCategory } from "../../../types/HospitalCategory";
import { Modal } from "../../../components/modal/Modal";
import uploadService from "../../../services/imageservices/ImageService";

interface HospitalInfoProps {
  hospitalInfo: HospitalDao;
  logo?: Blob | string
  callBack?:()=>void
  closeModal?:()=>void
}

const EditHospitalInfo = ({ hospitalInfo, logo,callBack, closeModal }: HospitalInfoProps) => {
  const [hospitalInfoData, setHospitalInfoData] =
    useState<HospitalDao>(hospitalInfo);
  const [editHospitalInfoErr, setEditHospitalInfoErr] = useState<string>("");
  const [editSuccessMsg, setEditSuccessMsg] = useState<string>("");
  const [newHospitalInfo, setNewHospitalInfo] = useState<boolean>(false);
  const [file, setFile] = useState<Blob | string>();
  const [imgSrc, setImgSrc] = useState<Blob | string>(logo || '');

  const bgRef = useRef<HTMLDivElement>(null);

  const {
    register,
    trigger,
    formState: { isValid, errors },
  } = useForm({ mode: "onBlur" });

  const editHospitalInfoLogo = (evt: any) => {
    const imageUploaded = evt.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFile(imageUploaded);
      setImgSrc(reader.result as string);
      if (bgRef.current) {
        bgRef.current.style.backgroundImage = `url(${imgSrc})`;
      }
    };
    reader.readAsDataURL(imageUploaded);
  };

  const handleFileUpload = async () => {
    if (!file) {
      setEditHospitalInfoErr("Logo is not updated");
      return;
    }

    uploadService.uploadHospitalLogo(file, hospitalInfoData.id)
      .then(() => {
        // get the edited logo image
        uploadService.getHospitalLogoByte(hospitalInfoData.id)
          .then((imgUrl: string) => {
            setImgSrc(imgUrl);
          })
          .catch(() => {
            setEditHospitalInfoErr(
              "Error occurred while getting the hospital logo edit from path"
            );
          });
      })
      .catch(() => {
        setEditHospitalInfoErr(
          "Error occurred while uploading the hospital logo edit"
        );
      });
  };

  const hospitalInfoAddressHandler = (evt: any) => {
    const { name, value } = evt.target;
    setHospitalInfoData((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        [name]: value,
      },
    }));
  };

  const editHospitalInfoHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setHospitalInfoData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    trigger().then();

    handleFileUpload().then();

    if (!isValid || !hospitalInfoData) {
      setEditHospitalInfoErr(
        "Please check again to ensure you have entered all necessary input and correctly"
      );
      return;
    }

    adminService.updateHospitalInfo(hospitalInfoData)
      .then((response: HospitalDao) => {
        setHospitalInfoData(response);
        setNewHospitalInfo(true);
        setEditSuccessMsg("Hospital information updated successfully");
        callBack && callBack()
      })
      .catch(() => {
        setEditHospitalInfoErr(
          "Technical error occurred while updating hospital info"
        );
      });
  };

  const closeUpdatedModal = () => {
    setNewHospitalInfo(false);
    closeModal && closeModal()
    // window.location.href = ParamsEnum.MANAGE_ACCOUNT;
  };

  return (
    <div>
      {editHospitalInfoErr && (
        <ErrorNotification
          name="Company Error"
          stringErrors={editHospitalInfoErr}
        />
      )}

      <section className="edit-hospital-form">
        <div className="form-logo">
          <div className="edit-logo-wrapper">
            <div
              style={{
                backgroundImage: `url(${imgSrc || logo})`,
              }}
              className="edit-logo"
              ref={bgRef}
            ></div>
            <div className="logo-file">
              <InputFile
                id="logo"
                name="logo"
                onChange={editHospitalInfoLogo}
                filename=""
                register={register}
              />
            </div>
          </div>
        </div>
        <div className="edit-form">
          <div>
            <div className="input-field">
              <InputField
                id="name"
                name="name"
                label="Hospital Name"
                defaultValue={hospitalInfoData.name}
                onChange={editHospitalInfoHandler}
                register={register}
                errors={errors}
                pattern={{
                  value: /^[A-Za-z0-9\s\.,#-]{4,}$/,
                  message:
                    "Minimum of 4 alphanumeric values and allowed special characters !#@",
                }}
                min={6}
                minLength={6}
                required={"Hospital Name is required"}
              />
            </div>
            <div className="input-field">
              <Dropdown
                id="hospitalCategory"
                name="hospitalCategory"
                label="Hospital Category"
                placeholder={
                  hospitalInfoData?.hospitalCategory ?? "Hospital Category"
                }
                getSelectedOptionValue={(value) => {
                  setHospitalInfoData({
                    ...hospitalInfoData,
                    hospitalCategory: value,
                  });
                }}
                defaultValue={hospitalInfoData?.hospitalCategory}
                onChange={editHospitalInfoHandler}
                errors={errors}
                options={[
                  {
                    value: HospitalCategory.PUBLIC_FEDERAL_HOSPITAL,
                    label: "Federal hospital",
                  },
                  { value: HospitalCategory.PHARMACY, label: "Pharmacy" },
                  {
                    value: HospitalCategory.PRIVATE_HOSPITAL,
                    label: "Private hospital",
                  },
                  {
                    value: HospitalCategory.PUBLIC_STATE_HOSPITAL,
                    label: "State hospital",
                  },
                  { value: HospitalCategory.LABORATORY, label: "Laboratory" },
                  {
                    value: HospitalCategory.PUBLIC_LOCAL_GOVERNMENT_HOSPITAL,
                    label: "Local government hospital",
                  },
                  {
                    value: HospitalCategory.CHURCH_HOSPITAL,
                    label: "Church hospital",
                  },
                ]}
              />
            </div>

            <div className="input-field">
              <InputField
                id="cacNumber"
                name="cacNumber"
                label="Corporate Affairs Commission Number"
                defaultValue={hospitalInfoData?.cacNumber}
                onChange={editHospitalInfoHandler}
                register={register}
                errors={errors}
                pattern={{
                  value: /^[\d]{4,20}$/,
                  message:
                    "Minimum of 4 and maximum of 20 numeric values are allowed",
                }}
                min={4}
                minLength={4}
              />
            </div>
            <div className="input-field">
              <InputField
                id="phone"
                name="phone"
                label="Phone Number"
                defaultValue={hospitalInfoData?.phone}
                onChange={editHospitalInfoHandler}
                register={register}
                errors={errors}
                pattern={{
                  value: /^[\d]{4,20}$/,
                  message:
                    "Minimum of 4 and maximum of 20 numeric values are allowed",
                }}
                min={4}
                minLength={4}
                required={"Phone Number is required"}
              />
            </div>
          </div>
          <div>
            <div className="input-field">
              <InputField
                id="streetHouseNr"
                name="streetHouseNr"
                label="Street Number"
                defaultValue={hospitalInfoData?.address?.streetHouseNr}
                onChange={hospitalInfoAddressHandler}
                register={register}
                errors={errors}
                pattern={{
                  value: /^[A-Za-z0-9\s\.,#-]{4,}$/,
                  message:
                    "Minimum of 4 alphanumeric values and allowed special characters (.,#-)",
                }}
                min={3}
                minLength={3}
                required={"Street Number is required"}
              />
            </div>
            <div className="input-field">
              <Dropdown
                id="country"
                name="country"
                label="Country"
                placeholder={hospitalInfoData?.address?.country ?? "Country"}
                defaultValue={hospitalInfoData?.address?.country}
                onChange={hospitalInfoAddressHandler}
                errors={errors}
                getSelectedOptionValue={(value) => {
                  setHospitalInfoData({
                    ...hospitalInfoData,
                    address: {
                      ...hospitalInfoData.address,
                      country: value,
                    },
                  });
                }}
                options={[{ value: "Nigeria", label: "Nigeria" }]}
              />
            </div>

            <div className="input-field">
              <InputField
                id="state"
                name="state"
                label="State"
                defaultValue={hospitalInfoData?.address?.state}
                onChange={hospitalInfoAddressHandler}
                register={register}
                errors={errors}
                pattern={{
                  value: /^[a-zA-Z]+(?:[\s'-][a-zA-Z]+)*$/,
                  message: "in of 6 letters",
                }}
                min={4}
                minLength={4}
                required={"State is required"}
              />
            </div>
            <div className="input-field">
              <InputField
                id="email"
                name="email"
                label="Email Address"
                defaultValue={hospitalInfoData?.email}
                onChange={editHospitalInfoHandler}
                register={register}
                errors={errors}
                pattern={{
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Valid email is required",
                }}
                min={5}
                minLength={5}
                required={"Official Email Address is required"}
              />
            </div>
          </div>
          <div>
            <div className="input-field">
              <Dropdown
                id="planOptions"
                name="planOptions"
                label="Plan Options"
                placeholder={hospitalInfoData?.planOptions ?? "Plan Options"}
                defaultValue={hospitalInfoData?.planOptions}
                onChange={editHospitalInfoHandler}
                errors={errors}
                getSelectedOptionValue={(value) => {
                  setHospitalInfoData({
                    ...hospitalInfoData,
                    planOptions: value as string,
                  });
                }}
                options={[
                  { label: "MONTHLY", value: "MONTHLY" },
                  { label: "ENTERPRISE", value: "ENTERPRISE" },
                  {
                    label: "DEDICATED_ENTERPRISE",
                    value: "DEDICATED_ENTERPRISE",
                  },
                ]}
              />
            </div>

            <div className="input-field">
              <Dropdown
                id="teamMembersCount"
                name="teamMembersCount"
                label="Team Members Count"
                defaultValue={hospitalInfoData?.teamMembersCount}
                onChange={editHospitalInfoHandler}
                getSelectedOptionValue={(value) => {
                  setHospitalInfoData({
                    ...hospitalInfoData,
                    teamMembersCount: value,
                  });
                }}
                errors={errors}
                placeholder={
                  hospitalInfoData?.teamMembersCount ?? "Team Members Count"
                }
                options={[
                  { label: "1 - 10", value: "1 - 10" },
                  { label: "10 - 20", value: "10 - 20" },
                  { label: "20 - 50", value: "20 - 50" },
                  { label: "50 - 100", value: "50 - 100" },
                ]}
              />
            </div>
            <div className="input-field">
              <InputField
                id="taxNumber"
                name="taxNumber"
                label="Tax Number"
                defaultValue={hospitalInfoData?.taxNumber}
                onChange={editHospitalInfoHandler}
                register={register}
                errors={errors}
                pattern={{
                  value: /^[A-Za-z0-9]{6,20}$/,
                  message:
                    "Tax number contains a minimum of 6 and maximum of 20 numeric characters",
                }}
                min={6}
                minLength={6}
              />
            </div>

            <div className="input-field">
              <InputField
                id="postcode"
                name="postcode"
                label="Postcode"
                defaultValue={hospitalInfoData?.address?.postcode}
                onChange={hospitalInfoAddressHandler}
                register={register}
                errors={errors}
                pattern={{
                  value: /^[0-9]{6}$/,
                  message:
                    "Post code can only contain numbers and must be only 6 characters long, e.g - (123456)",
                }}
                min={3}
                minLength={3}
              />
            </div>

            <Button id="save" buttonName="Save" onClick={handleSubmit} />
          </div>
        </div>
      </section>

      {newHospitalInfo && (
        <div className="updated-successfully-wrapper">
          <Modal
            isOpen={newHospitalInfo}
            onClose={() => closeUpdatedModal()}
            modalTitle="Updated Successfully"
          >
            <div className="msg-content">
              <p> {editSuccessMsg}</p>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default EditHospitalInfo;
