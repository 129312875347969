import { useCallback, useEffect, useState } from "react";
import "./UserInfo.scss";
import { UserDto } from "../../../types/UserDto";
import { useWhoAmI } from "../../../context/UserCtx";
import userService from "../../../services/UserService";
import { ErrorNotification } from "../../../modules/errornotification/ErrorNotification";
import { Spinner } from "../../../components/spinner/Spinner";
import { Button } from "../../../components/button/Button";
import { Modal } from "../../../components/modal/Modal";
import { UserData } from "../../../types/Helper";
import ProfilePic from '../../../resources/assets/images/userProfile.png'
import {capitalizeFirstCharacter} from "../../../util/Helpers";
import {Authorities} from "../../../userAuth/WhoAmI";
import {AddUser} from "../../adduser/AddUser";

const UserInfo = () => {
  const [savedUser, setSavedUser] = useState<UserDto>({} as UserDto)
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const whoAmI = useWhoAmI();

  const getUserInfo = useCallback(()=> {
    userService
      .getUserInfo(whoAmI.hospital.id, whoAmI.userId)
      .then((response: UserDto) => {
        setSavedUser(response);
        setIsLoading(false);
      })
      .catch(() => {
        setErrorMessage("Technical error occurred while getting your Information, please try again later!");
      });

  },[whoAmI])
  
  useEffect(() => {
    getUserInfo()
  }, [getUserInfo]);

  if (isLoading) return <Spinner />;
  return (
    <>
      {errorMessage && (
        <ErrorNotification
          name={"get-logo-error"}
          stringErrors={errorMessage}
        />
      )}
      <div className="user-info">

        <div className={'user-profile-headline'}>
          <p>{`Hi, ${capitalizeFirstCharacter(savedUser.firstname)} ${capitalizeFirstCharacter(savedUser.lastname)}`}</p>
        </div>

        <div className="profile-pic">
          <img src={ProfilePic} alt="Profile pic"/>
        </div>

        <section className={'user-info-w'}>
          <div className="user-info-item_left">
            <p>User ID: </p>
            <p>Firstname: </p>
            <p>Lastname: </p>
            <p>Username: </p>
            <p>Email: </p>
            <p>Phone number: </p>
            <p>Address: </p>
            <p>Role: </p>{" "}
            <p>Date of birth</p>{" "}
            <p>Active</p>{" "}
          </div>

          <div className="user-info-item_right">
            <p>{savedUser.id}</p>
            <p>{savedUser.firstname}</p>
            <p>{savedUser.lastname}</p>
            <p>{savedUser.username}</p>
            <p>{savedUser.email}</p>
            <p>{savedUser.phoneNumber}</p>
            <p>{savedUser.address ? savedUser.address : 'No address registered'}</p>
            <p>{savedUser.authorities.map((item) => item + " ")}</p>
            <p>{savedUser.dob ? savedUser.dob : "Null"}</p>
            <p>{savedUser.active ? "True" : "False"}</p>
          </div>
        </section>

        <div className="user-edit-btn">
          <Button
              id="edit"
              buttonName={whoAmI.authorities.includes(Authorities.SUPER_ADMIN) ? 'Edit' : "Change Password"}
              onClick={() => setIsModalOpen(true)}
          />
        </div>
      </div>

      <div className="user-edit-modal">
        <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            modalTitle="Edit Profile"
        >
          <AddUser userData={savedUser as unknown as UserData} isEdit={true} callBack={getUserInfo}/>
        </Modal>
      </div>
    </>
  );
};

export default UserInfo;
