import React from 'react';

const SalesIcon = () => {
	return (
		<svg width='17' height='18' viewBox='0 0 17 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path d='M2.90514 14.6648C3.48863 14.6648 3.96164 14.1918 3.96164 13.6083C3.96164 13.0248 3.48863 12.5518 2.90514 12.5518C2.32165 12.5518 1.84863 13.0248 1.84863 13.6083C1.84863 14.1918 2.32165 14.6648 2.90514 14.6648Z' fill='#3F51B5' />
			<path d='M5.72252 15.3694C6.30601 15.3694 6.77902 14.8963 6.77902 14.3129C6.77902 13.7294 6.30601 13.2563 5.72252 13.2563C5.13903 13.2563 4.66602 13.7294 4.66602 14.3129C4.66602 14.8963 5.13903 15.3694 5.72252 15.3694Z' fill='#3F51B5' />
			<path d='M8.5399 12.904C9.12339 12.904 9.59641 12.431 9.59641 11.8475C9.59641 11.264 9.12339 10.791 8.5399 10.791C7.95641 10.791 7.4834 11.264 7.4834 11.8475C7.4834 12.431 7.95641 12.904 8.5399 12.904Z' fill='#3F51B5' />
			<path d='M11.3573 13.6086C11.9408 13.6086 12.4138 13.1356 12.4138 12.5521C12.4138 11.9686 11.9408 11.4956 11.3573 11.4956C10.7738 11.4956 10.3008 11.9686 10.3008 12.5521C10.3008 13.1356 10.7738 13.6086 11.3573 13.6086Z' fill='#3F51B5' />
			<path d='M14.1747 12.1999C14.7582 12.1999 15.2312 11.7269 15.2312 11.1434C15.2312 10.5599 14.7582 10.0869 14.1747 10.0869C13.5912 10.0869 13.1182 10.5599 13.1182 11.1434C13.1182 11.7269 13.5912 12.1999 14.1747 12.1999Z' fill='#3F51B5' />
			<path d='M13.858 10.5093L11.2872 11.8123L8.36418 11.0727L5.54684 13.5379L3.08166 12.9392L2.72949 14.2775L5.899 15.0875L8.71635 12.6223L11.428 13.2914L14.4919 11.7771L13.858 10.5093Z' fill='#3F51B5' />
			<path d='M2.90514 8.3259C3.48863 8.3259 3.96164 7.85289 3.96164 7.26939C3.96164 6.6859 3.48863 6.21289 2.90514 6.21289C2.32165 6.21289 1.84863 6.6859 1.84863 7.26939C1.84863 7.85289 2.32165 8.3259 2.90514 8.3259Z' fill='#00BCD4' />
			<path d='M5.72252 9.03049C6.30601 9.03049 6.77902 8.55748 6.77902 7.97398C6.77902 7.39049 6.30601 6.91748 5.72252 6.91748C5.13903 6.91748 4.66602 7.39049 4.66602 7.97398C4.66602 8.55748 5.13903 9.03049 5.72252 9.03049Z' fill='#00BCD4' />
			<path d='M8.5399 6.56516C9.12339 6.56516 9.59641 6.09214 9.59641 5.50865C9.59641 4.92516 9.12339 4.45215 8.5399 4.45215C7.95641 4.45215 7.4834 4.92516 7.4834 5.50865C7.4834 6.09214 7.95641 6.56516 8.5399 6.56516Z' fill='#00BCD4' />
			<path d='M11.3573 8.3259C11.9408 8.3259 12.4138 7.85289 12.4138 7.26939C12.4138 6.6859 11.9408 6.21289 11.3573 6.21289C10.7738 6.21289 10.3008 6.6859 10.3008 7.26939C10.3008 7.85289 10.7738 8.3259 11.3573 8.3259Z' fill='#00BCD4' />
			<path d='M14.1747 4.09982C14.7582 4.09982 15.2312 3.62681 15.2312 3.04332C15.2312 2.45983 14.7582 1.98682 14.1747 1.98682C13.5912 1.98682 13.1182 2.45983 13.1182 3.04332C13.1182 3.62681 13.5912 4.09982 14.1747 4.09982Z' fill='#00BCD4' />
			<path d='M13.5763 2.65625C12.8368 3.78319 11.7098 5.47359 11.1464 6.3188C10.7238 6.07228 10.0546 5.61446 8.89249 4.91013L8.43468 4.62839L5.51168 7.19922L3.0465 6.60053L2.69434 7.97399L5.86385 8.78397L8.57554 6.42445C9.49118 6.98792 10.6181 7.69225 10.8646 7.86834L11.0407 8.04442L11.3577 8.0092C11.7451 7.97399 11.7451 7.97399 14.7033 3.46624L13.5763 2.65625Z' fill='#00BCD4' />
		</svg>
	);
};

export default SalesIcon;
