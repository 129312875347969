import { PaginationProps } from "../../../types/PaginationProps";
import { getPagination } from "../../../util/Pagination";
import { Button } from "../../button/Button";
import "./PaginationComponent.scss";
import React from "react";

const btnTexts: string[] = ["Previous", "Next"];
const Pagination: React.FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  currentPage,
  totalPages,
  hide,
  goToPage,
}) => {
  const length = totalPages || Math.ceil(totalItems as number / itemsPerPage);
  const numsArr = getPagination({
    totalPages: length,
    currentPage,
    maxDisplayLimit: 5,
  }).filter((item) => !!item);

  const handleClickBtn = (btnText: string) => {
    return goToPage(btnText === "Previous" ? currentPage - 1 : currentPage + 1);
  };
  if (hide) {
    return null;
  }
  return (
    <div className="pagination_wrapper">
      <div className="page_numbers_display">
        <span className="title">Page</span>
        {numsArr.map((item, index) => (
          <span
            className={currentPage === item ? "active" : "page_number"}
            key={`${item}${index}`}
            onClick={() => typeof item === "number" && goToPage(item)}
          >
            {`${item}`}
          </span>
        ))}
      </div>
      <div className="control_btns">
        {btnTexts.map((text) => (
          <Button
            key={text}
            onClick={() => handleClickBtn(text)}
            disabled={
              text === "Previous"
                ? !currentPage || currentPage === 1
                : text === "Next"
                ? !currentPage || currentPage === length
                : false
            }
            id={
              text === "Previous"
                ? !currentPage || currentPage === 1
                  ? "disabled_btn"
                  : ""
                : text === "Next"
                ? !currentPage || currentPage === length
                  ? "disabled_btn"
                  : ""
                : ""
            }
            buttonName={text}
          />
        ))}
      </div>
    </div>
  );
};

export default Pagination;
