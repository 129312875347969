import { PieChartProps, RenderCustomLabelProps } from '../../../types/Dashboard/DashboardModels';
import './PieChart.scss';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

const RADIAN = Math.PI / 180;

export default function CustomPieChart({ colors, data }: PieChartProps) {
	const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: RenderCustomLabelProps) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		const labelColor = data[index].colorShade === 'dark' ? '#ffffff' : '#000000';

		return (
			<text x={x} y={y} fill={labelColor} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
				{percent>0 && `${(percent * 100).toFixed(1)}%`}
			</text>
		);
	};

	return (
    <section className="pie_chart_container">
      <h2 className="chart_header">Admitted Patient Age range</h2>
      <PieChart
        width={250}
        height={250}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Pie
          data={data}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={120}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
      <div className="chart_details_container">
        {data.map((item, index) => (
          <div key={index}>
            <span
              data-bg-color={colors[index]}
              style={{
                backgroundColor: colors[index],
                width: "30px",
                height: "13px",
                display: "inline-block",
                marginRight: "10px",
              }}
            ></span>
            <span style={{ color: "#000000" }}>{item.name}</span>
          </div>
        ))}
      </div>
    </section>
  );
}
