const ProfitIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.38286 5.70228L10.8268 1.40737M10.8268 1.40737L9.06115 0.742188M10.8268 1.40737L10.1698 3.17296M11.2456 11.4179H9.19255V5.66943C9.19255 5.56054 9.23581 5.4561 9.31281 5.37909C9.38981 5.30209 9.49425 5.25883 9.60315 5.25883H10.835C10.9439 5.25883 11.0483 5.30209 11.1253 5.37909C11.2023 5.4561 11.2456 5.56054 11.2456 5.66943V11.4179ZM7.13953 11.4179H5.08651V6.90125C5.08651 6.79235 5.12977 6.68791 5.20677 6.61091C5.28377 6.5339 5.38821 6.49064 5.49711 6.49064H6.72892C6.83782 6.49064 6.94226 6.5339 7.01927 6.61091C7.09627 6.68791 7.13953 6.79235 7.13953 6.90125V11.4179ZM3.03349 11.4179H0.980469V8.13306C0.980469 8.02416 1.02373 7.91972 1.10073 7.84272C1.17774 7.76571 1.28217 7.72245 1.39107 7.72245H2.62288C2.73178 7.72245 2.83622 7.76571 2.91323 7.84272C2.99023 7.91972 3.03349 8.02416 3.03349 8.13306V11.4179Z"
        stroke="white"
        strokeWidth="0.821208"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProfitIcon;
