import React from "react";
import './SuccessResponse.scss';
import CheckIcon from '../../../../../../resources/assets/svg/Dashboard/CheckIcon';
import { Button } from '../../../../../../components/button/Button';

interface Props {
  onContinue: () => void;
  successMsg: string
}
const SuccessResponse = ({ onContinue, successMsg }: Props) => {
  return <div className='success_response'>
    <div className='container'>
      <CheckIcon />
      <h3>{successMsg}</h3>
      <h2 className='successful_text'>Successfully</h2>
      <Button onClick={onContinue} className='continue_btn' name={'continue_btn'} id={'continue_btn'} buttonName={'continue'} />
    </div>
  </div>;
};

export default SuccessResponse;
