import React, { useEffect, useState } from "react";
import { FieldValues, UseFormRegister, useForm } from "react-hook-form";
import { InputField } from "../../inputfield/InputField";
import ServiceFrequency from "../../ServiceFrequency/ServiceFrequency";
import { Dropdown } from "../../dropdown/Dropdown";
import "./AddService.scss";
import { Button } from "../../button/Button";
import {
  DayTimesProps,
  OfferedServiceDto,
  ServiceFrequencyEnum,
} from "../../../types/services/ServiceTypes";
import { Checkbox } from "../../checkbox/Checkbox";
import { LightBulb } from "../../../resources/assets/svg/servicepage/LightBulb";
import SuccessPopUp from "../../successPopUp/SuccessPopUp";
import departmentService from "../../../services/DepartmentService";
import { useWhoAmI } from "../../../context/UserCtx";
import { DepartmentDao } from "../../../types/department/DepartmentDao";
import servicesOffered from "../../../services/ServicesOffered";
import { ErrorNotification } from "../../../modules/errornotification/ErrorNotification";
import { Spinner } from "../../spinner/Spinner";
import { capitalizeFirstCharacter } from "../../../util/Helpers";

interface AddServiceProps {
  data: OfferedServiceDto;
  onClickProceed?: () => void;
  currentAction?: string;
  triggerSubmitted?: (state: boolean) => void;
}

const AddService = (props: AddServiceProps) => {
  const {
    register,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const { hospital, userId } = useWhoAmI();
  const [serviceData, setServiceData] = useState<OfferedServiceDto>(
    props?.data ?? ({} as OfferedServiceDto)
  );
  const [serviceAdded, setServiceAdded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [departments, setDepartments] = useState<DepartmentDao[]>([]);
  const [selectedDept, setSelectedDept] = useState<DepartmentDao>(
    {} as DepartmentDao
  );
  const [addServiceError, setAddServiceError] = useState<string>("");

  useEffect(() => {
    setServiceData((prevData) => ({
      ...prevData,
      price: prevData.unitPrice * prevData.quantity || 0,
    }));
  }, [serviceData?.unitPrice, serviceData.quantity]);

  useEffect(() => {
    handleServiceAdded(false);
    setServiceData((prevData) => ({
      ...prevData,
      hospitalId: hospital.id,
      createdBy: userId,
      deleted: false,
      serviceTimes: {
        frequency: props?.data?.serviceTimes?.frequency || null,
        openingTimes: props?.data?.serviceTimes?.openingTimes,
      },
    }));
  }, [userId]);

  useEffect(() => {
    if (hospital.id) {
      departmentService
        .getDepartments(hospital.id)
        .then((response) => setDepartments(response))
        .catch(() => setAddServiceError("Error fetching departments"));
    }

    if (serviceData.departmentName && hospital.id) {
      departmentService
        .getDepartmentByName(hospital.id, serviceData?.departmentName)
        .then((response) => {
          setSelectedDept(response);
          setServiceData((prevData) => ({
            ...prevData,
            hodName: response.unitHead,
          }));
        })
        .catch(() =>
          setAddServiceError(
            `Error fetching details for ${serviceData?.departmentName} department`
          )
        );
    }
  }, [hospital.id, serviceData?.departmentName]);

  function serviceDataHandler(e: any) {
    const { value, name } = e.target;
    const updatedServiceData = {
      ...serviceData,
      [name]: (name === "unitPrice" || name === "quantity" ? Number(value) : value.trim()),
    };
    setServiceData(updatedServiceData);
    clearErrors();
  }

  const handleGetFrequency = (value: ServiceFrequencyEnum) => {
    setServiceData({
      ...serviceData,
      serviceTimes: {
        ...serviceData.serviceTimes,
        frequency: value,
      },
    });
  };

  const handleSelectDayTimes = (daysAndTimes: DayTimesProps[]) => {
    setServiceData((prevData) => ({
      ...prevData,
      serviceTimes: { ...prevData.serviceTimes, openingTimes: daysAndTimes },
    }));
  };

  const handleChangeNotify = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.currentTarget;
    setServiceData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleServiceAdded = (state: boolean) => {
    setServiceAdded(state);
    props.triggerSubmitted!(state);
  };

  const onSubmit = () => {
    trigger().then();
    if (!serviceData.departmentName || !serviceData.categoryName) {
      !serviceData.departmentName &&
        setError("departmentName", {
          message: "Department is required",
        });
      !serviceData.categoryName &&
        setError("categoryName", {
          message: "Category is required",
        });
      return;
    }
    clearErrors();
    setLoading(true);
    setAddServiceError("");
    servicesOffered
      .createService(serviceData)
      .then(() => handleServiceAdded(true))
      .catch(() => {
        setAddServiceError(
          `Error ${
            props.currentAction === "Edit" ? "editing" : "adding"
          } service`
        );
      })
      .finally(() => setLoading(false));
  };

  const handleSuccessModalBtnClick = () => {
    setServiceAdded(false);
    props.onClickProceed!();
  };

  const departmentOptions = [...departments].map((item) => ({
    label: item.name,
    value: item.name,
  }));

  const categoryOptions = selectedDept?.categories?.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  return (
    <>
      {serviceAdded ? (
        <SuccessPopUp
          successMessage={"Service added"}
          btnText={"Proceed"}
          onBtnClick={handleSuccessModalBtnClick}
        />
      ) : (
        <div className="edit-service">
          <div className="service_list_notification">
            <LightBulb />
            <div className="service_notification_texts">
              <span>
                Services carried out in the <br /> hospital are added here
              </span>
            </div>
          </div>
          <section
            tabIndex={0}
            className="edit-service-form"
            onKeyDown={(e: any) => {
              if (e.key === "Enter") onSubmit();
            }}
          >
            <div>
              <InputField
                id="name"
                name="serviceName"
                defaultValue={serviceData?.serviceName}
                onChange={serviceDataHandler}
                errors={errors}
                placeholder="Service name"
                label="Service name"
                pattern={{
                  value: /^[a-zA-Z0-9\s]{6,24}$/,
                  message: "Min. of 6 alphanumeric values allowed",
                }}
                register={register}
                minLength={6}
                min={6}
                required={"Service name is required"}
              />
              <InputField
                id="unitPrice"
                name="unitPrice"
                defaultValue={serviceData?.unitPrice}
                onChange={serviceDataHandler}
                errors={errors}
                placeholder="Unit price"
                label="Unit price"
                pattern={{
                  value: /^[0-9]+(\.[0-9]+)?$/, // Regex pattern to accept decimals
                  message: "Only non-negative numeric values are allowed",
                }}
                register={register}
                minLength={2}
                min={0} // Ensure this reflects the minimum acceptable value
                type="number"
                required="Unit price is required"
                step="any" // Allows decimal values
              />

              <InputField
                id="quantity"
                name="quantity"
                defaultValue={serviceData?.quantity}
                onChange={serviceDataHandler}
                errors={errors}
                placeholder="Quantity"
                label="Quantity"
                pattern={{
                  value: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
                  message: "Only values greater than or equal to 1 are allowed",
                }}
                register={register}
                minLength={1}
                min="Please enter a value greater than zero for quantity"
                type="number"
                required="Quantity is required"
              />

              <div style={{ margin: "1rem auto" }}>
                <ServiceFrequency
                  getFrequency={handleGetFrequency}
                  defaultFrequency={serviceData?.serviceTimes?.frequency!}
                  defaultDaysAndTimes={serviceData?.serviceTimes?.openingTimes}
                  getDaysAndTimes={handleSelectDayTimes}
                />
              </div>
            </div>
            <div className="add_service_right">
              <Dropdown
                id="departmentName"
                name="departmentName"
                defaultValue={serviceData?.departmentName}
                onChange={serviceDataHandler}
                errors={errors}
                placeholder={
                  serviceData.departmentName ||
                  capitalizeFirstCharacter("department")
                }
                getSelectedOptionValue={(value) =>
                  setServiceData({ ...serviceData, departmentName: value })
                }
                options={departmentOptions}
                label="Department"
              />
              <InputField
                id="personnel"
                name="hodName"
                defaultValue={serviceData?.hodName}
                onChange={serviceDataHandler}
                errors={errors}
                placeholder="HOD / Personnel"
                label="HOD / Personnel"
                pattern={{
                  value: /^[a-zA-Z\s\.]{6,}$/,
                  message: "Min. of 6 alphanumeric values allowed",
                }}
                register={register}
                minLength={6}
                min={6}
                readOnly
              />
              <Dropdown
                id="categoryName"
                name="categoryName"
                defaultValue={serviceData?.categoryName}
                onChange={serviceDataHandler}
                errors={errors}
                placeholder={serviceData?.categoryName || "Category"}
                getSelectedOptionValue={(value: string) => {
                  setServiceData({ ...serviceData, categoryName: value });
                }}
                options={categoryOptions!}
                label={"Category"}
              />
              <div className="add_service_notify">
                <Checkbox
                  id={""}
                  name="notifyOtherUsers"
                  checked={serviceData.notifyOtherUsers || false}
                  label={"Notify Other Users"}
                  onChange={handleChangeNotify}
                  errors={{}}
                  register={
                    (() => {}) as unknown as UseFormRegister<FieldValues>
                  }
                />
                <p>
                  (Send a notification to other users of the new changes made.)
                </p>
                <Checkbox
                  id={""}
                  name="notifyPatients"
                  checked={serviceData.notifyPatients || false}
                  label={"Notify Patient via mail"}
                  onChange={handleChangeNotify}
                  errors={{}}
                  register={
                    (() => {}) as unknown as UseFormRegister<FieldValues>
                  }
                />
                <p>
                  (Send a notification to patient via mail of the new services
                  available)
                </p>
              </div>
              <Button buttonName="Save" id="submit-btn" onClick={onSubmit} />
            </div>
          </section>
        </div>
      )}
      {loading && <Spinner />}
      {addServiceError && (
        <ErrorNotification
          name={"service-error"}
          stringErrors={addServiceError}
        />
      )}
    </>
  );
};

export default AddService;
