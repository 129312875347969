import "./CompanyRegForm.scss";
import { Modal } from "../modal/Modal";
import { useForm } from "react-hook-form";
import { InputField } from "../inputfield/InputField";
import { Dropdown } from "../dropdown/Dropdown";
import states from "../../assets/states.json";
import languages from "../../assets/languages.json";
import bannerImg from "../../resources/assets/images/Screenshot_2024-02-21_153252-removebg-preview.png";
import registerService from "../../services/RegisterService";
import HospitalDao from "../../types/HospitalDao";
import { Address } from "../../types/NewPatientData";
import React, { useEffect, useState } from "react";
import { Button } from "../button/Button";
import { RegistrationStage } from "../../pages/register/Register";
import { HospitalCategory } from "../../types/HospitalCategory";
import WhoAmI from "../../userAuth/WhoAmI";
import imageService from "../../services/imageservices/ImageService";
import { InputFile } from "../inputfile/InputFile";
import CloseIcon from "../../resources/assets/svg/Dashboard/CloseIcon";
import { isFileImage } from "../../util/Helpers";
import { ErrorNotification } from "../../modules/errornotification/ErrorNotification";

type RegistrationFormError = string | "registration_error";

const CompanyRegForm = ({
    whoAmI,
    setRegistrationStage,
}: {
    whoAmI: WhoAmI;
    setRegistrationStage: React.Dispatch<React.SetStateAction<RegistrationStage>>;
}) => {
    const [selectedState, setSelectedState] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useState("English");
    const [teamCount, setTeamCount] = useState("");
    const [hospitalCategory, setHospitalCategory] = useState("");
    const [currentForm, setCurrentForm] = useState<number>(1);
    const [logoUrl, setLogoUrl] = useState<string | undefined>();
    const [hospitalData, setHospitalData] = useState<HospitalDao>(
        {} as HospitalDao
    );
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);
    const [error, setError] = useState<RegistrationFormError>("");
    const {
        register,
        trigger,
        formState: { errors, isValid },
    } = useForm({ mode: "onBlur" });
    const [logoImg, setLogoImg] = useState<Blob | string>()

    useEffect(() => {
        hospitalData.address = { ...hospitalData.address } as Address;
        hospitalData.address.state = selectedState ? selectedState : "";
        setHospitalData((prevState) => ({
            ...prevState,
            id: whoAmI?.hospital?.id,
            userId: whoAmI.userId,
            hospitalCategory: hospitalCategory,
            createdBy: whoAmI.userId,
            superUser: true,
            teamMembersCount: teamCount,
            language: selectedLanguage,
        }));
    }, [selectedState, hospitalCategory, teamCount, selectedLanguage]);

    const userDataHandler = (evt: any) => {
        const { target } = evt;
        const { name, value } = target as { name: keyof HospitalDao; value: any };

        const updateUserData = { ...hospitalData };
        (updateUserData[name] as any) = value;
        (updateUserData[name as keyof HospitalDao] as any) = (name === 'email' ? value.toLowerCase() : value).trim();
        setHospitalData(updateUserData)
    };

    const getSelectedStateHandler = (selectedState: string) => {
        setSelectedState(selectedState);
    };

    const getSelectedCountryHandler = (selectedCountry: string) => {
        setHospitalData({
            ...hospitalData,
            address: { ...hospitalData.address, country: selectedCountry }
        });
    };

    const getSelectedLanguageHandler = (selectedLanguage: string) => {
        setSelectedLanguage(selectedLanguage);
    };

    const handleSubmit = () => {
        trigger().then();
        if (isValid) {
            try {
                setIsSubmittingForm(true);
                registerService.updateHospital(hospitalData)
                    .then((hosp) => {
                        if (logoImg) {
                            imageService.uploadHospitalLogo(logoImg, hosp.id).then((logoPath) => {
                                setLogoUrl(logoPath);
                            }).catch(() => {
                                setError("registration_error");
                                setIsSubmittingForm(false);
                            })
                        }
                        setRegistrationStage("success")
                    })
                    .catch(() => {
                        setError("registration_error");
                        setIsSubmittingForm(false);
                    })
                    .catch(() => {
                        setIsSubmittingForm(false);
                        setError("registration_error");
                    });
            } catch (error) {
                setIsSubmittingForm(false);
                setError("registration_error");
            }
        } else {
            trigger().then();
        }
    };

    const nextPageHandler = (page: number) => {
        trigger().then();
        setCurrentForm(page);
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target
        if (files && files.length > 0) {
            const file = files[0]
            if (!isFileImage(file)) {
                return setError('Invalid file type. Please upload an image')
            }
            setLogoImg(file)
        }
    }

    const removeImg = (e: React.MouseEvent<HTMLLIElement>) => {
        e.stopPropagation()
        e.preventDefault()
        setLogoImg('')
    }

    return (
        <div id="company-reg-page">
            <div id="progress-bar">
                <div className={currentForm === 2 ? "complete" : ""}>
                    <span></span>
                </div>
            </div>
            <h1>Its time to register your hospital and get insights</h1>
            <p>
                Fields with <sup>*</sup> are compulsory
            </p>
            {currentForm === 1 ? (
                <div>
                    <div
                        onClick={(e) => {
                            e.currentTarget.querySelector("input")?.click();
                        }}
                        id="company-logo-img"
                        style={{ backgroundImage: logoImg ? `url(${URL.createObjectURL(logoImg as Blob)})` : 'none' }}
                    >
                        {!logoImg && <small>Click to upload your hospital logo</small>}
                        <InputFile
                            register={register}
                            onChange={handleImageChange}
                            name="companyLogo"
                            id="logo-file-input"
                            defaultValue={logoImg} accept="image/*"
                        />
                        {logoImg && <i className="company_logo_remove_icon" onClick={removeImg}><CloseIcon /></i>}
                    </div>
                    <div id="company-reg-form">
                        <div>
                            <InputField
                                id="name"
                                name="name"
                                defaultValue={hospitalData.name}
                                onChange={userDataHandler}
                                errors={errors}
                                placeholder="Hospital name"
                                label="Hospital name"
                                pattern={{
                                    value: /^[A-Za-z0-9\s,#-]{4,}$/,
                                    message:
                                        "Minimum of 4 alphanumeric values and allowed special characters !#@",
                                }}
                                register={register}
                                min={4}
                                minLength={4}
                                required={"Hospital name is required"}
                                animate={true}
                            />
                            <InputField
                                id="email"
                                name="email"
                                defaultValue={hospitalData.email}
                                onChange={userDataHandler}
                                errors={errors}
                                placeholder="Email"
                                label="Email"
                                pattern={{
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: "Valid email is required",
                                }}
                                register={register}
                                min={4}
                                minLength={4}
                                max={36}
                                maxLength={36}
                                required={"Email is required"}
                                animate type="email"
                            />

                            <Dropdown
                                options={[{ label: 'Nigeria', value: 'Nigeria' }]}
                                errors={errors}
                                getSelectedOptionValue={getSelectedCountryHandler}
                                name="country"
                                onChange={getSelectedCountryHandler}
                                defaultValue={hospitalData.address?.state || "Select Country"}
                                placeholder="Country"
                            />

                            <Dropdown
                                options={states}
                                errors={errors}
                                getSelectedOptionValue={getSelectedStateHandler}
                                name="state"
                                onChange={() => {
                                }}
                                defaultValue={hospitalData.address?.state || "Select state"}
                                placeholder="State"
                            />

                            <InputField
                                id="streetHouseNr"
                                name="streetHouseNr"
                                defaultValue={hospitalData.address?.streetHouseNr}
                                onChange={(e: any) => {
                                    const data = { ...hospitalData };
                                    data.address.streetHouseNr = e.target.value;
                                    setHospitalData({ ...data });
                                }}
                                errors={errors}
                                placeholder="Street name and number"
                                label="Street name and number"
                                pattern={{
                                    value: /^[A-Za-z0-9\s,#-]{4,}$/,
                                    message:
                                        "Minimum of 4 alphanumeric values and allowed special characters (.,#-)",
                                }}
                                register={register}
                                min={3}
                                minLength={3}
                                required={"Street number is required"}
                                animate={true}
                            />
                        </div>
                        <div>
                            <InputField
                                id="taxNumber"
                                name="taxNumber"
                                defaultValue={hospitalData.taxNumber}
                                onChange={userDataHandler}
                                errors={errors}
                                placeholder="Tax number"
                                label="Tax number"
                                pattern={{
                                    value: /^[A-Za-z0-9]{6,20}$/,
                                    message:
                                        "Tax number contains a minimum of 6 and maximum of 20 alphanumeric characters",
                                }}
                                register={register}
                                min={6}
                                minLength={6}
                                max={20}
                                maxLength={20}
                                animate={true}
                            />
                            <InputField
                                id="postcode"
                                name="postcode"
                                defaultValue={hospitalData.address?.postcode}
                                onChange={(e: any) => {
                                    const data = { ...hospitalData };
                                    data.address.postcode = e.target.value;
                                    setHospitalData({ ...data });
                                }}
                                type="number"
                                errors={errors}
                                placeholder="Post code"
                                label="Post code"
                                pattern={{
                                    value: /^[(A-Z|a-z)+0-9+]{6}$/,
                                    message:
                                        "Post code can only contain numbers and must be only 6 characters long, e.g - (123456)",
                                }}
                                register={register}
                                min={6}
                                minLength={6}
                                max={6}
                                maxLength={6}
                                animate={true}
                            />
                            <InputField
                                id="cacNumber"
                                name="cacNumber"
                                defaultValue={hospitalData.cacNumber}
                                onChange={userDataHandler}
                                errors={errors}
                                placeholder="Add your CAC number"
                                label="Corporate Affairs Commission number"
                                pattern={{
                                    value: /^[\d]{4,20}$/,
                                    message:
                                        "Minimum of 4 and maximum of 20 numeric values are allowed",
                                }}
                                register={register}
                                min={4}
                                minLength={4}
                                max={20}
                                maxLength={20}
                                animate={true}
                            />
                            <Dropdown
                                options={[
                                    { label: "1 - 10", value: "1 - 10" },
                                    { label: "10 - 20", value: "10 - 20" },
                                    { label: "20 - 50", value: "20 - 50" },
                                    { label: "50 - 100", value: "50 - 100" },
                                ]}
                                errors={errors}
                                getSelectedOptionValue={(value) => setTeamCount(value)}
                                name="team"
                                onChange={() => {
                                }}
                                defaultValue="none"
                                label="How many team members do you have ?"
                                placeholder="Select your employee size"
                            />
                        </div>
                    </div>
                    <div id="nav-btn-container">
                        <Button
                            id={"next-section-button"}
                            name={"next-section-button"}
                            buttonName={"Proceed"}
                            onClick={() => nextPageHandler(2)}
                        />
                    </div>
                </div>
            ) : (
                <div id="company-reg-form-2">
                    <div id="img-banner">
                        <figure>
                            <img src={bannerImg} alt="Doctor" />
                            <div id="img-divider"></div>
                            <figcaption>
                                Explore the accounting system for electronic medical records
                            </figcaption>
                        </figure>
                    </div>
                    <div>
                        <InputField
                            id="year"
                            name="year"
                            defaultValue={hospitalData?.yearFounded}
                            type={"number"}
                            onChange={userDataHandler}
                            errors={errors}
                            placeholder="Select year"
                            label="Select year"
                            pattern={{
                                value: /^\d{4}$/,
                                message:
                                    "Minimum of 4 alphanumeric values and allowed special characters !#@",
                            }}
                            register={register}
                            min={4}
                            minLength={4}
                            required={"Year is required"}
                            animate={true}
                        />
                        <Dropdown
                            options={languages}
                            errors={errors}
                            getSelectedOptionValue={getSelectedLanguageHandler}
                            name="language"
                            onChange={() => {
                            }}
                            defaultValue={selectedLanguage}
                            placeholder="Select language"
                        />
                        <InputField
                            id="currency"
                            name="currency"
                            defaultValue={"NGN"}
                            onChange={(e: any) => {
                                e.target.value = "NGN";
                                userDataHandler(e);
                            }}
                            errors={errors}
                            placeholder="Currency"
                            label="Currency"
                            pattern={{
                                value: /^[A-Z]{3}$/,
                                message:
                                    "Only 3 letters are allowed and they must be in uppercase",
                            }}
                            register={register}
                            min={3}
                            minLength={3}
                            required={"Your currency is required"}
                            animate={true}
                        />
                        <Dropdown
                            options={[
                                {
                                    value: HospitalCategory.PUBLIC_FEDERAL_HOSPITAL,
                                    label: "Federal hospital",
                                },
                                { value: HospitalCategory.PHARMACY, label: "Pharmacy" },
                                {
                                    value: HospitalCategory.PRIVATE_HOSPITAL,
                                    label: "Private hospital",
                                },
                                {
                                    value: HospitalCategory.PUBLIC_STATE_HOSPITAL,
                                    label: "State hospital",
                                },
                                { value: HospitalCategory.LABORATORY, label: "Laboratory" },
                                {
                                    value: HospitalCategory.PUBLIC_LOCAL_GOVERNMENT_HOSPITAL,
                                    label: "Local government hospital",
                                },
                                {
                                    value: HospitalCategory.CHURCH_HOSPITAL,
                                    label: "Church hospital",
                                },
                            ]}
                            errors={errors}
                            getSelectedOptionValue={(value) => setHospitalCategory(value)}
                            name="hospitalCategory"
                            onChange={() => {
                            }}
                            defaultValue={
                                hospitalData?.hospitalCategory?.replaceAll("_", " ") || "none"
                            }
                            placeholder="Select hospital category"
                        />
                    </div>
                    <span id="divider"></span>
                </div>
            )}
            <div id="nav-submit-btn-container">
                {currentForm === 2 && (
                    <>
                        <Button
                            id={"next-section-button"}
                            name={"next-section-button"}
                            buttonName={"Back"}
                            onClick={() => nextPageHandler(1)}
                        />
                        <Button
                            id={"next-section-button"}
                            name={"next-section-button"}
                            buttonName={isSubmittingForm ? "Please wait..." : "Submit"}
                            onClick={handleSubmit}
                            disabled={isSubmittingForm}
                        />
                    </>
                )}
            </div>
            {error === "registration_error" && (
                <Modal
                    isOpen={Boolean(error)}
                    onClose={() => setError("")}
                    modalTitle="Registration Error"
                >
                    <p>
                        <b>An unexpected error ocurred!</b>
                    </p>
                    <p>Try again.</p>
                </Modal>
            )}
            {error && error !== 'registration_error' && <ErrorNotification stringErrors={error} name={""} onClose={() => setError('')} />}
        </div>
    );
};
export default CompanyRegForm;
