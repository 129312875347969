import { useState } from "react";
import Checked from "../../resources/assets/svg/Checked";
import CloseIcon from "../../resources/assets/svg/Dashboard/CloseIcon";
import Unchecked from "../../resources/assets/svg/Unchecked";
import { SalesOverviewDTO } from "../../types/sales/SalesOverviewDTO";
import { Button } from "../button/Button";
import "./SalesOverview.scss";
import { formatNumberWithCommas } from "../../util/numberFormatter";
import ConfirmationPopup from "./confirmationPopup/ConfirmationPopup";

const SalesOverview: React.FC<
  SalesOverviewDTO & {
    confirmClose: (state: boolean) => void;
    closeModal: () => void;
    onCloseCounter: () => void;
  }
> = ({
  bankTransfer,
  cash,
  confirmClose,
  date,
  installment,
  paid,
  totalBalance,
  totalInstallment,
  totalPaid,
  totalWaived,
  waived,
  POS,
  showCounter,
  closeModal,
  onCloseCounter,
}) => {
  const [confirm, setConfirm] = useState<boolean>(false);
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const appBody = document.querySelector("body");
  if (appBody) {
    appBody.style.overflow = showCounter ? "hidden" : "auto";
  }

  const handleConfirm = () => {
    setConfirm(!confirm);
    confirmClose(!confirm);
  };

  const handleCloseCounter = () => {
    confirm ? setShowPopUp(!showPopUp) : setShowError(true);
  };

  return (
    <>
      {showCounter && (
        <div className="billing_receipt_blur">
          <div className="overview_container">
            <span className="overview_header">Overview of my sales</span>
            <OverviewItem label={"Date"} value={date} />
            <div>
              <OverviewItem
                label={"Bank Transfer"}
                value={formatNumberWithCommas(bankTransfer)}
              />
              <OverviewItem label={"POS"} value={formatNumberWithCommas(POS)} />
              <OverviewItem
                label={"Cash"}
                value={formatNumberWithCommas(cash)}
              />
            </div>
            <div>
              <OverviewItem
                label={"Paid"}
                value={formatNumberWithCommas(paid)}
              />
              <OverviewItem
                label={"Waiver"}
                value={formatNumberWithCommas(waived)}
              />
              <OverviewItem
                label={"Installment"}
                value={formatNumberWithCommas(installment)}
              />
            </div>
            <div>
              <OverviewItem
                label={"Total Paid"}
                value={formatNumberWithCommas(totalPaid)}
              />
              <OverviewItem
                label={"Total Waived"}
                value={formatNumberWithCommas(totalWaived)}
              />
              <OverviewItem
                label={"Total Installment"}
                value={formatNumberWithCommas(totalInstallment)}
              />
              <OverviewItem
                label={"Total Balance"}
                value={formatNumberWithCommas(totalBalance)}
              />
            </div>
            <p className="overview_item overview_checkbox">
              {confirm ? (
                <Checked
                  className="sales_overview_checked_icon"
                  onClick={handleConfirm}
                />
              ) : (
                <Unchecked
                  className="sales_overview_unchecked_icon"
                  onClick={handleConfirm}
                />
              )}
              <span className="confirm_close">
                I confirm I want to close my counter information
              </span>
            </p>
            {showError && !confirm && (
              <span className="confirmation_popup_warning ">
                Check the box above to proceed
              </span>
            )}
            <Button
              id="close_counter_btn"
              buttonName={"Close Counter"}
              onClick={handleCloseCounter}
            />
            <i className="sales_overview_close_icon" onClick={closeModal}>
              <CloseIcon />
            </i>
            <ConfirmationPopup
              closeModal={handleCloseCounter}
              onClickYes={onCloseCounter}
              show={showPopUp}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SalesOverview;

const OverviewItem: React.FC<{
  label: string;
  value: string | Date | number;
}> = ({ label, value }) => {
  return (
    <p className="overview_item">
      <span className="overview_item_label">{label}:</span>
      <span className="overview_item_value">{value as string}</span>
    </p>
  );
};
