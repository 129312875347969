import { useCallback, useEffect, useState } from "react";
import { AdmissionsListDTO } from "../../../types/admission/AdmissionDao";
import { Listing } from "../../../components/listing/Listing";
import "./AdmissionList.scss";
import admissionService from "../../../services/AdmissionService";
import { useWhoAmI } from "../../../context/UserCtx";
import { ErrorNotification } from "../../../modules/errornotification/ErrorNotification";
import {
    ActionType,
    DashboardTopNav,
} from "../../../components/dashboardnav/DashboardTopNav";
import { returnAdmissionsQueueTableData } from "../../../util/Helpers";
import { formatDate } from "../../billing/data";
import usePagination from "../../../customhook/usePagination";
import { Spinner } from "../../../components/spinner/Spinner";
import { useCustomNavigate } from "../../../customhook/CustomUseNavigate";

const admissionActions: ActionType[] = [
    { icon: "", name: "Admission Queue" },
    { icon: "", name: "View Bed Space" },
];
const AdmissionList = () => {
    const user = useWhoAmI();
    const { pageInfo, handlePageInfo } = usePagination();
    const { pN, pS, totalPage } = pageInfo;
    const navigate = useCustomNavigate()
    const [admissionList, setAdmissionList] = useState<AdmissionsListDTO[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedAction, setSelectedAction] = useState<string>(admissionActions[0].name)

    const getAdmissionList = useCallback(() => {
        setLoading(true);
        user?.hospital?.id &&
            admissionService
                .getAdmissionsQueue(user?.hospital?.id, pN, pS)
                .then((response) => {
                    setAdmissionList(response?.admissionListDto);
                    handlePageInfo("totalPage", response?.totalPage);
                })
                .catch(() => {
                    setErrorMessage(
                        "Technical error occurred while getting list of admission, please try again later!"
                    );
                })
                .finally(() => setLoading(false));
    }, [pN, pS, user?.hospital?.id]);

    const handleClickListItem = (obj: Record<string, any>) => {
        navigate('/dashboard/patients/admission?pid='+obj['Patient ID'])
    }

    useEffect(() => {
        getAdmissionList();
    }, [getAdmissionList]);

    return (
        <>
            <div className="admission-listing-wrapper">
                <div className="queue-listing">
                    {errorMessage && (
                        <ErrorNotification
                            name={"list-admission-error"}
                            stringErrors={errorMessage}
                        />
                    )}
                    {admissionList.length < 1 && !loading ? (
                        <ErrorNotification
                            name={"empty-list"}
                            stringErrors={"There is  no registered admission to be displayed"}
                        />
                    ) : (
                        <div>
                            <DashboardTopNav
                                hasLightBulb
                                action={admissionActions}
                                placeholder={"Search Patient name, ID, DOB..."}
                                itemList={[""]}
                                message="Below is the list of queue"
                                selectedAction={selectedAction}
                                getClickedAction={(action) => setSelectedAction(action.name)}
                            />
                            <div className="admissions_queue_lists">
                                {admissionList.map((item, index) => (
                                    <div
                                        className="admission_queue_list_wrapper"
                                        key={formatDate(item.date)}
                                    >
                                        <span>{formatDate(item.date)}</span>
                                        <Listing
                                            items={returnAdmissionsQueueTableData(item.admissions)}
                                            hidePagination={index < admissionList.length - 1}
                                            hideHeading={index > 0}
                                            totalPages={totalPage}
                                            goToPage={(page) => handlePageInfo("pN", page)}
                                            currentPage={pN}
                                            onClickListItem={handleClickListItem}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {loading && <Spinner />}
        </>
    );
};

export default AdmissionList;
