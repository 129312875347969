import { useState } from "react";
interface PaginationState {
  pN: number;
  pS: number;
  totalPage: number;
}

const usePagination = () => {
  const [pageInfo, setPageInfo] = useState<PaginationState>({
    pN: 0,
    pS: 25,
    totalPage: 1,
  });

  const handlePageInfo = (name: keyof PaginationState, value: number) => {
    setPageInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  return { pageInfo, handlePageInfo };
};

export default usePagination;
