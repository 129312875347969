import './PaymentBar.scss'
import {Checkbox} from "../../components/checkbox/Checkbox";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";

export type PaymentBarType = {
    name: string, //name of status or payment type
    label: string,
}

interface PaymentBarProps {
    checkboxesNames: PaymentBarType[],
    label: string,
    getSelectedOption(selected: string): any
}

type CheckedBoxObj = { index: number, checked: boolean }

export const PaymentBar = (props: PaymentBarProps) => {
    const [checkedBox, setCheckedBox] = useState<CheckedBoxObj>({} as CheckedBoxObj)
    const [selectedName, setSelectedName] = useState<string>('');
    const {register, formState: {errors}} = useForm({mode: 'onBlur'});

    useEffect(() => {
        props.getSelectedOption(selectedName)
    }, [selectedName, checkedBox]);

    const onChangeHandler = (evt: any, index: number) => {
        const target = evt.target;
        const name = target.name;
        const checked = target.checked;

        setCheckedBox((prevState) => ({
            ...prevState, index, checked
        }));

        if(checked) {
            setSelectedName((prevState) => {
                return prevState != name ? name : name
            })
        }

        if(!checked) {
            setSelectedName((prevState) => {
                return ''
            })
        }
    }

    return (
        <div className={'payment-bar-component'}>
            <div className={''}>
                <label><strong>{props.label}</strong> *</label>
                <div className={'payment-bar-checkboxes-wrapper'}>
                    {
                        props.checkboxesNames && props.checkboxesNames.length > 0 &&
                        props.checkboxesNames.map((item, index) => (
                            <div className={'payment-bar-checkboxes-mapper'} key={index}>
                                <Checkbox key={index} id={'id_' + item.name} name={item.name}
                                          checked={checkedBox.index === index ? checkedBox.checked : false}
                                          label={item.label}
                                          onChange={(e: any) => onChangeHandler(e, index)}
                                          flipLabel={true}
                                          register={register}
                                          errors={errors}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}