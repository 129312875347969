import './CombinedSalesComponent.scss';
import Sales from './Sales/Sales';
import TodaySalesAndServiceReport from './TodaySalesAndServiceReport/TodaySalesAndServiceReport';
import { useDashboardContext } from '../../../context/DashboardCtx';
import { DashboardModalList } from '../../../types/Dashboard/DashboardModels';

const CombinedSalesComponent = () => {
	const { selectedOptions } = useDashboardContext();
	return (
		<div className='combined_container'>
			{selectedOptions.find((item) => item.label === DashboardModalList.Sales) && <Sales />}
			{selectedOptions.find((item) => item.label === DashboardModalList.Daily_Sales_and_Services) && <TodaySalesAndServiceReport />}
		</div>
	);
};

export default CombinedSalesComponent;
