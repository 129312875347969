import React from 'react';

const RedDot = () => {
	return (
		<svg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<circle cx='2.87423' cy='2.87423' r='2.87423' transform='matrix(1 0 0 -1 0 5.74854)' fill='#F11348' />
		</svg>
	);
};

export default RedDot;
