import {ApiService} from "./ApiService";
import InvoiceDao from "../types/invoice/InvoiceDao";

const baseUrl = `${process.env.ACC_PROXY ?? process.env.REACT_APP_ACC_PROXY}invoice/`

class PaymentService extends ApiService {
    /** Gets patient admission fee or consultation fee payment in the last 1 hour that */
    // /{hospitalId}/{patientId}/recent
    async getPatientLatestPayment(hospitalId: number, patientId: number): Promise<InvoiceDao> {
        const response = await fetch(baseUrl + `${hospitalId}/${patientId}/recent`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            }
        )
        const invoiceDetails = await this.apiResponseHandler(response);
        return await invoiceDetails.json();

        //return {} as InvoiceDao;
    }
}

const paymentService = new PaymentService();
export default paymentService;
