import React, {useState} from "react";
import './HistoryList.scss';
import ImageSvg from '../../../../../../resources/assets/svg/ImageSvg';
import PdfSvg from '../../../../../../resources/assets/svg/PdfSvg';
import {OldMedicalRecords} from "../../../../../../types/OldMedicalRecords";
import {Modal} from "../../../../../../components/modal/Modal";
import oldMedicalRecordsService from "../../../../../../services/imageservices/OldMedicalRecordsService";

interface HistoryListProps {
    customFiles: File[];
    oldRecords: OldMedicalRecords
}

const HistoryList = (props: HistoryListProps) => {
    const [isPdf, setIsPdf] = useState<boolean>(false);
    const [viewFile, setViewFile] = useState<boolean>(false);
    const [imgSrc, setImgSrc] = useState<string>()
    const [recordError, setRecordError] = useState<string>()

    const fileOnClickHandler = (filePath: File) => {
        setIsPdf(String(filePath).includes('pdf'))
        const filterOldRecord = props.oldRecords.months
            .filter((mnt) => mnt.files
                .filter((fileP) => JSON.stringify(filePath)
                    .includes(fileP.name)
                )
            );
        const fileId = filterOldRecord.pop()?.id;

        fileId && oldMedicalRecordsService.getOldRecordById(fileId)
            .then((imgSrc) => {
                setImgSrc(imgSrc)
            }).catch(() => {
                setRecordError('Technical error occurred while fetching this file')
            })

        setViewFile(true)
    }

    return (
        <div className={'history-component-container'}>
            <div>
                {
                    !viewFile && props.customFiles?.map((eachFile, index) => (
                            <div key={Math.random().toFixed(index)}>
                                <div className='med-his-record-filename-w'
                                     onClick={() => fileOnClickHandler(eachFile)}
                                     key={eachFile.name + index}
                                     role={'button'}
                                     tabIndex={1}
                                >
                                    <div className={'med-his-record-filename-w-icon-name'}>
                                        <p>{String(eachFile).includes('pdf') ? <PdfSvg/> : <ImageSvg/>}</p>
                                    </div>
                                    <div className={'med-his-record-filename-w-icon-name'}>
                                        <p>
                                            {String(eachFile).includes('/') ? String(eachFile).split('/').pop()
                                            : String(eachFile).split('\\').pop()}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    )
                }
            </div>

            {
                viewFile && imgSrc && (
                    <Modal isOpen={viewFile} onClose={() => setViewFile(false)} modalTitle={'View File'}>
                        <div className={'medical-history-list-view-file'}>
                            {!isPdf && <img src={imgSrc} alt={'img src'}/>}
                            {/* TODO: use PDF viewer */}
                            {/*{isPdf && <PdfViewer pdfData={imgSrc} />}*/}
                            {isPdf && <p>Pdf view is currently being implemented...</p>}
                        </div>
                    </Modal>
                )
            }
        </div>
    )
};

export default HistoryList;
