import {NavigateOptions, useNavigate} from 'react-router-dom';


export const useCustomNavigate = (): ((path: string, options?: NavigateOptions) => void) => {
    const navigate = useNavigate();

    return (path: string, options?: NavigateOptions) => {
        sessionStorage.setItem(path, JSON.stringify(options))
        navigate(path, options);
        /*TODO: use sessionStorage instead*/
    };
};
