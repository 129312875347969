import { BillingData } from "../../types/billing/BillingsListDTO";
import {
  convertToLocaleString,
  dateFormatArrDDMMYYYY,
} from "../../util/Helpers";
import { CardsTypes, ReceiptStateProps } from "./types";
import React from "react";

export const years: number[] = [2020, 2021, 2022, 2023, 2024];

export function getCurrentMonthAndYear(months: string[]) {
  const currentDate = new Date();
  const currentMonthIndex = currentDate.getMonth();
  const currentMonth = months[currentMonthIndex];
  const currentYear = currentDate.getFullYear();
  return { month: currentMonth, year: currentYear };
}

function getLastDayOfMonth(month: number, year: number) {
  const lastDayOfMonth = new Date(year, month, 0).getDate();
  const formattedDate = `${lastDayOfMonth.toString().padStart(2, "0")}/${month
    .toString()
    .padStart(2, "0")}/${year}`;
  return formattedDate;
}

function getLastDayOfPreviousMonth(month: number, year: number): string {
  const firstDayOfMonth = new Date(year, month - 1, 1);
  firstDayOfMonth.setDate(0);
  const previousYear = firstDayOfMonth.getFullYear();
  const previousMonth = firstDayOfMonth.getMonth() + 1;
  const formattedDate = `${firstDayOfMonth
    .getDate()
    .toString()
    .padStart(2, "0")}/${previousMonth
    .toString()
    .padStart(2, "0")}/${previousYear}`;
  return formattedDate;
}

export const monthsOfYear: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const returnCardsData = (): CardsTypes[] => {
  const { month, year } = getCurrentMonthAndYear(monthsOfYear);
  const currentMonth = month;
  const currentYear = year;
  return years.map((year) => ({
    year,
    data: monthsOfYear
      .filter((item) =>
        year === currentYear
          ? monthsOfYear.indexOf(item) <= monthsOfYear.indexOf(currentMonth)
          : item
      )
      .map((month, index) => ({
        month,
        billing: {
          total: {
            revenue: 134040.03,
            lastMonth: 23,
          },
          atrizultFeePrevious: {
            totalAmount: 158234.23,
            vat: 7.5,
            deadline: getLastDayOfPreviousMonth(index + 1, year),
          },
          atrizultFeeCurrent: {
            totalAmount: 8234.23,
            vat: 7.5,
            nextDueDate: getLastDayOfMonth(index + 1, year),
          },
        },
      })),
  }));
};

export const tableHeaders: string[] = [
  "Invoice ID",
  "Mode of payment",
  "Payment Status",
  "₦ Revenue (95%)",
  "₦ Atrizult bill (5%)",
  "Due date",
  "Payment date",
];

const returnTableData = ({
  inProgress,
  processing,
}: {
  inProgress?: boolean;
  processing?: boolean;
}) => {
  const tableData: React.ReactNode[] = [
    `${inProgress ? "" : "0266111"}`,
    `${inProgress ? "" : "Bank Transfer"}`,
    `${inProgress ? "in progress" : processing ? "processing..." : "Paid"}`,
    "1,509,039.5",
    "76,000",
    "31/08/2024",
    "12/09/2024",
  ];

  return tableData;
};

export const returnTableBodyData = () => {
  const tableBodyData = Array.from({ length: 10 }).map((_, idx) => {
    const obj: Record<string, any> = {};
    tableHeaders.forEach((header, index) => {
      obj[header.toLowerCase()] = returnTableData({
        inProgress: idx === 0,
        processing: idx === 1,
      })[index];
    });
    return obj;
  });
  return tableBodyData;
};

export const restructuredBillsArr = (
  arr: BillingData[]
): ReceiptStateProps[] => {
  if (!arr) return [];
  return (
    arr?.map((item) => ({
      "invoice id": `${item.id}`,
      "mode of payment": item.paymentMethod || "",
      "payment status": item.paymentStatus || "",
      "₦ revenue": convertToLocaleString(item.hospitalRevenue),
      "₦ atrizult bill": convertToLocaleString(item.atrizultFee + item.vat),
      "due date": dateFormatArrDDMMYYYY(
        item.paymentDueDate.map((val) => `${val}`)
      ),
      "payment date": item.paymentDate || "",
    })) || []
  );
};

export const popUpTexts: string[] = ["Download Receipt", "View Receipt"];

export const getTextContent = (node: React.ReactNode): string => {
  if (typeof node === "string" || typeof node === "number") {
    return node.toString();
  } else if (
    React.isValidElement(node) &&
    typeof node.props.children === "string"
  ) {
    return node.props.children;
  } else {
    return "";
  }
};

export const validateFileFormat = (file: File): boolean => {
  const acceptedFormats = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  return acceptedFormats.includes(file.type);
};

export function formatDate(dateArray: [number, number, number]): string {
  if (dateArray && dateArray.length === 3) {
    const [year, month, day] = dateArray;
    const formattedDay = day.toString().padStart(2, "0");
    const formattedMonth = month.toString().padStart(2, "0");
    return `${formattedDay}/${formattedMonth}/${year}`;
  } else return "";
}

export function formatCurrentDate() {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
}

/**


 */
