import React from "react";

const SalesIcon = () => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19 6.5C17.35 6.5 16 6.05 16 5.5V6.5C16 7.05 17.35 7.5 19 7.5C20.65 7.5 22 7.05 22 6.5V5.5C22 6.05 20.65 6.5 19 6.5Z" fill="#FFA000"/>
<path d="M19 5C17.35 5 16 4.55 16 4V5C16 5.55 17.35 6 19 6C20.65 6 22 5.55 22 5V4C22 4.55 20.65 5 19 5ZM19 8C17.35 8 16 7.55 16 7V8C16 8.55 17.35 9 19 9C20.65 9 22 8.55 22 8V7C22 7.55 20.65 8 19 8Z" fill="#FFA000"/>
<path d="M19 9.5C17.35 9.5 16 9.05 16 8.5V9.5C16 10.05 17.35 10.5 19 10.5C20.65 10.5 22 10.05 22 9.5V8.5C22 9.05 20.65 9.5 19 9.5Z" fill="#FFA000"/>
<path d="M19 11C17.35 11 16 10.55 16 10V11C16 11.55 17.35 12 19 12C20.65 12 22 11.55 22 11V10C22 10.55 20.65 11 19 11Z" fill="#FFA000"/>
<path d="M19 12.5C17.35 12.5 16 12.05 16 11.5V12.5C16 13.05 17.35 13.5 19 13.5C20.65 13.5 22 13.05 22 12.5V11.5C22 12.05 20.65 12.5 19 12.5Z" fill="#FFA000"/>
<path d="M19 14C17.35 14 16 13.55 16 13V14C16 14.55 17.35 15 19 15C20.65 15 22 14.55 22 14V13C22 13.55 20.65 14 19 14Z" fill="#FFA000"/>
<path d="M19 15.5C17.35 15.5 16 15.05 16 14.5V15.5C16 16.05 17.35 16.5 19 16.5C20.65 16.5 22 16.05 22 15.5V14.5C22 15.05 20.65 15.5 19 15.5Z" fill="#FFA000"/>
<path d="M19 17C17.35 17 16 16.55 16 16V17C16 17.55 17.35 18 19 18C20.65 18 22 17.55 22 17V16C22 16.55 20.65 17 19 17Z" fill="#FFA000"/>
<path d="M19 18.5C17.35 18.5 16 18.05 16 17.5V18.5C16 19.05 17.35 19.5 19 19.5C20.65 19.5 22 19.05 22 18.5V17.5C22 18.05 20.65 18.5 19 18.5Z" fill="#FFA000"/>
<path d="M19 20C17.35 20 16 19.55 16 19V20C16 20.55 17.35 21 19 21C20.65 21 22 20.55 22 20V19C22 19.55 20.65 20 19 20Z" fill="#FFA000"/>
<path d="M19 5C20.6569 5 22 4.55228 22 4C22 3.44772 20.6569 3 19 3C17.3431 3 16 3.44772 16 4C16 4.55228 17.3431 5 19 5Z" fill="#FFC107"/>
<path d="M19 6C17.6 6 16.45 5.7 16.1 5.25C16.05 5.35 16 5.4 16 5.5C16 6.05 17.35 6.5 19 6.5C20.65 6.5 22 6.05 22 5.5C22 5.4 21.95 5.35 21.9 5.25C21.55 5.7 20.4 6 19 6ZM19 7.5C17.6 7.5 16.45 7.2 16.1 6.75C16.05 6.85 16 6.9 16 7C16 7.55 17.35 8 19 8C20.65 8 22 7.55 22 7C22 6.9 21.95 6.85 21.9 6.75C21.55 7.2 20.4 7.5 19 7.5ZM19 9C17.6 9 16.45 8.7 16.1 8.25C16.05 8.35 16 8.4 16 8.5C16 9.05 17.35 9.5 19 9.5C20.65 9.5 22 9.05 22 8.5C22 8.4 21.95 8.35 21.9 8.25C21.55 8.7 20.4 9 19 9ZM19 10.5C17.6 10.5 16.45 10.2 16.1 9.75C16.05 9.85 16 9.9 16 10C16 10.55 17.35 11 19 11C20.65 11 22 10.55 22 10C22 9.9 21.95 9.85 21.9 9.75C21.55 10.2 20.4 10.5 19 10.5ZM19 12C17.6 12 16.45 11.7 16.1 11.25C16.05 11.35 16 11.4 16 11.5C16 12.05 17.35 12.5 19 12.5C20.65 12.5 22 12.05 22 11.5C22 11.4 21.95 11.35 21.9 11.25C21.55 11.7 20.4 12 19 12ZM19 13.5C17.6 13.5 16.45 13.2 16.1 12.75C16.05 12.85 16 12.9 16 13C16 13.55 17.35 14 19 14C20.65 14 22 13.55 22 13C22 12.9 21.95 12.85 21.9 12.75C21.55 13.2 20.4 13.5 19 13.5ZM19 15C17.6 15 16.45 14.7 16.1 14.25C16.05 14.35 16 14.4 16 14.5C16 15.05 17.35 15.5 19 15.5C20.65 15.5 22 15.05 22 14.5C22 14.4 21.95 14.35 21.9 14.25C21.55 14.7 20.4 15 19 15ZM19 16.5C17.6 16.5 16.45 16.2 16.1 15.75C16.05 15.85 16 15.9 16 16C16 16.55 17.35 17 19 17C20.65 17 22 16.55 22 16C22 15.9 21.95 15.85 21.9 15.75C21.55 16.2 20.4 16.5 19 16.5ZM19 18C17.6 18 16.45 17.7 16.1 17.25C16.05 17.35 16 17.4 16 17.5C16 18.05 17.35 18.5 19 18.5C20.65 18.5 22 18.05 22 17.5C22 17.4 21.95 17.35 21.9 17.25C21.55 17.7 20.4 18 19 18ZM19 19.5C17.6 19.5 16.45 19.2 16.1 18.75C16.05 18.85 16 18.9 16 19C16 19.55 17.35 20 19 20C20.65 20 22 19.55 22 19C22 18.9 21.95 18.85 21.9 18.75C21.55 19.2 20.4 19.5 19 19.5Z" fill="#FFC107"/>
<path d="M5 9.5C3.35 9.5 2 9.05 2 8.5V9.5C2 10.05 3.35 10.5 5 10.5C6.65 10.5 8 10.05 8 9.5V8.5C8 9.05 6.65 9.5 5 9.5Z" fill="#FFA000"/>
<path d="M5 8C3.35 8 2 7.55 2 7V8C2 8.55 3.35 9 5 9C6.65 9 8 8.55 8 8V7C8 7.55 6.65 8 5 8ZM5 11C3.35 11 2 10.55 2 10V11C2 11.55 3.35 12 5 12C6.65 12 8 11.55 8 11V10C8 10.55 6.65 11 5 11Z" fill="#FFA000"/>
<path d="M5 12.5C3.35 12.5 2 12.05 2 11.5V12.5C2 13.05 3.35 13.5 5 13.5C6.65 13.5 8 13.05 8 12.5V11.5C8 12.05 6.65 12.5 5 12.5Z" fill="#FFA000"/>
<path d="M5 14C3.35 14 2 13.55 2 13V14C2 14.55 3.35 15 5 15C6.65 15 8 14.55 8 14V13C8 13.55 6.65 14 5 14Z" fill="#FFA000"/>
<path d="M5 15.5C3.35 15.5 2 15.05 2 14.5V15.5C2 16.05 3.35 16.5 5 16.5C6.65 16.5 8 16.05 8 15.5V14.5C8 15.05 6.65 15.5 5 15.5Z" fill="#FFA000"/>
<path d="M5 17C3.35 17 2 16.55 2 16V17C2 17.55 3.35 18 5 18C6.65 18 8 17.55 8 17V16C8 16.55 6.65 17 5 17Z" fill="#FFA000"/>
<path d="M5 18.5C3.35 18.5 2 18.05 2 17.5V18.5C2 19.05 3.35 19.5 5 19.5C6.65 19.5 8 19.05 8 18.5V17.5C8 18.05 6.65 18.5 5 18.5Z" fill="#FFA000"/>
<path d="M5 20C3.35 20 2 19.55 2 19V20C2 20.55 3.35 21 5 21C6.65 21 8 20.55 8 20V19C8 19.55 6.65 20 5 20Z" fill="#FFA000"/>
<path d="M5 8C6.65685 8 8 7.55228 8 7C8 6.44772 6.65685 6 5 6C3.34315 6 2 6.44772 2 7C2 7.55228 3.34315 8 5 8Z" fill="#FFC107"/>
<path d="M5 9C3.6 9 2.45 8.7 2.1 8.25C2.05 8.35 2 8.4 2 8.5C2 9.05 3.35 9.5 5 9.5C6.65 9.5 8 9.05 8 8.5C8 8.4 7.95 8.35 7.9 8.25C7.55 8.7 6.4 9 5 9ZM5 10.5C3.6 10.5 2.45 10.2 2.1 9.75C2.05 9.85 2 9.9 2 10C2 10.55 3.35 11 5 11C6.65 11 8 10.55 8 10C8 9.9 7.95 9.85 7.9 9.75C7.55 10.2 6.4 10.5 5 10.5ZM5 12C3.6 12 2.45 11.7 2.1 11.25C2.05 11.35 2 11.4 2 11.5C2 12.05 3.35 12.5 5 12.5C6.65 12.5 8 12.05 8 11.5C8 11.4 7.95 11.35 7.9 11.25C7.55 11.7 6.4 12 5 12ZM5 13.5C3.6 13.5 2.45 13.2 2.1 12.75C2.05 12.85 2 12.9 2 13C2 13.55 3.35 14 5 14C6.65 14 8 13.55 8 13C8 12.9 7.95 12.85 7.9 12.75C7.55 13.2 6.4 13.5 5 13.5ZM5 15C3.6 15 2.45 14.7 2.1 14.25C2.05 14.35 2 14.4 2 14.5C2 15.05 3.35 15.5 5 15.5C6.65 15.5 8 15.05 8 14.5C8 14.4 7.95 14.35 7.9 14.25C7.55 14.7 6.4 15 5 15ZM5 16.5C3.6 16.5 2.45 16.2 2.1 15.75C2.05 15.85 2 15.9 2 16C2 16.55 3.35 17 5 17C6.65 17 8 16.55 8 16C8 15.9 7.95 15.85 7.9 15.75C7.55 16.2 6.4 16.5 5 16.5ZM5 18C3.6 18 2.45 17.7 2.1 17.25C2.05 17.35 2 17.4 2 17.5C2 18.05 3.35 18.5 5 18.5C6.65 18.5 8 18.05 8 17.5C8 17.4 7.95 17.35 7.9 17.25C7.55 17.7 6.4 18 5 18ZM5 19.5C3.6 19.5 2.45 19.2 2.1 18.75C2.05 18.85 2 18.9 2 19C2 19.55 3.35 20 5 20C6.65 20 8 19.55 8 19C8 18.9 7.95 18.85 7.9 18.75C7.55 19.2 6.4 19.5 5 19.5Z" fill="#FFC107"/>
<path d="M12 14C10.35 14 9 13.55 9 13V14C9 14.55 10.35 15 12 15C13.65 15 15 14.55 15 14V13C15 13.55 13.65 14 12 14Z" fill="#FFA000"/>
<path d="M12 12.5C10.35 12.5 9 12.05 9 11.5V12.5C9 13.05 10.35 13.5 12 13.5C13.65 13.5 15 13.05 15 12.5V11.5C15 12.05 13.65 12.5 12 12.5ZM12 15.5C10.35 15.5 9 15.05 9 14.5V15.5C9 16.05 10.35 16.5 12 16.5C13.65 16.5 15 16.05 15 15.5V14.5C15 15.05 13.65 15.5 12 15.5Z" fill="#FFA000"/>
<path d="M12 17C10.35 17 9 16.55 9 16V17C9 17.55 10.35 18 12 18C13.65 18 15 17.55 15 17V16C15 16.55 13.65 17 12 17Z" fill="#FFA000"/>
<path d="M12 18.5C10.35 18.5 9 18.05 9 17.5V18.5C9 19.05 10.35 19.5 12 19.5C13.65 19.5 15 19.05 15 18.5V17.5C15 18.05 13.65 18.5 12 18.5Z" fill="#FFA000"/>
<path d="M12 20C10.35 20 9 19.55 9 19V20C9 20.55 10.35 21 12 21C13.65 21 15 20.55 15 20V19C15 19.55 13.65 20 12 20Z" fill="#FFA000"/>
<path d="M12 12.5C13.6569 12.5 15 12.0523 15 11.5C15 10.9477 13.6569 10.5 12 10.5C10.3431 10.5 9 10.9477 9 11.5C9 12.0523 10.3431 12.5 12 12.5Z" fill="#FFC107"/>
<path d="M12 13.5C10.6 13.5 9.45 13.2 9.1 12.75C9.05 12.85 9 12.9 9 13C9 13.55 10.35 14 12 14C13.65 14 15 13.55 15 13C15 12.9 14.95 12.85 14.9 12.75C14.55 13.2 13.4 13.5 12 13.5ZM12 15C10.6 15 9.45 14.7 9.1 14.25C9.05 14.35 9 14.4 9 14.5C9 15.05 10.35 15.5 12 15.5C13.65 15.5 15 15.05 15 14.5C15 14.4 14.95 14.35 14.9 14.25C14.55 14.7 13.4 15 12 15ZM12 16.5C10.6 16.5 9.45 16.2 9.1 15.75C9.05 15.85 9 15.9 9 16C9 16.55 10.35 17 12 17C13.65 17 15 16.55 15 16C15 15.9 14.95 15.85 14.9 15.75C14.55 16.2 13.4 16.5 12 16.5ZM12 18C10.6 18 9.45 17.7 9.1 17.25C9.05 17.35 9 17.4 9 17.5C9 18.05 10.35 18.5 12 18.5C13.65 18.5 15 18.05 15 17.5C15 17.4 14.95 17.35 14.9 17.25C14.55 17.7 13.4 18 12 18ZM12 19.5C10.6 19.5 9.45 19.2 9.1 18.75C9.05 18.85 9 18.9 9 19C9 19.55 10.35 20 12 20C13.65 20 15 19.55 15 19C15 18.9 14.95 18.85 14.9 18.75C14.55 19.2 13.4 19.5 12 19.5Z" fill="#FFC107"/>
</svg>

};

export default SalesIcon;
