import React from 'react';

const StaffSvg = () => {
	return (
		<svg width='19' height='17' viewBox='0 0 19 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M5.91447 15.2661V10.3389H12.4841V15.2661H14.9478V2.12679H3.45085V15.2661H5.91447ZM7.55689 15.2661H10.8417V11.9813H7.55689V15.2661ZM16.5902 15.2661H18.2326V16.9085H0.166016V15.2661H1.80843V1.30558C1.80843 1.08778 1.89495 0.878908 2.04896 0.724901C2.20296 0.570895 2.41184 0.484375 2.62964 0.484375H15.769C15.9868 0.484375 16.1956 0.570895 16.3496 0.724901C16.5037 0.878908 16.5902 1.08778 16.5902 1.30558V15.2661ZM8.37809 5.41162V3.76921H10.0205V5.41162H11.6629V7.05404H10.0205V8.69645H8.37809V7.05404H6.73568V5.41162H8.37809Z'
				fill='black'
			/>
		</svg>
	);
};

export default StaffSvg;
