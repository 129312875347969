import { useEffect, useRef, useState } from "react";
import "./RadioGroup.scss";

export type RadioGroupProps = {
	getSelectedValue(value: string): any;
	options: { value: string; label: string,checked:boolean }[];
	id?: string;
	name: string;
	defaultValue?: string;
};

const RadioGroup = ({
	getSelectedValue,
	name,
	options,
	id,
	defaultValue,
}: RadioGroupProps) => {
	const [selectedValue, setSelectedValue] = useState<string | null>(
		defaultValue || null
	);
	const radioGroupRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		selectedValue && getSelectedValue(selectedValue);
	}, [selectedValue]);

	useEffect(() => {
		if (defaultValue) {
			const { current } = radioGroupRef;
			const radioInput = current?.querySelector(
				`#${defaultValue.split(" ").join("")}`
			) as HTMLInputElement;
			radioInput.checked = true;
		}
	}, [defaultValue]);

	return (
		<div ref={radioGroupRef} id={id} className="radio-group-wrapper">
			{options.map((option) => (
				<span key={option.label} className="radio-input-wrapper">
					<input
						type="radio"
						name={name}
						id={option.value.split(" ").join("")}
						onChange={(e) => {
							if (e.target.checked) {
								setSelectedValue(option.value);
							}
						}}
						value={option.value} checked={option.checked||option.value===defaultValue}
					/>{" "}
					<label htmlFor={option.label.split(" ").join("")}>
						{option.label}
					</label>
				</span>
			))}
		</div>
	);
};

export default RadioGroup;
