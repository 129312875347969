import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from "react";
import PatientDao from "../../types/PatientDao";


const PatientDetailsCtx = createContext<PatientDao>({} as PatientDao);

export const usePatientDetails = () => useContext(PatientDetailsCtx);

interface CtxProp {
    value?: PatientDao,
    children: ReactNode
}

export const PatientDetailCtxProvider = (prop: CtxProp) => {
    const [customerDetails, setCustomerDetails] = useState<PatientDao>();

    useEffect(() => {
        if (prop.value) {
            getCustomerDetailCtxValue(prop.value)
        }
    }, [prop.value]);

    const getCustomerDetailCtxValue = useCallback(
        (newState: PatientDao) => setCustomerDetails(newState),
        [],
    );

    return (
        <PatientDetailsCtx.Provider value={customerDetails ?? {} as PatientDao}>
            {prop.children}
        </PatientDetailsCtx.Provider>
    )
}