import React from "react";

const Unchecked: React.FC<{ className?: string; onClick?: () => void }> = ({
  className,
  onClick,
}) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
    >
      <rect
        x="0.5"
        y="0.5"
        width="27"
        height="27"
        rx="3.5"
        fill="white"
        stroke="#E1E1E1"
      />
    </svg>
  );
};

export default Unchecked;
