import {FaChevronDown, FaChevronRight, FaInfo} from "react-icons/fa";
import {InputField} from "../../../components/inputfield/InputField";
import {Dropdown} from "../../../components/dropdown/Dropdown";
import './PatientComponent.scss'
import {Gender, MaritalStatus, Religion} from "../../../types/Helper";
import {Button} from "../../../components/button/Button";
import {Modal} from "../../../components/modal/Modal";
import {BsCheckCircle} from "react-icons/bs";
import states from "../../../assets/states.json";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import NewPatientData, {Address, NextOfKin} from "../../../types/NewPatientData";
import {DialogPageType, PatientActions} from "../newpatient/Patient";
import customerService from "../../../services/PatientService";
import PatientDao from "../../../types/PatientDao";
import {useLocation, useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../customhook/CustomUseNavigate";
import {ParamsEnum} from "../../../util/ParamsEnum";
import {useWhoAmI} from "../../../context/UserCtx";
import InputDate from "../../../components/Date-Time/InputDate/InputDate";
import {ErrorNotification} from "../../../modules/errornotification/ErrorNotification";

type CardFeeDto = { cardFeeIsPaid: boolean, cardFeeInvoiceNo: number }

export const PatientComponent = (props: {
    isNewCustomer: boolean;
    onClickCancel?: (action: PatientActions) => void
}) => {
    const user = useWhoAmI();
    const createPatientError = 'create-customer-error';
    const {register, setError, trigger, formState: {errors}} = useForm({mode: 'onBlur'});
    const [toggleCustomerAddress, setToggleCustomerAddress] = useState<boolean>(true)
    const [toggleNextOfKin, setToggleNextOfKin] = useState<boolean>(true)
    const [newPatientData, setNewPatientData] = useState<NewPatientData>({} as NewPatientData)
    const [patientAddress, setPatientAddress] = useState<Address>({} as Address)
    const [customerNextOfKin, setCustomerNextOfKin] = useState<NextOfKin>({} as NextOfKin)
    const [isNewCustomerCreated, setIsNewCustomerCreated] = useState(false);
    const location = useLocation();
    const {patientDao, address} = location?.state?.patient || {} as PatientDao;
    const navigate = useCustomNavigate();
    const [cardFeeIsPaid, setCardFeeIsPaid] = useState<CardFeeDto>({} as CardFeeDto)
    const [savedPatient, setSavedPatient] = useState<PatientDao>({} as PatientDao)
    const {sidebar} = useParams();

    const editCustomerOnLoadHandler = () => {
        if (patientDao && address) {
            setNewPatientData((prevState) => ({
                ...prevState,
                firstname: patientDao.firstname,
                lastname: patientDao.lastname,
                email: patientDao.email,
                phoneNumber: patientDao.phoneNumber,
                gender: patientDao.gender,
                dob: patientDao.dob,
                maritalStatus: patientDao?.maritalStatus,
                occupation: patientDao?.occupation,
                religion: patientDao?.religion,
                createdBy: `${patientDao?.createdBy}`,
                companyId: `${patientDao?.companyId}`,
                address,
                nextOfKin: {
                    fullName: patientDao?.nextOfKinName,
                    nextOfKinPhoneNumber: patientDao?.nextOfKinPhone,
                    relationship: patientDao?.nextOfKinRelationship,
                    nextOfKinEmail: patientDao.nextOfKinEmail
                },
                lastModifiedBy: !props.isNewCustomer ? `${patientDao.createdBy}` : `${patientDao.lastModifiedBy}`
            }));
        }
    }

    const cardFeePaymentHandler = () => {
        //TODO: make call to BE to check, return CardFeeDto
        setCardFeeIsPaid({cardFeeIsPaid: true, cardFeeInvoiceNo: 123456789})
    }

    useEffect(() => {
        cardFeePaymentHandler();

        if (!props.isNewCustomer) {
            editCustomerOnLoadHandler();
        }

        if (props.isNewCustomer && cardFeeIsPaid.cardFeeIsPaid) {
            setNewPatientData((prevState) => (
                {
                    ...prevState,
                    cardFeeInvoiceNo: cardFeeIsPaid.cardFeeInvoiceNo + '',
                    cardFeeIsPaid: 'true'
                })
            )
        }

    }, [patientDao, address, sidebar, errors[createPatientError]?.message, cardFeeIsPaid.cardFeeIsPaid]);

    const toggleCustomerAddressHandler = () => {
        setToggleCustomerAddress(!toggleCustomerAddress)
    }

    const toggleNextOfKinHandler = () => {
        setToggleNextOfKin(!toggleNextOfKin)
    }

    const getSelectedGender = (selectedGender: Gender) => {
        const updateCustomerDetails = {...newPatientData};
        updateCustomerDetails.gender = selectedGender
        setNewPatientData(updateCustomerDetails)
    }

    const getSelectedReligion = (selectedReligion: Religion) => {
        const updateCustomerDetails = {...newPatientData};
        updateCustomerDetails.religion = selectedReligion
        setNewPatientData(updateCustomerDetails)
    }

    const getSelectedMaritalStatus = (selectedMaritalStatus: MaritalStatus) => {
        const updateCustomerDetails = {...newPatientData};
        updateCustomerDetails.maritalStatus = selectedMaritalStatus
        setNewPatientData(updateCustomerDetails)
    }

    const getSelectedCountryValue = (country: string) => {
        const updateCustomerAddress = {...patientAddress};
        updateCustomerAddress.country = country
        setPatientAddress(updateCustomerAddress)
        setNewPatientData({
            ...newPatientData,
            address: updateCustomerAddress,
        });
    }

    const getSelectedStateValue = (state: string) => {
        const updateCustomerAddress = {...patientAddress};
        updateCustomerAddress.state = state
        setPatientAddress(updateCustomerAddress)
        setNewPatientData({...newPatientData, address: updateCustomerAddress})
    }

    const patientDetailsHandler = (evt: any) => {
        const target = evt.target;
        const name = target.name;
        const value = target.value;
        if (name) {
            const updateCustomerDetails = {...newPatientData};
            updateCustomerDetails[name as keyof NewPatientData] = value.trim();
            setNewPatientData(updateCustomerDetails)
        }

    }

    const patientAddressHandler = (evt: any) => {
        const target = evt.target;
        const name = target.name;
        const value = target.value;

        const updateCustomerAddress = {...patientAddress};
        updateCustomerAddress[name as keyof Address] = value.trim();
        setPatientAddress(updateCustomerAddress)

        const cus = {...newPatientData};
        cus.address = updateCustomerAddress;
        setNewPatientData(cus);
    }

    const patientNextOfKinHandler = (evt: any) => {
        const target = evt.target;
        const name = target.name;
        const value = target.value;

        const updateNextOfKin = {...customerNextOfKin};
        updateNextOfKin[name as keyof NextOfKin] = value.trim();
        setCustomerNextOfKin(updateNextOfKin)

        const updateCustomerDetail = {...newPatientData};
        updateCustomerDetail.nextOfKin = updateNextOfKin;

        setNewPatientData(updateCustomerDetail);
    }

    const saveNewPatient = async () => {
        trigger().then();
        const updatePatientData = {...newPatientData};
        updatePatientData.createdBy = `${user?.userId}`;
        updatePatientData.companyId = `${user?.hospital?.id}`;
        setNewPatientData(updatePatientData);

        if (props.isNewCustomer) {
            customerService.createPatient(updatePatientData)
                .then((response: PatientDao) => {
                    setSavedPatient({patientDao: response.patientDao, address: response.address});
                    setIsNewCustomerCreated(true);
                }).catch((err) => {
                if (String(err).includes("409")) {
                    setError(createPatientError, {message: 'This patient is already registered!'})
                } else {
                    setError(createPatientError, {
                        message: 'Technical error occurred while saving patient, ' +
                            'ensure that the inputs are correct!'
                    })
                }
            })
        }

        if (!props.isNewCustomer) {
            customerService.updatePatient(patientDao?.id, updatePatientData)
                .then((response: PatientDao) => {
                    setSavedPatient({patientDao: response.patientDao, address: response.address});
                    setIsNewCustomerCreated(true);
                }).catch(() => {
                setError('create-customer-error', {message: 'Something went wrong please try again later!'})
            })
        }
    }

    const cancelBtnHandler = () => {
        props.onClickCancel ? props.onClickCancel('Patient List') :
            window.history.back()
    }

    const modalBtnCallBackHandler = (action: DialogPageType) => {
        if (action === 'Generate Invoice') {
            navigate(ParamsEnum.PAYMENTS, {state: {patient: savedPatient}});
            return;
        }

        if (action === 'Continue To Profile') {
            navigate(ParamsEnum.PATIENT_PROFILE, {state: {patient: savedPatient}});
            return;
        }
    }

    return (
        <div className={'patient-component-parent'}>
            {
                !isNewCustomerCreated && (
                    <>
                        <div>
                            <div className={'customer-headline-text-wrapper'}>
                                <div className={'new-patient-img-divider-wrapper'}>
                                    <div className={'new-patient-img-divider'}>
                                        <p>
                                            <FaChevronDown style={{fontSize: '14px'}}/>
                                            <span
                                                className={'cus-sectional-p'}>{`${props.isNewCustomer ? 'New' : 'Edit'} Patient`}
                                            </span>
                                        </p>
                                        <i>The patient contact info will appear on the invoice.</i>
                                    </div>

                                    {/*<div className={'new-patient-img-divider'}>
                                        <img onClick={() => console.log('click handler comes here')} src={''}
                                             alt={'Upload image'}/>
                                    </div>*/}
                                </div>
                            </div>

                            {
                                /*toggleNewCustomer &&*/ (
                                <div className={"customer-input-fields-wrapper"}>
                                    {
                                        !props.isNewCustomer && (
                                            <InputField id={'patientId'}
                                                        name={'patientId'}
                                                        defaultValue={newPatientData?.patientId}
                                                        placeholder={'Patient id'}
                                                        label={'patient id'}
                                                        onChange={patientDetailsHandler}
                                                        register={register}
                                                        errors={errors}
                                                        pattern={{
                                                            value: /.*[0-9]{6,}$/,
                                                            message: 'only characters (minimum length of 3) are allowed'
                                                        }}
                                                        min={6}
                                                        minLength={6}
                                            />
                                        )
                                    }

                                    {/*TODO: if card fee is not made yet, registration wont be possible*/}
                                    {
                                        props.isNewCustomer && (
                                            <InputField id={'cardFeeIsPaid'}
                                                        name={'cardFeeIsPaid'}
                                                        defaultValue={cardFeeIsPaid?.cardFeeIsPaid ? String(cardFeeIsPaid.cardFeeInvoiceNo) : ''}
                                                        placeholder={cardFeeIsPaid?.cardFeeIsPaid ? cardFeeIsPaid.cardFeeInvoiceNo + '' : 'Card fee is unpaid'}
                                                        label={'Card Fee'}
                                                        register={register}
                                                        errors={errors}
                                                        pattern={{
                                                            value: /.*[0-9]{9,}$/,
                                                            message: 'only characters (minimum length of 3) are allowed'
                                                        }}
                                                        min={1}
                                                        minLength={1}
                                                        required={'Card fee is required'}
                                                        readOnly={true}
                                                        disabled={true}
                                            />
                                        )
                                    }

                                    <InputField id={'firstname'}
                                                name={'firstname'}
                                                defaultValue={newPatientData?.firstname}
                                                placeholder={'Patient Firstname'}
                                                label={'Patient Firstname other names'}
                                                onChange={patientDetailsHandler}
                                                register={register}
                                                errors={errors}
                                                required={'Firstname is required'}
                                                pattern={{
                                                    value: /.*[a-zA-Z]{2,}$/,
                                                    message: 'only characters (minimum length of 3) are allowed'
                                                }}
                                                min={2}
                                                minLength={2}
                                    />

                                    <InputField id={'lastname'}
                                                name={'lastname'}
                                                defaultValue={newPatientData?.lastname}
                                                placeholder={'Patient Lastname'}
                                                label={'Patient Lastname'}
                                                onChange={patientDetailsHandler}
                                                register={register}
                                                errors={errors}
                                                required={'Lastname is required'}
                                                pattern={{
                                                    value: /.*[a-zA-Z]{2,}$/,
                                                    message: 'only characters (minimum length of 3) are allowed'
                                                }}
                                                min={2}
                                                minLength={2}
                                    />

                                    <InputField id={'email'}
                                                name={'email'}
                                                defaultValue={newPatientData?.email?.toLowerCase()}
                                                label={'Patient Email '}
                                                placeholder={'Patient Email'}
                                                onChange={patientDetailsHandler}
                                                register={register}
                                                errors={errors}
                                                required={'Email is required'}
                                                pattern={{
                                                    value: /(^["a-zA-Z0-9#!$%&'*+-\/=?^_`{}|~]+[.a-zA-Z0-9#!$%&'*+-\/=?^_`{}|~"]+[@]+[.a-zA-Z0-9_-]+[.]+[a-zA-Z]{2,})/,
                                                    message: "A valid email address is required e.g. (yourEmail@gmail.com, email@yahoo.com)"
                                                }}
                                                min={9}
                                                minLength={9} type="email"
                                    />

                                    <InputField
                                        id={'phoneNumber'}
                                        name={'phoneNumber'}
                                        defaultValue={newPatientData?.phoneNumber}
                                        placeholder={'Patient Phone Number'}
                                        label={'Patient Phone Number'}
                                        onChange={patientDetailsHandler}
                                        register={register}
                                        errors={errors}
                                        required={'Phone number is required'}
                                        pattern={{
                                            value: /^(\+|00)?[0-9]{1,4}[-.\s]?(\([0-9]{1,5}\)|[0-9]{1,5})?[-.\s]?([0-9]{1,4}[-.\s]?){1,4}$/,
                                            message: "Phone number in one of these formats (08012345678 or +2348012345678) is required",
                                        }}
                                        min={6}
                                        minLength={6}
                                        max={17}
                                        type="text"
                                    />

                                    <InputDate
                                        id="dob"
                                        name="dob"
                                        defaultValue={newPatientData.dob}
                                        placeholder="Patient Date of Birth"
                                        label="Patient Date of Birth"
                                        required='Date of birth is required'
                                        onChange={patientDetailsHandler}
                                        register={register}
                                        errors={errors}
                                        animate={true}
                                        pattern={{
                                            value: /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(19|20)\d\d$/,
                                            message: "Date format allowed is dd-mm-yyyy, e.g: 20-03-2024",
                                        }}
                                    />

                                    <InputField id={'occupation'}
                                                name={'occupation'}
                                                defaultValue={newPatientData?.occupation?.toLowerCase()}
                                                placeholder={'Patient occupation'}
                                                label={'Patient occupation '}
                                                onChange={patientDetailsHandler}
                                                register={register}
                                                errors={errors}
                                                required={'occupation is required'}
                                                pattern={{
                                                    value: /.*[a-zA-Z]{3,}$/,
                                                    message: 'only characters (minimum length of 3) are allowed'
                                                }}
                                                min={3}
                                                minLength={3}
                                    />

                                    <Dropdown getSelectedOptionValue={getSelectedGender}
                                              id={"gender"}
                                              name={"gender"}
                                              errors={errors}
                                              onChange={patientDetailsHandler}
                                              options={[
                                                  {value: Gender.FEMALE, label: 'Female'},
                                                  {value: Gender.MALE, label: 'Male'}
                                              ]}
                                              placeholder={newPatientData?.gender ?? "Select Patient Gender"}
                                    />

                                    <Dropdown getSelectedOptionValue={getSelectedMaritalStatus}
                                              id={"maritalStatus"}
                                              name={"maritalStatus"}
                                              errors={errors}
                                              onChange={patientDetailsHandler}
                                              options={[
                                                  {value: MaritalStatus.SINGLE, label: 'Single'},
                                                  {value: MaritalStatus.MARRIED, label: 'Married'},
                                                  {value: MaritalStatus.IN_RELATIONSHIP, label: 'In a Relationship'}
                                              ]}
                                              placeholder={newPatientData?.maritalStatus ?? "Select Patient Marital Status"}
                                    />

                                    <Dropdown getSelectedOptionValue={getSelectedReligion}
                                              id={"religion"}
                                              name={"religion"}
                                              errors={errors}
                                              onChange={patientDetailsHandler}
                                              options={[
                                                  {value: Religion.CHRISTIANITY, label: 'Christianity'},
                                                  {value: Religion.ISLAM, label: 'Islam'},
                                                  {value: Religion.TRADITIONALIST, label: 'Traditionalist'},
                                                  {value: Religion.OTHERS, label: 'Others'}
                                              ]}
                                              placeholder={newPatientData?.religion ?? "Select Patient Religion"}
                                    />
                                </div>
                            )
                            }
                        </div>

                        <div>
                            <div className={'customer-address-next-of-kin-text'} role={'button'} tabIndex={0}
                                 onClick={toggleCustomerAddressHandler}
                            >
                                <p>
                                    {
                                        !toggleCustomerAddress ?
                                            <FaChevronRight style={{fontSize: '14px'}}/>
                                            : <FaChevronDown style={{fontSize: '14px'}}/>
                                    }
                                    <span className={'cus-sectional-p'}>Patient Address</span>
                                    <FaInfo className={'fa-info-style'} title={'click to toggle'}/>
                                </p>
                            </div>

                            {
                                toggleCustomerAddress && (
                                    <div className={"customer-input-fields-wrapper"}>
                                        <Dropdown getSelectedOptionValue={getSelectedCountryValue}
                                                  id={"country"}
                                                  name={"country"}
                                                  errors={errors}
                                                  onChange={patientDetailsHandler}
                                                  options={[{value: 'Nigeria', label: 'Nigeria'}]}
                                                  placeholder={newPatientData?.address?.country ?? "Select Country of Residence"}
                                        />
                                        <Dropdown getSelectedOptionValue={getSelectedStateValue}
                                                  id={"state"}
                                                  name={"state"}
                                                  errors={errors}
                                                  onChange={patientDetailsHandler}
                                                  options={states}
                                                  placeholder={newPatientData?.address?.state ?? "Select State of Residence"}
                                        />
                                        <InputField id={"streetHouseNr"}
                                                    name={"streetHouseNr"}
                                                    register={register}
                                                    defaultValue={newPatientData.address?.streetHouseNr?.toLowerCase()}
                                                    onChange={patientAddressHandler}
                                                    placeholder={"Enter Street Name and House Nr."}
                                                    label={"Enter Street Name and House Nr."}
                                                    required={"Only valid street are allowed"}
                                                    errors={errors}
                                                    pattern={{
                                                        value: /^(.*([a-zA-Z0-9]\s.*[a-z0-9]))$/,
                                                        message: "Min. 8 characters in format Udo club 21 or No 21 Udo club or 21 awkunanaw street"
                                                    }}
                                                    min={3}
                                                    minLength={9}
                                                    max={100}
                                                    maxLength={100}
                                        />
                                        <InputField id={"postcode"}
                                                    name={"postcode"}
                                                    register={register}
                                                    defaultValue={newPatientData.address?.postcode}
                                                    onChange={patientAddressHandler}
                                                    placeholder={"Enter Postcode"}
                                                    label={"Enter Postcode"}
                                                    errors={errors}
                                                    pattern={{
                                                        value: /(([0-9]{6})$)/,
                                                        message: "Postcode hast to be minimum 6, e.e 400254"
                                                    }}
                                                    min={6}
                                                    minLength={6}
                                                    max={6}
                                                    maxLength={6}
                                        />
                                    </div>
                                )
                            }
                        </div>

                        <div>
                            <div className={'customer-address-next-of-kin-text'}
                                 onClick={toggleNextOfKinHandler}
                            >
                                <p>
                                    {
                                        !toggleNextOfKin ?
                                            <FaChevronRight style={{fontSize: '14px'}}/>
                                            : <FaChevronDown style={{fontSize: '14px'}}/>
                                    }
                                    <span className={'cus-sectional-p'}>Patient Next of Kin</span>
                                    <FaInfo className={'fa-info-style'} title={'click to toggle'}/>
                                </p>
                            </div>

                            {
                                toggleNextOfKin && (
                                    <>
                                        <div className={"customer-input-fields-wrapper"}>
                                            <InputField id={'fullName'}
                                                        name={'fullName'}
                                                        defaultValue={newPatientData?.nextOfKin?.fullName?.toLowerCase()}
                                                        placeholder={'Firstname Lastname'}
                                                        label={'Name of Next of Kin'}
                                                        onChange={patientNextOfKinHandler}
                                                        register={register}
                                                        errors={errors}
                                                        required={'Next of kin is required'}
                                                        pattern={{
                                                            value: /.*[a-zA-Z]{3,}$/,
                                                            message: 'only characters (minimum length of 3) are allowed'
                                                        }}
                                                        min={3}
                                                        minLength={3}
                                            />

                                            <InputField id={'nextOfKinPhoneNumber'}
                                                        name={'nextOfKinPhoneNumber'}
                                                        defaultValue={newPatientData?.nextOfKin?.nextOfKinPhoneNumber}
                                                        placeholder={'Phone Number'}
                                                        label={'Phone Number'}
                                                        onChange={patientNextOfKinHandler}
                                                        register={register}
                                                        errors={errors}
                                                        required={'Phone number is required'}
                                                        pattern={{
                                                            value: /^(^[+][0-9]{6,15}|^[0-9]{6,15})$/,
                                                            message: "Phone number in one of these formats (08012345678 or " +
                                                                "+2348012345678) is required"
                                                        }}
                                                        min={6}
                                                        minLength={6} type="tel"
                                            />

                                            <InputField id={'relationship'}
                                                        name={'relationship'}
                                                        defaultValue={newPatientData?.nextOfKin?.relationship?.toLowerCase()}
                                                        placeholder={'Relationship to Patient'}
                                                        label={'Relationship to Patient'}
                                                        onChange={patientNextOfKinHandler}
                                                        register={register}
                                                        errors={errors}
                                                        required={'Relationship to Patient is required'}
                                                        pattern={{
                                                            value: /.*[a-zA-Z]{3,}$/,
                                                            message: 'only characters (minimum length of 3) are allowed'
                                                        }}
                                                        min={6}
                                                        minLength={6}
                                            />

                                            <InputField id={'nextOfKinEmail'}
                                                        name={'nextOfKinEmail'}
                                                        defaultValue={newPatientData?.nextOfKin?.nextOfKinEmail?.toLowerCase()}
                                                        label={'Email Address'}
                                                        placeholder={'Email Address'}
                                                        onChange={patientNextOfKinHandler}
                                                        register={register}
                                                        errors={errors}
                                                        pattern={{
                                                            value: /(^["a-zA-Z0-9#!$%&'*+-\/=?^_`{}|~]+[.a-zA-Z0-9#!$%&'*+-\/=?^_`{}|~"]+[@]+[.a-zA-Z0-9_-]+[.]+[a-zA-Z]{2,})/,
                                                            message: "A valid email address is required e.g. (yourEmail@gmail.com, email@yahoo.com)"
                                                        }}
                                                        min={9}
                                                        minLength={9} type="email"
                                            />
                                        </div>

                                        <div className={'new-customer-buttons-wrapper'}>
                                            <Button id={'new-customer-cancel-btn'}
                                                    name={'new-customer-cancel-btn'}
                                                    buttonName={'Cancel'}
                                                    onClick={cancelBtnHandler}/>

                                            <span>
                                                <Button id={'new-customer-save-btn'}
                                                        name={'new-customer-save-btn'}
                                                        buttonName={'Save'}
                                                        onClick={saveNewPatient}/>
                                            </span>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </>
                )
            }

            {
                isNewCustomerCreated && (
                    <div className={'customer-modal-wrapper'}>
                        <Modal
                            isOpen={isNewCustomerCreated}
                            onClose={() => window.location.href = ParamsEnum.PATIENTS}
                            modalTitle={'New Patient'}
                        >
                            <div style={{paddingBottom: '20px'}}>
                                <p><BsCheckCircle className={'modal-icon-wrapper'}/></p>
                            </div>
                            <p>{savedPatient?.patientDao?.lastname}'s profile has been created</p>
                            <h3 style={{
                                paddingTop: '20px',
                                fontSize: '25px',
                                color: 'green'
                            }}>Successfully</h3>

                            <div className={'new-patient-modal-btn-w'}>
                                <Button id={'generate-invoice'}
                                        buttonName={'Generate Invoice'}
                                        onClick={() => modalBtnCallBackHandler('Generate Invoice')}
                                />

                                <Button id={'continue-to-profile'}
                                        buttonName={'Continue To Profile'}
                                        onClick={() => modalBtnCallBackHandler('Continue To Profile')}
                                />
                            </div>

                        </Modal>
                    </div>
                )
            }

            <div>
                {
                    errors && errors[createPatientError]?.message &&
                    <ErrorNotification name={createPatientError} fieldErrors={errors}/>
                }
            </div>
        </div>
    )
}