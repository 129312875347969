import React, { useRef } from "react";
import { PaymentUploadProps } from "../types";
import "./PaymentUpload.scss";
import { Button } from "../../../components/button/Button";
import CloseIcon from "../../../resources/assets/svg/Dashboard/CloseIcon";
import PdfSvg from "../../../resources/assets/svg/PdfSvg";
import { PiMicrosoftWordLogoFill } from "react-icons/pi";
import UploadSuccess from "./UploadSuccess";
import { Spinner } from "../../../components/spinner/Spinner";
import { CloudIcon } from "../../../resources/assets/svg/Billing";

const PaymentUpload: React.FC<PaymentUploadProps> = ({
  show,
  dueDate,
  duePayment,
  paymentDate,
  selectedFile,
  onChangeFile,
  closeUpload,
  removeFile,
  onChangeDate,
  successfulUpload,
  loading,
  submitReceipt,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClickBrowse = () => {
    if (inputRef?.current) {
      inputRef?.current?.click();
    }
  };
  return (
    <>
      {show && (
        <div className="billing_paymentupload_bg">
          <section className="billing_paymentupload_content">
            <div className="billing_paymentupload_top">
              {!successfulUpload && (
                <>
                  <span>Due payment: {duePayment}</span>
                  <span>Due date: {`${dueDate}`}</span>
                  <i
                    className={"billing_close_icon upload_close_icon"}
                    onClick={closeUpload}
                  >
                    <CloseIcon />
                  </i>
                </>
              )}
            </div>
            {loading ? (
              <Spinner />
            ) : successfulUpload ? (
              <UploadSuccess onClickClose={closeUpload} />
            ) : (
              <>
                <div className="billing_amount_date">
                  <label htmlFor="">
                    Amount to be paid (NGN)
                    <input
                      type="text"
                      disabled
                      value={duePayment}
                      id="billing_paymentupload_amountpaid"
                    />
                  </label>
                  <label htmlFor="">
                    Payment Date
                    <input
                      type="date"
                      value={`${paymentDate}`}
                      onChange={onChangeDate}
                    />
                  </label>
                </div>
                <div className="billing_upload_section">
                  <span>
                    Please upload files corresponding to proof of payment.
                  </span>
                  <label htmlFor="upload">
                    {!!selectedFile ? (
                      selectedFile.type.includes("image") ? (
                        <div
                          style={{
                            backgroundImage: `url('${URL.createObjectURL(
                              selectedFile
                            )}')`,
                          }}
                          className="billing_uploadedfile_wrapper"
                        >
                          <span>{selectedFile.name}</span>
                        </div>
                      ) : (
                        <div className="billing_uploadedfile_wrapper">
                          {selectedFile.type.includes("pdf") ? (
                            <i className="billing_pdf_icon">
                              <PdfSvg />
                            </i>
                          ) : (
                            <PiMicrosoftWordLogoFill className="billing_word_icon" />
                          )}{" "}
                          <span>{selectedFile.name}</span>
                        </div>
                      )
                    ) : (
                      <>
                        <CloudIcon />
                        <Button
                          id=""
                          buttonName="Browse File"
                          onClick={handleClickBrowse}
                          type="button"
                        />
                      </>
                    )}
                    <input
                      type="file"
                      id="upload"
                      ref={inputRef}
                      accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
                      onChange={onChangeFile}
                    />
                    {!!selectedFile && (
                      <i
                        className={"billing_close_icon remove_file_icon"}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          removeFile();
                        }}
                      >
                        <CloseIcon />
                      </i>
                    )}
                  </label>
                  <Button
                    id="upload_payment"
                    buttonName="Upload File"
                    type="submit"
                    onClick={submitReceipt}
                  />
                </div>
              </>
            )}
          </section>
        </div>
      )}
    </>
  );
};

export default PaymentUpload;
