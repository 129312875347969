import React from 'react';

const OpenIcon = () => {
	return (
		<svg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<rect x='1.2856' y='0.801717' width='15.6029' height='15.6029' stroke='black' strokeWidth='0.821208' />
			<path
				d='M10.1974 8.0726C10.1203 8.1497 10.0158 8.19301 9.90673 8.19301C9.79769 8.19301 9.69312 8.1497 9.61602 8.0726C9.53892 7.9955 9.49561 7.89093 9.49561 7.78189C9.49561 7.67286 9.53892 7.56828 9.61602 7.49118L12.9009 4.20635C12.939 4.16818 12.9844 4.13789 13.0342 4.11723C13.0841 4.09657 13.1376 4.08594 13.1916 4.08594C13.2456 4.08594 13.299 4.09657 13.3489 4.11723C13.3988 4.13789 13.4441 4.16818 13.4823 4.20635C13.5204 4.24453 13.5507 4.28985 13.5714 4.33973C13.5921 4.38961 13.6027 4.44307 13.6027 4.49706C13.6027 4.55105 13.5921 4.60451 13.5714 4.65439C13.5507 4.70427 13.5204 4.74959 13.4823 4.78777L10.1974 8.0726ZM5.27019 12.9998C5.23201 13.038 5.18669 13.0683 5.13681 13.089C5.08693 13.1096 5.03347 13.1203 4.97948 13.1203C4.92549 13.1203 4.87203 13.1096 4.82215 13.089C4.77227 13.0683 4.72695 13.038 4.68877 12.9998C4.6506 12.9617 4.62032 12.9163 4.59965 12.8665C4.57899 12.8166 4.56836 12.7631 4.56836 12.7091C4.56836 12.6551 4.57899 12.6017 4.59965 12.5518C4.62032 12.5019 4.6506 12.4566 4.68877 12.4184L7.97361 9.1336C8.05071 9.0565 8.15528 9.01319 8.26431 9.01319C8.37335 9.01319 8.47792 9.0565 8.55502 9.1336C8.63212 9.2107 8.67544 9.31527 8.67544 9.42431C8.67544 9.53334 8.63212 9.63791 8.55502 9.71502L5.27019 12.9998Z'
				fill='black'
			/>
			<path d='M4.97896 13.1195C4.87006 13.1195 4.76563 13.0763 4.68862 12.9993C4.61162 12.9223 4.56836 12.8178 4.56836 12.7089C4.56836 12.6 4.61162 12.4956 4.68862 12.4186C4.76563 12.3416 4.87006 12.2983 4.97896 12.2983H8.26379C8.37269 12.2983 8.47713 12.3416 8.55414 12.4186C8.63114 12.4956 8.6744 12.6 8.6744 12.7089C8.6744 12.8178 8.63114 12.9223 8.55414 12.9993C8.47713 13.0763 8.37269 13.1195 8.26379 13.1195H4.97896Z' fill='black' />
			<path
				d='M5.38957 12.7091C5.38957 12.818 5.34631 12.9224 5.2693 12.9994C5.1923 13.0765 5.08786 13.1197 4.97896 13.1197C4.87006 13.1197 4.76563 13.0765 4.68862 12.9994C4.61162 12.9224 4.56836 12.818 4.56836 12.7091V9.42428C4.56836 9.31538 4.61162 9.21094 4.68862 9.13394C4.76563 9.05693 4.87006 9.01367 4.97896 9.01367C5.08786 9.01367 5.1923 9.05693 5.2693 9.13394C5.34631 9.21094 5.38957 9.31538 5.38957 9.42428V12.7091ZM13.6016 7.78186C13.6016 7.89076 13.5584 7.9952 13.4814 8.0722C13.4044 8.14921 13.2999 8.19246 13.191 8.19246C13.0821 8.19246 12.9777 8.14921 12.9007 8.0722C12.8237 7.9952 12.7804 7.89076 12.7804 7.78186V4.49703C12.7804 4.38813 12.8237 4.28369 12.9007 4.20669C12.9777 4.12969 13.0821 4.08643 13.191 4.08643C13.2999 4.08643 13.4044 4.12969 13.4814 4.20669C13.5584 4.28369 13.6016 4.38813 13.6016 4.49703V7.78186Z'
				fill='black'
			/>
			<path d='M9.9067 4.90763C9.7978 4.90763 9.69336 4.86437 9.61636 4.78737C9.53935 4.71037 9.49609 4.60593 9.49609 4.49703C9.49609 4.38813 9.53935 4.28369 9.61636 4.20669C9.69336 4.12969 9.7978 4.08643 9.9067 4.08643H13.1915C13.3004 4.08643 13.4049 4.12969 13.4819 4.20669C13.5589 4.28369 13.6021 4.38813 13.6021 4.49703C13.6021 4.60593 13.5589 4.71037 13.4819 4.78737C13.4049 4.86437 13.3004 4.90763 13.1915 4.90763H9.9067Z' fill='black' />
		</svg>
	);
};

export default OpenIcon;
