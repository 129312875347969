import React, {useEffect, useState} from "react";
import {UserData} from "../../types/Helper";
import {InputField} from "../../components/inputfield/InputField";
import {useForm} from "react-hook-form";
import './AddUser.scss'
import {Button} from "../../components/button/Button";
import {Checkbox} from "../../components/checkbox/Checkbox";
import {Authorities} from "../../userAuth/WhoAmI";
import registerService from "../../services/RegisterService";
import {useWhoAmI} from "../../context/UserCtx";
import {BiCheck} from "react-icons/bi";
import InputDate from "../../components/Date-Time/InputDate/InputDate";
import adminService from "../../services/AdminService";
import {ErrorNotification} from "../../modules/errornotification/ErrorNotification";
import {Spinner} from "../../components/spinner/Spinner";
import {Dropdown} from "../../components/dropdown/Dropdown";
import {DepartmentDao} from "../../types/department/DepartmentDao";
import departmentService from "../../services/DepartmentService";
import {Address} from "../../types/NewPatientData";

interface NewUserRole {
    receptionist: boolean
    nurse: boolean
    cashier: boolean
    doctor: boolean
    hod: boolean
    admin: boolean
    superAdmin: boolean
}

interface AddUserProps {
    buttonName?: string
    userData?: UserData
    isEdit?: boolean
    callBack?:()=>void
}

export const AddUser = (props: AddUserProps) => {
    const requestError = 'request-error';
    const user = useWhoAmI();
    const [newUser, setNewUser] = useState<UserData>(props?.userData ?? {} as UserData);
    const [newUserRole, setNewUserRole] = useState<NewUserRole>(
        {receptionist: false, nurse: false, cashier: false, doctor: false, hod: false, admin: false, superAdmin: false}
    );
    const {register, trigger, setError,clearErrors, formState: {errors, isValid}} = useForm({mode: 'onBlur'});
    const [userCreated, setUserCreated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [departments, setDepartments] = useState<DepartmentDao[]>([]);

    const updateRolesHandler = () => {
        let authorities: NewUserRole = {...newUserRole};

        if (props?.userData?.authorities && props?.userData?.authorities.length > 0) {
            props?.userData?.authorities.forEach((auth: Authorities) => {
                switch (auth) {
                    case Authorities.RECEPTIONIST:
                        authorities = {...authorities, receptionist: !!auth};
                        break;
                    case Authorities.NURSE:
                        authorities = ({...authorities, nurse: !!auth});
                        break;
                    case Authorities.DOCTOR:
                        authorities = ({...authorities, doctor: !!auth});
                        break;
                    case Authorities.CASHIER:
                        authorities = ({...authorities, cashier: !!auth});
                        break;
                    case Authorities.HOD:
                        authorities = ({...authorities, hod: !!auth});
                        break;
                    case Authorities.ADMIN:
                        authorities = ({...authorities, admin: !!auth});
                        break;
                    case Authorities.SUPER_ADMIN:
                        authorities = {...authorities, superAdmin: !!auth};
                        break;
                    default:
                        break;
                }
            })
        }
        setNewUserRole(authorities);
    }

    useEffect(() => {
        updateRolesHandler();

        if (user.hospital.id) {
            departmentService
                .getDepartments(user.hospital.id)
                .then((response) => setDepartments(response))
                .catch(() => setError(requestError, {message: "Technical error occurred while fetching departments"}));
        }

        if(props.isEdit && props.userData?.authorities.includes(Authorities.SUPER_ADMIN) && !user.authorities.includes(Authorities.SUPER_ADMIN)) {
            setError(requestError, {message: 'You cannot edit super admin'})
            return
        }
    }, []);

    const departmentOptions = [...departments].map((item) => ({
        label: item.name,
        value: item.name,
    }));

    const newUserInputHandler = (evt: any) => {
        const target = evt.target;
        const name = target.name;
        const value = target.value;

        const updateNewUser = {...newUser};
        updateNewUser[name as keyof UserData] = value.trim();
        setNewUser(updateNewUser);
        clearErrors(name)
        // refactor address to address object
        if(name === 'address' && value) {
            const userAddress = value?.split(',');

            const streetHouseNr = userAddress && userAddress[0]?.trim();
            const postcodeAndState = userAddress && userAddress[1]?.trim()?.split(' ');
            const country = userAddress && userAddress[2]?.trim();

            const postcode = postcodeAndState && postcodeAndState[0]?.trim();
            const state = postcodeAndState && postcodeAndState[1]?.trim();

            const address: Address = {streetHouseNr, postcode, state, country};

            setNewUser((prevState) => ({...prevState, address}));
        }
    }

    const newUserRoleHandler = (evt: any) => {
        const target = evt.target;
        const name = target.name;
        const checkedValue = target.checked;

        const updateNewUserRole = {...newUserRole}
        updateNewUserRole[name as keyof NewUserRole] = checkedValue
        setNewUserRole(updateNewUserRole);

        const roleArr: Authorities[] = [];

        Object.entries(updateNewUserRole).forEach(([key, value]) => {
            if (value) {
                switch (key) {
                    case Authorities.RECEPTIONIST.toLowerCase():
                        roleArr.push(Authorities.RECEPTIONIST)
                        break;
                    case 'nurse':
                        roleArr.push(Authorities.NURSE)
                        break;
                    case 'doctor':
                        roleArr.push(Authorities.DOCTOR)
                        break;
                    case 'cashier':
                        roleArr.push(Authorities.CASHIER)
                        break;
                    case 'hod':
                        roleArr.push(Authorities.HOD)
                        break;
                    case 'admin':
                        roleArr.push(Authorities.ADMIN)
                        break;
                    case 'superAdmin':
                        roleArr.push(Authorities.SUPER_ADMIN)
                        break;
                    default:
                        break;
                }
            }
        });

        setNewUser((prevState) => ({...prevState, authorities: roleArr}));
    }

    const saveNewUserHandler = () => {  
        trigger().then();

        if (!newUser.department) {
            return  setError('department', {message:'Please select a department'})
          }

        if (!isValid) {
            setError(requestError, {message: 'please check that your inputs are correctly filled'})
            return
        }

        if (!props?.isEdit) {
            setLoading(true)
            registerService.registerUser(user.hospital.id, newUser)
                .then((response) => {
                    if (response.ok) {
                        setLoading(false)
                        setUserCreated(true)
                       props.callBack&& props.callBack()
                    }
                })
                .catch(() => {
                    setLoading(false)
                    setError(requestError,
                        {message: 'Technical error occurred while registering new user, please try again later'})
                })
        }

        if (props?.isEdit) {
            setLoading(true)
            adminService.updateEmployeeInfo(user.hospital.id, newUser)
                .then((response) => {
                    if (response.id > 0) {
                        setLoading(false)
                        setUserCreated(true)
                        props.callBack&& props.callBack()
                        setError(requestError, {})
                    }

                })
                .catch(() => {
                    setLoading(false)
                    setError(requestError,
                        {message: 'Technical error occurred while updating user info, please try again later'})
                })
        }
    }

    return (
        <div className={'add-user-component'}>
            {
                !loading &&

                <>
                    {
                        !userCreated && !loading && (
                            <>
                                <div className={'add-user-component-input-wrapper'}>

                                    <div className={'arrange-in-twos'}>
                                        <InputField id={'firstname'}
                                                    name={'firstname'}
                                                    label={'Firstname'}
                                                    placeholder={'Firstname'}
                                                    defaultValue={newUser?.firstname}
                                                    onChange={newUserInputHandler}
                                                    register={register}
                                                    errors={errors}
                                                    required={'Firstname is required'}
                                                    pattern={{
                                                        value: /[a-zA-Z]{2,}$/,
                                                        message: 'only characters (minimum length of 3) are allowed'
                                                    }}
                                                    min={2}
                                                    minLength={2}
                                                    animate={true}
                                        />

                                        <InputField id={'lastname'}
                                                    name={'lastname'}
                                                    defaultValue={newUser.lastname}
                                                    placeholder={'Lastname'}
                                                    label={'Lastname'}
                                                    onChange={newUserInputHandler}
                                                    register={register}
                                                    errors={errors}
                                                    required={'Lastname is required'}
                                                    pattern={{
                                                        value: /[a-zA-Z]{2,}$/,
                                                        message: 'only characters (minimum length of 3) are allowed'
                                                    }}
                                                    min={2}
                                                    minLength={2}
                                                    animate={true}
                                        />
                                    </div>

                                    <div className={'arrange-in-twos'}>
                                        <InputField id={'email'}
                                                    name={'email'}
                                                    defaultValue={newUser.email}
                                                    label={'Email'}
                                                    placeholder={'Email'}
                                                    onChange={newUserInputHandler}
                                                    register={register}
                                                    errors={errors}
                                                    required={props.isEdit ? false : 'Email is required'}
                                                    pattern={{
                                                        value: /(^["a-zA-Z0-9#!$%&'*+-\/=?^_`{}|~]+[.a-zA-Z0-9#!$%&'*+-\/=?^_`{}|~"]+[@]+[.a-zA-Z0-9_-]+[.]+[a-zA-Z]{2,})/,
                                                        message: "A valid email address is required e.g. (yourEmail@gmail.com, email@yahoo.com)"
                                                    }}
                                                    min={9}
                                                    minLength={9}
                                                    animate={true}
                                                    disabled={props.isEdit}
                                        />

                                        <InputField id={'username'}
                                                    name={'username'}
                                                    defaultValue={newUser.username}
                                                    label={'Username'}
                                                    placeholder={'Username'}
                                                    onChange={newUserInputHandler}
                                                    register={register}
                                                    errors={errors}
                                                    required={props.isEdit  ? false : 'Username is required'}
                                                    pattern={{
                                                        value: /.*[a-zA-Z0-9]{4,}$/,
                                                        message: 'only characters (minimum length of 4) are allowed'
                                                    }}
                                                    min={4}
                                                    minLength={4}
                                                    animate={true}
                                                    disabled={props.isEdit}
                                        />
                                    </div>

                                    <div className={'arrange-in-twos'}>
                                        <InputField
                                            id="password"
                                            name="password"
                                            defaultValue={newUser.password}
                                            onChange={newUserInputHandler}
                                            errors={errors}
                                            placeholder={"Password"}
                                            label={"Password"}
                                            pattern={{
                                                value: /^[a-zA-Z0-9!#@]{8,24}$/,
                                                message: "Password must be min. of 8 alphanumeric value. Special character allowed are !#@",
                                            }}
                                            register={register}
                                            min={8}
                                            minLength={8}
                                            animate={true}
                                            type={'password'}
                                        />

                                        <InputField id={'phoneNumber'}
                                                    name={'phoneNumber'}
                                                    defaultValue={newUser.phoneNumber}
                                                    placeholder={'Phone Number'}
                                                    label={'Phone Number'}
                                                    onChange={newUserInputHandler}
                                                    register={register}
                                                    errors={errors}
                                                    required={'Phone number is required'}
                                                    pattern={{
                                                        value: /^(^[+][0-9]{6,15}|^[0-9]{6,15})$/,
                                                        message: "Phone number in one of these formats (08012345678 or " +
                                                            "+2348012345678) is required"
                                                    }}
                                                    min={6}
                                                    minLength={6}
                                                    animate={true}
                                        />
                                    </div>

                                    <div className={'arrange-in-twos'}>
                                        <Dropdown
                                            id="departmentName"
                                            name="department"
                                            defaultValue={newUser.department}
                                            onChange={newUserInputHandler}
                                            errors={errors}
                                            placeholder={
                                                newUser.department || 'Select department'
                                            }
                                            getSelectedOptionValue={(value) =>
                                                setNewUser({ ...newUser, department: value })
                                            }
                                            options={departmentOptions}
                                            label="Department"
                                        />

                                        <InputDate
                                            id="dob"
                                            name="dob"
                                            defaultValue={newUser.dob}
                                            placeholder={newUser.dob ?? "Date of Birth"}
                                            label="Date of Birth"
                                            required='Date of birth is required'
                                            onChange={newUserInputHandler}
                                            register={register}
                                            errors={errors}
                                            animate={true}
                                            pattern={{
                                                value: /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(19|20)\d\d$/,
                                                message: "Date format allowed is dd-mm-yyyy, e.g: 20-03-2024",
                                            }}
                                        />
                                    </div>

                                    <div className={'arrange-in-twos'}>
                                        <InputField id={'address'}
                                                    name={'address'}
                                                    defaultValue={(newUser.address) ? `${newUser?.address?.streetHouseNr}, ${newUser?.address?.postcode} ${newUser?.address?.state}, ${newUser?.address?.country}` : ''}
                                                    placeholder={(newUser.address) ? (`${newUser.address?.streetHouseNr}, ${newUser.address?.postcode} ${newUser.address?.state}, ${newUser.address?.country}`) : ('21 udo club, 22222 Enugu, Nigeria')}
                                                    label={'Address'}
                                                    onChange={newUserInputHandler}
                                                    register={register}
                                                    errors={errors}
                                                    pattern={{
                                                        value: /^(?:No\s*)?(\d{1,5})?\s*([a-zA-Z\s]+?)\s*(\d{1,5})?,\s*(\d{5})\s*([a-zA-Z\s]+),\s*([a-zA-Z\s]+)$/,
                                                        message: "Min. 8 characters in format 'Udo club 21, 22200 Enugu, Nigeria'"
                                                    }}
                                                    min={8}
                                                    minLength={8}
                                                    animate={true}
                                                    required={'Address is required'}
                                        />
                                    </div>

                                    <div className={'add-user-component-label-checkbox'}>

                                        <label className={'add-user-label'}>
                                            <strong>Assign user role(s)</strong> <sup style={{color: 'red'}}>*</sup>
                                        </label>

                                        <div className={'add-user-component-checkbox-wrapper'}>
                                            <Checkbox id={'receptionist'}
                                                      name={'receptionist'}
                                                      checked={newUserRole.receptionist}
                                                      label={'Receptionist'}
                                                      onChange={newUserRoleHandler}
                                                      register={register}
                                                      errors={errors}
                                            />

                                            <Checkbox id={'nurse'}
                                                      name={'nurse'}
                                                      checked={newUserRole.nurse}
                                                      label={'Nurse'}
                                                      onChange={newUserRoleHandler}
                                                      register={register}
                                                      errors={errors}
                                            />

                                            <Checkbox id={'doctor'}
                                                      name={'doctor'}
                                                      checked={newUserRole.doctor}
                                                      label={'Doctor'}
                                                      onChange={newUserRoleHandler}
                                                      register={register}
                                                      errors={errors}
                                            />

                                            <Checkbox id={'cashier'}
                                                      name={'cashier'}
                                                      checked={newUserRole.cashier}
                                                      label={'Cashier'}
                                                      onChange={newUserRoleHandler}
                                                      register={register}
                                                      errors={errors}
                                            />

                                            <Checkbox id={'hod'}
                                                      name={'hod'}
                                                      checked={newUserRole.hod}
                                                      label={'HOD'}
                                                      onChange={newUserRoleHandler}
                                                      register={register}
                                                      errors={errors}
                                            />

                                            <Checkbox id={'admin'}
                                                      name={'admin'}
                                                      checked={newUserRole.admin}
                                                      label={'Admin'}
                                                      onChange={newUserRoleHandler}
                                                      register={register}
                                                      errors={errors}
                                            />

                                            <Checkbox id={'superAdmin'}
                                                      name={'superAdmin'}
                                                      register={register}
                                                      checked={newUserRole.superAdmin}
                                                      label={'Super Admin'}
                                                      onChange={newUserRoleHandler}
                                                      errors={errors}
                                                      disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={'add-user-component-btn-wrapper'}>
                                    <p><span style={{color: 'red'}}>*</span> Required fields</p>
                                    <Button id={'save-user'} name={'save-user'} buttonName={props.buttonName ?? 'Save'}
                                            onClick={saveNewUserHandler}/>
                                </div>
                            </>
                        )
                    }

                    {
                        userCreated && (
                            <div className={'user-created-success'}>
                                <p className={'text-icon-success'}>
                                    {`User profile ${props.isEdit ? 'edited' : 'created'} successfully`}
                                    <span><BiCheck className={'span-icon'}/></span>
                                </p>

                                <p style={{marginTop: '30px'}}>
                                    {
                                        !props.isEdit ?
                                            'A verification code email has been sent to the new user email address.\n' +
                                            'The user should follow the link in the email to confirm the email address.'
                                            : 'User profile updated successfully'
                                    }
                                </p>
                            </div>
                        )
                    }

                    {
                        errors && errors[requestError]?.message &&
                        <ErrorNotification name={requestError} stringErrors={String(errors[requestError]?.message)}/>
                    }
                </>
            }

            {
                loading && <Spinner/>
            }
        </div>
    )
}