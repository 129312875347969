import { ApiService } from "./ApiService";
import { NewDepartmentDto } from "../types/department/Department";
import { DepartmentDao } from "../types/department/DepartmentDao";

const baseUrl = `${process.env.ACC_PROXY ?? process.env.REACT_APP_ACC_PROXY}`;

class DepartmentService extends ApiService {
  /** Creates new department and the categories under the department */
  async createNewDepartment(
    departmentModal: NewDepartmentDto
  ): Promise<DepartmentDao> {
    const response = await fetch(baseUrl + `department`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(departmentModal),
    });

    const registeredDepartment = await this.apiResponseHandler(response);
    return await registeredDepartment.json();
  }

  /** Creates new department and the categories under the department */
  async updateDepartment(departmentModal: NewDepartmentDto): Promise<DepartmentDao> {
    const response = await fetch(baseUrl + `department`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(departmentModal),
    });

    const registeredDepartment = await this.apiResponseHandler(response);
    return await registeredDepartment.json();
  }

  async getDepartments(hospitalId: number): Promise<DepartmentDao[]>{
    const response = await fetch(baseUrl + `department/${hospitalId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const departmentList = await this.apiResponseHandler(response);
    return await departmentList.json();
  };

  async getDepartmentByName(hospitalId: number, departmentName: string): Promise<DepartmentDao> {
    const response = await fetch(`${baseUrl}department/${hospitalId}/department?name=${departmentName}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const department = await this.apiResponseHandler(response);
    return await department.json();
  };

  async deleteDepartment(hospitalId: number, departmentId: number, userId: number): Promise<boolean> {
    const response = await fetch(`${baseUrl}department/${hospitalId}/${departmentId}/${userId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const department = await this.apiResponseHandler(response);
    return await department.json();
  };
}

const departmentService =  new DepartmentService();
export default departmentService;
