import InvoiceDao from "../invoice/InvoiceDao";

export interface PieProps {
  name: string;
  value: number | undefined;
  colorShade: string;
}

export interface PieChartProps {
  colors: string[];
  data: PieProps[];
}

export interface RenderCustomLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}

export interface BarChartProp {
  name: string;
  patients: number;
  departments: number;
  clinic: number;
  admission: number;
}

export interface HorizontalBarChartProps {
  data: BarChartProp[];
}

export interface SalesDOTResponse {
  todayTotalAmountPaid: number;
  todayTotal: number;
  listOfTodaySales: InvoiceDao[];
  hospitalTotalAmountPaid: number;
  hospitalTotalRevenue: number;
  lossGainPercentage: number;
}

export enum AgeRange {
  ZERO_TO_FIFTEEN = "0-15 years",
  SIXTEEN_TO_THIRTY_TWO = "16-32 years",
  THIRTY_THREE_TO_FORTY_NINE = "33-49 years",
  FIFTY_AND_ABOVE = "50-Above",
}

export type PatientAgeRange = {
  ZERO_TO_FIFTEEN?: number;
  SIXTEEN_TO_THIRTY_TWO?: number;
  THIRTY_THREE_TO_FORTY_NINE?: number;
  FIFTY_AND_ABOVE?: number;
};

export interface ClinicState {
  waitingToSeeDoctor: number;
  seenDoctor: number;
  checkedOut: number;
}

export interface AdmissionDTO {
  totalNumberOfPatients: number;
  numOfPatientsInAdmission: number;
  numOfPatientsInClinic: number;
  numOfPatientsInEmergency: number;
  clinicState: ClinicState;
}

export interface AdmissionAnalyticsDto {
  patientsAgeRange: PatientAgeRange;
  admissionDto: AdmissionDTO;
}

export enum DashboardModalList {
  Total_Revenue = "Total Revenue",
  Total_Staff = "Total Staffs",
  Number_of_Patient = "Number of Patient",
  Total_Patient_on_Treatment = "Total Patient on Treatment",
  Sales = "Sales",
  Daily_Sales_and_Services = "Daily sales and services",
  Patient_on_admission_and_clinic = "Patient on admission and clinic",
  Admitted_patient_Age_range = "Admitted patient Age range",
  Emergency = "Emergency",
  Notification = "Notification",
  Admin_online = "Admin online",
  Bar_Chart_for_patients = "Bar Chart for patients",
}

export interface SalesTableDTO {
  invoice: number;
  salesTitle: string;
  salesDate: Date | string;
  patientName: string;
  phone: string;
  modeOfPayment: string;
  cost: string;
  balance: string;
}
