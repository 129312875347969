export abstract class ApiService {
	async apiResponseHandler(response: Response) {
		if (response.ok) {
			return response;
		}
		if (response.status === 401) {
			await fetch('host/services/api/token/logout', {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				method: 'POST',
			});
			window.location.assign('/');
			return Promise.reject('Unauthorized request');
		} else {
			throw new Error(response.status.toString());
		}
	}
}
