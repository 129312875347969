
export const Notifications = () => {

    return (
        <div>
            <h2>Notification Page</h2>

            <p>New user created</p>
            <p>New patient registered</p>
            <p>New payment made</p>
        </div>
    )
}