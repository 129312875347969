import { UUID } from "crypto";

export type AppointmentDto = {
  id?: UUID;
  title: string;
  reason: ReasonForAppointment;
  patientId: number;
  patientName: string;
  patientEmail: string;
  hospitalId: number;
  hospitalName: string;
  personnelName: string;
  createdBy: number;
  personnelId: number;
  personnelEmail: string;
  appointmentTime: string;
  appointmentDate:string;
  dateCreated: string;
  lastUpdated: string;
  status: AppointmentStatus;
  emailSentToPersonnel: boolean;
  emailSentToPatient: boolean;
};

export enum ReasonForAppointment {
  CONSULTATION = "CONSULTATION",
  SURGERY = "SURGERY",
  IMMUNIZATION = "IMMUNIZATION",
  ANTENATAL = "ANTENATAL",
  POSTNATAL = "POSTNATAL",
  CHILD_DELIVERY = "CHILD_DELIVERY",
  CLINIC = "CLINIC",
  LABORATORY = "LABORATORY",
  CHECK_UP = "CHECK_UP",
  ADMISSION = "ADMISSION"
}

export enum AppointmentStatus {
  APPROVED = "APPROVED",
  WAITING_APPROVAL = "WAITING_APPROVAL",
  DECLINED = "DECLINED",
  ACCEPTED = "ACCEPTED",
}
