import "./Footer.scss";
import { Button } from "../../components/button/Button";

export const Footer = () => {
  const isDashboard = window.location.href.includes('dashboard')
  return (
    <div className={`footer-component-container ${isDashboard ? 'dashboard_footer' : ''}`}>
      <Button
        id="get-started"
        name="get-started"
        buttonName="Get Started"
        onClick={() => (window.location.href = "/register")}
      />
      <div className="footer_right">
        <p>Copy Right &copy; {new Date().getFullYear()}</p>
        <a href={"/privacy-policy"}>Privacy policy</a>
        <a href={"/terms-of-use"}>Terms of use</a>
        <div className={"powered-by"}>
          <p>powered by : </p>
          <a href={"https://atrizult.com"} target="_blank" rel="noreferrer">
            AtRiZult
          </a>
        </div>
      </div>
    </div>
  );
};
