import React from "react";

const TreatmentIcon = () => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.75 12H12.75C12.3523 12.0004 11.971 12.1586 11.6898 12.4398C11.4086 12.721 11.2504 13.1023 11.25 13.5V18H3V10.5H1.5V22.5H3V19.5H21V22.5H22.5V15.75C22.4988 14.7558 22.1033 13.8027 21.4003 13.0997C20.6973 12.3967 19.7442 12.0012 18.75 12ZM21 18H12.75V13.5H18.75C19.3466 13.5006 19.9185 13.7378 20.3403 14.1597C20.7622 14.5815 20.9994 15.1534 21 15.75V18Z" fill="black"/>
<path d="M7.125 12.75C7.34751 12.75 7.56501 12.816 7.75002 12.9396C7.93502 13.0632 8.07922 13.2389 8.16437 13.4445C8.24951 13.65 8.27179 13.8762 8.22838 14.0945C8.18498 14.3127 8.07783 14.5132 7.9205 14.6705C7.76316 14.8278 7.56271 14.935 7.34448 14.9784C7.12625 15.0218 6.90005 14.9995 6.69448 14.9144C6.48892 14.8292 6.31321 14.685 6.1896 14.5C6.06598 14.315 6 14.0975 6 13.875C6.0004 13.5768 6.11905 13.2908 6.32994 13.0799C6.54084 12.8691 6.82675 12.7504 7.125 12.75ZM7.125 11.25C6.60583 11.25 6.09831 11.404 5.66663 11.6924C5.23495 11.9808 4.8985 12.3908 4.69982 12.8705C4.50114 13.3501 4.44915 13.8779 4.55044 14.3871C4.65173 14.8963 4.90173 15.364 5.26885 15.7312C5.63596 16.0983 6.10369 16.3483 6.61289 16.4496C7.12209 16.5508 7.64989 16.4989 8.12954 16.3002C8.6092 16.1015 9.01917 15.7651 9.30761 15.3334C9.59605 14.9017 9.75 14.3942 9.75 13.875C9.75 13.1788 9.47344 12.5111 8.98116 12.0188C8.48887 11.5266 7.82119 11.25 7.125 11.25ZM15.75 4.5H12.75V1.5H11.25V4.5H8.25V6H11.25V9H12.75V6H15.75V4.5Z" fill="black"/>
</svg>
};

export default TreatmentIcon;
