const Handshake = () => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5922 10.8618L18.2058 5.03409L15.5334 6.67615C14.2134 6.45077 13.8592 6.45077 12.8289 5.96781C12.2171 5.67804 11.4444 5.87122 11.058 6.0966C10.4785 5.48485 6.71139 6.38638 6.29282 6.57956C5.16592 6.22539 3.49166 4.9375 3.49166 4.9375L2.91211 11.5057L3.68484 11.6989C3.74924 11.9243 3.94242 12.4717 3.94242 12.4717C3.23408 13.3732 4.07121 14.4357 4.87614 14.0815C4.52197 14.983 5.45569 15.8202 6.19623 15.2406C6.09964 16.0778 6.96897 16.6895 7.61291 16.11C7.32313 17.1081 8.45004 17.8486 9.12618 17.0437C9.60914 17.3656 10.0599 17.6232 10.4463 17.752C11.1224 18.0418 11.8629 17.3978 11.7342 16.5929C12.5069 17.4944 13.666 16.6251 13.4084 15.627C14.2777 16.3031 15.3403 15.1762 14.7929 14.1781C15.7588 14.7255 16.5959 13.2444 15.6622 12.2785L18.5922 10.8618Z"
        fill="#FFDD67"
      />
      <path
        d="M4.94174 11.7978C5.4247 12.0232 5.74667 12.7637 5.23151 13.4077L5.00613 13.6653C4.74855 13.9872 4.49098 14.0838 4.2334 14.0838C4.42658 14.1804 4.65196 14.1804 4.87734 14.0838C4.90954 13.9872 4.97394 13.8584 5.07053 13.7619L5.29591 13.5043C5.4247 13.3433 5.58568 13.2145 5.71447 13.1501C5.97205 12.474 5.52129 11.83 4.94174 11.7978ZM8.61223 14.9854C9.12738 15.1141 9.51375 15.7903 9.12738 16.5308L8.9342 16.8206C8.74102 17.1748 8.48344 17.3358 8.22586 17.3679C8.58003 17.4645 8.9664 17.3358 9.22398 16.8528L9.41716 16.563C9.90012 15.6615 9.28837 14.921 8.61223 14.9854ZM6.35842 14.7278L6.87357 13.9228C6.84138 13.4721 6.45501 13.0857 6.06864 13.0535C6.5516 13.2789 6.87357 14.0194 6.35842 14.6634L6.13304 14.921C5.87546 15.2429 5.61788 15.3395 5.32811 15.3395C5.58568 15.4683 5.87546 15.4361 6.16523 15.2429C6.19743 15.0819 6.26183 14.8888 6.35842 14.7278ZM7.7429 13.2789C8.25806 13.4399 8.61223 14.1482 8.16146 14.8566L7.54972 15.8225C7.32434 16.1766 7.06676 16.3054 6.80918 16.3054C7.13115 16.402 7.54972 16.3054 7.83949 15.8547L8.45124 14.8888C8.9664 14.0516 8.38685 13.2467 7.7429 13.2789ZM12.0251 14.0838C12.0573 14.0838 12.4437 14.0194 12.5725 14.0194C12.0895 13.5365 11.3168 13.9228 10.866 13.6653C11.188 14.2126 11.8642 14.0838 11.9285 14.0838L12.7013 15.0819C13.3452 15.8869 12.8301 16.8206 12.1539 16.9172C12.8623 17.1426 13.5706 16.402 13.3452 15.5971C13.313 15.5649 12.0251 14.0838 12.0251 14.0838ZM12.7979 12.2164C12.7979 12.2164 13.2808 12.1198 13.6994 12.1198C13.2164 11.7334 12.4759 12.0876 11.9929 11.7656C12.1861 12.1842 12.6047 12.1842 12.7335 12.2164L14.2146 13.9228C14.8907 14.6956 14.4077 15.6293 13.7316 15.7903C14.5043 16.0157 15.2449 15.0175 14.7619 14.1482C14.4399 14.0194 12.7979 12.2164 12.7979 12.2164Z"
        fill="#EBA352"
      />
      <path
        d="M11.7673 15.9178C11.2843 15.5315 10.6082 15.8212 10.3184 15.6281C10.4794 16.0788 11.0912 15.9822 11.1234 15.9822C11.6707 16.8194 11.0912 17.6887 10.415 17.7531C11.0912 18.0429 11.8317 17.3989 11.7029 16.594C11.6707 16.5618 11.22 15.9822 11.22 15.9822C11.2843 15.95 11.6385 15.9178 11.7673 15.9178ZM18.239 10.3155C18.239 10.3155 17.6916 11.4424 15.5666 12.0542C15.2768 11.7 14.6651 10.9917 13.9567 10.3799C14.4075 10.2833 14.6329 9.92914 14.987 9.86475C14.8582 9.73596 14.4075 9.80035 14.0533 9.92914C13.4094 10.1545 11.9283 9.12421 11.9283 9.12421L10.7048 9.2852C9.83547 10.1223 7.96803 10.5731 7.87144 9.2852C7.80704 8.38367 9.77107 7.77192 10.3828 7.19237C11.1556 6.45184 11.8317 5.51811 13.0874 6.16206C14.1177 6.67722 14.5363 6.64502 15.9529 6.9026C16.4359 6.99919 16.7579 7.22457 17.1121 7.70753C16.8223 7.03139 16.4037 6.74161 15.8886 6.64502L18.1424 5.58251L18.1746 5.03516L15.5022 6.54843C14.2787 6.35524 13.8279 6.35524 12.8298 5.87229C12.1859 5.55031 11.6707 5.6469 11.2521 5.90448C10.4794 5.45372 10.0287 5.55031 9.73888 5.61471C8.83735 5.90448 7.38848 5.93668 6.10059 6.45184C6.32597 6.51623 6.55135 6.58063 6.71233 6.58063C7.96803 6.19426 9.28812 6.22645 9.89986 6.00107C10.093 5.93668 10.4794 5.93668 10.898 6.16206C10.6404 6.38744 10.415 6.64502 10.1574 6.9026C9.54569 7.48215 7.67825 8.31928 7.80704 9.38179C7.87144 9.89694 8.57978 11.2492 10.9302 9.73596L11.9283 9.60717C11.9283 9.60717 13.7635 10.4765 15.47 12.4727C16.1461 13.2455 15.7276 14.0826 15.0514 14.2758C15.9208 14.4368 16.6613 13.2133 15.8242 12.3761L17.8526 11.2814L18.239 10.3155Z"
        fill="#EBA352"
      />
      <path
        d="M17.6583 5.35938L18.7208 10.8651L17.8515 11.2515C17.4007 10.0602 16.7246 7.19462 16.8856 5.81014L17.6583 5.35938Z"
        fill="#3B946F"
      />
      <path
        d="M4.03901 12.3434C3.87803 11.957 3.78144 11.4419 3.78144 11.4419L2.9765 10.8945L2.91211 11.5063L3.62045 11.6995C3.71704 12.0214 3.81363 12.279 3.94242 12.4722L4.03901 12.3434Z"
        fill="#EBA352"
      />
      <path
        d="M2.42871 11.4115L4.16736 5.39062L4.9723 5.93798C5.10108 6.8395 3.62001 10.9608 3.07266 11.5725L2.42871 11.4115Z"
        fill="#3B946F"
      />
      <path
        d="M17.6256 5.32602C17.368 5.487 18.3017 11.0571 18.6881 10.8961C19.9116 10.3488 20.459 10.1878 20.459 10.1878V3.55517C20.4268 3.55517 19.6862 4.10252 17.6256 5.32602ZM1.1084 3.00781V11.2181C1.1084 11.2181 1.68795 11.3147 2.42849 11.4757C2.84705 11.5723 4.36032 5.5192 4.26373 5.42261C3.16902 4.68207 1.1084 3.00781 1.1084 3.00781Z"
        fill="#47B892"
      />
    </svg>
  );
};

export default Handshake;
