import React, {useState} from 'react';
import './CalendarDropDown.scss';
import {Button} from "../../../../components/button/Button";
import {getYearList} from "../../../../util/UserRolesHelper";

const yearList = getYearList();

interface Props {
    getSelectedYear?(selectedYear: number): any
}

const CalendarDropDown = ({getSelectedYear}: Props) => {
    const [selectedYear, setSelectedYear] = useState<number>()
    const [open, setOpen] = useState<boolean>(false)

    const onClickHandler = (yearClicked: number) => {
        getSelectedYear && getSelectedYear(yearClicked)
        setSelectedYear(yearClicked);
        setOpen(false);
    }

    const onMouseLeaveHandler = () => {
        setOpen(false);
    }

    return (
        <div className='calendar_dropdown'>
            <div className={'calendar-add-btn-w'}>
                <Button
                    onClick={() => setOpen(!open)}
                    name={'admit-patient'}
                    id={'admit-patient'}
                    buttonName={'+ Add Year'}
                />
            </div>

            <div>
                {
                    open &&
                    <div className={'calendar-design-w'}>
                        <section className='calendar-years-content'>
                            <ul className='calendar_dropdown-w'>
                                {
                                    yearList.map((year) => (
                                        <li key={year }
                                            onClick={() => onClickHandler(year)}
                                            data-isselected={year === selectedYear}
                                        >
                                            {year}
                                        </li>
                                    ))
                                }
                            </ul>
                        </section>
                    </div>
                }
            </div>
        </div>
    )
};

export default CalendarDropDown;
