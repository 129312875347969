import './InvoiceHeader.scss'
import {PaymentBar} from "../paymentbar/PaymentBar";
import {PaymentMethod, PaymentStatus} from "../../types/Helper";
import {getTodaySDate,} from "../../util/Helpers";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import PatientDao from "../../types/PatientDao";
import {useWhoAmI} from "../../context/UserCtx";
import {ErrorNotification} from "../errornotification/ErrorNotification";
import {Button} from "../../components/button/Button";
import {ParamsEnum} from "../../util/ParamsEnum";
import imageService from "../../services/imageservices/ImageService";

type InvoiceHeaderType = '' | 'invoice' | 'invoiceSummary'

interface InvoiceHeaderProps {
    page: InvoiceHeaderType,
    paymentStatus?: PaymentStatus,
    paymentMethod?: PaymentMethod,
    getPaymentStatus?(status: PaymentStatus): any,
    getPaymentMethod?(method: PaymentMethod): any,
    invoiceNo?: number,
    invoiceDate?: string
}

export const InvoiceHeader = (props: InvoiceHeaderProps) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
    const location = useLocation();
    const {patientDao, address} = location?.state?.patient ?? {} as PatientDao;
    const user = useWhoAmI();
    const [invoiceError, setInvoiceError] = useState<string>('')
    const [logo, setLogo] = useState<string>();
    const hospitalOwner = 'Dr. Martin Chukwunwike'

    const {streetHouseNr, postcode, state, country} = user?.hospital?.address;

    const getLogo = async () => {
        imageService.getHospitalLogo(user?.hospital.id)
            .then((logoSrc) => {
                setLogo(logoSrc)
            }).catch(() => {
                setInvoiceError('Error occurred while loading hospital logo')
        })
    }

    const handleWindowSizeChange = () => {
        setIsMobile(window.innerWidth < 960);
    }

    useEffect(() => {
        getLogo().then();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const getSelectedMethod = (method: PaymentMethod) => {
        props.getPaymentMethod && props.getPaymentMethod(method)
    }

    const getSelectedStatus = (status: PaymentStatus) => {
        props.getPaymentStatus && props.getPaymentStatus(status)
    }

    return (
        <div>
            {!invoiceError && <div className={'invoice-header-wrapper'}>
                <div className={'invoice-company-customer-info-wrapper'}>
                    <div>
                        <div className={'invoice-company-info-section'}>
                            {/*Do not delete, used for styling*/}
                            <div className={!isMobile ? 'company-info-box' : ''}></div>
                            <div className={'company-info-box'}>
                                <div className={'invoice-logo-company-name'}>
                                    <img src={logo} alt={'logo'}/>
                                    <h3>{user?.hospital?.name ?? ''}</h3>
                                </div>
                                {/*TODO: has to come from env variable, DISPLAY_OWNERS_NAME*/}
                                <i>{hospitalOwner}</i>
                                <hr style={{borderBottom: '1px solid #0492C2', width: '100%'}}/>
                                <div>
                                    <p>{`${streetHouseNr}`}</p>
                                    <p>{`${postcode} ${state}`}</p>
                                    <p>{`${country}`}</p>
                                    <p>Tel.: {user?.hospital?.phone}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'invoice-customer-info-section'}>
                        <div className={'company-info-box'}>
                            <h3>{`${patientDao?.lastname} ${patientDao?.firstname}`}</h3>
                            <p>{address?.streetHouseNr}</p>
                            <p>{address?.state}</p>
                            <p>{address?.country}</p>
                        </div>
                        <div className={'company-info-box'}>
                            <div className={'invoice-details-left-right'}>
                                <div className={'invoice-details-left'}>
                                    <p>Date: </p>
                                    <p>Invoice No: </p>
                                </div>
                                <div className={'invoice-details-right'}>
                                    <p>{props?.invoiceDate ?? getTodaySDate()}</p> {/*TODO: should be printedDate, so add printedDate to invoice table*/}
                                    <p>{props?.invoiceNo}</p>
                                </div>
                            </div>
                            <i style={{fontSize: '10px'}}>(Please enter the invoice no in Bank Transfer)</i>
                        </div>
                    </div>
                </div>

                {
                    props.page === 'invoice' && (
                        <div className={'invoice-payment-bar-wrapper'}>
                            <PaymentBar checkboxesNames={[
                                {name: PaymentStatus.PAID, label: PaymentStatus.PAID},
                                {name: PaymentStatus.WAIVER, label: PaymentStatus.WAIVER},
                                {name: PaymentStatus.INSTALLMENT, label: PaymentStatus.INSTALLMENT}
                            ]}
                                        label={'Payment Status'}
                                        getSelectedOption={getSelectedStatus}
                            />
                            <PaymentBar checkboxesNames={[
                                {name: PaymentMethod.CASH, label: PaymentMethod.CASH},
                                {name: PaymentMethod.POS, label: PaymentMethod.POS},
                                {name: PaymentMethod.TRANSFER, label: PaymentMethod.TRANSFER},
                                {name: PaymentMethod.OTHERS, label: PaymentMethod.OTHERS}
                            ]}
                                        label={'Payment Method'}
                                        getSelectedOption={getSelectedMethod}
                            />
                        </div>
                    )
                }
                {
                    props.page === 'invoiceSummary' && props.paymentStatus && (
                        <div className={'payment-status-method-wrapper'}>
                            <div>
                                <p><strong>Payment Status:</strong></p>
                                <p><strong>Payment Method:</strong></p>
                            </div>
                            <div className={'left-pad-selected-options'}>
                                <p>{props.paymentStatus}</p>
                                <p>{props.paymentMethod}</p>
                            </div>
                        </div>
                    )
                }

            </div>
            }
            {
                invoiceError &&
                <div>
                    <ErrorNotification name={'patient-not-defined'} stringErrors={invoiceError} />
                    <Button id={'go-back'} name={'go-back'}
                            buttonName={'Go Back'}
                            onClick={() => window.location.href = ParamsEnum.PATIENTS}
                    />
                </div>
            }
        </div>
    )
}