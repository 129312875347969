import { ParamsEnum } from "../util/ParamsEnum";
import { Authorities } from "../userAuth/WhoAmI";
import {Address} from "./NewPatientData";

export type CookieType =
  | "is-user-logged"
  | "customerInfo"
  | "companyInfo"
  | ParamsEnum.ACCESS_TOKEN;

export enum Gender {
  MALE = "Male",
  FEMALE = "Female",
}

export enum MaritalStatus {
  SINGLE = "Single",
  IN_RELATIONSHIP = "In a Relationship",
  MARRIED = "Married",
}

export enum Religion {
  CHRISTIANITY = "Christianity",
  ISLAM = "Islam",
  TRADITIONALIST = "Traditionalist",
  OTHERS = "Others",
}

export enum PaymentStatus {
  PAID = "Paid",
  WAIVER = "Waiver",
  INSTALLMENT = "Installment",
}

export enum PaymentMethod {
  CASH = "Cash",
  TRANSFER = "Transfer",
  POS = "POS",
  OTHERS = "Others",
}

export type RegistrationData = {
  email: string;
  password: string;
  confirmPassword: string;
  firstname: string;
  lastname: string;
  username: string;
  phoneNumber: string;
  privacyConsent:boolean
};

export type UserData = {
  username: string;
  password: string;
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber: string;
  department: string;
  dob: string;
  address: Address;
  active: string | boolean;
  authorities: Authorities[];
};

export type ContactInfo = {
  fullName: string;
  email: string;
  phoneNumber: string;
  message: string;
  topic: string;
};

export enum Topic {
  ENQUIRY = "ENQUIRY",
  SALES = "SALES",
  SUPPORT = "SUPPORT",
}

export enum PurposeOfVisit {
  CLINIC = "Clinic",
  CHILD_DELIVERY = "Child Delivery",
  MATERNITY = "Maternity",
  IMMUNIZATION = "Immunization",
  CHECK_UP = "Check up",
  SURGERY = "Surgery",
  LAB = "Laboratory",
  PRESCRIPTION = "Drug prescription",
  OTHERS = "Others",
}

export type KnownUser = {
  userId: number;
  hospitalId: number;
};
