import './AdmitPatientInClinic.scss'
import {Dropdown, DropdownOptionsType} from "../../../components/dropdown/Dropdown";
import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import PatientDao from "../../../types/PatientDao";
import {
    admissionStatusMapper,
    calculateAge,
    capitalizeFirstCharacter,
    dateFormatArrDDMMYYYY,
    getDate,
    getPatientCategory,
    getTime
} from "../../../util/Helpers";
import {TextArea} from "../../../components/textarea/TextArea";
import {useCustomNavigate} from "../../../customhook/CustomUseNavigate";
import {Checkbox} from "../../../components/checkbox/Checkbox";
import {AdmissionType} from "../../../types/admission/AdmissionType";
import {InputField} from "../../../components/inputfield/InputField";
import {useWhoAmI} from "../../../context/UserCtx";
import AdmissionDao from "../../../types/admission/AdmissionDao";
import NewAdmissionDto from "../../../types/admission/NewAdmissionDto";
import {Button} from "../../../components/button/Button";
import admissionService from "../../../services/AdmissionService";
import {ErrorNotification} from "../../../modules/errornotification/ErrorNotification";
import {AdmissionState} from "../../../types/admission/AdmissionState";
import {ParamsEnum} from "../../../util/ParamsEnum";
import {getAllDoctors, userHasRole, userIsDoctor} from "../../../util/UserRolesHelper";
import {UserDto} from "../../../types/UserDto";
import {Modal} from "../../../components/modal/Modal";
import {AddExtraNote} from "../addextranote/AddExtraNote";
import {Authorities} from "../../../userAuth/WhoAmI";
import InvoiceDao from "../../../types/invoice/InvoiceDao";
import invoiceService from "../../../services/InvoiceService";
import userService from "../../../services/UserService";
import SuccessResponse from "../../customer/MedicalHistory/YearlyFolder/MonthlyFolder/SuccessResponse/SuccessResponse";
import {InputSearch} from "../../../components/inputsearch/InputSearch";
import {OfferedServiceDto} from "../../../types/services/ServiceTypes";
import {Spinner} from "../../../components/spinner/Spinner";

interface AdmitPatientProps {
    patient: PatientDao,
    newAdmissionDto?: NewAdmissionDto,
    activeAdmission?: AdmissionDao,
    paidAdmissionFee?: boolean,
    paidConsultationFee?: boolean,
}

export const AdmitPatientInClinic = (props: AdmitPatientProps) => {
    const loggedInUser = useWhoAmI();
    const isDoctor = userIsDoctor(loggedInUser);
    const patientProfilePath = ParamsEnum.PATIENT_PROFILE;
    const patientListPath = ParamsEnum.PATIENTS;
    const activeDoctor = isDoctor ? loggedInUser.firstname + ' ' + loggedInUser.lastname : '';
    const [admissionType, setAdmissionType] = useState<AdmissionType>(AdmissionType.CLINIC);
    const [patient, setPatient] = useState<PatientDao>(props.patient)
    const {patientDao} = {...patient}
    const {register, trigger, setError, formState: {errors}} = useForm({mode: 'onBlur'});
    const [payments, setPayments] = useState<InvoiceDao>({} as InvoiceDao);
    const [admitError, setAdmitError] = useState<string>();
    const navigate = useCustomNavigate();
    const [newAdmissionDto, setNewAdmissionDto] = useState<NewAdmissionDto>(props.newAdmissionDto ?? {} as NewAdmissionDto);
    const [activeAdmission, setActiveAdmission] = useState<AdmissionDao>(props.activeAdmission || {} as AdmissionDao);
    const [doctorsOptions, setDoctorsOptions] = useState<DropdownOptionsType[]>([])
    const [listOfUsers, setListOfUsers] = useState<UserDto[]>([]);
    const [openNurseSectionAddNoteModal, setOpenNurseSectionAddNoteModal] = useState<boolean>(false);
    const [openDoctorSectionAddNoteModal, setOpenDoctorSectionAddNoteModal] = useState<boolean>(false);
    const [newAdmissionIsSubmitted, setNewAdmissionIsSubmitted] = useState<boolean>(false);
    const [patientIsCheckedOut, setPatientIsCheckedOut] = useState<boolean>(false);
    const rolesAllowedAccess: Authorities[] = [
        Authorities.RECEPTIONIST, Authorities.NURSE, Authorities.DOCTOR, Authorities.ADMIN, Authorities.SUPER_ADMIN
    ]
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [foundPurposeOfVisit, setFoundPurposeOfVisit] = useState<OfferedServiceDto[]>([]);
    const [loading, setLoading] = useState(false)

    const getPatientLatestPayment = () => {
        invoiceService.getPatientLatestSale(loggedInUser?.hospital?.id, patient?.patientDao?.id)
            .then((latestSale: InvoiceDao) => {
                setPayments(latestSale);
                setNewAdmissionDto({...newAdmissionDto, invoiceNumber: latestSale.invoiceNumber})
            }).catch(() => {
                setAdmitError('Technical error occurred while checking patient latest payment');
        })
    }

    const doctorsOptionsHandler = () => {
        if(!listOfUsers || listOfUsers.length < 1 || Object.keys(listOfUsers).length === 0 ) {
            return
        }
        const doctors = getAllDoctors(listOfUsers);
        const docsOpts: DropdownOptionsType[] = [];
        doctors && doctors.length > 0 && doctors.map((doc) => {
            docsOpts.push({label: `${doc.firstname} ${doc.lastname}`, value: `${doc.firstname} ${doc.lastname}`})
        })
        setDoctorsOptions(docsOpts)
    }

    const initAdmission = () => {
        setNewAdmissionDto((prevState) => ({
            ...prevState,
            patientId: patientDao.id,
            invoiceNumber: payments.invoiceNumber,
            hospitalId: patientDao.companyId,
            createdBy: userHasRole(loggedInUser, rolesAllowedAccess) ? loggedInUser.userId : 0,
            patientCategory: getPatientCategory(admissionType),
            admissionStatus: admissionStatusMapper(admissionType),
            paidAdmissionFee: activeAdmission?.paidAdmissionFee ?? props.paidAdmissionFee,
            paidConsultationFee: activeAdmission?.paidConsultationFee ?? props.paidConsultationFee,
            admissionDate: getDate(),
            admissionTime: getTime(),
            seenByDoctor: 0
        }))
    }

    useEffect(() => {
        if (loggedInUser?.hospital?.id) {
            userService.getAllUsers(loggedInUser?.hospital?.id)
                .then((users: UserDto[]) => {
                    setListOfUsers(users)
                });
        }

        props.newAdmissionDto && setNewAdmissionDto(props.newAdmissionDto);
        props.activeAdmission && setActiveAdmission(props.activeAdmission);
        doctorsOptionsHandler();
        getPatientLatestPayment();
    }, [props.activeAdmission?.admissionState, listOfUsers.length]);

    useEffect(() => {
        initAdmission();
    }, [newAdmissionDto.admissionDate, newAdmissionDto.admissionTime])

    const vitalSignsInputHandler = (evt: any) => {
        const target = evt.target;
        const name = target.name;
        const value = target.value;

        if (name !== 'vitalSigns') {
            return
        }

        setNewAdmissionDto((prevState) => ({
            ...prevState, vitalSigns: value
        }));
    }

    const vitalSignsInputOnBlurHandler = () => {
        if (!newAdmissionDto?.vitalSigns || newAdmissionDto?.vitalSigns.length < 1) {
            setAdmitError('Vital Signs cannot be empty');
            return;
        }
        setAdmitError('');
    }

    const checkboxesHandler = (evt: any) => {
        const {name, checked} = evt.target;

        if(!activeAdmission?.consultationAssessment) {
            setError('consultationAssessment', {message: 'Consultation area cannot be empty'});
            return;
        }

        if((name !== 'confirmConsultation' && !activeAdmission?.confirmConsultation)) {
            setError('confirmConsultation', {message: 'Please check that the patient has been consulted!'});
            setAdmitError('Please check that the patient has been consulted !');
            return;
        }

        if(name === 'confirmConsultation') {
            setActiveAdmission((prevState) => ({
                ...prevState,
                seenByDoctor: userHasRole(loggedInUser, [Authorities.DOCTOR]) ? loggedInUser.userId : 0
            }));
        }

        setActiveAdmission((prevState) => ({
            ...prevState, [name]: checked
        }))
    }

    const textAreaChangeHandler = (evt: any) => {
        const target = evt.target;
        const name = target.name;
        const value = target.value

        setActiveAdmission((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const textAreasOnBlurHandler = (evt: any) => {
        const {name, value} = evt.target;
        if (!value) {
            setAdmitError(name?.toLowerCase() + ' cannot be empty');
            return;
        }
        setActiveAdmission({...activeAdmission, [name]: value})
        setAdmitError('');

        //TODO: update the admission using patch request
    }

    /** Fetch doctor on sit or patient doctor or list of doctors on seat or list of all doctors in the patient clinic */
    const patientDoctorChangeHandler = (evt: any) => {
        const target = evt.target;
        const name = target.name;
        const value = target.value;

        if (name !== 'seenByDoctor') {
            return;
        }

        const updateNewAdmissionDao = {...activeAdmission};
        updateNewAdmissionDao['admissionStatus'] = value as AdmissionType;
        setActiveAdmission(updateNewAdmissionDao);
    }

    const getPatientDoctorHandler = (doctorToSeePatient: string) => {
        setNewAdmissionDto((prevState) => ({
            ...prevState, doctorToSeePatient
        }))

        setActiveAdmission((prevState) => ({
            ...prevState, doctorToSeePatient
        }))
    }

    const submitNewAdmissionHandler = () => {
        trigger().then();

        if(!newAdmissionDto.vitalSigns) {
            setError('vitalSigns', {message: 'Vital signs is required'})
            return;
        }

        if (newAdmissionDto?.admissionStatus && !activeAdmission?.id && !activeAdmission?.admissionState
            && newAdmissionDto.purposeOfVisit && newAdmissionDto.vitalSigns && newAdmissionDto.admissionDate
            && newAdmissionDto.admissionTime &&  newAdmissionDto.invoiceNumber > 0) {
            admissionService.newAdmission(newAdmissionDto)
                .then((response) => {
                    setActiveAdmission(response);
                    setNewAdmissionIsSubmitted(true);
                    setNewAdmissionDto({} as NewAdmissionDto);
                }).catch(() => {
                setError('request-error', {message: 'Please fill all compulsory fields'});
            })
            return;
        }
        setAdmitError('Something when wrong, please ensure you have entered all the inputs correct,' +
            ' make use of the hints to find out');
    }

    /** At least of the action checkboxes must be selected*/
    const atLeastOneCheckboxSelected = (): boolean => {
        return activeAdmission?.drugPrescription && !!activeAdmission?.drugPrescriptionText
        || activeAdmission?.surgery && !!activeAdmission?.surgeryText
        || activeAdmission?.laboratory && !!activeAdmission?.laboratoryText
        || activeAdmission?.followUp && !!activeAdmission?.followUpText;
    }

    const updateAdmissionHandler = () => {
        if(!activeAdmission.consultationAssessment) {
            setError('consultationAssessment', {message: 'Consultation assessment is required'})
            return;
        }

        if (activeAdmission?.id && activeAdmission?.admissionState && activeAdmission.confirmConsultation
            && activeAdmission?.admissionState !== AdmissionState.CHECKED_OUT && atLeastOneCheckboxSelected()) {
            admissionService.updateAdmission(activeAdmission)
                .then((response) => {
                    setActiveAdmission(response);
                }).catch(() => {
                setError('request-error', {message: 'Something went wrong updating admission'});
            });
            return;
        }
        setAdmitError('Please ensure you have entered all required fields');
    }

    const doctorDischargePatientHandler = () => {
        admissionService.dischargePatientByDoctor(loggedInUser?.hospital.id, activeAdmission.id)
            .then((dataDao) => {
                setActiveAdmission(dataDao)
            }).catch(() => {
                setAdmitError('Technical error occurred while discharging this patient')
        })
    }

    const checkoutPatientHandler = () => {
        admissionService.checkoutPatient(activeAdmission.hospitalId, activeAdmission.id)
            .then((response) => {
                setActiveAdmission(response);
                setPatientIsCheckedOut(true);
            }).catch(() => {
            setError('request-error', {message: 'Something went wrong checking out patient'});
        });
    }

    const handleSearchResults = (results: OfferedServiceDto[]): void => {
        setFoundPurposeOfVisit(results);
    };

    return (
        <div>
            {
                admitError && (
                    <div>
                        <ErrorNotification name={'admit-patient-error'} stringErrors={admitError} />
                    </div>
                )
            }

            {/* Receptionist/Nurse section */}
            <section className={'admit-patient-section-1 ' + `${activeAdmission.admissionState ? 'disable-section' : ''}`}>
                <div className={'admit-patient-section-1-divider'}>
                    <div className={'admission-patient-info'}>
                        <h2>Patient Info</h2>
                        <div className={'admit-patient-section-1-align-content'}>
                            <div className={'admit-patient-section-1-align-left'}>
                                <p>Patient Id: </p>
                                <p>Lastname: </p>
                                <p>Firstname: </p>
                                <p>Age: </p>
                                <p>Marital: </p>
                            </div>
                            <div>
                                <p>{patient.patientDao?.id || ''}</p>
                                <p>{capitalizeFirstCharacter(patient.patientDao?.lastname) || ''}</p>
                                <p>{capitalizeFirstCharacter(patient.patientDao?.firstname) || ''}</p>
                                <p>{calculateAge(patient.patientDao?.dob) || ''}</p>
                                <p>{patient.patientDao?.maritalStatus || ''}</p>
                            </div>
                        </div>
                        <div className={'admit-patient-profile-redirect'}>
                            <a className={'unset-disable-section'} onClick={() => navigate(patientProfilePath, {state: {patient}})}>View Profile</a>

                            <div className={'admit-patient-change-patient'}>
                                <p><b><i>Not the patient ?</i></b>
                                    <a className={'unset-disable-section'} onClick={() => navigate(patientListPath)}>Change Patient</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'admit-patient-section-1-divider admit-date-time'}>
                    <div className={'admit-date-time-label-input-wrapper'}>
                        <div className={'admit-date-time-label-input'}>
                            {
                                <InputField
                                    id={'admissionDate'}
                                    name={'admissionDate'}
                                    label={`${capitalizeFirstCharacter(newAdmissionDto?.admissionStatus || activeAdmission?.admissionStatus)} Date`}
                                    disabled={true}
                                    placeholder={''}
                                    defaultValue={activeAdmission.admissionDate ? dateFormatArrDDMMYYYY(activeAdmission.admissionDate) : newAdmissionDto.admissionDate}
                                    onChange={(e: any) => e}
                                    register={register}
                                    errors={errors}
                                />
                            }
                        </div>
                        <div className={'admit-date-time-label-input'}>
                            {
                                <InputField
                                    id={'admissionTime'}
                                    name={'admissionTime'}
                                    label={`${capitalizeFirstCharacter(newAdmissionDto?.admissionStatus)} Time`}
                                    disabled={true}
                                    placeholder={''}
                                    defaultValue={activeAdmission.admissionTime ? activeAdmission.admissionTime : newAdmissionDto.admissionTime}
                                    onChange={(e: any) => e}
                                    register={register}
                                    errors={errors}
                                />
                            }
                        </div>
                    </div>

                    <div className={'purpose-of-visit'}>
                         <InputSearch
                            id={'search-purpose-of-visit'}
                            name={'purposeOfVisit'}
                            placeholder={'search purpose of visit...'}
                            label={'Purpose Of Visit'}
                            defaultValue = {newAdmissionDto?.purposeOfVisit || activeAdmission?.purposeOfVisit || searchQuery}
                            register={register}
                            searchQuery={searchQuery}
                            controllerReqPath={'offered-services'}
                            onResults={handleSearchResults}
                            onChange={(e: any) => {
                                setSearchQuery(e.target.value);
                            }}
                            errors={errors}
                            onFocus={(e: any) => {
                                setSearchQuery(searchQuery ?? '')
                            }}
                        />

                        {loading && <span><Spinner /></span>}

                        { searchQuery &&
                            foundPurposeOfVisit?.length > 0 && (
                                foundPurposeOfVisit.map((pov, index) => (
                                    <div key={Math.random().toFixed(index)} className={'search-input-result-w'}>
                                        <button onClick={() => {
                                            setNewAdmissionDto((prevState) => ({...prevState, purposeOfVisit: pov.serviceName}));
                                            setFoundPurposeOfVisit([]);
                                            setLoading(false);
                                        }}
                                           title={`${pov.serviceName} in ${pov.categoryName} category in ${pov.departmentName} department`}
                                        >
                                            {`${pov.serviceName}`}
                                        </button>
                                    </div>
                                ))
                            )
                        }
                    </div>

                </div>

                <div className={'admit-patient-section-1-divider'}>
                    <Dropdown name={'seenByDoctor'}
                              onChange={patientDoctorChangeHandler}
                              options={doctorsOptions}
                              label={'Doctor/Consultant'}
                              placeholder={activeAdmission?.doctorToSeePatient ?? 'Doctor/Consultant'}
                              getSelectedOptionValue={getPatientDoctorHandler}
                              errors={errors}
                    />

                    <div className={'admit-patient-section-vital-sign'}>
                        <TextArea name={'vitalSigns'}
                                  label={'Patient Vital Sign(s)'}
                                  placeholder={'Enter patients vital signs'}
                                  onChange={vitalSignsInputHandler}
                                  value={newAdmissionDto?.vitalSigns || activeAdmission?.vitalSigns}
                                  onBlur={vitalSignsInputOnBlurHandler}
                                  required={true}
                                  register={register}
                                  min={10}
                                  minLength={10}
                                  errors={errors}
                        />

                        {
                            activeAdmission?.admissionState && activeAdmission.admissionState !== AdmissionState.CHECKED_OUT && (
                                <div className={'add-extra-note-btn-w unset-disable-section'}>
                                    {
                                        activeAdmission.admissionState !== AdmissionState.DISCHARGED_BY_DOCTOR &&
                                        <button
                                            onClick={() => setOpenNurseSectionAddNoteModal(!openNurseSectionAddNoteModal)}>
                                            Add Note
                                        </button>
                                    }

                                    {openNurseSectionAddNoteModal &&
                                        <Modal
                                            isOpen={openNurseSectionAddNoteModal}
                                            onClose={() => {
                                                setOpenNurseSectionAddNoteModal(false);
                                            }}
                                            onAction={() => console}
                                            modalTitle={'Add vital signs extra note'}
                                        >
                                            <AddExtraNote
                                                admissionId={activeAdmission?.id}
                                                name={'vitalSignsExtraNotes'}
                                                register={register}
                                                getVitalSignExtraNote={(note: string) => {
                                                    setActiveAdmission((prevState) => ({
                                                        ...prevState,
                                                        vitalSignsExtraNotes: [note]
                                                    }))
                                                }}
                                                closeModal={(openModal: boolean) => setOpenNurseSectionAddNoteModal(openModal)}
                                            />
                                        </Modal>
                                    }
                                </div>
                            )
                        }
                    </div>

                    {
                        !activeAdmission || !activeAdmission.admissionState &&
                        <div className={'wrap-check-in-patient-btn'}>
                            <Button id={'check-in-patient-btn'}
                                    buttonName={'Check In Patient'}
                                    onClick={submitNewAdmissionHandler}
                            />
                        </div>
                    }

                    {
                        activeAdmission?.admissionState === AdmissionState.DISCHARGED_BY_DOCTOR &&
                        userHasRole(loggedInUser, [Authorities.NURSE, Authorities.RECEPTIONIST, Authorities.ADMIN,
                            Authorities.SUPER_ADMIN]) &&
                        (
                            <div className={'wrap-check-in-patient-btn unset-disable-section'}>
                                <Button id={'check-in-patient-btn'}
                                        buttonName={'Check Out Patient'}
                                        onClick={checkoutPatientHandler}
                                />

                                {
                                    <Modal isOpen={patientIsCheckedOut} modalTitle={'Checkout Patient'}
                                           onClose={() => {
                                        setPatientIsCheckedOut(false);
                                        window.location.href = ParamsEnum.ADMISSION
                                    }} >
                                        <SuccessResponse onContinue={() => {}} successMsg={'Patient discharged successfully'} />

                                        <p>Patient discharged successfully</p>
                                    </Modal>
                                }
                            </div>
                        )
                    }

                    {
                        newAdmissionIsSubmitted &&
                        <div className={'new-admission-success-modal unset-disable-section'}>
                            <Modal isOpen={newAdmissionIsSubmitted} onClose={() => {
                                setNewAdmissionIsSubmitted(false);
                                window.location.href = ParamsEnum.PATIENTS;
                            }} modalTitle={'New Admission Saved Successfully !'}>
                                <p>Patient is successfully checked in !</p>
                            </Modal>
                        </div>
                    }
                </div>
            </section>

            {/* Doctors section */}
            <section
                className={'admit-patient-section-2 ' + (!isDoctor || activeAdmission.admissionState !== AdmissionState.WAITING_TO_SEE_DOCTOR ? 'disable-section' : '')}>
                <h2>Doctor's Consultation</h2>

                <div className={'admit-patient-section-doctor-diagnosis'}>
                    <TextArea className={!isDoctor ? 'disable-section' : ''}
                              id={'consultationAssessment'}
                              name={'consultationAssessment'}
                              label={'Doctor\'s Diagnosis / Medical Assessment'}
                              placeholder={'Enter diagnosis'}
                              onChange={textAreaChangeHandler}
                              value={activeAdmission?.consultationAssessment}
                              onBlur={textAreasOnBlurHandler}
                              required={true}
                              register={register}
                              min={5}
                              minLength={5}
                              errors={errors}
                    />

                    {
                        activeAdmission?.admissionState && activeAdmission.admissionState !== AdmissionState.WAITING_TO_SEE_DOCTOR && (
                            <div className={'add-extra-note-btn-w unset-disable-section'}>
                                {
                                    activeAdmission.admissionState !== AdmissionState.DISCHARGED_BY_DOCTOR &&
                                    <button onClick={() => setOpenDoctorSectionAddNoteModal(!openDoctorSectionAddNoteModal)}>
                                        Add Note
                                    </button>}

                                {openDoctorSectionAddNoteModal &&
                                    <Modal
                                        isOpen={openDoctorSectionAddNoteModal}
                                        onClose={() => {
                                            setOpenDoctorSectionAddNoteModal(false);
                                        }}
                                        onAction={() => console}
                                        modalTitle={'Add doctor extra note'}
                                    >
                                        <AddExtraNote
                                            admissionId={activeAdmission?.id}
                                            name={'doctorsNote'}
                                            register={register}
                                            getDoctorExtraNote={(note: string) => {
                                                setActiveAdmission((prevState) => ({...prevState, doctorsExtraNotes: [note]}))
                                            }}
                                            closeModal={(closeModal: boolean) => setOpenDoctorSectionAddNoteModal(closeModal)}
                                        />
                                    </Modal>
                                }
                            </div>
                        )
                    }
                </div>

                <div className={'confirm-patient-consultation-checkbox'}>
                    <Checkbox id={'confirmConsultation'}
                              name={'confirmConsultation'}
                              checked={activeAdmission?.confirmConsultation || false}
                              label={`This Patient has been consulted by me: ${activeAdmission?.seenByDoctor || activeDoctor}`}
                              onChange={checkboxesHandler}
                              register={register}
                              errors={errors}
                    />
                </div>

                <div className={'after-consultation-steps'}>
                    <div>
                        <Checkbox id={'drugPrescription'}
                                  name={'drugPrescription'}
                                  checked={activeAdmission?.drugPrescription || false}
                                  label={`Drug Prescription`}
                                  onChange={checkboxesHandler}
                                  register={register}
                                  errors={errors}
                        />
                    </div>

                    <div>
                        <Checkbox id={'surgery'}
                                  name={'surgery'}
                                  checked={activeAdmission?.surgery || false}
                                  label={`Surgery`}
                                  onChange={checkboxesHandler}
                                  register={register}
                                  errors={errors}
                        />
                    </div>

                    <div>
                        <Checkbox id={'admitPatient'}
                                  name={'admitPatient'}
                                  checked={activeAdmission?.admitPatient || false}
                                  label={`Admit Patient`}
                                  onChange={checkboxesHandler}
                                  register={register}
                                  errors={errors}
                        />
                    </div>

                    <div>
                        <Checkbox id={'laboratory'}
                                  name={'laboratory'}
                                  checked={activeAdmission?.laboratory || false}
                                  label={`laboratory`}
                                  onChange={checkboxesHandler}
                                  register={register}
                                  errors={errors}
                        />
                    </div>

                    <div>
                        <Checkbox id={'followUp'}
                                  name={'followUp'}
                                  checked={activeAdmission?.followUp || false}
                                  label={`followUp`}
                                  onChange={checkboxesHandler}
                                  register={register}
                                  errors={errors}
                        />
                    </div>

                   <div className={activeAdmission?.admissionState !== AdmissionState.SEEN_DOCTOR ? 'disable-section' : 'unset-disable-section'}>
                       <Checkbox id={'dischargedByDoctor'}
                                 name={'dischargedByDoctor'}
                                 checked={(activeAdmission?.dischargedByDoctor === true) || false}
                                 label={`discharge`}
                                 onChange={checkboxesHandler}
                                 register={register}
                                 errors={errors}
                                 withHint={'Submit consultation assessment before discharging the patient'}
                       />
                   </div>
                </div>

                <div className={'next-steps-after-consultation-wrapper'}>
                    {
                        activeAdmission?.drugPrescription && (
                            <TextArea className={!isDoctor ? 'disable-section' : ''}
                                      id={'drugPrescriptionText'}
                                      name={'drugPrescriptionText'}
                                      label={'Drug Prescription'}
                                      placeholder={'Enter drug prescription'}
                                      onChange={textAreaChangeHandler}
                                      value={activeAdmission?.drugPrescriptionText}
                                      onBlur={textAreasOnBlurHandler}
                                      required={true}
                                      register={register}
                                      min={5}
                                      minLength={5}
                                      errors={errors}
                            />
                        )
                    }

                    {
                        activeAdmission?.surgery && (
                            <TextArea className={!isDoctor ? 'disable-section' : ''}
                                      id={'surgeryText'}
                                      name={'surgeryText'}
                                      label={'Surgery'}
                                      placeholder={'Enter description'}
                                      onChange={textAreaChangeHandler}
                                      value={activeAdmission?.surgeryText}
                                      onBlur={textAreasOnBlurHandler}
                                      required={true}
                                      register={register}
                                      min={5}
                                      minLength={5}
                                      errors={errors}
                            />
                        )
                    }

                    {
                        activeAdmission?.admitPatient && (
                            <TextArea className={!isDoctor ? 'disable-section' : ''}
                                      id={'admitPatientText'}
                                      name={'admitPatientText'}
                                      label={'Admit patient to ward'}
                                      placeholder={'Enter ward and admission details'}
                                      onChange={textAreaChangeHandler}
                                      value={activeAdmission?.admitPatientText}
                                      onBlur={textAreasOnBlurHandler}
                                      required={true}
                                      register={register}
                                      min={5}
                                      minLength={5}
                                      errors={errors}
                            />
                        )
                    }

                    {
                        activeAdmission?.laboratory && (
                            <TextArea className={!isDoctor ? 'disable-section' : ''}
                                      id={'laboratoryText'}
                                      name={'laboratoryText'}
                                      label={'Laboratory'}
                                      placeholder={'Enter details of lab'}
                                      onChange={textAreaChangeHandler}
                                      value={activeAdmission?.laboratoryText}
                                      onBlur={textAreasOnBlurHandler}
                                      required={true}
                                      register={register}
                                      min={5}
                                      minLength={5}
                                      errors={errors}
                            />
                        )
                    }

                    {
                        activeAdmission?.followUp && (
                            <TextArea className={!isDoctor ? 'disable-section' : ''}
                                      id={'followUpText'}
                                      name={'followUpText'}
                                      label={'Schedule follow up'}
                                      placeholder={'Enter followup details'}
                                      onChange={textAreaChangeHandler}
                                      value={activeAdmission?.followUpText}
                                      onBlur={textAreasOnBlurHandler}
                                      required={true}
                                      register={register}
                                      min={5}
                                      minLength={5}
                                      errors={errors}
                            />

                        )
                    }

                    {
                        activeAdmission?.dischargedByDoctor
                        && activeAdmission?.admissionState !== AdmissionState.CHECKED_OUT
                        && activeAdmission?.admissionState !== AdmissionState.DISCHARGED_BY_DOCTOR && (
                            <div className={'doctor-discharge-modal-w unset-disable-section'}>
                                <Modal isOpen={activeAdmission?.dischargedByDoctor}
                                       onClose={() => setActiveAdmission({...activeAdmission, dischargedByDoctor: false})}
                                       modalTitle={'Discharge Patient'}
                                >
                                    <h2>
                                        {/* @ts-ignore */}
                                        {activeAdmission?.admissionState === AdmissionState.DISCHARGED_BY_DOCTOR
                                            ? 'Patient discharged successfully' : 'Are you sure you want to discharge this patient ?'}
                                    </h2>
                                    <div className={'doctor-discharge-cancel-btn-w'}>
                                        <Button
                                            id={'discharge-btn'}
                                            buttonName={'Discharge'}
                                            onClick={doctorDischargePatientHandler}
                                        />

                                        <Button
                                            id={'cancel-btn'}
                                            buttonName={'Cancel'}
                                            onClick={() => setActiveAdmission({...activeAdmission, dischargedByDoctor: false})}
                                        />
                                    </div>
                                </Modal>
                            </div>
                        )
                    }
                </div>

                {
                    activeAdmission?.admissionState === AdmissionState?.CHECKED_OUT ||
                    activeAdmission?.admissionState ===  AdmissionState?.WAITING_TO_SEE_DOCTOR &&
                    <div className={'admit-patient-submit-btn-wrapper'}>
                        <Button id={'clinic-admit-btn'} name={'clinic-admit-btn'}
                                buttonName={'Submit Assessment'}
                                onClick={updateAdmissionHandler}
                        />
                    </div>
                }

                {
                    errors && ((errors['request-error']?.message) || (errors['user-input-error']?.message)) && (
                        <div className={'admission-request-error'}>
                            <ErrorNotification name={'request-error'} fieldErrors={errors}/>
                        </div>
                    )
                }
            </section>

            {/* Ward section */}
            <section className={'admit-patient-section-3'}>
                <h2>Ward Section</h2>
            </section>

            {/* Notification section */}
            <section className={'admit-patient-section-4'}>
                <h2>Notification Section</h2>
            </section>
        </div>
    )
}