import React, {useState, useEffect} from "react";
import './YearlyFolder.scss';
import CustomHeader from '../CustomHeader/CustomHeader';
import FolderIcon from '../../../../resources/assets/svg/FolderIcon';
import {getAllMonths, Month, OldMedicalRecords} from "../../../../types/OldMedicalRecords";
import {Modal} from "../../../../components/modal/Modal";
import UploadMedicalRecord from "./MonthlyFolder/AddHistoryForm/UploadMedicalRecord";
import HistoryList from "./MonthlyFolder/HistoryList/HistoryList";

interface YearLyFolderProps {
    oldRecords: OldMedicalRecords;
    newRecord: boolean
}

type FilesPerMonth = {month: Month, files: File[]}

const YearlyFolder = (props: YearLyFolderProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(true)
    const [activeMonth, setActiveMonth] = useState<Month>();
    const [fileCount, setFileCount] = useState<Map<Month, number>>(new Map<Month, number>())
    const [filesRecord, setFilesRecords] = useState<Set<FilesPerMonth[]>>(new Set([]))
    const [monthsPerYear, setMonthsPerYear] = useState<Map<number, Month[]>>(new Map<number, Month[]>())
    const [OldMedicalRecords, setOldMedicalRecords] = useState<OldMedicalRecords>(props.oldRecords)
    const year = props.oldRecords.year;

    const getMonthKey = (fileCountMap: Map<Month, number>, currMonth: Month): Month | undefined => {
        for (let [key, value] of fileCountMap.entries()) {
            if (key === currMonth)
                return key;
        }
    }

    useEffect(() => {
        extractMonthAndFiles();
    }, [props.oldRecords.year]);

    // extract or init months/month and files handler
    const extractMonthAndFiles = () => {
        let yearMonthsMap = new Map<number, Month[]>();

        // for existing records
        if (props.oldRecords.months?.length > 0 && !props.newRecord) {
            const extractMonths: Month[] = [];
            const updateFileCount = new Map<Month, number>();

            props.oldRecords?.months?.forEach(filesMap => {
                const eachMonth = filesMap.month;
                const getFiles: File[] = filesMap.files;

                updateFileCount.set(eachMonth, getFiles.length)
                setFileCount(updateFileCount);

                filesRecord?.add([{month: eachMonth, files:  getFiles}])
                setFilesRecords(filesRecord);

                // add months missing
                getAllMonths().map((mnt) => {
                    if(!yearMonthsMap.get(year)?.includes(mnt)) {
                        extractMonths.push(mnt);
                    }
                });

                yearMonthsMap.set(year, extractMonths);

                setMonthsPerYear(yearMonthsMap);
            });

            return;
        }

        // for new uploads
        yearMonthsMap.set(year, getAllMonths());
        setMonthsPerYear(yearMonthsMap)
        setFilesRecords(filesRecord);
    }

    // handler for new files upload
    const getCountHandler = (month: Month, count: number) => {
        const newMap = new Map<Month, number>()
        newMap.set(month, count);
        setFileCount(newMap)
    }

    function getRecordFilesByMonth(month: Month): File[] | undefined {
        let customFiles: File[] = []

        for (const file of filesRecord) {
           file.map((filesPerMnt) => {
               if (filesPerMnt.month === month) {
                   customFiles = filesPerMnt.files;
                   return customFiles;
               }
           })
        }
        return customFiles?.length > 0 ? customFiles : undefined;
    }


    return (
        <div className='yearly_folder'>
            <div onClick={() => setIsOpen(!isOpen)} className={'yearly-custom-header-w'}>
                {
                    props.oldRecords?.year > 1899 &&
                    < CustomHeader
                        title={`Old Medical Records for the year: ${props.oldRecords.year}`}
                        withIcon={true}
                        deg={90}
                    />
                }
            </div>
            {
                isOpen &&
                <div className='yearly_folder-months' data-isopen={isOpen}>
                    {
                        monthsPerYear.get(year)?.map((month, index) => (
                                <div key={Math.random().toFixed(index)} className='yearly_folder-months-month'>
                                <span className='yearly_folder-folder_icon'
                                      onClick={() => setActiveMonth(month)}
                                >
                                  <FolderIcon/>
                                  <span className={`${getMonthKey(fileCount, month) === month ? 'count' : ''}`}>
                                      {getMonthKey(fileCount, month) === month ? fileCount.get(month) : ''}
                                  </span>
                                </span>
                                    <span className='month'>{month}</span>
                                </div>
                            )
                        )
                    }

                    {
                        activeMonth && (
                            <Modal
                                isOpen={!!activeMonth}
                                onClose={() => setActiveMonth(undefined)}
                                modalTitle={activeMonth + ' ' + props.oldRecords.year}
                            >
                                <UploadMedicalRecord
                                    year={props.oldRecords.year}
                                    month={activeMonth}
                                    setCountHandler={getCountHandler}
                                />

                                <div>
                                    <HistoryList
                                        oldRecords={OldMedicalRecords}
                                        customFiles={getRecordFilesByMonth(activeMonth) || []}
                                    />
                                </div>
                            </Modal>
                        )
                    }
                </div>
            }
        </div>);
};

export default YearlyFolder;
