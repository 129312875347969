import React from "react";

const CheckIcon = () => {
  return <svg width="52" height="56" viewBox="0 0 52 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5150_25156)">
      <path d="M25.7184 0.691406C11.52 0.691406 0.00976562 12.8689 0.00976562 27.8904C0.00976562 42.9127 11.52 55.0894 25.7184 55.0894C39.9176 55.0894 51.427 42.9127 51.427 27.8904C51.427 12.8689 39.9176 0.691406 25.7184 0.691406ZM25.7184 51.743C13.3148 51.743 3.22334 41.0131 3.22334 27.8903C3.22334 14.7676 13.3148 4.09117 25.7184 4.09117C38.122 4.09117 48.2134 14.7677 48.2134 27.8903C48.2134 41.0129 38.122 51.743 25.7184 51.743ZM35.9785 17.9381L20.8948 33.9966L14.1021 26.8101C13.4746 26.1463 12.4575 26.1463 11.8293 26.8101C11.2018 27.4739 11.2018 28.55 11.8293 29.2138L19.7821 37.6285C20.4095 38.2915 21.4266 38.2915 22.0549 37.6285C22.1272 37.552 22.1891 37.4686 22.2453 37.382L38.2522 20.3426C38.8788 19.6788 38.8788 18.6027 38.2522 17.9381C37.6239 17.2743 36.6068 17.2743 35.9785 17.9381Z" fill="#53BD8A" />
    </g>
    <defs>
      <clipPath id="clip0_5150_25156">
        <rect width="51.4173" height="54.398" fill="white" transform="translate(0.00976562 0.691406)" />
      </clipPath>
    </defs>
  </svg>

};

export default CheckIcon;
