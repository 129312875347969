import React, {useEffect, useState} from "react";
import './UploadMedicalRecord.scss';
import {TextArea} from '../../../../../../components/textarea/TextArea';
import {useForm} from 'react-hook-form';
import {Button} from '../../../../../../components/button/Button';
import UploadIcon from '../../../../../../resources/assets/svg/Dashboard/UploadIcon';
import SuccessResponse from '../SuccessResponse/SuccessResponse';
import {Month, OldMedicalRecords} from "../../../../../../types/OldMedicalRecords";
import {isEmpty} from "../../../../../../util/Helpers";
import oldMedicalRecordsService from "../../../../../../services/imageservices/OldMedicalRecordsService";
import {useWhoAmI} from "../../../../../../context/UserCtx";
import {Patient} from "../../../../../../types/PatientDao";
import {ErrorNotification} from "../../../../../../modules/errornotification/ErrorNotification";

interface Props {
    month: Month;
    year: number;
    setCountHandler?(month: Month, count: number): any
}

const UploadMedicalRecord = ({month, year, setCountHandler}: Props) => {
    const getPatient = sessionStorage.getItem('/dashboard/patients/admission');
    const patientDao = getPatient && JSON.parse(getPatient);
    const patient = patientDao && patientDao?.state?.patient?.customerDao as Patient || {} as Patient
    const user = useWhoAmI();
    const {register, setError, formState: {errors}, watch} = useForm({mode: 'onBlur'});
    const [newFiles, setNewFiles] = useState<OldMedicalRecords>({} as OldMedicalRecords)
    const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
    const [renameFile, setRenameFile] = useState<boolean>(false);
    const [filename, setFilename] = useState<string>('');
    const [addComment, setAddComment] = useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [listOfNewFiles, setListOfNewFiles] = useState<File[]>([])
    const [savedImgSrc, setSavedImgSrc] = useState<string>()
    const requestError = 'request-error';

    const onChangeComment = (e: any) => {
    }

    const onSubmit = () => {
        if(!patient || !patient?.id || patient?.id < 1) {
            setError(requestError, {message: 'Patient Is must be defined'})
            setUploadSuccess(false)
            return
        }

        oldMedicalRecordsService
            .uploadOldRecord(listOfNewFiles[0], user.hospital.id, patient.id, newFiles.year, newFiles.months[0].month)
            .then((response) => {
                setSavedImgSrc(response)
                setUploadSuccess(true)
            }).catch(() => {
            setError(requestError, {message: 'Technical error occurred while uploading old record'})
            setUploadSuccess(false)
        })
    }

    const fileOnChangeHandler = (evt: any) => {
        const multipleFiles: File[] = evt.target.files;
        setListOfNewFiles(multipleFiles);
        setNewFiles({...newFiles, year, months: [{month, files: multipleFiles as unknown as File[]}]})
        setCountHandler && setCountHandler(month, multipleFiles.length);
    }

    // get the filename and iterate newFiles and update the exact file's name
    const renameFileHandler = (evt: any, blob: File, index: number) => {
        const {value} = evt.target;

        if(isEmpty(value)) {
            setRenameFile(false)
            return;
        }

        setFilename(value);

        const renamedFile = new File([blob], value, {
            type: blob.type,
            lastModified: blob.lastModified,
        });

        setListOfNewFiles((prevFiles: any) => {
            let updatedFiles = [...prevFiles];
            updatedFiles[index] = renamedFile;
            setNewFiles({...newFiles, year, months: [{month, files: updatedFiles}]});
            return updatedFiles;
        });
        setRenameFile(false)
    }

    return (
        <div className='add-history-component'>
            {
                errors && errors[requestError]?.message &&
                <ErrorNotification name={requestError} stringErrors={String(errors[requestError]?.message)}/>
            }
            {
                uploadSuccess ?
                    <SuccessResponse onContinue={() => window.location.reload()} successMsg={'File has been uploaded'}/> :
                    errors && errors[requestError]?.message &&
                    <ErrorNotification name={requestError} stringErrors={String(errors[requestError]?.message)}/>
            }

            <section className='add-new-old-record-w'>
                <h3>Please upload records corresponding to the current month and year</h3>
                <section className='new-old-record-form-container'>
                    <div>
                        <div className='upload_container'>
                            <div className='file_name_btn'>
                                <div className='upload_btn'>
                                    <UploadIcon/>
                                    <label htmlFor="select-file-id">Select file</label>
                                    <input
                                        id={'select-file-id'}
                                        type="file"
                                        multiple={true}
                                        {...register('file', {
                                            required: 'Please select a file to upload', validate: {
                                                validates: value => {
                                                    console.log(value);
                                                    const allowedExtensions = ["pdf", "jpg", "jpeg", "png", "gif", "webp"];
                                                    const fileExtension = value[0]?.type?.split('/')[1]?.toLowerCase();
                                                    return allowedExtensions.includes(fileExtension) || 'File type not supported';
                                                }
                                            }
                                        })
                                        }
                                        onChange={fileOnChangeHandler}
                                    />
                                </div>
                                {errors.file && <span
                                    className='error_text'>{errors.file.message as unknown as string}</span>}
                            </div>
                        </div>

                        {
                            listOfNewFiles?.length > 0 && (
                                <span className={'rename-file-hint'}>
                                            To rename a file, click on the file and enter new name in the right input filed
                                        </span>
                            )
                        }
                        {
                            Array.from(listOfNewFiles)?.map((eachFile, index) => (
                                <div
                                    key={Math.random().toFixed(index)}
                                    className={'new-file-label-input-w'}
                                    onClick={() => setActiveIndex(index)}
                                >
                                    <label
                                        className={'add-new-list-file-names-w'}
                                        onClick={() => setRenameFile(!renameFile)}
                                        htmlFor={'rename-file'}
                                    >
                                        {eachFile.name}
                                    </label>
                                    {
                                        renameFile && index === activeIndex &&
                                        <input
                                            className={'add-new-list-file-names-w'}
                                            name={'rename-file'}
                                            id={'rename-file'}
                                            {...register}
                                            defaultValue={filename}
                                            placeholder={'enter new name'}
                                            disabled={index !== activeIndex}
                                            onBlur={(evt: any) => renameFileHandler(evt, eachFile, index)}
                                            onFocus={() => setFilename('')}
                                        />
                                    }
                                </div>
                            ))
                        }
                        {
                            addComment && (
                                <div>
                                    <TextArea
                                        name="comment"
                                        onChange={onChangeComment}
                                        required={false}
                                        register={register}
                                        label={'Add a comment'}
                                        id="comment"
                                        className='text_area'
                                    />
                                </div>
                            )
                        }

                        {
                            listOfNewFiles?.length > 0 &&
                            <div className={'upload-new-record-btn-w'}>
                                <Button
                                    type='submit'
                                    className='upload_file_btn'
                                    name={'upload_file'} id={'upload-file'}
                                    buttonName={'Upload'}
                                    onClick={onSubmit}
                                />
                            </div>
                        }
                    </div>
                </section>
            </section>
        </div>
    )
};

export default UploadMedicalRecord;
