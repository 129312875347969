import  { useEffect, useState } from "react";
import "./SideBar.scss";
import classNames from "classnames";
import { CiMoneyBill, CiSettings } from "react-icons/ci";
import { AiOutlineDashboard } from "react-icons/ai";
import { FcDepartment, FcSalesPerformance } from "react-icons/fc";
import { PiUsersThreeBold } from "react-icons/pi";
import { GrBusinessService } from "react-icons/gr";
import { GiMedicalDrip } from "react-icons/gi";
import { FaHandshake } from "react-icons/fa";
import { MdLogout, MdOutlineNotificationsActive } from "react-icons/md";
import { useParams } from "react-router-dom";
import { SideBarType } from "../../appstate/SidebarChildrenState";
import HLogo from "../../resources/assets/images/mdi_world.png";
import { useCustomNavigate } from "../../customhook/CustomUseNavigate";
import { useWhoAmI } from "../../context/UserCtx";
import loginService from "../../services/LoginService";
import { logout, isActivePage } from "../../util/Helpers";
import { ParamsEnum } from "../../util/ParamsEnum";
import {userHasRole, userIsAdmin, userIsCashier, userIsSuperAdmin} from "../../util/UserRolesHelper";
import {Authorities} from "../../userAuth/WhoAmI";

export const SideBar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const navigate = useCustomNavigate();
  const currentLocation = window.location.pathname

  const { sidebar } = useParams();
  const [activeSideBar, setActiveSideBar] = useState<SideBarType>(
    (sidebar as SideBarType) || "dashboard"
  );
  const signedInUser = useWhoAmI();

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth < 960);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [activeSideBar]);

  const sideBarClickHandler = (currentSideBar: SideBarType) => {
    setActiveSideBar(currentSideBar);
    navigate(`/dashboard/${currentSideBar.toLowerCase()}`, {
      state: { sidebar: currentSideBar },
    });
  };

  const logoutHandler = () => {
    loginService
      .logout()
      .then(() => {
        logout();
        sessionStorage.removeItem(ParamsEnum.ACCESS_TOKEN);
        window.location.href = ParamsEnum.HOME;
      })
      .catch(() => undefined);
  };

  return (
    <div className={"side-bar-layout-wrapper"}>
      <div className={"side-bar-wrapper"}>
        <div className={classNames("company-name-logo")}>
          <img src={HLogo} alt={"Logo"} />
          <div className={isMobile ? "hide-side-bar-text" : ""}>
            <p className={"side-bar-text "}>
              Signed in as <br />
              <b>{`${signedInUser?.firstname} ${signedInUser?.lastname}`}</b>
            </p>
          </div>
        </div>
        <div
          className={classNames("side-bar-icon-text-wrapper", {
            "active-side-bar": isActivePage('dashboard', currentLocation),
          })}
          onClick={() => sideBarClickHandler("")}
        >
          <AiOutlineDashboard />
          <p className={"side-bar-text "}>
            {isMobile ? "" : "Dashboard"}
          </p>
        </div>

        <div
          className={classNames("side-bar-icon-text-wrapper", {
            "active-side-bar": isActivePage('departments', currentLocation),
          })}
          onClick={() => sideBarClickHandler("departments")}
        >
          <FcDepartment />
          <p className={"side-bar-text"}>
            {isMobile ? "" : "Departments"}
          </p>
        </div>

        <div
          className={classNames("side-bar-icon-text-wrapper", {
            "active-side-bar": isActivePage('services', currentLocation),
          })}
          onClick={() => sideBarClickHandler("services")}
        >
          <GrBusinessService />
          <p className={"side-bar-text"}>
            {isMobile ? "" : "Services"}
          </p>
        </div>

        <div
          className={classNames("side-bar-icon-text-wrapper", {
            "active-side-bar": isActivePage('patients', currentLocation),
          })}
          onClick={() => sideBarClickHandler("patients")}
        >
          <PiUsersThreeBold />
          <p className={"side-bar-text "}>
            {isMobile ? "" : "Patients"}
          </p>
        </div>

        {
          userHasRole(signedInUser, [Authorities.CASHIER, Authorities.ADMIN, Authorities.SUPER_ADMIN]) && (
                <div
                    className={classNames("side-bar-icon-text-wrapper", {
                      "active-side-bar": isActivePage('sales', currentLocation),
                    })}
                    onClick={() => sideBarClickHandler("sales")}
                    role={"button"}
                >
                  <FcSalesPerformance/>
                  <p className={"side-bar-text "}>
                    {isMobile ? "" : "Sales"}
                  </p>
                </div>
            )
        }

        <div
            className={classNames("side-bar-icon-text-wrapper", {
              "active-side-bar": isActivePage('admission', currentLocation),
            })}
            onClick={() => sideBarClickHandler("admission")}
            role={"button"}
        >
          <GiMedicalDrip/>
          <p className={"side-bar-text "}>
            {isMobile ? "" : "Admission"}
          </p>
        </div>

        <div className={classNames("side-bar-icon-text-wrapper", {
          "active-side-bar": isActivePage('appointments', currentLocation),
        })}
          onClick={() => sideBarClickHandler("appointments")}
        >
          <FaHandshake />
          <p className={"side-bar-text "}>
            {isMobile ? "" : "Appointments"}
          </p>
        </div>

        {
          (userIsSuperAdmin(signedInUser) || userIsAdmin(signedInUser)) && (
            <div
              className={classNames("side-bar-icon-text-wrapper", {
                "active-side-bar": isActivePage('notifications', currentLocation),
              })}
              onClick={() => sideBarClickHandler("notifications")}
            >
              <MdOutlineNotificationsActive />
              <p className={"side-bar-text "}>
                {isMobile ? "" : "Notifications"}
              </p>
            </div>
          )
        }

        {/*<div
            className={classNames("side-bar-icon-text-wrapper", {
              "active-side-bar": activeSideBar === "purchases",
            })}
            onClick={() => sideBarClickHandler("purchases")}
        >
          <p>
            <BsCart3/>
          </p>
          <Link to={"/dashboard/purchases"} className={"side-bar-text "}>
            {isMobile ? "" : "Purchases"}
          </Link>
        </div>*/}

        <div
          className={classNames("side-bar-icon-text-wrapper", {
            "active-side-bar": isActivePage('manage-account', currentLocation),
          })}
          onClick={() => sideBarClickHandler("manage-account")}
        >
          <CiSettings />
          <p className={"side-bar-text "}>
            {isMobile ? "" : "Manage Account"}
          </p>
        </div>

        {
          userIsSuperAdmin(signedInUser) && (
            <div className={classNames("side-bar-icon-text-wrapper", {
              "active-side-bar": isActivePage('billing', currentLocation),
            })}
              onClick={() => sideBarClickHandler("billing")}>
              <CiMoneyBill />
              <p className={"side-bar-text "}>
                {isMobile ? "" : "Billing"}
              </p>
            </div>
          )
        }

        <div
          className={classNames("side-bar-logout side-bar-icon-text-wrapper", {
            "active-side-bar": isActivePage('logout', currentLocation),
          })}
          onClick={() => logoutHandler()}
        >
          <MdLogout />
          <p
            className={"side-bar-text "}
            onClick={() => logoutHandler()}
          >
            {isMobile ? "" : "Logout"}
          </p>
        </div>
      </div>
    </div>
  );
};
