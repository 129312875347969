import { useState } from "react";
import AsideBanner from "../../components/asideBanner/AsideBanner";
import { Button } from "../../components/button/Button";
import { InputField } from "../../components/inputfield/InputField";
import "./ForgetPassword.scss";
import { useForm } from "react-hook-form";
import EmailVerificationForm from "../../components/emailVerificationForm/EmailVerificationForm";
import { RegistrationSuccessModal } from "../../components/registrationSuccessModal/RegistrationSuccessModal";
import registerService from "../../services/RegisterService";
import loginService from "../../services/LoginService";
import { ErrorNotification } from "../../modules/errornotification/ErrorNotification";
import { KnownUser } from "../../types/Helper";
import ForgotPasswordDto from "../../types/ForgotPasswordDto";
import { FormSubmissionStatus, RegistrationStage } from "../register/Register";

type Password = {
  newPassword: string;
  confirmPassword: string;
};

const ForgetPassword = () => {
  const [registrationStage, setRegistrationStage] =
    useState<RegistrationStage>("main_registration");
  const [formSubmissionStatus, setFormSubmissionStatus] =
    useState<FormSubmissionStatus>("");
  const [isSubmittingForm, setIsSubmittingForm] = useState<boolean>(false);
  const [knownUser, setKnownUser] = useState<KnownUser>({} as KnownUser);
  const [emailOrUsername, setEmailOrUsername] = useState<string>("");
  const [resetPasswordErr, setResetPasswordErr] = useState<string>("");
  const [forgotPasswordData, setForgotPasswordData] =
    useState<ForgotPasswordDto>({} as ForgotPasswordDto);
  const [password, setPassword] = useState<Password>({} as Password);

  const {
    register,
    trigger,
    formState: { errors, isValid },
    getValues,
  } = useForm({ mode: "onBlur" });

  const emailHandler = (e: any) => {
    const { value } = e.target;
    setEmailOrUsername(value);
  };

  const passwordHandler = (e: any) => {
    const { name, value } = e.target;
    setPassword((prev) => ({ ...prev, [name]: value }));

    if (name === "confirmPassword" && value === getValues("newPassword")) {
      setForgotPasswordData((prev) => ({
        ...prev,
        userid: knownUser.userId,
        hospitalId: knownUser.hospitalId,
        newPassword: value,
      }));
    }
  };

  const handleMailSubmit = (e: any) => {
    e.preventDefault();
    trigger().then();

    if (!isValid) {
      setResetPasswordErr("Ensure you enter the necessary inputs correctly");
      return;
    }

    if (isValid) {
      loginService
        .forgotPassword(emailOrUsername)
        .then((user: KnownUser) => {
          setKnownUser(user);
          setRegistrationStage("email_verification");
        })
        .catch(() => {
          setFormSubmissionStatus("failed");
          setIsSubmittingForm(false);
        });
    }
  };

  const handleResetPassword = (e: any) => {
    e.preventDefault();
    trigger().then();

    if (!isValid) {
      setResetPasswordErr("Ensure you enter the necessary inputs correctly");
      return;
    }

    if (isValid) {
      // console.log(forgotPasswordData);
      registerService
        .resetPassword(forgotPasswordData)
        .then((resetData: ForgotPasswordDto) => {
          setForgotPasswordData(resetData);
          setRegistrationStage("success");
        })
        .catch(() => {
          setFormSubmissionStatus("failed");
        });
    }
  };

  return (
    <div className="forget-password-wrapper">
      <AsideBanner />
      <span style={{ clear: "left" }}></span>
      <div className="divider"></div>

      <div className="validation-section">
        <h3 className="forget-password-header">Forgot Password</h3>
        <div className="forget-password">
          {registrationStage === "main_registration" && (
            <>
              <form
                onSubmit={handleMailSubmit}
                className="forget-password-form"
              >
                {resetPasswordErr && (
                  <ErrorNotification
                    name={"book-apt-error"}
                    stringErrors={resetPasswordErr}
                  />
                )}
                <p className="reset-instruction">
                  Please enter your email or username to reset your password
                </p>
                <div className="input-field-container">
                  <InputField
                    id="email|username"
                    name="emailUsername"
                    type="text"
                    defaultValue={emailOrUsername}
                    register={register}
                    errors={errors}
                    placeholder="Enter your email"
                    label="Email or Username"
                    required="Email or Username is required"
                    onChange={emailHandler}
                    pattern={{
                      value:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$|^(?=.*[a-zA-Z])[a-zA-Z0-9._%+-]{6,20}$/,
                      message:
                        "Use a valid email or Username. Email must be of this format username123@provider.com. or Username must be at least 6 characters or alphanumeric long, (e.g. techFan, techFan99 or 99techFan)",
                    }}
                  />
                </div>
                <div className="reset-button-container">
                  <Button id="reset" buttonName="Reset Password" />
                </div>
              </form>
            </>
          )}
          {registrationStage === "email_verification" && (
            <EmailVerificationForm
              setRegistrationStage={setRegistrationStage}
              isSubmittingForm={isSubmittingForm}
              setFormSubmissionStatus={setFormSubmissionStatus}
              setIsSubmittingForm={setIsSubmittingForm}
              forgotPassword={true}
              hospitalId={knownUser.hospitalId}
              userId={knownUser.userId}
            />
          )}
          {registrationStage === "company_registration" && (
            <>
              <form
                onSubmit={handleResetPassword}
                className="forget-password-form"
              >
                {resetPasswordErr && (
                  <ErrorNotification
                    name={"book-apt-error"}
                    stringErrors={resetPasswordErr}
                  />
                )}
                <h3 className="set-password">Set a new password</h3>
                <p className="create-instruction">
                  Create a new password. Ensure it differs from the previous
                  password
                </p>
                <div className="input-field-container">
                  <InputField
                    id="password"
                    name="newPassword"
                    defaultValue={password.newPassword}
                    placeholder="Password"
                    type="password"
                    register={register}
                    errors={errors}
                    label="Password"
                    min={8}
                    minLength={8}
                    required={"Password is required"}
                    animate={true}
                    onChange={passwordHandler}
                    pattern={{
                      value: /^[a-zA-Z0-9!#@]{8,24}$/,
                      message:
                        "Password must be min. of 8 alphanumeric value. Special character allowed are !#@",
                    }}
                  />
                </div>
                <div className="input-field-container">
                  <InputField
                    id="c-password"
                    name="confirmPassword"
                    placeholder="Comfirm password"
                    type="password"
                    defaultValue={password.confirmPassword}
                    register={register}
                    errors={errors}
                    label="Comfirm Password"
                    onChange={passwordHandler}
                    animate={true}
                    validate={(value) =>
                      value === getValues("newPassword") ||
                      "Password confirmation must match password"
                    }
                    min={8}
                    minLength={8}
                    required={"Password confirmation is required"}
                  />
                </div>
                <div className="reset-button-container">
                  <Button id="reset" buttonName="Reset Password" />
                </div>
              </form>
            </>
          )}
          {registrationStage === "success" && (
            <RegistrationSuccessModal
              message={
                "Congratulations, your password has been reset successfully"
              }
            />
          )}
          {formSubmissionStatus === "failed" && (
            <div className={"signup-error-wrapper"}>
              <span></span> {/*Do not delete, used for styling*/}
              <span>
                <ErrorNotification
                  name={"signup-error"}
                  stringErrors={"Something went wrong, please try again later"}
                />
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
