import { ApiService } from "./ApiService";
import { RegistrationData, UserData } from "../types/Helper";
import WhoAmI from "../userAuth/WhoAmI";
import VerifyEmail from "../types/VerifyEmail";
import HospitalDao from "../types/HospitalDao";
import ForgotPasswordDto from "../types/ForgotPasswordDto";

const baseUrl = `${process.env.AUTH_PROXY ?? process.env.REACT_APP_AUTH_PROXY}`;

class RegisterService extends ApiService {
  async registerSuperAdmin(userData: RegistrationData): Promise<WhoAmI> {
    const registerData = Object.assign(
      {
        firstname: userData.firstname,
        lastname: userData.lastname,
        username: userData.username,
        password: userData.password,
        email: userData.email,
        phoneNumber: userData.phoneNumber,
        privacyConsent: userData.privacyConsent,
      },
      {}
    );
    const response = await fetch(baseUrl + "register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(registerData),
    });
    const loginResponse = await this.apiResponseHandler(response);
    return loginResponse.json();
  }

  async verifyEmail(verificationData: VerifyEmail): Promise<Response> {
    const response = await fetch(baseUrl + `register/verify_email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(verificationData),
    });
    return await this.apiResponseHandler(response);
  }

  async updateHospital(newData: HospitalDao): Promise<HospitalDao> {
    const response = await fetch(baseUrl + `admin/hospital`, {
      method: "PATCH",
      body: JSON.stringify(newData),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const responseData = await this.apiResponseHandler(response);
    return await responseData.json();
  }

  async registerUser(hospitalId: number, userData: UserData): Promise<Response> {
    const response = await fetch(baseUrl + `register/${hospitalId}/employee`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });
    return await this.apiResponseHandler(response);
  }

  async resetPassword(
    resetData: ForgotPasswordDto
  ): Promise<ForgotPasswordDto> {
    const response = await fetch(baseUrl + `register`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(resetData),
    });
    const responseData = await this.apiResponseHandler(response);
    return await responseData.json();
  }
}

const registerService = new RegisterService();
export default registerService;
