export enum BillingState {
  PAID = "PAID",
  NOT_PAID = "NOT_PAID",
  PROCESSING = "PROCESSING",
  TRIAL = "TRIAL",
  ON_GOING = "ON_GOING",
  DUE_FOR_PAYMENT = "DUE_FOR_PAYMENT",
}

export class PaymentSummaryDTO {
  constructor(
    public total: Total,
    public atrizultFeeCurrent: AtrizultCurrentFee,
    public atrizultFeePrevious: AtrizultPreviousFee
  ) {}
}

interface Total {
  revenue: number;
  lastMonthRevenue: number;
  percentageDecInc: number;
}

interface AtrizultCurrentFee {
  totalAmount: number;
  vat: number;
  nextDueDate: [number, number, number];
  billingState: BillingState | null;
}
interface AtrizultPreviousFee {
  totalAmount: number;
  vat: number;
  deadline: [number, number, number];
  billingState: BillingState | null;
}
