import './ToggleSwitch.scss'
import {useState} from "react";

interface ToggleSwitchProps {
    label?: string
    toggled?: boolean
    onToggle?(toggled: boolean): any
}

export const ToggleSwitch = (props: ToggleSwitchProps) => {
    const [isChecked, setIsChecked] = useState(props?.toggled);

    const handleToggle = () => {
        const newChecked = !isChecked;
        setIsChecked(newChecked);
        props.onToggle && props.onToggle(newChecked);
    };

    return (
        <div className={'switch-component'}>
            <h2>{props?.label ?? 'Switch'}</h2>
            <label className="switch">
                <input type="checkbox" checked={isChecked} onChange={handleToggle}/>
                <span className="slider round"></span>
            </label>

        </div>
    )
}