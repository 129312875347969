import React from 'react';

const DownloadIcon = () => {
	return (
		<svg width='20' height='19' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<rect x='1.10396' y='0.801717' width='15.6029' height='15.6029' stroke='black' strokeWidth='0.821208' />
			<path
				d='M8.90757 11.0513L6.48637 8.62937L6.9702 8.13733L8.5654 9.73253V3.81299H9.24974V9.73253L10.8442 8.13802L11.3288 8.62937L8.90757 11.0513ZM5.2224 13.3937C4.9076 13.3937 4.64481 13.2884 4.43404 13.0776C4.2228 12.8663 4.11719 12.6033 4.11719 12.2885V10.6304H4.80153V12.2885C4.80153 12.3935 4.84533 12.49 4.93292 12.578C5.02097 12.6656 5.11746 12.7094 5.2224 12.7094H12.5927C12.6977 12.7094 12.7942 12.6656 12.8822 12.578C12.9698 12.49 13.0136 12.3935 13.0136 12.2885V10.6304H13.6979V12.2885C13.6979 12.6033 13.5926 12.8661 13.3818 13.0769C13.1705 13.2881 12.9075 13.3937 12.5927 13.3937H5.2224Z'
				fill='black'
			/>
		</svg>
	);
};

export default DownloadIcon;
