import { ApiService } from './ApiService';
import NewAdmissionDto from '../types/admission/NewAdmissionDto';
import AdmissionDao, { AdmissionsListResponse } from '../types/admission/AdmissionDao';
import { AdmissionAnalyticsDto } from '../types/Dashboard/DashboardModels';
import {ExtraNoteDto} from "../pages/admission/addextranote/AddExtraNote";
import {UUID} from "crypto";

const baseUrl = `${process.env.ACC_PROXY ?? process.env.REACT_APP_ACC_PROXY}admission`;

class AdmissionService extends ApiService {
	/** Handles admission of a patient irrespective of the patient type */
	async newAdmission(newAdmissionDto: NewAdmissionDto): Promise<AdmissionDao> {
		const response = await fetch(baseUrl+'/'+newAdmissionDto.hospitalId, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(newAdmissionDto),
		});
		const loginResponse = await this.apiResponseHandler(response);
		return loginResponse.json();
	}

	/** Handles admission of a patient irrespective of the patient type */
	async updateAdmission(admissionDao: AdmissionDao): Promise<AdmissionDao> {
		const response = await fetch(baseUrl+'/'+admissionDao.hospitalId, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(admissionDao),
		});
		const loginResponse = await this.apiResponseHandler(response);
		return loginResponse.json();
	}

	// /accounting/v1/admission/{hospital_id}/analytics?from={from}&to={to}
	async fetchAdmission(hospitalId: number, fromDate: string, toDate: string): Promise<AdmissionAnalyticsDto> {
		try {
			const response = await fetch(`${baseUrl}/${hospitalId}/analytics?from=${fromDate}&to=${toDate}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			});
			const data = await this.apiResponseHandler(response);
			return data.json();
		} catch (error: any) {
			return error.message;
		}
	}

	async getPatientLatestAdmission(hospitalId: number, patientId: number): Promise<AdmissionDao> {
		const response = await fetch(`${baseUrl}/${hospitalId}/${patientId}/latest`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		const data = await this.apiResponseHandler(response);
		return await data.json();

	}

	async updateAdmissionExtraNote(hospitalId: number, admissionId: UUID, extraNote: ExtraNoteDto): Promise<AdmissionDao> {
		const response = await fetch(`${baseUrl}/${hospitalId}/${admissionId}/extra-note`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(extraNote),
		});
		const updateResponse = await this.apiResponseHandler(response);
		return updateResponse.json();
	}

	async dischargePatientByDoctor(hospitalId: number, admissionId: UUID): Promise<AdmissionDao> {
		const response = await fetch(`${baseUrl}/${hospitalId}/${admissionId}/discharge?action=DISCHARGED_BY_DOCTOR`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			}
		});
		const updateResponse = await this.apiResponseHandler(response);
		return updateResponse.json();
	}

	async appointmentList(hospitalId: number): Promise<AdmissionDao[]> {
		const response = await fetch(`${baseUrl}/${hospitalId}/all`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		const getResponse = await this.apiResponseHandler(response);
		return getResponse.json();
	}

	getAdmissionsQueue = async (hospitalId: number, pN: number=0, pS: number=25):Promise<AdmissionsListResponse> => {
		const response = await fetch(`${baseUrl}/${hospitalId}?pN=${pN}&ps=${pS}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		const getResponse = await this.apiResponseHandler(response);
		return getResponse.json();
	}

	async checkoutPatient(hospitalId: number, admissionId: UUID): Promise<AdmissionDao> {
		const response = await fetch(`${baseUrl}/${hospitalId}/${admissionId}/discharge?action=CHECKOUT`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			}
		});
		const updateResponse = await this.apiResponseHandler(response);
		return updateResponse.json();
	}
}

const admissionService = new AdmissionService();
export default admissionService;
