import {InvoiceHeader} from "../invoice/InvoiceHeader";
import NewInvoiceReqData from "../../types/invoice/NewInvoiceReqData";
import './InvoiceSummary.scss'
import {DialogPageType} from "../../pages/customer/newpatient/Patient";
import InvoiceDao from "../../types/invoice/InvoiceDao";
import {getTodaySDate, formatZoneDate, convertToLocaleString, capitalizeFirstCharacter} from "../../util/Helpers";
import {useState} from "react";
import {useWhoAmI} from "../../context/UserCtx";
import Handshake from "../../resources/assets/svg/Handshake";

interface InvoiceSummaryComponentProps {
    newInvoice?: NewInvoiceReqData,
    savedInvoice?:  InvoiceDao,
    modalAction?: DialogPageType
}

export const InvoiceSummaryComponent = (props: InvoiceSummaryComponentProps) => {
    const [invoiceData] = useState(props.savedInvoice ? props?.savedInvoice : props?.newInvoice);
    const invoiceDate = props.savedInvoice?.createdDate ?
        formatZoneDate(props.savedInvoice?.createdDate) : getTodaySDate();
    const invoiceLastPrintedDate = props.savedInvoice?.lastPrintedDate ?
        formatZoneDate(String(props.savedInvoice?.lastPrintedDate)) : getTodaySDate();
    const whoAmI = useWhoAmI();

    return (
        <div>
            <InvoiceHeader page={'invoiceSummary'}
                           paymentStatus={props?.newInvoice?.paymentStatus ?? props?.savedInvoice?.paymentStatus}
                           paymentMethod={props?.newInvoice?.paymentMethod ?? props?.savedInvoice?.paymentMethod}
                           invoiceDate={invoiceLastPrintedDate}
                           invoiceNo={props?.savedInvoice?.invoiceNumber}
            />

            <div className={'invoice-summary-content-wrapper'}>
                <div>
                    <div className={'invoice-summary-table invoice-summary-headline'}>
                        {/*<p>Date</p>*/}
                        <p>Id</p>
                        <p>Qty</p>
                        <p>Service</p>
                        <p>Unit Price(N)</p>
                        <p>Price(N)</p>
                    </div>
                    <div>
                        {/*<p className={'date-of-service'}>{invoiceDate}</p>*/}
                        {
                            invoiceData?.selectedServices && invoiceData.selectedServices.map((service, index) => (
                                <div className={'invoice-summary-table bring-invoice-up'} key={index}>
                                    {/*Do not   delete, used for styling*/}
                                    <p>{service.id}</p>
                                    <p>{service.quantity}</p>
                                    <p>{service.serviceName}</p>
                                    <p>{service.unitPrice}</p>
                                    <p>{service.price.toFixed(2)}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className={'invoice-summary-total-section-wrapper'}>
                    {/*Do not delete, used for styling*/}
                    <div className={'width-10-percent-style'}></div>
                    <div className={'invoice-summary-table total-wrapper'}>
                        {/* Do not delete, this is used for styling */}
                        <div className={'summary-total-paid-balance'}></div>
                        <div className={'summary-total-paid-balance'}></div>

                        <div className={'summary-total-paid-balance'}>
                            <div className={'summary-total-paid-balance-left'}>
                                <p><b>Total:</b></p>
                                <p><b>Amount Paid:</b></p>
                                <p><b>Balance:</b></p>
                            </div>
                            <div>
                                <p>{convertToLocaleString(invoiceData?.total)}</p>
                                <p>{convertToLocaleString(invoiceData?.amountPaid) || 0}</p>
                                <p><b>{convertToLocaleString(invoiceData?.balance) || 0}</b></p>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    props?.modalAction === 'Print' && (
                        <div className={'thank-you-msg'}>
                            <p>
                                <Handshake/>
                            </p>
                            <p className={'align-thank-you-txt'}>
                                Thank you for choosing {capitalizeFirstCharacter(whoAmI?.hospital?.name)}.
                                We appreciate your trust in us.
                            </p>
                        </div>
                    )
                }
            </div>
        </div>
    )
}