import { Link } from "react-router-dom";
import { InputField } from "../inputfield/InputField";
import { Button } from "../button/Button";
import {
	FieldErrors,
	FieldValues,
	UseFormRegister,
	UseFormSetError,
} from "react-hook-form";
import { RegistrationData } from "../../types/Helper";
import "./RegistrationForm.scss";
import ShowPassword from "../showPassword/ShowPassword";
import { useState } from "react";


type RegistrationFormProps = {
	register: UseFormRegister<FieldValues>;
	errors: FieldErrors<FieldValues>;
	setError: UseFormSetError<FieldValues>;
	isSubmittingForm: boolean;
	userData: RegistrationData;
	userDataHandler(e: any): any;
	handleSubmit(): any;
	handleAcceptTerms: (state: boolean) => void
	getValues: any
};

function RegistrationForm({
	userData,
	errors,
	userDataHandler,
	register,
	handleSubmit, handleAcceptTerms,
	isSubmittingForm,
	getValues
}: RegistrationFormProps) {
	const [confirmPasswordType, setConfirmPasswordType] = useState<'text' | 'password'>('password')
	return (
		<div id="form-container">
			<div id="active-form-tabs">
				<Link to={"/register"} className="button signup active-form-tab">
					<strong>Sign Up</strong>
				</Link>
				<Link className="button" to={"/login"}>
					<strong>Login</strong>
				</Link>
			</div>
			<div id="signup-form">
				<p>
					Fields with <sup>*</sup> are compulsory.
				</p>

				<InputField
					id="username"
					name="username"
					defaultValue={userData.username}
					onChange={userDataHandler}
					errors={errors}
					placeholder="Username"
					label="Username"
					pattern={{
						value: /^(?=.*[a-zA-Z])[a-zA-Z0-9]{6,20}$/,
						message:
							"Username must be at least 6 characters or alphanumeric long, (e.g. techFan, techFan99 or 99techFan).",
					}}
					register={register}
					minLength={6}
					min={6}
					required={"Username is required"}
					animate={true}
				/>

				<InputField
					id="firstname"
					name="firstname"
					defaultValue={userData.firstname}
					onChange={userDataHandler}
					errors={errors}
					placeholder="Firstname"
					label="Firstname"
					pattern={{
						value: /^[a-zA-Z]{2,20}$/,
						message:
							"Min. of 2 characters required. Names may only contain letters (e.g. Solomon), no special characters are allowed.",
					}}
					register={register}
					minLength={2}
					min={2}
					required={"Firstname is required"}
					animate={true}
				/>

				<InputField
					id="lastname"
					name="lastname"
					defaultValue={userData.lastname}
					onChange={userDataHandler}
					errors={errors}
					placeholder="Lastname"
					label="Lastname"
					pattern={{
						value: /^[a-zA-Z]{2,20}$/,
						message:
							"Min. of 2 characters required. Names may only contain letters, no special characters are allowed.",
					}}
					register={register}
					min={2}
					minLength={2}
					required={"Lastname is required"}
					animate={true}
				/>

				<InputField
					id="phoneNumber"
					name="phoneNumber"
					defaultValue={userData.phoneNumber}
					onChange={userDataHandler}
					errors={errors}
					placeholder="Phone number"
					label="Phone number"
					pattern={{
						value: /^\+?(\d{1,4}[-.\s]?)?((\(\d{1,4}\))|\d{1,4})[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
						message:
							"Phone number in one of these formats (08012345678 or +2348012345678) is required",
					}}
					type="tel"
					register={register}
					minLength={11}
					maxLength={16}
					required={"Phone number is required"}
					animate={true}
				/>

				<InputField
					id="email"
					name="email"
					defaultValue={userData.email}
					onChange={userDataHandler}
					errors={errors}
					placeholder="Email"
					label="Email"
					pattern={{
						value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
						message:
							"Use a valid email. Email must be of this format username123@provider.com.",
					}}
					register={register}
					min={1}
					minLength={1}
					required={"Email is required"}
					animate={true}
					type="email"
				/>

				<InputField
					id="password"
					name="password"
					defaultValue={userData.password}
					onChange={userDataHandler}
					errors={errors}
					placeholder="Password"
					label="Password"
					pattern={{
						value: /^[a-zA-Z0-9!#@]{8,24}$/,
						message:
							"Password must be min. of 8 alphanumeric value. Special character allowed are !#@",
					}}
					register={register}
					min={8}
					minLength={8}
					required={"Password is required"}
					animate={true}
					type={"password"}
					showPassword={true}
					togglePasswordVisibility={setConfirmPasswordType}
				/>

				<InputField
					id="confirmPassword"
					name="confirmPassword"
					defaultValue={userData.confirmPassword}
					onChange={userDataHandler}
					errors={errors}
					placeholder="Confirm password"
					label="confirm password"
					register={register}
					validate={(value) =>
						value === getValues('password') || "Password confirmation must match password"
					}
					min={8}
					minLength={8}
					required={"Password confirmation is required"}
					animate={true}
					type={confirmPasswordType}
					onKeyDown={(evt: KeyboardEvent) => {
						if (evt.key === 'Enter') {
							handleSubmit();
						}
					}}
				/>
				<ShowPassword isTerms isVisible toggleShowPassword={handleAcceptTerms} errors={errors} name="privacyConsent" />
				<div className={"register-btn-wrapper"}>
					<Button
						id="submit-btn"
						name="submit-btn"
						buttonName={isSubmittingForm ? "Please wait..." : "Sign Up"}
						onClick={handleSubmit}
						value={isSubmittingForm ? "Please wait..." : "Login"}
					/>

					<p className="already-have-account">
						Already have an account?{" "}
						<strong>
							<a href="/login">Login</a>
						</strong>
					</p>
				</div>
			</div>
		</div>
	);
}

export default RegistrationForm;
