import "./HomePage.scss";
import { Link } from "react-router-dom";
import { InputField } from "../components/inputfield/InputField";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { TextArea } from "../components/textarea/TextArea";
import { Button } from "../components/button/Button";
import { ContactInfo, Topic } from "../types/Helper";
import upprojrction from "../resources/assets/images/upprojection.png";
import dotlines from "../resources/assets/images/dotlines.png";
import womanWithTestube from "../resources/assets/images/testtube.png";
import doctorimg from "../resources/assets/images/doctorimg.png";
import freehand from "../resources/assets/images/freehand.png";
import online from "../resources/assets/images/online.png";
import mobilefriendly from "../resources/assets/images/mobilefriendly.png";
import doctorwoman from "../resources/assets/images/doctorwoman.png";
import frontpin from "../resources/assets/images/backpin.png";
import backpin from "../resources/assets/images/frontpin.png";
import { CiCircleCheck } from "react-icons/ci";
import homepageService from "../services/HompageService";
import { Modal } from "../components/modal/Modal";
import { Dropdown } from "../components/dropdown/Dropdown";
import SuccessPopUp from "../components/successPopUp/SuccessPopUp";

const HomePage = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [formIsSubmitted, setFormIsSubmitted] = useState(false);
  const [successMsg, setSuccessMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [contactInfo, setContactInfo] = useState<ContactInfo>(
    {} as ContactInfo
  );
  const [activeIndex, setActiveIndex] = useState(1);

  const aboutCards = [
    {
      title: " Mission and vision",
      info:
        "Most Nigerian hospitals still operate with very little or no hospital management software which causes " +
        "inefficient patient management, inaccuracies, cost intensive, and unnecessary patient visitation especially " +
        "services that can easily be offered digitally from anywhere in the world. To solve these challenges, AtRiZult " +
        "engineered HealthProSuite.",
    },
    {
      title: "Scalability",
      info:
        "Our system has the capacity to carry as many users or as many data and still maintain a high performance level," +
        " which makes it flexible and can adapt and expand to meet with changing requirement or integration with other system. " +
        "Our system can be accessed from every location and in every device. Our database architecture, server infrastructure and " +
        "network configuration is well standardised. All our data is highly secured and can be retrive at any point",
    },
    {
      title: "Key Features",
      info:
        "Access based system with customizable analytics dashboard, patient management, appointment Scheduling assistant," +
        " admission, queuing, price control, invoicing, payment, billing, purchase, payroll, many more",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) =>
        prevIndex === aboutCards.length ? 1 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  });

  useEffect(() => {
    const carousel = document.querySelector(
      ".about-health-pro-suite-wrapper"
    ) as HTMLDivElement;
    const carouselWidth = carousel?.scrollWidth;
    carousel?.scroll({
      left: (carouselWidth / aboutCards.length) * (activeIndex - 1),
    });
  }, [activeIndex, aboutCards.length]);

  const updateCard = (index: number) => {
    setActiveIndex(index);
  };

  const categories = [
    {
      name: `basic plan`,
      level: `free`,
      condition: `Available Module`,
      items: [
        "30 days free",
        "1 Super Admin",
        "2 user",
        "247 support",
        "mobile friendly",
      ],
      button: `active`,
      url: `/register`,
    },
    {
      name: `Business Plan`,
      level: `5% on Fees`,
      condition: `Available Module`,
      items: [
        "unlimited days",
        "3 Super Admin",
        "unlimited Admin",
        "247 Support",
        "Notification support",
        "customized Dashboard",
        "mobile friendly",
      ],
      button: `buy now`,
      url: `/register`,
    },
    {
      name: `Enterprise Plan`,
      level: `Let's Talk`,
      condition: `Available Module`,
      items: [
        "unlimited days",
        "3 Super Admin",
        "unlimited Admin",
        "247 Support",
        "Notification support",
        "customized Dashboard",
        "mobile phone friendly",
      ],
      button: `contact us`,
    },
    {
      name: `Super Enterprise`,
      level: `Let's Talk`,
      condition: `Available Module`,
      items: [],
      button: `contact us`,
    },
  ];

  const scrollToDiv = (sectionId: any) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const {
    register,
    trigger,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: "onBlur" });
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSuccessMessage('')
    setErrorMessage('')
  };

  const getContactFormTopic = (topic: Topic) => {
    setContactInfo((prevState) => ({ ...prevState, topic }));
  };

  const contactDataHandler = (evt: any) => {
    const { target } = evt;
    const { name, value } = target;

    const updatedContactInfo = { ...contactInfo };
    updatedContactInfo[name as keyof ContactInfo] = value;
    setContactInfo(updatedContactInfo);
  };

  const handleReset = () => {
    const inputElements = document.getElementsByTagName("input");
    const inputArray = Array.from(inputElements);
    inputArray.forEach((element) => {
      element.value = "";
    });
  };
  const onSubmit = () => {
    trigger().then();
    if (isValid && contactInfo?.topic) {
      homepageService
        .contact(contactInfo)
        .then((response) => {
          if (response.ok) {
            setFormIsSubmitted(true);
            setFormSubmit(true);
            openModal();
            setSuccessMessage("Thank you for contacting us");
          } else {
            setFormSubmit(true);
            setFormIsSubmitted(false);
            openModal();
            setErrorMessage("Server error while submitting your contact form, Please Try again later");
          }
          reset();
          setContactInfo({} as ContactInfo);
          handleReset();
        })
        .catch(() => {
          setFormIsSubmitted(false);
          setFormSubmit(true);
          openModal();
          setErrorMessage(
            "Technical error occurred while submitting your contact form, Please Try again later"
          );
          reset();
        });
    } else
      setErrorMessage(
        "Something went wrong while submitting your contact form, Please Try again later"
      );
  };

  return (
    <main>
      <div className="home-page-container">
        {/* banner */}
        <div id="banner">
          <div id="hero">
            <div id="main">
              <section>
                <h1>Grow With Us</h1>
                <img
                  src={upprojrction}
                  className="up_projection"
                  alt="up"
                />{" "}
                <br />
                <h6>
                  Manage Your Hospital Activities With{" "}
                  <span className="lg">HealthProSuite</span>.
                </h6>
              </section>
              <p>
                Organize, manage your patients and hospital with{" "}
                <b>HealthProSuite</b> - a hospital management system that
                guarantee efficient operation of your hospital. Managing a
                hospital requires efficient handling of resources, which in turn
                enhances services, improves life saving and increases
                productivity in general.
              </p>
              <div>
                <Button
                  id={"get-started"}
                  name={"get-started"}
                  buttonName={"Get Started"}
                  onClick={() => (window.location.href = "/register")}
                />
              </div>
            </div>
            <div className="fade-in" id="aside">
              <img src={womanWithTestube} className="woman-testube" alt="" />
            </div>
          </div>
        </div>
        <div className="base-img">
          <img src={dotlines} className="dotlines" alt="" />
        </div>

        {/* features */}
        <div id="features">
          <h2>
            <span>HealthProSuite</span> Features
          </h2>
          <div className="features-content">
            <p className="info">
              From electronic medical record to invoicing, analytics, patient
              portal, laboratory, etc. HealthProSuite has all the tools you need
              to manage your resources anywhere anytime.
            </p>
            <div className="doctor-point">
              <div className="img-section">
                <img src={doctorimg} className="doctor-img" alt="A doctor" />
              </div>
              <div className="point-section">
                <div className="point">
                  <img
                    src={freehand}
                    className="freehand"
                    alt="Hand  holding heart"
                  />
                  <div>
                    <h3>Free Trial</h3>
                    <p>
                      That's right, completely free for the first month.
                      HealthProSuite is completely free and user can have an
                      access to the entire features of the system to manage own
                      hospital.
                    </p>
                  </div>
                </div>
                <div className="point">
                  <img src={online} className="online" alt="Globe icon" />
                  <div>
                    <h3>Online</h3>
                    <p>
                      View analytics, schedule appointments, consult patients,
                      etc., anytime, anywhere on your computer, tablet or phone.
                      Save life from anywhere and know your cash position.
                    </p>
                  </div>
                </div>
                <div className="point">
                  <img
                    src={mobilefriendly}
                    className="friendly"
                    alt="Mobile phone"
                  />
                  <div>
                    <h3>Flexible</h3>
                    <p>
                      HealthProSuite is engineered in such a way to allow
                      AtRiZult clients to request features features they wish to
                      have as an enterprise client or to be integrated to
                      HealthProSuite.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="base-img">
          <img src={dotlines} className="dotlines" alt="" />
        </div>

        {/* patient registration */}
        <div id="patient-reg">
          <div className="reg">
            <h3>Patient Registration</h3>
            <div className="text-pin">
              <p>
                <img src={backpin} className="back-pin" alt="pin" />
                Give your clients the ease of client portal. Share the
                transactions and invoices with your clients and accept bulk
                payments Give your clients the ease of client portal. Share the
                transactions and invoices with your clients and accept bulk
                payments
              </p>
            </div>
            <div className="button-pin">
              <img src={frontpin} className="front-pin" alt="pin" />
              <div className="get-started-button">
                <Link to="/register">
                  <Button
                    id={"get-started"}
                    name={"get-started"}
                    buttonName={"Get Started"}
                    onClick={(e: any) => e}
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="doc-section">
            <img src={doctorwoman} className="doctorwoman" alt="" />
          </div>
        </div>
        <div className="base-img">
          <img src={dotlines} className="dotlines" alt="" />
        </div>

        {/* About HealthProSuite*/}
        <div className="about" id="about">
          <h2>
            About <span>HealthProSuite</span>
          </h2>

          <p className={"about-health-pro-suite"}>
            <span className="ref-to-atrizult">
              HealthProSuite is product of{" "}
              <a href={"https://atrizult.com"} target="_blank" rel="noreferrer">
                AtRiZult Global Limited.
              </a>
              HealthProSuite aims to leverage your hospital and patients daily
              operations by providing you feature such as patient management,
              invoicing, payments, laboratory, admission, payroll, upload,
              downloads, etc. and in doing so aids to save more lives while
              given you insight into the entire hospital happenings in a minute.
            </span>
          </p>

          <div className={"about-health-pro-suite-wrapper"}>
            {aboutCards &&
              aboutCards.length > 0 &&
              aboutCards.map((card, index) => (
                <div className={"about-health-pro-suite-all"} key={index}>
                  <h3>{card.title}</h3>
                  <p>{card.info}</p>
                </div>
              ))}
          </div>
          <div className="indicators">
            {aboutCards &&
              aboutCards.length > 0 &&
              aboutCards.map((card, index) => (
                <div
                  key={index}
                  className={`indicator ${index + 1 === activeIndex ? "active" : ""
                    }`}
                  onClick={() => updateCard(index)}
                ></div>
              ))}
          </div>
        </div>
        <div className="base-img">
          <img src={dotlines} className="dotlines" alt="" />
        </div>

        {/* billing */}
        <div className="billing">
          <div className="billing-info">
            <h3>Choose your Pricing plan</h3>
            <p>
              All Pricing plan are free for the first 30 days
              <br />
              Specifically billed monthly
            </p>
          </div>
          <div className="billing-cards">
            {categories.map((category, index) => (
              <div className={`card card-${index + 1}`} key={index}>
                <div className="card-content">
                  <h3 className="card-name">{category.name}</h3>
                  <h3 className="card-level">{category.level}</h3>
                  <p className="card-condition">{category.condition}</p>
                  <ul>
                    {category.items.map((item, itemIdex) => (
                      <li key={itemIdex}>
                        <CiCircleCheck
                          style={{
                            color: "white",
                            background: "black",
                            borderRadius: "50%",
                            width: "18px",
                            height: "18px",
                          }}
                        ></CiCircleCheck>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                {category.url ? (
                  <Link to={`${category.url}`} style={{ marginTop: "auto" }}>
                    <button className={`btn btn-${index + 1}`}>
                      {category.button}
                    </button>
                  </Link>
                ) : (
                  <button
                    className={`btn btn-${index + 1}`}
                    onClick={() => scrollToDiv("contactUs")}
                  >
                    {category.button}
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="base-img">
          <img src={dotlines} className="dotlines" alt="" />
        </div>

        {/* contact */}
        <div id="contactUs" className="contact-us">
          <h2>
            Contact <span className="pro">HealthProSuite</span>
          </h2>

          <div className="form-item">
            <div className="fullname">
              <InputField
                id="fullName"
                name="fullName"
                placeholder="Full Name"
                label="Full Name"
                onChange={contactDataHandler}
                defaultValue={""}
                type="text"
                register={register}
                pattern={{
                  value: /^.*[a-zA-Z]\s[a-zA-Z]+$/,
                  message: "Min. of 2 characters required",
                }}
                required={"Full name is required"}
                minLength={3}
                maxLength={50}
                errors={errors}
              />
            </div>

            <div className="email">
              <InputField
                id="email"
                name="email"
                placeholder="example@gmail.com"
                label="Email"
                onChange={contactDataHandler}
                defaultValue={""}
                type="text"
                register={register}
                required={"email is required"}
                minLength={3}
                errors={errors}
                pattern={{
                  value: /^[*a-z.0-9]{2,24}@[a-z]{2,9}.[a-z]{2,9}$/,
                  message: "Valid email is required.",
                }}
              />
            </div>
            <div className="dropDown">
              <Dropdown
                key={successMsg || errorMessage}
                id="topic"
                name="topic"
                onChange={getContactFormTopic}
                defaultValue={contactInfo?.topic}
                placeholder="Topic"
                options={[
                  { label: Topic.SALES, value: Topic.SALES },
                  { label: Topic.ENQUIRY, value: Topic.ENQUIRY },
                  { label: Topic.SUPPORT, value: Topic.SUPPORT },
                ]}
                getSelectedOptionValue={getContactFormTopic}
                errors={errors}
              />
            </div>
            <div className="p-number">
              <InputField
                id="phoneNumber"
                name="phoneNumber"
                placeholder="12345678"
                label="Phone Number"
                required={"Phone number is required"}
                onChange={contactDataHandler}
                defaultValue={contactInfo?.phoneNumber}
                type="text"
                pattern={{
                  value: /^(^[+][0-9]{6,15}|^[0-9]{6,15})$/,
                  message:
                    "Phone number in one of these formats (08012345678 or +2348012345678) is required",
                }}
                min={6}
                minLength={6}
                errors={errors}
                register={register}
              />
            </div>
            <div className="textarea">
              <TextArea
                name="message"
                onChange={contactDataHandler}
                placeholder="Enter your message here"
                defaultValue={contactInfo?.message}
                value={contactInfo?.message}
                required
                register={register}
                label={"Enter your message below"}
                id="message"
                min={20}
                maxLength={1500}
              />
            </div>
            <div className="custom-button">
              <Button
                id="send"
                name="send"
                buttonName="Send"
                type="submit"
                onClick={onSubmit}
              />
            </div>
          </div>
          {formSubmit && (
            <div className="homepage-modal-wrapper">
              <Modal
                isOpen={isModalOpen}
                onClose={closeModal}
                modalTitle={
                  formIsSubmitted ? "Successfully Sent!" : "Failed to send"
                }
              >
                {formIsSubmitted ? (
                  <SuccessPopUp successMessage={successMsg} onBtnClick={closeModal} />
                ) : (
                  <div className="msg">{errorMessage}</div>
                )}
              </Modal>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export { HomePage };
