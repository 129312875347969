import './SearchBar.scss'
import {BsSearch} from "react-icons/bs";
import React, {Ref} from "react";
import {FieldErrors, FieldValues, Message, UseFormRegister, ValidationRule} from "react-hook-form";

interface SearchBarProps {
    id: string,
    ref?: Ref<any>,
    name: string,
    defaultValue: string,
    placeholder: string,
    required?: Message | ValidationRule<boolean>,
    className?: string,
    register?: UseFormRegister<FieldValues>,
    errors?: FieldErrors,
    //endpoint?(searchInput: string): any, //TODO; returns search result from backend
    onChange: any,
    onBlur?: any,
    query?: string,
    pattern?: ValidationRule<RegExp>,

    getSearchResult?(results: []): [],
}

export const SearchBar = React.forwardRef((props: SearchBarProps, ref: any) => {
    const register = props.register as UseFormRegister<FieldValues>;
    let error = props.errors && props.errors[props.name as string];

    const onBlurHandler = () => {
        if (props.onBlur) {
            props.onBlur()
        }
    }

    return (
        <div className={"search-bar-component"} ref={ref}>
            <div className={"search-bar-input-icon-wrapper"}>
                <div className={"search-bar-input"}>
                    <span>
                        <input
                            id={"id-" + props.id}
                            placeholder={props.placeholder}
                            defaultValue={props.defaultValue}
                            {...register(props.name, {
                                required: props.required,
                                pattern: props.pattern,
                                onChange: (e: any) => {
                                    props.onChange(e);
                                    //handleInputChange(e)
                                },
                                onBlur: () => {
                                    onBlurHandler()
                                }
                            })}
                            type={'search'}
                        />
                        <BsSearch className={'search-bar-icon'} size={20}/>
                    </span>
                </div>
                <div className={'search-bar-input input-error-wrapper'}>
                    {
                        error?.message && error.message && (
                            <label>
                                <strong className={"input-error"}>{error.message as unknown as string}</strong>
                            </label>
                        )
                    }
                </div>
            </div>
        </div>
    )
})