import { useState, useEffect } from "react";
import { useWhoAmI } from "../context/UserCtx";
import imageService from "../services/imageservices/ImageService";
const useHospitalLogo = () => {
  const { hospital } = useWhoAmI();
  const [imgSrc, setImgSrc] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const getHospitalLogo = () => {
    try {
      imageService
        .getHospitalLogo(hospital.id)
        .then((imgUrl: string) => {
          setImgSrc(imgUrl);
        })
        .catch(() => {
          setErrorMessage("Error occurred while getting the hospital logo");
        });
    } catch (err) {
      setErrorMessage(
        "Technical error occurred while getting the hospital logo"
      );
    }
  };

  useEffect(() => {
    hospital.id && getHospitalLogo();
  }, [hospital.id]);

  return { imgSrc, error:errorMessage };
};

export default useHospitalLogo;
