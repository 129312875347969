import React from 'react';

const SalesAndServiceIcon = () => {
	return (
		<svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path fillRule='evenodd' clipRule='evenodd' d='M12.7003 19.1581V3.14451C12.7003 2.4271 12.6985 1.96197 12.6563 1.621C12.616 1.30073 12.5509 1.20022 12.4987 1.14404C12.4464 1.08787 12.3529 1.01791 12.055 0.974548C11.7369 0.929217 11.3051 0.927246 10.6378 0.927246C9.97041 0.927246 9.53773 0.929217 9.22055 0.974548C8.92262 1.01791 8.82912 1.08787 8.77687 1.14404C8.72462 1.20022 8.65953 1.30073 8.6192 1.621C8.57703 1.96295 8.5752 2.4271 8.5752 3.14451V19.1581H12.7003Z' fill='#85B6FF' />
			<path
				opacity='0.7'
				d='M7.20033 7.57942C7.20033 7.3834 7.1279 7.19541 6.99896 7.05681C6.87003 6.9182 6.69515 6.84033 6.51281 6.84033H3.76272C3.58038 6.84033 3.4055 6.9182 3.27657 7.05681C3.14763 7.19541 3.0752 7.3834 3.0752 7.57942V19.1584H7.20033V7.57942ZM18.2007 12.5067C18.2007 12.3106 18.1283 12.1227 17.9993 11.9841C17.8704 11.8454 17.6955 11.7676 17.5132 11.7676H14.7631C14.5807 11.7676 14.4059 11.8454 14.2769 11.9841C14.148 12.1227 14.0756 12.3106 14.0756 12.5067V19.1584H18.2007V12.5067Z'
				fill='#85B6FF'
			/>
			<path opacity='0.5' d='M1.47073 19.1582C1.28838 19.1582 1.11351 19.2361 0.984574 19.3747C0.855638 19.5133 0.783203 19.7013 0.783203 19.8973C0.783203 20.0933 0.855638 20.2813 0.984574 20.4199C1.11351 20.5585 1.28838 20.6364 1.47073 20.6364H19.8047C19.987 20.6364 20.1619 20.5585 20.2908 20.4199C20.4198 20.2813 20.4922 20.0933 20.4922 19.8973C20.4922 19.7013 20.4198 19.5133 20.2908 19.3747C20.1619 19.2361 19.987 19.1582 19.8047 19.1582H1.47073Z' fill='#85B6FF' />
		</svg>
	);
};

export default SalesAndServiceIcon;
