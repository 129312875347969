import './PatientsChart.scss';
import MixedBarChart from '../../../components/Charts/MultipleBarChart/MixedBarChart';

const PatientsChart = () => {
	return (
		<div className='patients_chart_container'>
			<MixedBarChart />
		</div>
	);
};

export default PatientsChart;
