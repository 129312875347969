import {useEffect, useState} from "react";
import salesService from "../services/SalesService";
import {useWhoAmI} from "../context/UserCtx";
import admissionService from "../services/AdmissionService";
import {
    AdmissionDTO,
    PatientAgeRange,
    SalesDOTResponse,
} from "../types/Dashboard/DashboardModels";
import {formatDateDDMMYYYY, yesterdayAndToday} from "../util/Helpers";
import adminService from "../services/AdminService";

const useDashboardHook = () => {
    const [patientsAgeRange, setPatientsAgeRange] = useState<PatientAgeRange>();
    const [admissionDto, setAdmissionDto] = useState<AdmissionDTO>();
    const [salesServices, setSalesServices] = useState<SalesDOTResponse>({} as SalesDOTResponse);
    const [staffCount, setStaffCount] = useState<number>(0);
    const [loadingStates, setLoadingStates] = useState<{
        dataRep: boolean;
        totalStaff: boolean;
        admission: boolean;
    }>({dataRep: false, totalStaff: false, admission: false});
    const [error, setError] = useState<string>("");
    const user = useWhoAmI();
    const {yesterday, today} = yesterdayAndToday;
    const loading = loadingStates.admission || loadingStates.dataRep || loadingStates.totalStaff;

    const handleLoadingStates = (name: string, state: boolean) => {
        setLoadingStates((prevStates) => ({
            ...prevStates,
            [name]: state,
        }));
    };

    const fetchDataRepresentation = async (from?: string, to?: string) => {
        const dateFrom = from ? from : encodeURIComponent(formatDateDDMMYYYY(yesterday));
        const dateTo = to ? to : encodeURIComponent(formatDateDDMMYYYY(today));

        handleLoadingStates("dataRep", true);
        salesService.fetchSalesDOT(user.hospital.id, dateFrom, dateTo)
            .then((data) => setSalesServices(data))
            .catch(() => {
                setError("Technical error occurred while fetching sales analytics, please try again later");
            })
            .finally(() => handleLoadingStates("dataRep", false));
    };

    const getNoOfStaff = async () => {
        handleLoadingStates("totalStaff", true);
        adminService.getStaffCount(user.hospital.id)
            .then((response) => setStaffCount(response))
            .catch(() =>
                setError(
                    "Technical error occurred while fetching staff count, please try again later"
                )
            )
            .finally(() => handleLoadingStates("totalStaff", false));
    };

    const fetchAdmissionDTO = async (id: number = user?.hospital?.id, fromDate: string, toDate: string) => {
        handleLoadingStates("admission", true);
        admissionService.fetchAdmission(id, fromDate, toDate)
            .then((data) => {
                setPatientsAgeRange(data?.patientsAgeRange);
                setAdmissionDto(data?.admissionDto);
            })
            .catch(() => {
                setError(
                    "Technical error occurred while fetching admission analytics, please try again later"
                );
            })
            .finally(() => handleLoadingStates("admission", false));
    };

    useEffect(() => {
        user.hospital.id &&
        (() => {
            getNoOfStaff().then();
            fetchDataRepresentation().then();
            fetchAdmissionDTO(user.hospital.id, "", "").then();
        })();
    }, [user?.hospital?.id, yesterdayAndToday]);
    return {
        error,
        user,
        salesServices,
        patientsAgeRange,
        admissionDto,
        staffCount,
        loading,
        fetchAdmissionDTO,
        fetchDataRepresentation,
        getNoOfStaff,
    };
};

export default useDashboardHook;
