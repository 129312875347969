import { createContext, useContext, useState, ReactNode, useEffect, useMemo } from 'react';
import RevenueIcon from '../resources/assets/svg/Dashboard/Modal/RevenueIcon';
import StaffsIcon from '../resources/assets/svg/Dashboard/Modal/StaffsIcon';
import PatientIcon from '../resources/assets/svg/Dashboard/Modal/PatientIcon';
import TreatmentIcon from '../resources/assets/svg/Dashboard/Modal/TreatmentIcon';
import SalesIcon from '../resources/assets/svg/Dashboard/Modal/SalesIcon';
import ServicesIcon from '../resources/assets/svg/Dashboard/Modal/ServicesIcon';
import RangeIcon from '../resources/assets/svg/Dashboard/Modal/RangeIcon';
import AdmissionIcon from '../resources/assets/svg/Dashboard/Modal/AdmissionIcon';
import EmergencyIcon from '../resources/assets/svg/Dashboard/Modal/EmergencyIcon';
import NotificationIcon from '../resources/assets/svg/Dashboard/Modal/NotificationIcon';
import AdminOnlineIcon from '../resources/assets/svg/Dashboard/Modal/AdminOnlineIcon';
import ChartIcon from '../resources/assets/svg/Dashboard/Modal/ChartIcon';
import { useWhoAmI } from './UserCtx';
import { DashboardModalList } from '../types/Dashboard/DashboardModels';
import { getFromStorage, handleFilterOptions, returnLabels, saveToStorage } from '../util/Helpers';
import { userIsAdmin, userIsSuperAdmin } from '../util/UserRolesHelper';

export interface DashboardOption {
	id: number;
	label: string;
	icon: ReactNode;
	enabled: boolean;
	visible?: boolean;
}

// Define the type for your context
interface DashboardContextType {
	options: DashboardOption[];
	selectedOptions: DashboardOption[];
	dashboardOptions: DashboardOption[];
	toggleOption: (id: number) => void;
	handleSave: () => void;
	setOptions: React.Dispatch<React.SetStateAction<DashboardOption[]>>;
	setSelectedOptions: React.Dispatch<React.SetStateAction<DashboardOption[]>>;
}

const initialValue = {
	options: [],
	selectedOptions: [],
	toggleOption: (id: number) => { },
	handleSave: () => { },
	dashboardOptions: [],
	setOptions: () => [],
	setSelectedOptions: () => []
};

// Create the context
const DashboardContext = createContext<DashboardContextType>(initialValue);

// Create a provider component that wraps your app and provides the context value
export const DashboardProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const authUser = useWhoAmI();
	const [selectedOptions, setSelectedOptions] = useState<DashboardOption[]>([]);
	const isAdmin = useMemo(() => userIsAdmin(authUser), [authUser])
	const isSuperAdmin = useMemo(() => userIsSuperAdmin(authUser), [authUser]);
	const isUser = !isAdmin && !isSuperAdmin
	const dashboardOptions = useMemo(() => [
		{ id: 1, label: DashboardModalList.Total_Revenue, icon: <RevenueIcon />, enabled: true, visible: true },
		{ id: 2, label: DashboardModalList.Total_Staff, icon: <StaffsIcon />, enabled: true, visible: true },
		{ id: 3, label: DashboardModalList.Number_of_Patient, icon: <PatientIcon />, enabled: true, visible: true },
		{ id: 4, label: DashboardModalList.Total_Patient_on_Treatment, icon: <TreatmentIcon />, enabled: true, visible: true },
		{ id: 5, label: DashboardModalList.Sales, icon: <SalesIcon />, enabled: true, visible: true },
		{ id: 6, label: DashboardModalList.Daily_Sales_and_Services, icon: <ServicesIcon />, enabled: true, visible: true },
		{ id: 8, label: DashboardModalList.Patient_on_admission_and_clinic, icon: <AdmissionIcon />, enabled: false, visible: true },
		{ id: 9, label: DashboardModalList.Admitted_patient_Age_range, icon: <RangeIcon />, enabled: false, visible: true },
		{ id: 10, label: DashboardModalList.Emergency, icon: <EmergencyIcon />, enabled: false, visible: true },
		{ id: 11, label: DashboardModalList.Notification, icon: <NotificationIcon />, enabled: false, visible: true },
		{ id: 12, label: DashboardModalList.Admin_online, icon: <AdminOnlineIcon />, enabled: false, visible: true },
		{ id: 13, label: DashboardModalList.Bar_Chart_for_patients, icon: <ChartIcon />, enabled: false, visible: true },
	].filter((item) => isUser ? (item.label === DashboardModalList.Notification || item.label === DashboardModalList.Bar_Chart_for_patients) : item)
		.map((item) => isUser ? { ...item, enabled: item.label === DashboardModalList.Notification ? true : item.enabled } : item), [isUser])

	// Total sales, today sales, this week sales,
	const [options, setOptions] = useState<DashboardOption[]>(dashboardOptions);
	const toggleOption = (id: number) => {
		setOptions((prevOptions: any) => prevOptions.map((option: any) => (option.id === id ? { ...option, enabled: !option.enabled } : option)));
	};
	const handleSave = () => {
		// save the enabled options to the selectedOptions array
		const filtered = handleFilterOptions(options);
		const labels = returnLabels(filtered)
		setSelectedOptions(filtered);
		saveToStorage('options', labels)
	};
	const labelsFromStorage = useMemo(() => getFromStorage("options"), []);

	// Set the selected options on mount
	useEffect(() => {
		if (!labelsFromStorage) {
			const filtered = dashboardOptions.filter((item) => item.enabled)
			saveToStorage('options', returnLabels(filtered))
			setSelectedOptions(filtered);
			setOptions(dashboardOptions);
			return
		}
		const mappedOptions = dashboardOptions?.map((option) => ({
			...option,
			enabled: labelsFromStorage?.includes(option?.label),
		}));
		const filtered = dashboardOptions?.filter((option) => labelsFromStorage?.includes(option?.label));
		setOptions(mappedOptions);
		setSelectedOptions(filtered)
	}, [dashboardOptions, labelsFromStorage]);

	const contextValue: DashboardContextType = {
		options,
		toggleOption,
		selectedOptions,
		handleSave,
		dashboardOptions,
		setOptions,
		setSelectedOptions,
	};

	return <DashboardContext.Provider value={contextValue}>{children}</DashboardContext.Provider>;
};

// Create a custom hook for using the DashboardContext
export const useDashboardContext = () => useContext(DashboardContext);
