import WhoAmI from "../userAuth/WhoAmI";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import HospitalDao from "../types/HospitalDao";
import { Address } from "../types/NewPatientData";
import { getCookie, isEmpty } from "../util/Helpers";
import { ParamsEnum } from "../util/ParamsEnum";
import { jwtDecode } from "jwt-decode";

const initialAddress: Address = {
  streetHouseNr: "",
  state: "",
  postcode: "",
  country: "",
};

const initialHospitalDao: HospitalDao = {
  id: 0,
  name: "",
  userId: 0,
  email: "",
  phone: "",
  hospitalCategory: "",
  superUser: false,
  address: initialAddress,
  createdBy: 0,
  dateCreated: null,
  teamMembersCount: "",
  yearFounded: "",
  taxNumber: "",
  cacNumber: "",
  planOptions: "",
  accountIsEnabled: false,
};

const initialWhoAmI: WhoAmI = {
  userId: 0,
  hospital: initialHospitalDao,
  username: "",
  firstname: "",
  lastname: "",
  email: "",
  lastLogin: "",
  department: "",
  authorities: [],
  sub: "",
  exp: 0,
  iat: 0,
};

const WhoAmICtx = createContext<WhoAmI>(initialWhoAmI);

export const useWhoAmI = () => useContext(WhoAmICtx);

interface UserCtxProps {
  value?: WhoAmI;
  children: ReactNode;
}

export const UserCtx = (props: UserCtxProps) => {
  const [activeWhoAmI, setActiveWhoAmI] = useState<WhoAmI>();

  const setWhoAmIFromCookieHandler = () => {
    const loggedInUser = getCookie(ParamsEnum.ACCESS_TOKEN) ?? "";

    if (isEmpty(loggedInUser)) {
      return;
    }

    const toWhoAmI = jwtDecode(loggedInUser) as WhoAmI;
    Object.assign(initialWhoAmI, toWhoAmI);
    setActiveWhoAmI(toWhoAmI);
  };

  useEffect(() => {
    if (props.value) {
      activeUserSetter(props.value);
    }
    setWhoAmIFromCookieHandler();
  }, [props.value, setActiveWhoAmI]);

  const activeUserSetter = useCallback(
    (newState: WhoAmI) => setActiveWhoAmI(newState),
    []
  );

  return (
    <WhoAmICtx.Provider value={activeWhoAmI ?? initialWhoAmI}>
      {props.children}
    </WhoAmICtx.Provider>
  );
};
