import "./DataRepresentation.scss";
import {
  ClinicSvg,
  IncreaseSvg,
  PatientSvg,
  RevenueSvg,
  StaffSvg,
} from "../../../resources/assets/svg/DataRep";
import { useDashboardContext } from "../../../context/DashboardCtx";
import useDashboardHook from "../../../customhook/useDashboardHook";
import { formatNumberWithCommas } from "../../../util/numberFormatter";
import { DashboardModalList } from "../../../types/Dashboard/DashboardModels";
import { userIsSuperAdmin } from "../../../util/UserRolesHelper";
import { useWhoAmI } from "../../../context/UserCtx";

const DataRepresentation = () => {
  const { selectedOptions } = useDashboardContext();
  const {...authUser} = useWhoAmI()
  const { admissionDto, salesServices, staffCount } = useDashboardHook();
const isSuperAdmin = userIsSuperAdmin(authUser);

  return (
    <div className="data_representation_container">
      {isSuperAdmin && (
        <section
          data-name="total_revenue"
          className={`total_revenue  ${
            selectedOptions.find(
              (item) => item.label === DashboardModalList.Total_Revenue
            )
              ? "card"
              : "hide_card"
          }`}
        >
          <div className="card_content">
            <span className="card_icon">
              <RevenueSvg />
            </span>
            <div className="card_details">
              <h2 className="content_header">Total Revenue</h2>
              <span className="content_sub_header">
                <IncreaseSvg /> +{salesServices?.lossGainPercentage || 0}% last
                year
              </span>
            </div>
          </div>
          <div className={`card_footer amount`}>
            ₦{formatNumberWithCommas(salesServices?.hospitalTotalRevenue) || 0}
          </div>
        </section>
      )}

      <section
        data-name="total_staff"
        className={`total_staff  ${
          selectedOptions.find(
            (item) => item.label === DashboardModalList.Total_Staff
          )
            ? "card"
            : "hide_card"
        }`}
      >
        <div className="card_content">
          <span className="card_icon">
            <StaffSvg />
          </span>
          <div className="card_details">
            <h2 className="content_header">Total Staff</h2>
            <span className={`content_sub_header staff_Count`}>
              {staffCount}
            </span>
          </div>
        </div>
        {/* <div className="card_footer">
          <p className="pending">Pending application 2</p>
          <p className="probation">On probation 4</p>
        </div> */}
      </section>
      <section
        data-name="patients"
        className={`no_of_patients  ${
          selectedOptions.find(
            (item) => item.label === DashboardModalList.Number_of_Patient
          )
            ? "card"
            : "hide_card"
        }`}
      >
        <div className="card_content">
          <span className="card_icon">
            <PatientSvg />
          </span>
          <div className="card_details">
            <h2 className="content_header">Total Patients</h2>
            <p className={`content_sub_header patient_number`}>
              {admissionDto?.totalNumberOfPatients || 0}
            </p>
          </div>
        </div>
      </section>
      <section
        data-name="clinic"
        className={`patients_in_clinic  ${
          selectedOptions.find(
            (item) =>
              item.label === DashboardModalList.Total_Patient_on_Treatment
          )
            ? "card"
            : "hide_card"
        }`}
      >
        <div className={`card_content clinic`}>
          <span className="card_icon">
            <ClinicSvg />
          </span>
          <div className="card_details">
            <h2>Total patients in clinic</h2>
            <p className="content_sub_header">
              {admissionDto?.numOfPatientsInClinic || 0}
            </p>
          </div>
        </div>
        <hr />
        <div className={`card_content clinic`}>
          <span className="card_icon">
            <ClinicSvg />
          </span>
          <div className="card_details">
            <h2>Total patients in admission</h2>
            <p className="content_sub_header">
              {admissionDto?.numOfPatientsInAdmission || 0}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DataRepresentation;
