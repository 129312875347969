import { Button } from "../button/Button";
import { InputField } from "../inputfield/InputField";
import registerService from "../../services/RegisterService";
import { useForm } from "react-hook-form";
import {
  FormSubmissionStatus,
  RegistrationStage,
} from "../../pages/register/Register";
import React, { useState } from "react";
import "./EmailVerificationForm.scss";
import MailBox from "../../resources/assets/svg/MailBox";

type EmailVerificationFormProps = {
  hospitalId: number;
  userId: number;
  setRegistrationStage: React.Dispatch<React.SetStateAction<RegistrationStage>>;
  setIsSubmittingForm: React.Dispatch<React.SetStateAction<boolean>>;
  setFormSubmissionStatus: React.Dispatch<
    React.SetStateAction<FormSubmissionStatus>
  >;
  isSubmittingForm: boolean;
  forgotPassword?: boolean;
};

function EmailVerificationForm({
  hospitalId,
  userId,
  isSubmittingForm,
  setFormSubmissionStatus,
  setRegistrationStage,
  setIsSubmittingForm,
  forgotPassword,
}: EmailVerificationFormProps) {
  const [verificationCode, setVerificationCode] = useState<string>("");
  const {
    register,
    formState: { errors },
    setError,
  } = useForm({ mode: "onBlur" });

  const emailVerificationHandler = () => {
    registerService
      .verifyEmail({
        hospitalId: hospitalId,
        userId: userId,
        verificationCode: Number(verificationCode),
        forgotPassword,
      })
      .then((response) => {
        if (response.ok) {
          setIsSubmittingForm(false);
          setRegistrationStage("company_registration");
        }
      })
      .catch(() => {
        setError("account-verification-input", {
          message: "You entered wrong verification code",
        });
        setFormSubmissionStatus("failed");
      });
  };

  const verificationCodeInputHandler = (evt: any) => {
    const target = evt.target;
    const value = target.value;
    setVerificationCode(value);
  };

  return (
    <div id="verification-container">
      <h2>Verify Your Email Address</h2>
      <div id="dashboard">
        <MailBox/>
      </div>
      <div>
        <h3>
          Welcome to{" "}
          <span style={{ fontSize: "32px", color: "#00b0ff" }}>
            HealthProSuite
          </span>
        </h3>
        <p>
          A code has been sent to your email, complete yout account
          verification, copy and paste the code sent to your email address
        </p>
        <div>
          <div className={"sign-up-email-verification"}>
            <InputField
              id={"account-verification-input"}
              name={"account-verification-input"}
              register={register}
              defaultValue={verificationCode}
              label={"Enter verification code below"}
              placeholder={"Enter verification code"}
              required={"Verification code is required"}
              onChange={verificationCodeInputHandler}
              min={6}
              max={6}
              minLength={6}
              maxLength={6}
              pattern={{
                value: /\d{6}/,
                message: "Please use a valid verification code",
              }}
              errors={errors}
            />
          </div>
          <div className={"sign-up-email-verification"}>
            <Button
              id={"email-verification-btn"}
              name={"email-verification-btn"}
              buttonName={isSubmittingForm ? "Please wait..." : "Verify"}
              onClick={emailVerificationHandler}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailVerificationForm;
