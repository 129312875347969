import { Button } from "../../../components/button/Button";
import { ReceiptProps } from "../types";
import "./Receipt.scss";
import React, { useEffect } from "react";
import PrintingModule from "../../../components/printingModule/PrintingModule";
import { ReceiptLogo } from "../../../resources/assets/svg/Billing";
import {
  convertToLocaleString,
  removeCommasAndConvert,
  returnNextPaymentDate,
} from "../../../util/Helpers";
import { Modal } from "../../../components/modal/Modal";
import { useWhoAmI } from "../../../context/UserCtx";
import useHospitalLogo from "../../../customhook/useHospitalLogo";
import { ErrorNotification } from "../../../modules/errornotification/ErrorNotification";
import { UserDetail } from "../../manageaccount/hospitalInfo/HospitalInfo";
import Handshake from "../../../resources/assets/svg/Handshake";
import { monthsOfYear } from "../data";

const Receipt: React.FC<ReceiptProps> = ({
  showReceipt,
  paymentMode,
  atrizultBill,
  vat,
  closeReceipt,
  currentAction,
  currentMonth,
  currentYear,
  benefactorBank,
  balance,
}) => {
  const { hospital } = useWhoAmI();
  const { imgSrc, error } = useHospitalLogo();
  const { address, email, taxNumber, name } = hospital;
  const { streetHouseNr, country } = address;
  const appBody = document.querySelector("body");
  appBody!.style.overflow = showReceipt ? "hidden" : "auto";

  const totalPaid = convertToLocaleString(
    removeCommasAndConvert(atrizultBill) + Number(vat)
  );
  const { day, month, year } = returnNextPaymentDate(
    currentMonth as number,
    currentYear as number
  );
  useEffect(() => {
    currentAction === "Download Receipt" &&
      showReceipt &&
      setTimeout(() => {
        window.print();
      }, 1000);
  }, [currentAction, showReceipt]);

  return (
    <>
      <PrintingModule>
        <Modal
          isOpen={!!showReceipt}
          onClose={closeReceipt}
          modalTitle="INVOICE"
        >
          <div className={"billing_receipt_wrapper"}>
            <div className="billing_receipt_top">
              <div>
                <ReceiptLogo />
                <span>AtRiZult Practical Consulting</span>
                <UserDetail
                  label={"Address"}
                  value={"no 65 Chime Street Enugu. "}
                />
                <UserDetail label={"Phone"} value={"08065006131"} />
                <UserDetail
                  label={"Customer Care"}
                  value={"Support@Atrizult.com"}
                />
              </div>
              <div>
                <span className="billing_receipt_logo_wrapper">
                  <img
                    className="billing_receipt_hospital_logo"
                    src={imgSrc}
                    alt={name || "Logo"}
                  />{" "}
                  {name}
                </span>
                <span>{streetHouseNr}</span>
                <span>{country}</span>
                <UserDetail label={"Tax Number"} value={taxNumber} />
                <span>{email}</span>
              </div>
            </div>
            <div className="billing_receipt_description_wrapper">
              <span className="billing_blue_bar">Description</span>
              <div className="billing_receipt_details_wrapper">
                <UserDetail label={"Mode of payment"} value={paymentMode} />
                <UserDetail label={"Beneficiary Acc No"} value={"8065006131"} />
                <UserDetail label={"Beneficiary Bank"} value={"Opay"} />
                <UserDetail
                  label={"Beneficiary Name"}
                  value={"Atrizult Group of companies"}
                />
                <UserDetail label={"Benefactor"} value={name} />
                <UserDetail
                  label={"Payment Description"}
                  value={`use of HPS for the month of ${
                    monthsOfYear[currentMonth as number]
                  } ${currentYear || ""}`}
                />
                <UserDetail
                  label="Benefactor Bank"
                  value={benefactorBank as string}
                />
                <UserDetail
                  label={"Balance Left"}
                  value={`${convertToLocaleString(
                    removeCommasAndConvert(`${balance}`)
                  )} Naira`}
                />
                <p className="billing_receipt_next_payment_info">
                  Your hospital account for {name} has been successfully renewed
                  for {monthsOfYear[currentMonth as number]} {currentYear}. Your
                  next due date is {monthsOfYear[month]} {day}, {year}.
                </p>
              </div>
            </div>
            <div className="billing_receipt_total_display">
              <UserDetail label={"Total Paid"} value={totalPaid} />
            </div>
            <Button
              id={"billing_download_btn"}
              buttonName="Print"
              onClick={() => window.print()}
            />
            <span className="billing_receipt_footer">
              <Handshake /> Thank you for choosing Atrizult. We appreciate your
              business and look forward to serving you again in the future.
            </span>
          </div>
        </Modal>
      </PrintingModule>
      {error && (
        <ErrorNotification name={"hospital-logo-error"} stringErrors={error} />
      )}
    </>
  );
};

export default Receipt;
