import './Pagination.scss';
import React, {useState} from 'react';
import Accordion from "../accordion/Accordion";
import PatientDao from "../../types/PatientDao";
import {SideBarChildrenType} from "../../appstate/SidebarChildrenState";
import {AiOutlineFieldTime} from "react-icons/ai";
import {MdAdd, MdHistory, MdVerifiedUser} from "react-icons/md";
import {CiMoneyBill} from "react-icons/ci";
import {useWhoAmI} from "../../context/UserCtx";
import {userHasRole} from "../../util/UserRolesHelper";
import {Authorities} from "../../userAuth/WhoAmI";
import {FcCollaboration} from "react-icons/fc";

interface PaginationProps<T> {
    itemList: T[],
    itemsPerPage: number
}

type PatientListItem = {
    patients: PatientDao[], //TODO: patient list
    serviceIcon: { serviceName: SideBarChildrenType, icon: any }[]
}

export const Pagination = (props: PaginationProps<PatientDao>) => {
    const [currentPage, setCurrentPage] = useState(1);
    const user = useWhoAmI();

    const totalPages = Math.ceil(props.itemList.length / props.itemsPerPage);

    const indexOfLastItem = currentPage * props.itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - props.itemsPerPage;
    const currentItems = props.itemList.slice(indexOfFirstItem, indexOfLastItem);

    const hasCashierAccess = userHasRole(user, [Authorities.SUPER_ADMIN, Authorities.ADMIN, Authorities.CASHIER])

    const patientNavs: PatientListItem[] = [
        {
            patients: currentItems,
            serviceIcon: [
                /*{
                    serviceName: (activeSideBarChild === 'check-in') ? 'check-out' : 'check-in',
                    icon: (activeSideBarChild === 'check-in') ? <IoBagCheckOutline/> : <BsCheck/>
                },*/
                //TODO: change to discharge when patient in check-in or admitted icons {serviceName: 'discharge', icon: <IoBagCheckOutline/>},
                {serviceName: 'admission', icon: <MdAdd/>},
                {serviceName: 'laboratory', icon: <FcCollaboration/>},
                {serviceName: hasCashierAccess ? 'payments' : '', icon: hasCashierAccess ? <CiMoneyBill/> : ''},
                {serviceName: 'appointments', icon: <AiOutlineFieldTime/>},
                {serviceName: 'profile', icon: <MdVerifiedUser/>},
                {serviceName: 'medical-history', icon: <MdHistory/>},
            ]
        }
    ]

    const handlePageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div>
            <Accordion items={patientNavs}/>

            <div className={'pagination-btn-wrapper'}>
                {Array.from({length: totalPages}, (_, index) => index + 1).map(
                    (pageNumber) => (
                        <button
                            key={pageNumber}
                            onClick={() => handlePageClick(pageNumber)}
                            disabled={currentPage === pageNumber}
                        >
                            {pageNumber}
                        </button>
                    )
                )}
            </div>
            <p className={'pagination-pages'}>Pages</p>
        </div>
    );
};
