import React from "react";

const Checked: React.FC<{
  className?: string;
  fill?: string;
  onClick?: () => void;
}> = ({ className, fill, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
    >
      <rect width="28" height="28" rx="4" fill={fill || "#0492C2"} />
      <path
        d="M8.5 14.5L12 18L20 9"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Checked;
