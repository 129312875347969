import React from 'react'

const MoneyIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16.1713" cy="15.9936" r="15.6029" fill="#F8F8F8" />
      <path
        d="M16.4057 18.7608V20.1826M16.4057 11.7078V13.0311M16.4057 23.6458C21.3329 23.6458 24.8524 21.9001 24.8524 18.0146C24.8524 13.7913 22.7407 10.9757 18.5174 8.86402L20.1786 6.72419C20.263 6.58379 20.3087 6.42358 20.3112 6.25979C20.3138 6.096 20.2729 5.93445 20.1929 5.79153C20.1129 5.64861 19.9965 5.52938 19.8555 5.44594C19.7145 5.3625 19.554 5.31781 19.3902 5.31641H13.4212C13.2574 5.31781 13.0969 5.3625 12.9559 5.44594C12.8149 5.52938 12.6985 5.64861 12.6185 5.79153C12.5385 5.93445 12.4976 6.096 12.5001 6.25979C12.5027 6.42358 12.5484 6.58379 12.6328 6.72419L14.294 8.8781C10.0707 11.0039 7.95898 13.8194 7.95898 18.0428C7.95898 21.9001 11.4784 23.6458 16.4057 23.6458Z"
        stroke="black"
        strokeWidth="0.821208"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9979 18.114C15.1697 18.3179 15.3859 18.4797 15.6299 18.5871C15.874 18.6945 16.1394 18.7445 16.4057 18.7334C16.8129 18.7612 17.2149 18.63 17.5273 18.3674C17.8398 18.1049 18.0382 17.7315 18.081 17.3256C18.0382 16.9198 17.8398 16.5463 17.5273 16.2838C17.2149 16.0213 16.8129 15.8901 16.4057 15.9178C15.9986 15.9456 15.5966 15.8144 15.2841 15.5519C14.9717 15.2893 14.7732 14.9159 14.7305 14.51C14.7698 14.1028 14.9673 13.7272 15.2806 13.464C15.5938 13.2007 15.9978 13.0709 16.4057 13.1023C16.6674 13.0863 16.9294 13.1285 17.1728 13.2258C17.4162 13.3232 17.6351 13.4733 17.8135 13.6654"
        stroke="black"
        strokeWidth="0.821208"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MoneyIcon