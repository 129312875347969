import {ApiService} from "./ApiService";
import InvoiceDao from "../types/invoice/InvoiceDao";
import NewInvoiceReqData from "../types/invoice/NewInvoiceReqData";
import {SendInvoiceDto} from "../types/invoice/SendInvoiceDto";

const baseUrl = `${process.env.ACC_PROXY ?? process.env.REACT_APP_ACC_PROXY}invoice`

class InvoiceService extends ApiService {

    async createNewInvoice(invoiceRequest: NewInvoiceReqData): Promise<InvoiceDao> {
        const response = await fetch(baseUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(invoiceRequest)
            }
        )
        const invoiceDetails = await this.apiResponseHandler(response);
        return await invoiceDetails.json()
    }

    async updateInvoice(invoice: InvoiceDao): Promise<InvoiceDao> {
        const response = await fetch(baseUrl + '/update', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(invoice)
            }
        )
        const invoiceDetails = await this.apiResponseHandler(response);
        return await invoiceDetails.json();
    }

    async sendInvoiceToCustomerEmail(invoiceData: SendInvoiceDto): Promise<boolean> {
        const response = await fetch(baseUrl + '/send-email', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(invoiceData)
            }
        )
        const invoiceDetails = await this.apiResponseHandler(response);
        return await invoiceDetails.json();
    }

    //TODO: pagination to be implemented
    async getAllSales(hospitalId: number, page: number, size: number): Promise<InvoiceDao[]> {
        if(!hospitalId) {
            throw new Error("hospitalId must be defined")
        }
        const response = await fetch(`${baseUrl}/${hospitalId}/invoices?pg=${page}&sz=${size}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = await this.apiResponseHandler(response);
        return await data.json();
    }

    //TODO: Implement the endpoint
    async getLoggedInUserSales(hospitalId: number, userId: number, page: number, size: number): Promise<InvoiceDao[]> {
        if(!hospitalId) {
            throw new Error("hospitalId must be defined")
        }
        const response = await fetch(`${baseUrl}/${hospitalId}/${userId}/invoices?pg=${page}&sz=${size}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = await this.apiResponseHandler(response);
        return await data.json();
    }

    async getPatientLatestSale(hospitalId: number, patientId: number): Promise<InvoiceDao> {
        const response = await fetch(`${baseUrl}/${hospitalId}/${patientId}/recent`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const latestSale = await this.apiResponseHandler(response);
        return await latestSale.json();
    }
}

const invoiceService =  new InvoiceService();
export default invoiceService;