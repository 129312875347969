import React from 'react';

const ClinicSvg = () => {
	return (
		<svg width='19' height='14' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M3.30617 3.57685C3.5224 3.57685 3.72977 3.5072 3.88266 3.38323C4.03555 3.25926 4.12145 3.09112 4.12145 2.91579C4.12145 2.74047 4.03555 2.57233 3.88266 2.44835C3.72977 2.32438 3.5224 2.25473 3.30617 2.25473C3.08995 2.25473 2.88258 2.32438 2.72969 2.44835C2.57679 2.57233 2.4909 2.74047 2.4909 2.91579C2.4909 3.09112 2.57679 3.25926 2.72969 3.38323C2.88258 3.5072 3.08995 3.57685 3.30617 3.57685ZM3.30617 4.89897C3.95485 4.89897 4.57695 4.69003 5.03563 4.31811C5.49431 3.94619 5.752 3.44176 5.752 2.91579C5.752 2.38982 5.49431 1.88539 5.03563 1.51348C4.57695 1.14156 3.95485 0.932617 3.30617 0.932617C2.6575 0.932617 2.0354 1.14156 1.57672 1.51348C1.11804 1.88539 0.860352 2.38982 0.860352 2.91579C0.860352 3.44176 1.11804 3.94619 1.57672 4.31811C2.0354 4.69003 2.6575 4.89897 3.30617 4.89897Z'
				fill='black'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M3.3476 5.39476C3.02326 5.39476 2.71221 5.49923 2.48287 5.68519C2.25353 5.87115 2.12469 6.12336 2.12469 6.38635V7.37794C2.12469 7.55326 2.03879 7.7214 1.8859 7.84538C1.73301 7.96935 1.52564 8.039 1.30941 8.039C1.09319 8.039 0.885823 7.96935 0.732929 7.84538C0.580035 7.7214 0.494141 7.55326 0.494141 7.37794V6.38635C0.494141 5.77272 0.794772 5.18422 1.3299 4.75031C1.86503 4.31641 2.59082 4.07264 3.3476 4.07264C4.10439 4.07264 4.83017 4.31641 5.3653 4.75031C5.90043 5.18422 6.20106 5.77272 6.20106 6.38635V7.37794C6.20106 7.55326 6.11517 7.7214 5.96227 7.84538C5.80938 7.96935 5.60201 8.039 5.38579 8.039C5.16956 8.039 4.96219 7.96935 4.8093 7.84538C4.65641 7.7214 4.57051 7.55326 4.57051 7.37794V6.38635C4.57051 6.12336 4.44167 5.87115 4.21233 5.68519C3.98299 5.49923 3.67194 5.39476 3.3476 5.39476ZM13.0901 3.57685C12.8739 3.57685 12.6665 3.5072 12.5136 3.38323C12.3607 3.25926 12.2749 3.09112 12.2749 2.91579C12.2749 2.74047 12.3607 2.57233 12.5136 2.44835C12.6665 2.32438 12.8739 2.25473 13.0901 2.25473C13.3064 2.25473 13.5137 2.32438 13.6666 2.44835C13.8195 2.57233 13.9054 2.74047 13.9054 2.91579C13.9054 3.09112 13.8195 3.25926 13.6666 3.38323C13.5137 3.5072 13.3064 3.57685 13.0901 3.57685ZM13.0901 4.89897C12.4415 4.89897 11.8194 4.69003 11.3607 4.31811C10.902 3.94619 10.6443 3.44176 10.6443 2.91579C10.6443 2.38982 10.902 1.88539 11.3607 1.51348C11.8194 1.14156 12.4415 0.932617 13.0901 0.932617C13.7388 0.932617 14.3609 1.14156 14.8196 1.51348C15.2783 1.88539 15.536 2.38982 15.536 2.91579C15.536 3.44176 15.2783 3.94619 14.8196 4.31811C14.3609 4.69003 13.7388 4.89897 13.0901 4.89897Z'
				fill='black'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13.0497 5.39487C13.3741 5.39487 13.6851 5.49934 13.9145 5.6853C14.1438 5.87126 14.2727 6.12347 14.2727 6.38646V7.37805C14.2727 7.55337 14.3586 7.72151 14.5114 7.84548C14.6643 7.96946 14.8717 8.0391 15.0879 8.0391C15.3042 8.0391 15.5115 7.96946 15.6644 7.84548C15.8173 7.72151 15.9032 7.55337 15.9032 7.37805V6.38646C15.9032 5.77283 15.6026 5.18433 15.0674 4.75042C14.5323 4.31652 13.8065 4.07275 13.0497 4.07275C12.293 4.07275 11.5672 4.31652 11.032 4.75042C10.4969 5.18433 10.1963 5.77283 10.1963 6.38646V7.37805C10.1963 7.55337 10.2822 7.72151 10.4351 7.84548C10.588 7.96946 10.7953 8.0391 11.0116 8.0391C11.2278 8.0391 11.4352 7.96946 11.588 7.84548C11.7409 7.72151 11.8268 7.55337 11.8268 7.37805V6.38646C11.8268 6.12347 11.9557 5.87126 12.185 5.6853C12.4144 5.49934 12.7254 5.39487 13.0497 5.39487Z'
				fill='black'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.24002 7.21274C7.69946 7.21274 7.18104 7.38686 6.79881 7.69679C6.41657 8.00672 6.20184 8.42708 6.20184 8.86539V9.85698C6.20184 10.0323 6.11594 10.2004 5.96305 10.3244C5.81016 10.4484 5.60279 10.518 5.38656 10.518C5.17034 10.518 4.96297 10.4484 4.81008 10.3244C4.65718 10.2004 4.57129 10.0323 4.57129 9.85698V8.86539C4.57129 8.07643 4.95782 7.31979 5.64584 6.76191C6.33386 6.20404 7.26701 5.89063 8.24002 5.89062C9.21303 5.89063 10.1462 6.20404 10.8342 6.76191C11.5222 7.31979 11.9088 8.07643 11.9088 8.86539V9.85698C11.9088 10.0323 11.8229 10.2004 11.67 10.3244C11.5171 10.4484 11.3097 10.518 11.0935 10.518C10.8773 10.518 10.6699 10.4484 10.517 10.3244C10.3641 10.2004 10.2782 10.0323 10.2782 9.85698V8.86539C10.2782 8.42708 10.0635 8.00672 9.68124 7.69679C9.299 7.38686 8.78058 7.21274 8.24002 7.21274Z'
				fill='black'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.1968 5.39472C8.41302 5.39472 8.62039 5.32507 8.77329 5.2011C8.92618 5.07713 9.01207 4.90899 9.01207 4.73366C9.01207 4.55834 8.92618 4.3902 8.77329 4.26622C8.62039 4.14225 8.41302 4.07261 8.1968 4.07261C7.98058 4.07261 7.77321 4.14225 7.62031 4.26622C7.46742 4.3902 7.38153 4.55834 7.38153 4.73366C7.38153 4.90899 7.46742 5.07713 7.62031 5.2011C7.77321 5.32507 7.98058 5.39472 8.1968 5.39472ZM8.1968 6.71684C8.84547 6.71684 9.46758 6.5079 9.92626 6.13598C10.3849 5.76406 10.6426 5.25963 10.6426 4.73366C10.6426 4.20769 10.3849 3.70326 9.92626 3.33135C9.46758 2.95943 8.84547 2.75049 8.1968 2.75049C7.54813 2.75049 6.92602 2.95943 6.46734 3.33135C6.00866 3.70326 5.75098 4.20769 5.75098 4.73366C5.75098 5.25963 6.00866 5.76406 6.46734 6.13598C6.92602 6.5079 7.54813 6.71684 8.1968 6.71684Z'
				fill='black'
			/>
		</svg>
	);
};

export default ClinicSvg;
