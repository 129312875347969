import { useEffect, useState } from "react";
import { Listing } from "../listing/Listing";
import { Modal } from "../modal/Modal";
import AddService from "./addservice/AddService";
import "./ServiceList.scss";
import {
  OfferedServiceDto,
  ServiceListDTO,
} from "../../types/services/ServiceTypes";
import { restructureServicesResponse } from "../../util/Helpers";
import {userIsAdmin, userIsSuperAdmin} from "../../util/UserRolesHelper";
import {useWhoAmI} from "../../context/UserCtx";

export enum Action {
  List = "List",
  Edit = "Edit",
  Delete = "Delete",
  Deactivate = "Deactivate",
}

interface ServiceListProps {
  services?: OfferedServiceDto[];
  onClickAction?: (action: string) => void;
  triggerSubmitted?: (state: boolean) => void;
}

const ServiceList = (props: ServiceListProps) => {
  const [listOfOfferedServices, setListOfOfferedServices] = useState<ServiceListDTO[]>([]);
  const [clickedItem, setClickedItem] = useState<ServiceListDTO>({} as ServiceListDTO);
  const [action, setAction] = useState<Action>(Action.List);
  const [openModal, setOpenModal] = useState<boolean>(true);
  const itemTobeEdited = props.services?.find((service) => service.serviceName === clickedItem?.name);
  const user = useWhoAmI();

  const handleCloseModal = () => {
    setAction(Action.List);
    setOpenModal(false);
    props.onClickAction!("List of Services");
  };

  useEffect(() => {
    props.services && props.services?.length > 0 && setListOfOfferedServices(restructureServicesResponse(props.services));
  }, [props?.services?.length, action]);

  function actionHandler(currentAction: Action) {
    switch (currentAction) {
      case "Edit":
        setOpenModal(true);
        props.onClickAction!("Edit");
        setAction(Action.Edit)
        break;
      case "List":
        setOpenModal(false);
        props.onClickAction!("List of Services");
        setAction(Action.List)
        break;
      case "Deactivate":
        setOpenModal(true);
        setAction(Action.Deactivate)
        break;
      case "Delete":
        setOpenModal(true);
        setAction(Action.Delete)
        break;
      default:
        setAction(action)
        break;
    }
  }

  return (
    <>
      <div className="service_list_wrapper">
        {listOfOfferedServices?.length > 0 &&
            <Listing
            items={listOfOfferedServices ?? []}
            actions={[Action.Edit, Action.Deactivate, Action.Delete]}
            withAction={userIsSuperAdmin(user) || userIsAdmin(user)}
            getAction={actionHandler}
            getClickedItem={(clickedItem) => setClickedItem(clickedItem)}
        />}
      </div>

      {action === Action.Edit && (
        <Modal
          isOpen={openModal}
          onClose={handleCloseModal}
          modalTitle={`Edit Service`}
        >
          <AddService
            data={itemTobeEdited as any}
            onClickProceed={() => props.onClickAction!("")}
            currentAction={Action.Edit}
            triggerSubmitted={props.triggerSubmitted}
          />
        </Modal>
      )}

      {/* {action == "Delete" && (
        <Modal
          isOpen={openModal}
          onClose={() => {
            setAction(undefined);
            setOpenModal(false);
          }}
          modalTitle={`Edit Service`}
        >
          <AddService
            list={listOfOfferedServices ?? []}
            setList={setList}
            data={clickedItem as any}
            setAction={setAction}
          />
        </Modal>
      )} */}
    </>
  );
};

export default ServiceList;
