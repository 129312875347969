const CloudIcon = () => {
  return (
    <svg
      width="63"
      height="67"
      viewBox="0 0 63 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.4688 56.2835V43.7762H41.3438L31.5 31.2688L21.6562 43.7762H29.5312V56.2835H19.6875V56.1793C19.3567 56.2002 19.0417 56.2835 18.7031 56.2835C14.787 56.2835 11.0313 54.6364 8.26225 51.7044C5.49316 48.7724 3.9375 44.7958 3.9375 40.6494C3.9375 32.628 9.66656 26.0908 17.0179 25.1944C17.6624 21.6269 19.4609 18.4087 22.104 16.0933C24.7471 13.7778 28.0696 12.51 31.5 12.5078C34.9309 12.5098 38.254 13.7775 40.8977 16.0929C43.5415 18.4082 45.3407 21.6266 45.9861 25.1944C53.3374 26.0908 59.0586 32.628 59.0586 40.6494C59.0586 44.7958 57.5029 48.7724 54.7338 51.7044C51.9647 54.6364 48.209 56.2835 44.2929 56.2835C43.9622 56.2835 43.6432 56.2002 43.3086 56.1793V56.2835H33.4688Z"
        fill="#00B0FF"
      />
    </svg>
  );
};

export default CloudIcon;
