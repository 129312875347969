import React, { useEffect, useState } from "react";
import { Dropdown } from "../../components/dropdown/Dropdown";
import "./Billing.scss";
import {
  popUpTexts,
  getCurrentMonthAndYear,
  monthsOfYear,
  validateFileFormat,
  formatDate,
  restructuredBillsArr,
  formatCurrentDate,
} from "./data";
import { Listing } from "../../components/listing/Listing";
import { ReceiptStateProps } from "./types";
import { Button } from "../../components/button/Button";
import Receipt from "./Receipt/Receipt";
import { ErrorNotification } from "../../modules/errornotification/ErrorNotification";
import PaymentUpload from "./PaymentUpload/PaymentUpload";
import billingServices from "../../services/BillingService";
import { useWhoAmI } from "../../context/UserCtx";
import { Months, YearsMonthsDTO } from "../../types/billing/YearsMonthsDTO";
import {
  BillingState,
  PaymentSummaryDTO,
} from "../../types/billing/PaymentSummaryDTO";
import {
  convertToLocaleString,
  convertToTitleCase,
  isDateLessThanOrEqualToCurrent,
  returnClassName,
  showPaymentButton,
} from "../../util/Helpers";
import { BillingData } from "../../types/billing/BillingsListDTO";
import { PaymentDTO } from "../../types/billing/PaymentDTO";
import { MoneyIcon, ProfitIcon } from "../../resources/assets/svg/Billing";
import { Spinner } from "../../components/spinner/Spinner";
const { month, year } = getCurrentMonthAndYear(monthsOfYear);

const Billing = () => {
  const { getYearsAndMonths, getPaymentSummary, getBills, makePayment } =
    billingServices;
  const user = useWhoAmI();
  const { hospital } = user;
  const { id } = hospital;
  const [selectedYear, setSelectedYear] = useState<number>(year);
  const [selectedMY, setSelectedMY] = useState<{ month: string; year: number }>(
    { month, year }
  );
  const [currentMonths, setCurrentMonths] = useState<
    { value: string; label: string }[]
  >([]);
  const [clickedRow, setClickedRow] = useState<ReceiptStateProps | null>(null);
  const [showReceipt, setShowReceipt] = useState<boolean>(false);
  const [clickedAction, setClickedAction] = useState<string>("");
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [paymentDate, setPaymentDate] = useState<string>("");
  const [receipt, setReceipt] = useState<File | null>(null);
  const [yearsMonths, setYearsMonths] = useState<YearsMonthsDTO[]>([]);
  const [paymentSummary, setPaymentSummary] =
    useState<PaymentSummaryDTO | null>(null);
  const [bills, setBills] = useState<BillingData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSelectYear = (year: string) => {
    setSelectedYear(Number(year));
  };

  const handleSelectMonth = (month: string) => {
    setSelectedMY((prevMY) => ({
      ...prevMY,
      month: month.split(" ")[0],
      year: selectedYear,
    }));
  };

  const handleChangeYear = (e: React.FormEvent<HTMLOptionElement>) => {
    const { label } = e.currentTarget;
    setSelectedYear(Number(label));
  };

  const handleChangeMonth = (e: React.FormEvent<HTMLOptionElement>) => {
    const { value } = e.currentTarget;
    setSelectedMY((prev) => ({
      ...prev,
      month: value.split(" ")[0],
      year: selectedYear,
    }));
  };

  const handleCloseReceipt = () => {
    setShowReceipt(false);
    setClickedAction("");
  };

  const handleGetClickedAction = (action: string) => {
    if (!!action && !clickedRow?.["invoice id"]) {
      return (
        setError("No Invoice"),
        setTimeout(() => {
          setError("");
        }, 5000)
      );
    }
    return (
      !!action &&
      (setClickedAction(action),
      setShowReceipt(true),
      action === "Download Receipt" &&
        setTimeout(() => {
          handleCloseReceipt();
        }, 5000))
    );
  };

  const getClickedRow = (row: any) => {
    setClickedRow(row);
  };

  const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget;
    if (files && files.length > 0) {
      const file = files[0];
      if (validateFileFormat(file)) {
        setReceipt(file);
      } else {
        setError("Invalid file format");
        setTimeout(() => {
          setError("");
        }, 5000);
      }
    }
  };

  const handleChangePaymentDate = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.currentTarget;
    if (!isDateLessThanOrEqualToCurrent(value)) {
      setError("Payment date cannot be greater than current date");
      setTimeout(() => {
        setError("");
      }, 5000);
      return;
    }
    setPaymentDate(value);
  };

  const handleCloseUpload = () => {
    setShowUpload(false);
    setUploadSuccess(false);
    setReceipt(null);
    setPaymentDate("");
  };

  const handleSubmitPayment = () => {
    if (!receipt) {
      setError("Please upload payment receipt");
      setTimeout(() => {
        setError("");
      }, 5000);
      return;
    }

    if (!paymentDate) {
      setError("Please enter date payment was made");
      setTimeout(() => {
        setError("");
      }, 5000);
      return;
    }
    setLoading(true);
    const parts = paymentDate.split("-");
    const yr = Number(parts[0]);
    const mnt = Object.values(Months)[Number(parts[1]) - 1];
    const formData = new FormData();
    const payload: PaymentDTO & { [key: string]: any } = {
      file: receipt!,
      yr,
      mnt,
    };
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        formData.append(key, payload[key]);
      }
    }
    makePayment(id, formData)
      .then((response) => {
        return response && setUploadSuccess(true);
      })
      .catch(() => {
        setError("Could not post payment receipt. Please try again later");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const months =
      yearsMonths
        .find((data) => data.year === selectedYear)
        ?.months.map((item) => ({
          value: `${item} ${selectedYear}`,
          label: `${item} ${selectedYear}`,
        })) || [];
    setCurrentMonths(months);
    handleSelectMonth(month);
  }, [selectedYear, yearsMonths]);

  useEffect(() => {
    getYearsAndMonths(id)
      .then((response) => setYearsMonths(response))
      .catch(() =>
        setError(
          "Error occurred while getting payment info. Please try again later"
        )
      );
  }, [getYearsAndMonths, id]);

  useEffect(() => {
    !!selectedMY.month &&
      getPaymentSummary({
        hospitalId: id,
        yr: selectedMY?.year,
        mnt: selectedMY?.month?.split(" ")[0],
      })
        .then((response) => setPaymentSummary(response))
        .catch(() =>
          setError(
            "An error occured while getting payment summary. Please try again later"
          )
        );
  }, [getPaymentSummary, id, selectedMY?.month, selectedMY?.year]);

  useEffect(() => {
    setLoading(true)
    getBills({ hospitalId: id, yr: selectedYear })
      .then((response) => setBills(response))
      .catch(() =>
        setError("An error occured while getting bills. Please try again later")
      ).finally(()=>setLoading(false));
  }, [getBills, id, selectedYear]);

  const totalBill =
    Number(paymentSummary?.atrizultFeePrevious?.vat) +
    Number(paymentSummary?.atrizultFeePrevious?.totalAmount);

  return (
    <div className={"billing_page_wrapper"}>
      <div className="billing_page_top">
        <p className="billing_page_top_texts">
          <span className={"billing_page_title"}>Billing Page</span>
          <span>
            This page houses the fees charged by AtRiZult from the hospital
          </span>
        </p>
        <div className={"billing_drop_downs first_dropdown"}>
          <Dropdown
            name={""}
            onChange={handleChangeYear}
            options={yearsMonths?.map((item) => ({
              value: item.year,
              label: `${item.year}`,
            }))}
            label={`Year ${selectedYear}`}
            placeholder={`${selectedYear}`}
            getSelectedOptionValue={handleSelectYear}
            errors={{}}
          />
        </div>
      </div>
      <div className={"billing_stat_cards"}>
        <div className={"billing_stat_card total_revenue_card"}>
          <MoneyIcon />
          <div className={"billing_card_content"}>
            <span className={"billing_card_title"}>Total Revenue</span>
            <span className={"billing_small_texts"}>
              <ProfitIcon />{" "}
              {`${paymentSummary?.total?.percentageDecInc! < 0 ? "" : "+"}${
                paymentSummary?.total?.percentageDecInc ?? 0
              }`}
              % last month
            </span>
            <p className={"billing_total_amount"}>
              <span>₦</span>
              {convertToLocaleString(paymentSummary?.total?.revenue) || 0}
            </p>
          </div>
        </div>
        <div
          className={`billing_stat_card ${returnClassName(
            paymentSummary?.atrizultFeePrevious?.billingState
          )}`}
        >
          <MoneyIcon />
          <div className={"billing_card_content"}>
            <span className={"billing_card_title"}>Atrizult 5%</span>
            <p className={"billing_total_amount"}>
              <span>₦</span>
              {convertToLocaleString(
                paymentSummary?.atrizultFeePrevious?.totalAmount
              ) || 0}
            </p>
            <span className={"billing_small_texts"}>
              VAT (7.5%) = ₦
              {convertToLocaleString(
                paymentSummary?.atrizultFeePrevious?.vat
              ) || 0}
            </span>
            <span className={"billing_small_texts"}>
              Total = ₦{convertToLocaleString(totalBill)}
            </span>
            <span className={"billing_small_texts"}>
              (
              {convertToTitleCase(
                paymentSummary?.atrizultFeePrevious?.billingState
              ) || BillingState.TRIAL}
              ) &nbsp;
              {formatDate(paymentSummary?.atrizultFeePrevious?.deadline!) ||
                formatCurrentDate()}
            </span>
            {showPaymentButton(
              paymentSummary?.atrizultFeePrevious?.billingState
            ) && (
              <Button
                id={"billing_make_payment_btn"}
                buttonName={"Make payment"}
                onClick={() => setShowUpload(true)}
              />
            )}
          </div>
        </div>
        <div className={"billing_stat_card atrizult_bill_card_current"}>
          <MoneyIcon />
          <div className={"billing_card_content"}>
            <span className={"billing_card_title"}>Atrizult 5%</span>
            <p className={"billing_total_amount"}>
              <span>₦</span>
              {convertToLocaleString(
                paymentSummary?.atrizultFeeCurrent?.totalAmount
              ) || 0}
            </p>
            <span className={"billing_small_texts"}>
              VAT (7.5%) = ₦{paymentSummary?.atrizultFeeCurrent?.vat || 0}
            </span>
            <span className={"billing_small_texts"}>Current month's bill</span>
            <span className={"billing_small_texts"}>
              Next Due Date{" "}
              {formatDate(paymentSummary?.atrizultFeeCurrent?.nextDueDate!)}
            </span>
          </div>
        </div>
        <div className={"billing_drop_downs second_drop_down"}>
          <Dropdown
            name={""}
            onChange={handleChangeMonth}
            options={currentMonths}
            placeholder={`${selectedMY?.month} ${selectedMY?.year}`}
            label={`Billing Period: ${selectedMY?.month} ${selectedMY?.year}`}
            getSelectedOptionValue={handleSelectMonth}
            errors={{}}
          />
        </div>
      </div>
      <div className={"billing_blue_bar"}></div>
      <Listing
        items={restructuredBillsArr(bills)}
        withAction
        actions={popUpTexts}
        getAction={handleGetClickedAction}
        getClickedItem={getClickedRow}
      />
      <Receipt
        showReceipt={showReceipt}
        paymentMode={clickedRow?.["mode of payment"]! || ""}
        atrizultBill={clickedRow?.["₦ atrizult bill"]! || ""}
        vat={
          Number(clickedRow?.["₦ atrizult bill"]!?.split(",")?.join("")) *
            0.075 || 0
        }
        closeReceipt={handleCloseReceipt}
        currentAction={clickedAction}
        currentYear={selectedYear}
        currentMonth={Number((clickedRow?.["due date"] as string)?.[2]) - 1}
        balance={0}
      />
      {!!error && (
        <div className={"billing_error_notification"}>
          <ErrorNotification name={"invoice_id"} stringErrors={error} />
        </div>
      )}
      <PaymentUpload
        submitReceipt={handleSubmitPayment}
        show={showUpload}
        duePayment={`₦${
          convertToLocaleString(
            (paymentSummary?.atrizultFeePrevious?.totalAmount as number) +
              (paymentSummary?.atrizultFeePrevious?.vat as number)
          ) || 0
        }`}
        dueDate={formatDate(paymentSummary?.atrizultFeePrevious?.deadline!)}
        paymentDate={paymentDate}
        onChangeFile={handleSelectFile}
        closeUpload={handleCloseUpload}
        selectedFile={receipt}
        removeFile={() => setReceipt(null)}
        onChangeDate={handleChangePaymentDate}
        successfulUpload={uploadSuccess}
        loading={loading}
      />
      {loading && !showUpload && <Spinner />}
    </div>
  );
};

export default Billing;
