import CloseIcon from "../../../resources/assets/svg/Dashboard/CloseIcon";
import { Button } from "../../button/Button";
import "./ConfirmationPopup.scss";
interface ConfirmationPopupProps {
  closeModal: () => void;
  onClickYes: () => void;
  show: boolean;
}

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
  closeModal,
  show,
  onClickYes,
}) => {
  return (
    <>
      {show && (
        <div className="overview_container confirmation_popup_wrapper">
          <span>Are you sure you want to save this ? </span>
          <div className="confirmation_popup_btns">
            <Button id={""} buttonName={"No"} onClick={closeModal} />
            <Button
              id={"confirmation_popup_yes_btn"}
              buttonName={"Yes"}
              onClick={onClickYes}
            />
          </div>
          <span className="confirmation_popup_warning">
            Counter Sales CANNOT be undone
          </span>
          <i className="sales_overview_close_icon" onClick={closeModal}>
            <CloseIcon />
          </i>
        </div>
      )}
    </>
  );
};

export default ConfirmationPopup;
