import "./InvoiceSummary.scss";
import React, {useState} from "react";
import InvoiceDao from "../../types/invoice/InvoiceDao";
import invoiceService from "../../services/InvoiceService";
import NewInvoiceReqData from "../../types/invoice/NewInvoiceReqData";
import {DialogPageType} from "../../pages/customer/newpatient/Patient";
import {InvoiceSummaryComponent} from "./InvoiceSummaryComponent";
import {getTodaySDate} from "../../util/Helpers";
import {Invoice} from "../invoice/Invoice";
import {Spinner} from "../../components/spinner/Spinner";
import {BsCheckCircle} from "react-icons/bs";
import {Button} from "../../components/button/Button";
import {Modal} from "../../components/modal/Modal";
import {ParamsEnum} from "../../util/ParamsEnum";
import PatientDao, {Patient} from "../../types/PatientDao";
import {useLocation} from "react-router-dom";
import PrintingModule from "../../components/printingModule/PrintingModule";
import {InputField} from "../../components/inputfield/InputField";
import {useForm} from "react-hook-form";
import {SendInvoiceDto} from "../../types/invoice/SendInvoiceDto";

interface InvoiceSummaryProps {
    back?(): NewInvoiceReqData;

    next(): NewInvoiceReqData | InvoiceDao;
}

export const InvoiceSummary = (props: InvoiceSummaryProps) => {
    const location = useLocation();
    const {email} = location?.state?.patient || {} as PatientDao;
    const [newInvoice, setNewInvoice] = useState<NewInvoiceReqData>(props.next() as NewInvoiceReqData);
    const [savedInvoice, setSavedInvoice] = useState<InvoiceDao>(
        props.next() as InvoiceDao
    ); // saves response from BE
    const [modalAction, setModalAction] = useState<DialogPageType>("SummaryModal");
    const [summaryError, setSummaryError] = useState<string>("");
    const [activePage, setActivePage] = useState("Summary");
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(true);
    const [emailAddress, setEmailAddress] = useState(email)
    const {register, formState: {errors}} = useForm({mode: 'onBlur'})

    //TODO: refactor with navigate
    const backToInvoiceBtnHandler = (path?: string) => {
        setLoading(true);

        /*if (path) {
            navigate(path, {state: {newInvoice: newInvoice}});
            return;
        }*/
        setModalAction("");
        setActivePage("Invoice");
        setLoading(false);
    };

    const updateInvoiceAfterPrintHandler = async () => {
        // TODO: hide the buttons via css
        // window.print();

        // TODO: change the if (env.INVOICE_PRINTED === 'Printed') presently in index.html
        const updateSavedInvoice = {...savedInvoice};
        updateSavedInvoice.printed = true;
        updateSavedInvoice.lastPrintedDate = getTodaySDate();

        setSavedInvoice((prevState) => ({
            ...prevState,
            ...updateSavedInvoice,
        }));

        setLoading(true);

        invoiceService
            .updateInvoice(updateSavedInvoice)
            .then(() => {
                //setModalAction("Send To Email");
                setLoading(false);
            })
            .catch(() => {
                setSummaryError("Something went wrong updating invoice");
                setLoading(false);
            });
    };

    const saveHandler = () => {
        setLoading(true);

        // amount paid in full
        const updateNewInvoice = {...newInvoice};
        if (newInvoice.paymentStatus === "Paid") {
            updateNewInvoice.amountPaid = newInvoice.total;
            setNewInvoice((prevState) => ({
                ...prevState,
                updateNewInvoice,
            }));
        }

        invoiceService.createNewInvoice(updateNewInvoice)
            .then((response: InvoiceDao) => {
                setSavedInvoice((prevState) => ({
                    ...prevState,
                    ...response,
                }));
                setModalAction("Saved");
                setLoading(false);
            })
            .catch(() => {
                setSummaryError("Something went wrong while creating new invoice");
                setLoading(false);
            });

    };

    const sendInvoiceViaEmailHandler = () => {
        const updateSavedInvoice = {...savedInvoice};
        updateSavedInvoice.sendEmail = true;

        setSavedInvoice((prevState) => ({
            ...prevState,
            updateSavedInvoice,
        }));

        setLoading(true);

        const sendInvoiceDto: SendInvoiceDto = {
            invoiceNumber: updateSavedInvoice.invoiceNumber,
            patientId: updateSavedInvoice.customerId,
            hospitalId: updateSavedInvoice.companyId,
            patientEmail: emailAddress
        }

        invoiceService.sendInvoiceToCustomerEmail(sendInvoiceDto)
            .then((resp: boolean) => {
                resp && setModalAction("Email Sent");
                resp && setLoading(false);
            })
            .catch(() => {
                setSummaryError("Something went wrong sending invoice email");
                setLoading(false);
            });
    };

    const onCloseHandler = () => {
        //setActivePage('Invoice')
        window.location.href = ParamsEnum.PAYMENTS;
    }

    const backFromSummaryPageHandler = (): NewInvoiceReqData => {
        setNewInvoice(newInvoice);
        return newInvoice;
    };

    return (
        <>
            {!loading && (
                <>
                    {activePage === "Summary" && (
                        <div className={"invoice-summary-component"}>
                            {modalAction === 'SummaryModal' &&
                                <Modal
                                    isOpen={openModal}
                                    onClose={() => onCloseHandler()}
                                    modalTitle={'New Invoice Summary'}
                                    onAction={() => setActivePage('invoice')}
                                >
                                    {newInvoice && <InvoiceSummaryComponent newInvoice={newInvoice}/>}

                                    <div className={'invoice-summary-btn-wrapper'}>
                                        <Button id={'back-to-invoice'}
                                                buttonName={'Back To Invoice'}
                                                onClick={() => backToInvoiceBtnHandler(ParamsEnum.PAYMENTS)}
                                        />
                                        <Button id={'Save-new-invoice'}
                                                buttonName={'Save'}
                                                onClick={saveHandler}
                                        />
                                    </div>
                                </Modal>
                            }

                            {/*{modalAction === "Saved" && (
                                <Modal
                                    isOpen={openModal}
                                    onClose={() => onCloseHandler()}
                                    modalTitle={'Send via Email or Print Invoice Copy'}
                                    onAction={() => console}
                                >
                                    <InvoiceSummaryComponent savedInvoice={savedInvoice} modalAction={modalAction}/>

                                    <div className={'invoice-summary-btn-wrapper'}>
                                        <Button id={'send-invoice-via-email'} buttonName={'Send To Email'}
                                                onClick={() => sendInvoiceViaEmailHandler()}
                                        />
                                        <Button id={'print-saved-invoice'} buttonName={modalAction}
                                                onClick={() => setModalAction('Print')}
                                        />
                                    </div>
                                </Modal>
                            )}*/}

                            {modalAction === "Saved" && (
                                <PrintingModule>
                                    <InvoiceSummaryComponent savedInvoice={savedInvoice} modalAction={modalAction}/>

                                    <Button id={'print-saved-invoice'} buttonName={'Print'}
                                            onClick={() => {
                                                window.print()
                                                setModalAction('Send To Email')
                                            }}
                                    />
                                </PrintingModule>
                            )}

                            <div className={'invoice-summary-send-to-email'}>
                                {modalAction === "Send To Email" && (
                                    <Modal
                                        isOpen={openModal}
                                        modalTitle={'Send Invoice to Patient Email'}
                                        onClose={() => onCloseHandler()}
                                        onAction={() => console}
                                    >
                                        <InputField
                                            id={'sendEmail'}
                                            name={'sendEmail'}
                                            register={register}
                                            placeholder={emailAddress}
                                            defaultValue={emailAddress}
                                            label={'Email where the Invoice is to be sent'}
                                            required={'Email is required'}
                                            errors={errors}
                                            onChange={(evt: any) => {
                                                setEmailAddress(evt.target.value)
                                            }}
                                            disabled={!!email}
                                        />

                                        <div className={'invoice-summary-btn-wrapper'}>
                                            <Button id={'view-payment'} buttonName={'To Payment'}
                                                    onClick={() => window.location.href = ParamsEnum.PAYMENTS}
                                            />
                                            <Button id={'to-patient-profile'} buttonName={'Send Invoice'}
                                                    onClick={sendInvoiceViaEmailHandler}
                                            />
                                        </div>
                                    </Modal>
                                )}
                            </div>

                            {modalAction === "Email Sent" && (
                                <Modal
                                    isOpen={openModal}
                                    modalTitle={'Email Sent !'}
                                    onClose={() => onCloseHandler()}
                                    onAction={() => console}
                                >
                                    <div style={{paddingBottom: "20px"}}>
                                        <p>
                                            <BsCheckCircle className={"modal-icon-wrapper"}/>
                                        </p>
                                    </div>
                                    <p>Invoice has been sent to your email</p>
                                    <h3
                                        style={{
                                            paddingTop: "20px",
                                            fontSize: "25px",
                                            color: "green",
                                        }}
                                    >
                                        Successfully
                                    </h3>

                                    <div className={'invoice-summary-btn-wrapper'}>
                                        <Button id={'view-payment'} buttonName={'View Payment'}
                                                onClick={() => window.location.href = ParamsEnum.PAYMENTS}
                                        />
                                        <Button id={'to-patient-profile'} buttonName={'Patient Profile'}
                                                onClick={() => window.location.href = ParamsEnum.PATIENT_PROFILE}
                                        />
                                    </div>
                                </Modal>
                            )}
                        </div>
                    )}
                    {activePage === "Invoice" && (
                        <div>
                            <Invoice backFromSummary={backFromSummaryPageHandler}/>
                        </div>
                    )}
                </>
            )}
            {loading && <Spinner/>}
        </>
    );
};
