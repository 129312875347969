import { PatientCategory } from "../types/admission/PatientCategory";
import { AdmissionType } from "../types/admission/AdmissionType";
import { jwtDecode } from "jwt-decode";
import { ParamsEnum } from "./ParamsEnum";
import WhoAmI from "../userAuth/WhoAmI";
import { BillingState } from "../types/billing/PaymentSummaryDTO";
import {
  OfferedServiceDto,
  ServiceListDTO,
} from "../types/services/ServiceTypes";
import { Dropdown } from "../components/dropdown/Dropdown";
import { DepartmentDao } from "../types/department/DepartmentDao";
import { DashboardOption } from "../context/DashboardCtx";
import InvoiceDao from "../types/invoice/InvoiceDao";
import { SalesTableDTO } from "../types/Dashboard/DashboardModels";
import { formatNumberWithCommas } from "./numberFormatter";
import { UserDto } from "../types/UserDto";
import AdmissionDao from "../types/admission/AdmissionDao";

/**
 * Unprotected paths accessible be everyone
 */

export const userIsLogged = (): boolean => {
  const activeUser = getLoggedInUser();
  return activeUser !== undefined && activeUser?.userId! > 100000;
};

export const getLoggedInUser = (): WhoAmI | null => {
  let accessToken = getCookie(ParamsEnum.ACCESS_TOKEN) ?? "";

  if (isEmpty(accessToken) || !accessToken) {
    //accessToken = localStorage.getItem(ParamsEnum.ACCESS_TOKEN) ?? '';
    accessToken = sessionStorage.getItem(ParamsEnum.ACCESS_TOKEN) ?? "";
    if (isEmpty(accessToken) || !accessToken) {
      return null;
    }
  }

  /** Ensure access token is a valid jwt token */
  const isValidTokenFormat = accessToken && accessToken.split(".").length === 3;
  if (!isValidTokenFormat) {
    return null;
  }

  /** Validates the authToken cookie in BE */
  fetch(
    `${process.env.AUTH_PROXY ?? process.env.REACT_APP_AUTH_PROXY}` +
      "login/validate",
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response: Response) => response.json())
    .then((data: { validate: boolean }) => {
      if (!data.validate) {
        accessToken = "";
        sessionStorage.removeItem(ParamsEnum.ACCESS_TOKEN);
        deleteCookie(ParamsEnum.ACCESS_TOKEN);
        window.location.href = ParamsEnum.LOGIN;
        //TODO: use refreshToken (if valid -> to be checked in BE) to renew the token instead of redirecting to login and save it back to cookies and localstorage
        return;
      }

      if (data.validate) {
        sessionStorage.setItem(ParamsEnum.ACCESS_TOKEN, accessToken);
        setCookie(ParamsEnum.ACCESS_TOKEN, accessToken);
      }
    })
    .catch((e) => {
      sessionStorage.removeItem(ParamsEnum.ACCESS_TOKEN);
      deleteCookie(ParamsEnum.ACCESS_TOKEN);
      window.location.href = ParamsEnum.LOGIN;
    });

  let decodeAuthUser = jwtDecode(accessToken) as WhoAmI;

  return {
    userId: decodeAuthUser.userId,
    hospital: decodeAuthUser.hospital,
    username: decodeAuthUser.username,
    firstname: decodeAuthUser.firstname,
    lastname: decodeAuthUser.lastname,
    email: decodeAuthUser.email,
    department: decodeAuthUser.department,
    lastLogin: decodeAuthUser.lastLogin,
    authorities: decodeAuthUser.authorities,
    sub: decodeAuthUser.sub,
    exp: decodeAuthUser.exp,
    iat: decodeAuthUser.iat,
  };
};

/**
 * @param name cookie name to search for
 * @return the value of a given cookie name
 * */
export const getCookie = (name: string): string | null => {
  const cookies = document.cookie.split(";");
  for (const element of cookies) {
    const cookie = element.trim();
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};

const setCookie = (name: string, value: string) => {
  document.cookie = name + "=" + (value ?? "") + ";path=/";
};

export const deleteCookie = (name: string) => {
  document.cookie =
    // name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT" + ";path=/";
    `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
};

export const logout = () => {
  deleteCookie(ParamsEnum.ACCESS_TOKEN);
  sessionStorage.removeItem(ParamsEnum.ACCESS_TOKEN);
  window.location.href = ParamsEnum.HOME;
};

export const isEmpty = (str: string): boolean => {
  return !str || str.trim() === "" || str === "" || str.length === 0;
};

// Breaks numbers into 3's, e.g 1, 234, 567
export const convertToLocaleString = (
  num: string | number | undefined
): string => {
  if (!num) {
    return "";
  }
  return num.toLocaleString();
};

export const capitalizeFirstCharacter = (str: string) => {
  if (!str || isEmpty(str)) {
    return "";
  }
  return str && str[0].toUpperCase() + str.slice(1);
};

/** Returns date in format "dd-MM-yyyy */
export const getDate = (): string => {
  const currentDate = new Date();
  const day =
    currentDate.getDate() < 10
      ? `0${currentDate.getDate()}`
      : currentDate.getDate();
  const month =
    currentDate.getMonth() < 10
      ? `0${currentDate.getMonth() + 1}`
      : currentDate.getMonth() + 1; // January is 0, so we add 1
  const year = currentDate.getFullYear();
  return `${day}-${month}-${year}`;
};

/** returns time in format HH:mm:ss */
export const getTime = (): string => {
  const date = new Date();
  return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
};

export const formatZoneDate = (zoneDateTime: string): string => {
  if (!zoneDateTime) {
    return "";
  }
  return zoneDateTime.split("T")[0];
};

export const calculateAge = (dob: string) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const birthYear = Number(dob.split("-")[2]);
  const res = currentYear - birthYear;
  return res < 1 ? res * -1 : res;
};

/**
 * formats date to dd-MM-yyyy pattern
 * @param date comes in format yyyy-mm-dd
 * @return date in format dd-mm-yyyy
 * */
export const dateFormatDDMMYYYY = (date: string): string => {
  if (!date) {
    return "";
  }
  const splitDate = date.split("-");
  const day = splitDate[2];
  const month = splitDate[1];
  const year = splitDate[0];
  return `${day}-${month}-${year}`;
};

/**
 * formats date to dd-MM-yyyy pattern
 * @param date comes in format[yyyy, mm, dd]
 * @return date in format dd-mm-yyyy
 * */
export const dateFormatArrDDMMYYYY = (date: string | string[]): string => {
  if (Array.isArray(date) && date.length > 0) {
    return `${date[2]}-${date[1]}-${date[0]}`;
  }

  let splitDate;
  if (typeof date === "string") {
    splitDate = date.split("-");
  }

  if (!splitDate) {
    return "";
  }

  const day = splitDate[2];
  const month = splitDate[1];
  const year = splitDate[0];
  return `${day}-${month}-${year}`;
};

export const getTodaySDate = (): string => {
  const todaySDate = new Date();
  return (
    todaySDate.getFullYear() +
    "-" +
    (todaySDate.getMonth() + 1) +
    "-" +
    todaySDate.getDate()
  );
};

/** Returns category of patient based on the admission status*/
export const getPatientCategory = (
  admissionType: AdmissionType
): PatientCategory => {
  switch (admissionType) {
    case "CLINIC":
      return PatientCategory.OUT_PATIENT;
    case "ADMITTED":
      return PatientCategory.IN_PATIENT;
    case "EMERGENCY":
      return PatientCategory.EMERGENCY;
    default:
      return PatientCategory.OUT_PATIENT;
  }
};

export const admissionStatusMapper = (admissionType: string): AdmissionType => {
  switch (admissionType) {
    case "clinic":
      return AdmissionType.CLINIC;
    case "admit":
      return AdmissionType.ADMITTED;
    case "emergency":
      return AdmissionType.EMERGENCY;
    default:
      return AdmissionType.CLINIC;
  }
};

/** converts blob to image using FileReader */
export const blobToBase64 = (blob: Blob): string => {
  /*return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result as string);
      } else {
        reject(new Error("Failed to convert blob to base64"));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });*/
  return URL.createObjectURL(blob);
};

/** converts byte array to image using createObjectURL and mimeType */
export const arrayBufferToBlobUrl = (
  buffer: ArrayBuffer,
  mimeType: string
): string => {
  const blob = new Blob([buffer], { type: mimeType });
  return URL.createObjectURL(blob);
};

export const showPaymentButton = (status?: BillingState | null) => {
  return (
    status &&
    status !== BillingState.TRIAL &&
    status !== BillingState.ON_GOING &&
    status !== BillingState.PROCESSING &&
    status !== BillingState.PAID
  );
};

export const returnClassName = (status?: BillingState | null) => {
  return status === BillingState.ON_GOING
    ? "atrizult_bill_card_inprogress"
    : status === BillingState.TRIAL || !status
    ? "atrizult_bill_card_grace"
    : status === BillingState.NOT_PAID
    ? "atrizult_bill_card_suspended"
    : status === BillingState.PAID
    ? "atrizult_bill_card_paid"
    : "atrizult_bill_card_default";
};

export function convertToTitleCase(text?: string | null) {
  if (!text) {
    return "";
  }
  const lowerCaseText = text.toLowerCase();
  const words = lowerCaseText.split("_");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  const titleCaseText = capitalizedWords.join(" ");
  return titleCaseText;
}

export const restructureServicesResponse = (
  arr: OfferedServiceDto[]
): ServiceListDTO[] => {
  if (!arr) {
    return [];
  }
  return arr.map((item) => ({
    name: item.serviceName,
    department: item.departmentName,
    category: item.categoryName,
    availableDays: (
      <Dropdown
        id="table_dropdown"
        name={""}
        onChange={() => {}}
        options={item.serviceTimes.openingTimes.map((item) => ({
          label: `${item.day}: ${item.startTime} to ${item.endTime}`,
          value: "",
        }))}
        placeholder={item?.serviceTimes?.frequency || ""}
        getSelectedOptionValue={() => {}}
        errors={{}}
      />
    ),
    unitPrice: item.unitPrice,
    quantity: item.quantity,
  }));
};

export const restructureDeptData = (
  arr: DepartmentDao[]
): Record<string, any>[] => {
  return arr.map((item) => ({
    name: item.name,
    unitHead: item.unitHead,
    categories: (
      <Dropdown
        id="table_dropdown"
        name={""}
        onChange={(e: any) => e}
        options={item.categories.map((item) => ({
          label: `${item.name}`,
          value: "",
        }))}
        placeholder={item.categories[0].name || ""}
        getSelectedOptionValue={(e: any) => e}
        errors={{}}
      />
    ),
    createdDate: dateFormatArrDDMMYYYY(item.createdDate),
    dateModified: dateFormatArrDDMMYYYY(item.dateModified),
    modifiedBy: item.modifiedBy,
  }));
};

export const restructureUsers = (users: UserDto[]): Record<string, any>[] => {
  return users.map((usr) => ({
    userId: usr.id,
    username: usr.username,
    name: `${usr.firstname} ${usr.lastname}`,
    dob: usr.dob,
    email: usr.email,
    active: usr.active ? 'Yes' : 'No',
    department: usr.department,
    phoneNumber: usr.phoneNumber,
    authorities: (
      <Dropdown
        id="table_dropdown"
        name={""}
        onChange={(e: any) => e}
        options={usr.authorities.map((item) => ({
          label: ``,
          value: item,
        }))}
        label={""}
        placeholder={usr.authorities[0] || ""}
        getSelectedOptionValue={(e: any) => e}
        errors={{}}
      />
    )
  }));
};

export const generateTimes = () => {
  const hour = Array.from({ length: 12 }, (_, i) =>
    i < 9 ? "0" + (i + 1) : i + 1
  );

  const minutes = Array.from({ length: 2 }, (_, i) =>
    i * 30 < 10 ? "0" + i * 30 : "" + i * 30
  );

  const times = [];
  for (let hr of hour) {
    for (let min of minutes) {
      times.push(`${hr}:${min}`);
    }
  }
  return times;
};

export const appointmentTimeToInstant = (
  year: number,
  hour: number,
  min: number
) => {
  const newDate = new Date(year, 0, 0, 0);
  // milliseconds since the UNIX epoch. Multiply by 1000 and you'll get the time you want.
  const timeStamp = newDate.setHours(hour, min);
  return new Date(timeStamp * 1000); // in BE Instant.ofEpochSecond(1437647493L)
};

export function isDateLessThanOrEqualToCurrent(inputDateValue: string) {
  const inputDate = new Date(inputDateValue);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  inputDate.setHours(0, 0, 0, 0);
  return inputDate <= currentDate;
}

/**
 * formats date to LocalDateTime format dd-MM-yyyy HH:mm:ss pattern
 * @param date comes in format Fri Jul 05 2024 04:42:59 GMT+0100 (West Africa Standard Time)
 * @return date in format dd-MM-yyyy HH:mm:ss
 * */
export function formatDateDDMMYYYY(date: Date, removeTime?: boolean): string {
  const year: number = date.getFullYear();
  const month: number = date.getMonth() + 1;
  const day: number = date.getDate();

  const hours: number = date.getHours();
  const minutes: number = date.getMinutes();
  const seconds: number = date.getSeconds();

  const formatDay = day >= 10 ? day : "0" + day;
  const formatMonth = month >= 10 ? month : "0" + month;
  const formatHours = hours >= 10 ? hours : "0" + hours;
  const formatMinutes = minutes >= 10 ? minutes : "0" + minutes;
  const formatSeconds = seconds >= 10 ? seconds : "0" + seconds;

  return removeTime
    ? `${formatDay}-${formatMonth}-${year}`
    : `${formatDay}-${formatMonth}-${year} ${formatHours}:${formatMinutes}:${formatSeconds}`;
}

const today: Date = new Date();
const yesterday: Date = new Date(today);
yesterday.setDate(today.getDate() - 1);
export const yesterdayAndToday = { yesterday, today };

export const saveToStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getFromStorage = (key: string) => {
  const itemFromStorage = localStorage.getItem(key);
  return itemFromStorage && JSON.parse(itemFromStorage);
};

export const handleFilterOptions = (options: DashboardOption[]) => {
  return options.filter((option: any) => option.enabled && option.visible);
};

export const returnLabels = (arr: DashboardOption[]) => {
  return arr.map((item) => item.label);
};

export function arraysEqual<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

export const salesTableData = (arr: InvoiceDao[]): SalesTableDTO[] => {
  if (!arr) return [];
  return arr.map((item) => ({
    invoice: item.invoiceNumber,
    salesTitle: item.selectedServices
      .map((service) => service.serviceName)
      .join(", "),
    salesDate: item.createdDate as string,
    patientName: `${item.customerId}`,
    phone: "",
    modeOfPayment: item.paymentMethod,
    cost: formatNumberWithCommas(item.amountPaid),
    balance: formatNumberWithCommas(item.balance),
  }));
};

export const removeCommasAndConvert = (str: string): number => {
  return Number(str.split(",").join(""));
};

export const isFileImage = (file: File) => file.type.includes("image");

export const joinTwoStrings = (str1: string, str2: string) => {
  return str1 && !str2
    ? str1
    : !str1 && str2
    ? str2
    : str1 && str2
    ? `${str1} ${str2}`
    : "";
};

export const isActivePage = (
  activeSideBar: string,
  currentLocation: string
) => {
  const splitText = currentLocation.split("/");
  const lowerCaseActiveSideBar = activeSideBar?.toLowerCase();
  return lowerCaseActiveSideBar === "dashboard"
    ? !splitText[2]
    : splitText[2] === lowerCaseActiveSideBar;
};


export const returnNextPaymentDate = (
  currentMonth: number,
  currentYear: number
) => {
  const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
  const nextYear = nextMonth === 0 ? currentYear + 1 : currentYear;
  const isLeapYear = nextYear % 4 === 0;
  const monthsWith30Days = [3, 5, 8, 10];
  const has30Days = monthsWith30Days.includes(nextMonth);
  return {
    day: nextMonth === 1 ? (isLeapYear ? 29 : 28) : has30Days ? 30 : 31,
    month: nextMonth,
    year: nextYear,
  };
}

export const returnAdmissionsQueueTableData = (data: AdmissionDao[]) => {
  return !data
    ? []
    : data.map((item) => ({
        "Patient ID": item.patientId,
        "Patient Name": item.patientId,
        "Doctor in Charge": item.doctorToSeePatient,
        "Purpose of Visit": item.purposeOfVisit,
        "Payment Status": item.paidAdmissionFee ? "Paid" : "Not Paid",
        "Admission State": item.admissionState,
        "Admitted Time": item.admissionTime,
      }));
};
