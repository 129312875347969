import {SlCalender} from "react-icons/sl";
import "./InputDate.scss";
import {
    MdArrowBackIos,
    MdArrowForwardIos,
    MdKeyboardArrowDown,
    MdKeyboardArrowUp,
} from "react-icons/md";
import {useEffect, useState, useRef} from "react";
import {
    FieldValues,
    UseFormRegister,
} from "react-hook-form";
import {CiCircleInfo} from "react-icons/ci";
import {IoMdClose} from "react-icons/io";
import {FaCheck} from "react-icons/fa";
import classNames from "classnames";
import {getAllMonths} from "../../../types/OldMedicalRecords";
import {getYearList} from "../../../util/UserRolesHelper";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import {InputProps} from "../../inputfield/InputField";
import {formatDateDDMMYYYY} from "../../../util/Helpers";

export interface InputDateProps extends InputProps {}

const yearList = getYearList();

const InputDate = (props: InputDateProps) => {
    const [month, setMonth] = useState<number>(new Date().getMonth());
    const [year, setYear] = useState<number>(new Date().getFullYear());
    const [show, setShow] = useState<boolean>(false);
    const [inputDate, setInputDate] = useState<string>(formatDateDDMMYYYY(new Date(),true)
    );
    const [touched, setTouched] = useState(false);
    const register = props.register as UseFormRegister<FieldValues>;
    const error = props.errors && props.errors[props.name as string];
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [showYear, setShowYear] = useState<boolean>(false);
    const [showMonth, setShowMonth] = useState<boolean>(false);
    const [dateClick, setDateClick] = useState<boolean>(false)
    //const currentYear = new Date().getFullYear();
    //const currentMonth = new Date().getMonth();
    const months: string[] = getAllMonths();

    const markTouched = () => {
        setTouched(true);
        if (props.onBlur) {
            props.onBlur();
        }
    };

    useEffect(() => {
        if (props.defaultValue) {
          setInputDate(String(props.defaultValue));
        }
      }, [props.defaultValue]);

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
                setShow(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef, inputDate]);

    const renderCalender = () => {
        const start = new Date(year, month, 1).getDay();
        const endDate = new Date(year, month + 1, 0).getDate();
        const end = new Date(year, month, endDate).getDay();
        const endDatePrev = new Date(year, month, 0).getDate();

        const prevDates = Array.from({length: start}, (_, index) => (
            <li key={`prev-${endDatePrev - start + index + 1}`} className="disable">
                {endDatePrev - start + index + 1}
            </li>
        ));

        const currentDates = Array.from({length: endDate}, (_, i) => {
            const day = i + 1;
            const isToday = day === new Date().getDate() && month === new Date().getMonth()
                && year === new Date().getFullYear();
            return (
                <li
                    key={day}
                    className={isToday ? "today" : ""}
                    onClick={() => handleDateClick(day)}
                >
                    {day}
                </li>
            );
        });

        const nextDates = Array.from({length: 6 - end}, (_, index) => (
            <li key={`next-${index + 1}`} className="disable">
                {index + 1}
            </li>
        ));
    

        return [...prevDates, ...currentDates, ...nextDates];
    };

    const handleDateClick = (day: number) => {
        const formattedDate = `${String(day).padStart(2, "0")}-${String(month + 1).padStart(2, "0")}-${year}`;
        markTouched();
        setInputDate(formattedDate);
        setShow(false);
        setDateClick(true)
        const event = {
            target: {value: formattedDate, name: props.name},
        };
        handleDateChange(event);
    };

    const handleNavClick = (increment: number) => {
        let newMonth = month + increment;
        let newYear = year;

        if (newMonth < 0) {
            newMonth = 11;
            newYear -= 1;
        } else if (newMonth > 11) {
            newMonth = 0;
            newYear += 1;
        }

        if (newYear < 1900) {
            newYear = 1900;
            newMonth = 0;
        } else if (newYear > year) {
            newYear = month;
            newMonth = 11;
        }

        setMonth(newMonth);
        setYear(newYear);
    };

    const handleYearIncrease = (e: any) => {
        setYear((prevYear) => {
            if (prevYear < year) {
                return year + 1;
            }
            return prevYear;
        });
    };

    const handleYearDecrease = (e: any) => {
        setYear((prevYear) => {
            if (prevYear > 1900) {
                return year - 1;
            }
            return prevYear;
        });
    };
    const handleDateChange = (e: any) => {
        const inputValue = e.target.value;
        setInputDate(inputValue);
        if (props.onChange) {
            props.onChange({...e, target: {...e.target, value: inputValue}});
        }
    };

    const handleYearSelected = (selectedYear: number) => {
        setYear(selectedYear);
        setShowYear(false);
    };

    const handleMonthSelected = (selectedMonth: number) => {
        setMonth(selectedMonth);
        setShowMonth(false);
    };

    return (
        <div className="date-field-container">
            <label htmlFor={props.id}>
                <strong>
                    {props.label}{" "}
                    {props.required && <sup style={{color: "#b24d4d"}}>*</sup>}
                </strong>
                {props.withHint && (
                    <span style={{paddingLeft: "5px"}} title={props.withHint}>
                        <CiCircleInfo/>
                    </span>
                )}
            </label>
            
            <div className="custom-date">
                <SlCalender
                    className="calender"
                    onClick={() => {
                        setShow(true);
                    }}
                    style={{fontSize: "1rem"}}
                />

                <input
                    className={classNames("date-input", {
                        "input-success": !error && touched,
                        "input-error": error && error.message,
                    })}
                    id={props.id}
                    placeholder={props.placeholder}
                    type={props?.type ? props.type : "text"}
                    disabled={props.disabled}
                    value={inputDate}
                    autoComplete="off"
                    onFocus={(e) => {
                        setTouched(false);
                        setShow(true);
                    }}
                    {...register(props.name, {
                        required: props.required,
                        pattern: props.pattern,
                        validate: (value) => props.validate && props.validate(value),
                        min: props.min,
                        max: props.max,
                        onChange: (e: any) => {
                            handleDateChange(e);
                        },
                        onBlur: () => {
                            markTouched();
                        },
                    })}
                    autoFocus={props.autoFocus}
                />
                
                <span className="icon-position">
						{(!error || !error.message) &&
							!dateClick &&
							(!show ? (
								<BiChevronDown color={"gray"} size={"1.5em"}  onClick={() => {
                                    setShow(!show);
                                }} />
							) : (
                                <BiChevronUp color={"gray"} size={"1.5em"}  onClick={() => {
                                    setShow(!show);
                                }} />
							))}
						{error && error.message && <IoMdClose color={"red"} size={"1em"}  />}
						{(!error || !error.message) && dateClick && (
							<FaCheck color={"#4198BE"} size={"1em"}  />
						)}
					</span>
                {show && (
                    <div
                        ref={dropdownRef}
                        className={`datepicker-calender`}
                        tabIndex={-3}
                        onMouseDown={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <div className="heading">
                            <div className="heading-up-down-flex">
                                <div className="year-month-container">
                                    <h3
                                        onClick={() => setShowMonth(true)}
                                        className="month-header"
                                    >{`${months[month]}`}</h3>
                                    <h3 className="year-header" onClick={() => setShowYear(true)}>
                                        {`${year}`}
                                    </h3>
                                </div>
                                <ul className={showYear ? "year" : "hide"}>
                                    {yearList.map((yr, index) => (
                                        <li key={index}
                                            onClick={() => handleYearSelected(yr)}>{yr}</li>
                                    ))}
                                </ul>
                                <ul className={showMonth ? "year" : "hide"}>
                                    {months.map((mnt, index) => (
                                        <li
                                            key={Math.random().toFixed(index + 5)}
                                            className={`year-list ${
                                                index < month && year === year
                                                    ? "disable"
                                                    : ""
                                            }`}
                                            onClick={() => {
                                                if (!(year === year && index < month)) {
                                                    handleMonthSelected(index);
                                                }
                                            }}
                                        >
                                            {mnt}
                                        </li>
                                    ))}
                                </ul>
                                <button
                                    className="month-change"
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <MdKeyboardArrowUp
                                        onClick={(e) => handleYearIncrease(e)}
                                        style={{fontSize: "1rem"}}
                                    />
                                </button>
                                <button
                                    className="month-change"
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <MdKeyboardArrowDown
                                        onClick={(e) => handleYearDecrease(e)}
                                        style={{fontSize: "1rem"}}
                                    />
                                </button>
                            </div>
                            <div>
                                <button
                                    className="month-change goto-prev-month"
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <MdArrowBackIos
                                        onClick={() => handleNavClick(-1)}
                                        style={{fontSize: "1rem"}}
                                    />
                                </button>
                                <button
                                    className="month-change goto-next-month"
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <MdArrowForwardIos
                                        onClick={() => handleNavClick(1)}
                                        style={{fontSize: "1rem"}}
                                    />
                                </button>
                            </div>
                        </div>
                        <div>
                            <ul className="days">
                                <li>Sun</li>
                                <li>Mon</li>
                                <li>Tue</li>
                                <li>Wed</li>
                                <li>Thu</li>
                                <li>Fri</li>
                                <li>Sat</li>
                            </ul>
                            <ul className="dates">{renderCalender()}</ul>
                        </div>
                    </div>
                )}
            </div>
            {error?.message && (
                <label style={{lineHeight: 1}}>
                    <strong className={"input-error"}>
                        {error?.message as unknown as string}
                    </strong>
                </label>
            )}
        </div>
    );
};

export default InputDate;
