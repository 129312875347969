import React, { useEffect, useState } from "react";
import DaysCheckbox from "../DaysCheckbox/DaysCheckbox";
import RadioGroup from "../RadioGroup/RadioGroup";
import "./ServiceFrequency.scss";
import TimePicker from "../TimePicker/TimePicker";
import {
  DayTimesProps,
  DaysCheckBoxTypes,
  ServiceDays,
  TimeTypes,
} from "../../types/services/ServiceTypes";
import { days } from "../DaysCheckbox/data";

export type ServiceFrequencyProps = {
  getFrequency(value: string): any;
  getDaysAndTimes?: (params: DayTimesProps[]) => void;
  defaultFrequency?: string;
  defaultDaysAndTimes?: DayTimesProps[];
};

const frequencyOptions = [
  {
    label: "Daily",
    value: "DAILY",
    checked: false,
  },
  {
    label: "Weekly",
    value: "WEEKLY",
    checked: false,
  },
  {
    label: "Biweekly",
    value: "BI_WEEKLY",
    checked: false,
  },
  {
    label: "Monthly",
    value: "MONTHLY",
    checked: false,
  },
];

const ServiceFrequency = (props: ServiceFrequencyProps) => {
  const [options, setOptions] = useState([...frequencyOptions]);
  const [daysAndTimes, setDaysAndTimes] = useState<DayTimesProps[]>([]);
  const [allDays, setAllDays] = useState<DaysCheckBoxTypes[]>([...days]);

  const checkedDays = [...allDays].filter((day) =>
    props.defaultDaysAndTimes?.find((item) => item.day === day.value)
  );

  const handleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name } = event.target;
    const copyArr = [...allDays];
    const requiredDay = { ...copyArr[index] };
    requiredDay.checked = !requiredDay.checked;
    copyArr[index] = requiredDay;
    setAllDays(copyArr);
    const found = !!daysAndTimes?.find((item) => item.day === name);
    setDaysAndTimes((prevTimes: any) =>
      found
        ? prevTimes.filter((item: { day: string }) => item.day !== name)
        : [...prevTimes, { day: name, startTime: "", endTime: "" }]
    );
  };

  const handleSelectTimes = (selectedTime: TimeTypes, day: string) => {
    const copyArr = [...daysAndTimes];
    const foundIndex = copyArr.findIndex((item) => item.day === day);
    setDaysAndTimes((prevTimes: any) =>
      foundIndex > -1
        ? [...prevTimes].map((time, index) =>
            index === foundIndex ? { day, ...selectedTime } : time
          )
        : [...prevTimes, { day, ...selectedTime }]
    );
  };

  const handleSelectFrequency = (value: string) => {
    props.getFrequency(value);
    setOptions((prevOptions) =>
      [...prevOptions].map((item) =>
        item.value === (props.defaultFrequency || value)
          ? { ...item, checked: !item.checked }
          : { ...item, checked: false }
      )
    );
  };

  const returnDefaultTimes = (value: ServiceDays): TimeTypes => {
    const found = props.defaultDaysAndTimes?.find((item) => item.day === value);
    return { startTime: found?.startTime, endTime: found?.endTime };
  };

  useEffect(() => {
    props.getDaysAndTimes!(daysAndTimes);
  }, [daysAndTimes]);

  useEffect(() => {
    props?.defaultDaysAndTimes &&
      setDaysAndTimes(props?.defaultDaysAndTimes! || []);
    const isFound = (val: ServiceDays) => {
      return !!props.defaultDaysAndTimes?.find((item) => item.day === val);
    };
    const defaultDays = [...allDays].map((day) =>
      isFound(day.value) ? { ...day, checked: true } : day
    );
    setAllDays(defaultDays);
  }, []);

  return (
    <div className="service-frequency">
      <p className="header">Select Service Frequency</p>
      <RadioGroup
        defaultValue={props.defaultFrequency}
        getSelectedValue={handleSelectFrequency}
        name="radio"
        options={options}
      />
      <p className="header">Select Days</p>
      <DaysCheckbox
        handleCheck={handleCheck}
        allDays={allDays}
        className="days-checkbox"
      />
      <p className="header">Select Time</p>
      <div className="days_times">
        {checkedDays?.map((day) => (
          <div key={day.value} className="day_time">
            <span>{day.label}</span>
            <TimePicker
              className="time-picker-edit"
              defaultTime={returnDefaultTimes(day.value)}
              getTime={(times: TimeTypes) =>
                handleSelectTimes(times, day.value)
              }
            />
			
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceFrequency;
