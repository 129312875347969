import WhoAmI, {Authorities} from "../userAuth/WhoAmI";
import {UserDto} from "../types/UserDto";
import {DropdownOptionsType} from "../components/dropdown/Dropdown";

/** Given a valid user and list of allowed role(s)
 * @param user logged in user
 * @param allowedRoles list of roles allowed access
 * @return true if given role(s) contains at least one allowed role otherwise false
 * */
export const userHasRole = (user: WhoAmI, allowedRoles: string[]): boolean => {
    if (!user || user?.authorities?.length < 1 || allowedRoles.length < 1 || Object.keys(user.authorities).length === 0) {
        return false;
    }

    const hasRole = user?.authorities?.filter((auth: string) => allowedRoles.includes(auth))
        .map((auth: string) => auth) ?? [];
    return hasRole && hasRole.length > 0;
}

/** Gets all users that has doctor role */
export const getAllDoctors = (users: UserDto[]): UserDto[] => {
    if(!users || users.length < 1) {
        return [];
    }
    return users?.filter((user) => user.authorities.includes(Authorities.DOCTOR));
}

export const userIsSuperAdmin = (user: WhoAmI): boolean => {
    return user?.authorities?.includes(Authorities.SUPER_ADMIN);
}

export const userIsAdmin = (user: WhoAmI): boolean => {
    return user?.authorities?.includes(Authorities.ADMIN);
}

export const userIsDoctor = (user: WhoAmI): boolean => {
    return user?.authorities?.includes(Authorities.DOCTOR);
}

export const userIsNurse = (user: WhoAmI): boolean => {
    return user?.authorities?.includes(Authorities.NURSE);
}

export const userIsCashier = (user: WhoAmI): boolean => {
    return user?.authorities?.includes(Authorities.CASHIER);
}

export const userIsReceptionist = (user: WhoAmI): boolean => {
    return user?.authorities?.includes(Authorities.RECEPTIONIST);
}

export const allUsersOption = (users: UserDto[]): DropdownOptionsType[] => {
    if(!users || users.length < 1) {
        return []
    }

    const usersOptionArr: DropdownOptionsType[] = [];
    users.forEach((user) => {
        usersOptionArr.push({value: user.firstname + ' ' + user.lastname, label: user.firstname + ' ' + user.lastname})
    });

    return usersOptionArr;
}

export const getAllHODs = (users: UserDto[]): DropdownOptionsType[] => {
    if(!users || users.length < 1) {
        return [];
    }

    const allHODs = users && users.length > 0 && users?.filter((user) => user.authorities.includes(Authorities.HOD));

    const hodsOptionArr: DropdownOptionsType[] = [];
    allHODs && allHODs.length > 0 && allHODs.forEach((user) => {
        hodsOptionArr.push({value: user.firstname + ' ' + user.lastname, label: user.firstname + ' ' + user.lastname})
    });

    return hodsOptionArr;
}

export const getYearList = (): number[] => {
    const startYear: number = 1900;
    const endYear: number = new Date().getFullYear();
    return Array.from({length: endYear - startYear + 1}, (v, i) => endYear - i);
}
