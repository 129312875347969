import React, { useEffect, useState } from "react";
import { TimeTypes } from "../../types/services/ServiceTypes";
import InputTime from "../Date-Time/inputTime/InputTime";
import { useForm } from "react-hook-form";

const TimePicker = (props: {
  getTime: (times: TimeTypes) => void;
  className?: string;
  defaultTime?: TimeTypes;
}) => {
  const [times, setTimes] = useState<TimeTypes>({
    startTime: "",
    endTime: "",
  });

  const {
    register,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  useEffect(()=>{
    props.getTime( times);
  },[times])
  
  const handleChangeTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTimes((prevTimes) => ({ ...prevTimes, [name]: value }));
  };

  return (
    <div className={props.className && props.className}>
      <InputTime
        id="startTime"
        name="startTime"
        placeholder="00:00AM"
        onChange={handleChangeTime}
        register={register}
        defaultValue={`${props.defaultTime?.startTime}`}
        errors={errors}
        pattern={{
          value: /^(0[1-9]|1[0-2]):[0-5][0-9]\s?(AM|PM|am|pm)$/,
          message: "Time format allowed is hh:mm AM/PM e.g: 09:00 AM",
      }}
      />
      to{" "}
       <InputTime
          id="endTime"
          name="endTime"
          placeholder="00:00AM"
          onChange={handleChangeTime}
          register={register}
          defaultValue={`${props.defaultTime?.startTime}`}
          errors={errors}
          pattern={{
            value: /^(0[1-9]|1[0-2]):[0-5][0-9]\s?(AM|PM|am|pm)$/,
            message: "Time format allowed is hh:mm AM/PM e.g: 09:00 AM",
          }}
      />
    </div>
  );
};

export default TimePicker;
